<app-common-modal-form
  [header]="'Queue Organisation for Processing'"
  [icon]="'icon ic-external-link'"
  [confirmText]="'Publish'"
  [closeText]="'Cancel'"
  [isInvalid]="!orgId"
  (close)="close()"
  (confirm)="publish()"
>
  <div class="d-flex flex-column gap-24">
    <div class="form-group">
      <app-select 
        [label]="'Organisation'"
        [placeholder]="'Select Organisation'"
        [valuePrimitive]="true"
        [options]="organisations"
        [(ngModel)]="orgId"
        [ngModelOptions]="{standalone: true}">
      </app-select>
      <app-select 
        [label]="'Mailbox'"
        [placeholder]="'Select Mailbox'"
        [valuePrimitive]="true"
        [options]="mailboxes"
        [(ngModel)]="useSecondary"
        [ngModelOptions]="{standalone: true}">
      </app-select>
    </div>
  </div>
</app-common-modal-form>
