export class Address {
  normalizedFullAddress: string;
  careOf: string;
  street: string;
  line2: string;
  suburb: string;
  state: string;
  postcode: string;
  country: string;
  gnafID: string;
  meshBlock: string;
  level: string;
  buildingName: string;
  region: string;

  constructor(data: Partial<Address> = {}) {
    this.normalizedFullAddress = Address.patchNormalizedFullAddress(data.normalizedFullAddress);
    this.careOf = data.careOf ?? '';
    this.street = data.street ?? '';
    this.line2 = data.line2 ?? '';
    this.suburb = data.suburb ?? '';
    this.state = data.state ?? '';
    this.postcode = data.postcode ?? '';
    this.country = data.country ?? '';
    this.gnafID = data.gnafID ?? '';
    this.meshBlock = data.meshBlock ?? '';
    this.level = data.level ?? '';
    this.buildingName = data.buildingName ?? '';
    this.region = data.region ?? '';
  }

  isEqual(address: Address): boolean {
    if (this.isEmpty() || address.isEmpty()) return false;

    const exceptProps: (keyof Address)[] = ['normalizedFullAddress'];

    return Object.entries(this).every(([key, value]) => {
      if (exceptProps.includes(key as keyof Address)) return true;
      return value === address[key];
    });
  }

  isEmpty(exceptKeys: (keyof Address)[] = []): boolean {
    return Object.entries(this)
      .filter(([key, _]) => !(exceptKeys as string[]).includes(key))
      .every(([_, value]) => !value);
  }

  static patchNormalizedFullAddress(normalizedFullAddress?: string): string {
    if (!normalizedFullAddress || (normalizedFullAddress && normalizedFullAddress.trim() === ','))
      return '';
    return normalizedFullAddress;
  }

  static buildNormalizedFullAddress(address: Address): string {
    // BE string $"{(string.IsNullOrEmpty(Line2) ? "" : $"{Line2} ")}{Street}, {Suburb} {State} {Postcode}";
    return `${ address.careOf ?? '' } ${ address.line2 ?? '' } ${ address.street ?? '' }, ${ address.suburb ?? '' } ${ address.region ?? address.state ?? '' } ${ address.postcode ?? '' }`
      .trim() // remove spaces by sides
      .replace(/\s+/g, ' '); // replace multiple spaces in string with one space
  }
}
