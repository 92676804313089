import { Component, DestroyRef, inject } from '@angular/core';
import { ICellRendererParams } from "ag-grid-community";
import { BulkSignatoryGroup } from "../../../../../../../models/signDocuments";
import { DocumentFileInfoShort } from '../../../../../../../models/files';
import { FilesService } from '../../../../../../../services/files.service';
import { DocumentSubFolderName } from '../../../../../../../models/enums/documentFolderNameEnum';
import { catchError, of } from 'rxjs';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { downloadBase64File } from '../../../../../../../functions/download-base64-file';
import { ToastrService } from 'ngx-toastr';

type CellRendererParams = ICellRendererParams<BulkSignatoryGroup>

@Component({
  selector: 'app-ag-documents-for-sign',
  standalone: true,
  imports: [],
  templateUrl: './ag-documents-for-sign.component.html',
  styleUrl: './ag-documents-for-sign.component.scss'
})

export class AgDocumentsForSignComponent {
  protected params!: CellRendererParams;
  protected filesService = inject(FilesService);
  protected toastr = inject(ToastrService);
  destroyRef: DestroyRef = inject(DestroyRef);

  protected documentsForSign: DocumentFileInfoShort[] = [];

  agInit(params: CellRendererParams): void {
    this.params = params;
    this.documentsForSign = params.data?.filesInfo ?? [];
    this.setRowHeight();
  }

  refresh(params: CellRendererParams): boolean {
    this.params = params;
    return true;
  }

  setRowHeight(): void {
    const rowHeight = 28;

    if (this.documentsForSign.length > 1) {
      const documentsForSignRowHeight = this.documentsForSign.length * rowHeight;
      this.params.node.setRowHeight(documentsForSignRowHeight);
      this.params.api.onRowHeightChanged();
    }
  }

  downloadFile(id: string, fileName: string): void {
    this.filesService.downloadFile(id, fileName, DocumentSubFolderName.Final)
    .pipe(
      catchError(() => {
        this.toastr.error(`Download ${fileName} error`, "Error");
        return of('ERR');
      }),
      takeUntilDestroyed(this.destroyRef)
    )
    .subscribe((res) => {
      if(res !== 'ERR') {
        downloadBase64File(res, fileName);
      }
    });
  }
}
