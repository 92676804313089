import { booleanAttribute, Component, EventEmitter, Input, Optional, Output } from '@angular/core';
import { LinkComponent } from "../link/link.component";
import { ControlValueAccessor, NgControl, ReactiveFormsModule } from "@angular/forms";
import { NgbDropdown, NgbDropdownItem, NgbDropdownMenu, NgbDropdownToggle } from "@ng-bootstrap/ng-bootstrap";
import { JsonPipe, NgClass } from "@angular/common";
import { SelectOption, SelectOptionPrimitive } from "../../../../models/selectOptions";
import { ValidationErrorComponent } from "../validation-error/validation-error.component";

@Component({
  selector: 'app-select',
  standalone: true,
  imports: [
    LinkComponent,
    ReactiveFormsModule,
    NgbDropdown,
    NgbDropdownItem,
    NgbDropdownMenu,
    NgbDropdownToggle,
    JsonPipe,
    NgClass,
    ValidationErrorComponent,
  ],
  templateUrl: './select.component.html',
  styleUrl: './select.component.scss'
})
export class SelectComponent implements ControlValueAccessor {
  @Input() width = '100%';
  @Input() placeholder = ''
  @Input() label = 'Label'
  @Input() link = ''
  @Input() text = ''
  @Input() helperText = '';
  @Input() noOptionsText = 'There are no available options.';
  @Input({ transform: booleanAttribute }) disabled = false;
  @Input({ transform: booleanAttribute }) isDisabled = false;
  @Input({ transform: booleanAttribute }) valuePrimitive = false;
  @Input({ transform: booleanAttribute }) requireSorting = true;

  @Input({ required: true })
  set options(value: SelectOption[]) {
    this._options = value || [];
    if(this._originValue) {
      this.writeValue(this._originValue);
    }
  }

  @Output() select = new EventEmitter<SelectOption>();

  private _options: SelectOption[] = [];
  private _originValue: SelectOption | null = null;
  #value: SelectOption | null = null;
  dropdownOpen = false;

  constructor(@Optional() protected ngControl: NgControl) {
    if (ngControl) {
      ngControl.valueAccessor = this;
    }
  }
  
  get options(): SelectOption[] {
    return this._options;
  }

  get value(): SelectOption | null {
    return this.#value;
  }

  set value(value: SelectOption) {
    this.#value = value;
    this.onChange(this.valuePrimitive ? this.#value?.value : this.#value);
  }


  get displayValidationError(): boolean {
    return !!(this.ngControl && this.ngControl.touched && this.ngControl.errors);
  }

  get sortedOptions(): SelectOption[] {
    if (!this.requireSorting || this.options?.length === 1) return this.options;

    const sorted = this.options
      .filter(option => option !== this.value)
      .sort((a, b) => a.label.localeCompare(b.label));

    if (this.value) {
      return [this.value, ...sorted];
    }

    return sorted;
  }


  selectOption(selectedOption: SelectOption): void {
    this.value = selectedOption;
    this.select.emit(selectedOption);
  }

  onChange: any = (): void => {};
  onTouched: any = (): void => {};

  writeValue(value: SelectOption): void {
    this._originValue = value;
    this.value = this.valuePrimitive ?
      this.options.find((option) => option.value === value as unknown as SelectOptionPrimitive)!
      : value;
  }

  registerOnChange(fn: () => void): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  getDropdownState(dropdownState: boolean): void {
    this.dropdownOpen = dropdownState;
  }
}
