import { Component, EventEmitter, Inject, Input, Optional, Output } from '@angular/core';
import { AbstractControl, ControlValueAccessor, FormsModule, NgControl, ReactiveFormsModule } from '@angular/forms';
import { NgbDropdown, NgbTypeahead, NgbTypeaheadSelectItemEvent } from '@ng-bootstrap/ng-bootstrap';
import {
  catchError,
  debounceTime,
  distinctUntilChanged,
  filter,
  finalize,
  map,
  merge,
  Observable,
  of,
  Subject,
  switchMap,
  tap
} from 'rxjs';
import { NgClass } from '@angular/common';
import { Address } from '../../../../models/address';
import { AutocompleteService, autocompleteServiceToken } from '../../../../services/autocomplete.service';
import { GoogleMapsService } from '../../../../services/google-maps.service';
import { LinkComponent } from '../link/link.component';
import { FormSwitchButtonComponent } from '../form-switch-button/form-switch-button.component';
import { AddressStatus } from '../../../../models/enums/addressEnums';
import { DotsLoaderComponent } from '../dots-loader/dots-loader.component';
import { ValidationErrorComponent } from '../validation-error/validation-error.component';

@Component({
  selector: 'app-address-autocomplete',
  standalone: true,
  imports: [
    FormsModule,
    NgbTypeahead,
    NgbDropdown,
    LinkComponent,
    NgClass,
    FormSwitchButtonComponent,
    DotsLoaderComponent,
    ValidationErrorComponent,
    ReactiveFormsModule,
  ],
  providers: [GoogleMapsService],
  templateUrl: './address-autocomplete.component.html',
  styleUrl: './address-autocomplete.component.scss',
})
export class AddressAutocompleteComponent implements ControlValueAccessor {
  @Input() showBulkSearch = false;
  @Input() useInternationalAddresses = false;
  @Input() isAustraliaSelected = true;
  @Input() country = '';
  @Input() width = '100%';
  @Input() placeholder = 'Type Address';
  @Input() label = 'Address';
  @Input() link = '';
  @Input() text = '';
  @Input() switchBtnText = 'Enter Address Manually';
  @Input() helperText = '';
  @Input() shouldSearchAddresses = true;
  @Input() suggestedAddresses: Address[] = [];

  @Input() set dataStatus(value: AddressStatus | undefined) {
    this.status = value;
    this.displayStatus = true;
    clearTimeout(this.timeoutId);
    if (this.clearStatusAfter && value) {
      this.timeoutId = setTimeout(() => {
        this.displayStatus = false;
      }, this.clearStatusAfter);
    }
  }
  @Input() clearStatusAfter: number | undefined;
  @Input() isDisabled = false;
  @Input() isValidating = false;

  @Output() switchBtnClick = new EventEmitter<boolean>();
  @Output() onSelect = new EventEmitter<Address | string | null>();
  @Output() dataStatusChange = new EventEmitter<AddressStatus | undefined>();
  @Output() onTextChange = new EventEmitter<string>();
  @Output() focus = new EventEmitter<FocusEvent>();
  @Output() blur = new EventEmitter<FocusEvent>();
  @Output() searchUpdate = new EventEmitter<boolean>;
  @Output() tryParse = new EventEmitter<void>;

  readonly customErrors = {
    required: 'Please fill in correct address',
    isValidating: 'Checking validity...',
    isSearching: 'Searching...'
  };

  #value: any = null;
  searchTerm = '';
  disabled = false;
  searchFailed = false;
  searching = false;
  AddressStatus = AddressStatus;
  displayStatus = false;
  status: AddressStatus | undefined;
  timeoutId: number | undefined;
  foundForeignAddresses: Address[] = [];
  autocompleteTrigger$ = new Subject<void>();

  get value() {
    return this.#value;
  }

  get displayValidationError(): boolean {
    return !!(this.control && this.control.touched && this.control.errors);
  }

  get control(): AbstractControl {
    return this.ngControl.control as AbstractControl;
  }

  constructor(
    @Optional() protected ngControl: NgControl,
    @Inject(autocompleteServiceToken) private readonly service: AutocompleteService,
    private googleMapsService: GoogleMapsService
  ) {
    if (ngControl) {
      ngControl.valueAccessor = this;
    }
  }

  search = (text$: Observable<string>) =>
    merge(
      text$.pipe(
        tap(() => this.dataStatusChange.emit(undefined)),
        debounceTime(500),
        distinctUntilChanged()
      ),
      this.autocompleteTrigger$.pipe(filter(() => this.suggestedAddresses.length > 0))
    )
      .pipe(
        map((text) => text || ' '),
        tap((text) => this.onTextChange.emit(text)),
        switchMap((term) =>
          this.useInternationalAddresses && !this.isAustraliaSelected
            ? this.searchForeignAddresses(term)
            : this.searchAuAddresses(term)),
      );

  selectOption(event: NgbTypeaheadSelectItemEvent): void {
    this.#value = event.item;
    this.onChange(this.#value);

    const address = this.suggestedAddresses.find((address) => address.normalizedFullAddress === event.item);
    if (address) {
      this.onSelect.emit(address);
      return;
    }

    if (this.useInternationalAddresses && !this.isAustraliaSelected) {
      this.onSelect.emit(this.foundForeignAddresses.find(address => address.normalizedFullAddress === event.item));
      return;
    }

    this.onSelect.emit(this.#value);
  }

  onClickBtn(): void {
    this.switchBtnClick.emit(true);
  }

  onChange: any = () => {};
  onTouch: any = () => {};

  registerOnChange(fn: any) {
    this.onChange = fn;
  }

  registerOnTouched(fn: any) {
    this.onTouch = fn;
  }

  setDisabledState(isDisabled: boolean) {
    this.disabled = isDisabled;
  }

  writeValue(value: any): void {
    this.#value = value;
  }

  searchAuAddresses(term: string): Observable<string[]> {
    if (!this.shouldSearchAddresses)
      return of([]);

    this.searchTerm = term;

    if (this.suggestedAddresses.length && term.length <= 3) {
      return (of(this.suggestedAddresses.map(address => address.normalizedFullAddress)));
    }

    if (term.length > 3) {
      return this.service.searchAddress(term)
        .pipe(
          finalize(() => this.setSearching(false)),
          tap((addressesList) => {
            this.setSearching();
            this.searchFailed = false;
            if (!addressesList.length) {
              this.dataStatusChange.emit(AddressStatus.WARNING);
              this.onChange(term);
              this.onSelect.emit(null);
            }
          }),
          catchError(() => {
            this.searchFailed = true;
            this.dataStatusChange.emit(AddressStatus.WARNING);
            return of([]);
          }),
        );
    } else {
      this.setSearching(false);
      this.dataStatusChange.emit(AddressStatus.WARNING);
      return of([]);
    }
  }

  searchForeignAddresses(term: string): Observable<string[]> {
    if (!this.shouldSearchAddresses)
      return of([]);

    this.searchTerm = term;

    if (term.length > 3) {
      return this.googleMapsService.searchAddress(term, this.country)
        .pipe(
          finalize(() => this.setSearching(false)),
          map((addresses) => {
            this.setSearching();
            this.foundForeignAddresses = addresses;
            return addresses.map(address => address.normalizedFullAddress);
          }),
          tap((addressesList) => {
            this.searchFailed = false;
            if (!addressesList.length) {
              this.dataStatusChange.emit(AddressStatus.WARNING);
              this.onChange(term);
              this.onSelect.emit(null);
            }
          }),
          catchError(() => {
            this.searchFailed = true;
            this.dataStatusChange.emit(AddressStatus.WARNING);
            this.foundForeignAddresses = [];
            return of([]);
          }),
        );
    } else {
      this.setSearching(false);
      this.dataStatusChange.emit(AddressStatus.WARNING);
      return of([]);
    }
  }

  clear(): void {
    this.searchTerm = '';
    this.#value = null;
    this.onTextChange.emit('');
  }

  focusOnActiveOption(event: KeyboardEvent, element: HTMLElement): void {
    const keysToHandle = ['ArrowDown', 'ArrowUp'];
    if (!keysToHandle.includes(event.key)) return;

    setTimeout(() => {
      const activeOption = element.parentElement?.querySelector('.dropdown-item.active');

      if (activeOption) {
        activeOption.scrollIntoView({ block: 'center', inline: 'center', behavior: 'instant' });
      }
    });
  }

  onFocusSearchControl(event: FocusEvent): void {
    this.autocompleteTrigger$.next();
    this.focus.emit(event);
  }

  onBlurSearchControl(event: FocusEvent): void {
    this.control.markAsTouched();
    this.blur.emit(event);
  }

  private setSearching(searching = true): void {
    this.searching = searching;
    this.searchUpdate.emit(searching);
  }
}
