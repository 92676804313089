<app-common-modal-form
  [header]="'Add Approver'"
  [icon]="'icon ic-note'"
  [confirmText]="'Save'"
  [closeText]="'Cancel'"
  [isInvalid]="!selectedApprover"
  (close)="close()"
  (confirm)="add()"
>
  <div class="body-container d-flex flex-column gap-24" [formGroup]="form">
    <app-select
      [label]="'Approver *'"
      [placeholder]="'Select approver'"
      [valuePrimitive]="true"
      [options]="approverOptions"
      [(ngModel)]="selectedApprover"
      (ngModelChange)="onApproverSelect($event)"
      [ngModelOptions]="{standalone: true}"
    ></app-select>

    @if(selectedApprover) {
      <app-input [label]="'Email *'" [placeholder]="'Enter email'" [isDisabled]="true" formControlName="email"></app-input>
    }

    <app-input-phone-number [label]="'Phone'" [placeholder]="'Enter phone'" formControlName="phone"/>
  </div>
</app-common-modal-form>
