import { NgClass } from '@angular/common';
import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';
import { SigningStatus } from '../../../../../../models/enums/annualStatementEnums';
import { SigningStatusPipe } from '../../../../../../pipes/enumsPipes/signinStatusPipe';

@Component({
  selector: 'app-ag-document-signing-status',
  standalone: true,
  imports: [
    SigningStatusPipe,
    NgClass
  ],
  templateUrl: './ag-document-signing-status.component.html',
  styleUrl: './ag-document-signing-status.component.scss'
})
export class AgDocumentSigningStatusComponent implements ICellRendererAngularComp {
  readonly SigningStatus = SigningStatus;

  status = SigningStatus.ReadyToSend;

  agInit(params: ICellRendererParams): void {
    this.status = params.value as number;
  }

  refresh(params: ICellRendererParams): boolean {
    this.status = params.value as number;
    return true;
  }
}
