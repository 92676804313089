import { Component, EventEmitter, inject, OnInit, Output } from '@angular/core';
import { ButtonComponent } from "../../../components/common/button/button.component";
import { CommonModalFormComponent } from "../../common-modal-form/common-modal-form.component";
import { DividerComponent } from "../../../components/common/divider/divider.component";
import { RadioComponent } from "../../../components/common/radio/radio.component";
import { FormBuilder, FormGroup, FormsModule, ReactiveFormsModule, Validators } from "@angular/forms";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { SelectComponent } from "../../../components/common/select/select.component";
import { UserBrief } from "../../../../models/uiProfile";
import { SelectOption } from "../../../../models/selectOptions";
import { OrganisationService } from "../../../../services/organisation.service";
import { InputComponent } from "../../../components/common/input/input.component";
import { Signatory } from "../../../../models/signDocuments";
import { InputPhoneNumberComponent } from "../../../components/common/input-phone-number/input-phone-number.component";
import { CustomFormValidators } from "../../../../custom-form-validators/custom-form-validators";
import { UsersService } from '../../../../services/users.service';
import { UserRecord } from '../../../../models/userRecord';
import { firstValueFrom } from 'rxjs';
import { ApproverStatus } from '../../../../models/enums/approverStatus';

@Component({
  selector: 'app-add-approver',
  standalone: true,
  imports: [
    ButtonComponent,
    CommonModalFormComponent,
    DividerComponent,
    RadioComponent,
    ReactiveFormsModule,
    SelectComponent,
    FormsModule,
    InputComponent,
    InputPhoneNumberComponent
  ],
  templateUrl: './add-approver.component.html',
  styleUrl: './add-approver.component.scss'
})
export class AddApproverComponent implements OnInit {
  @Output() confirm = new EventEmitter<Signatory>();

  private fb: FormBuilder = inject(FormBuilder);
  private activeModal = inject(NgbActiveModal);
  private usersService = inject(UsersService);
  private organisationService = inject(OrganisationService);

  profileUserList: UserRecord[] = [];
  approverOptions: SelectOption[] = [];
  selectedApprover = '';

  form!: FormGroup

  async ngOnInit(): Promise<void> {
    this.form = this.fb.group({
      id: ['', Validators.required],
      fullName: ['', Validators.required],
      email: ['', [Validators.required, CustomFormValidators.emailValidator]],
      phone: [''],
    });

    this.profileUserList = await firstValueFrom(this.usersService.getAssignableUsersCached());
    this.approverOptions = this.profileUserList.map(profile => ({
      label: profile.fullName,
      value: profile.id
    })); 

    this.selectedApprover = this.profileUserList[0].id!;
    this.onApproverSelect(this.profileUserList[0].id!);
  }

  onApproverSelect(id: string): void {
    const user = this.profileUserList.find(user => user.id === id);
    if (user) {
      this.form.get('id')?.patchValue(id);
      this.form.get('fullName')?.patchValue(user.fullName);
      this.form.get('email')?.patchValue(user.email);
    }
  }

  add(): void {
    var approver = this.form.getRawValue() as Signatory;
    approver.status = ApproverStatus.Created;
    this.confirm.emit(approver);
    this.activeModal.close();
  }

  close(): void {
    this.activeModal.close();
  }
}
