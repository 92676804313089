import { Component, EventEmitter, Output, inject } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ButtonComponent } from '../../components/common/button/button.component';
import { CommonModalFormComponent } from '../common-modal-form/common-modal-form.component';

@Component({
  selector: 'app-regenerate-statement-modal',
  standalone: true,
  imports: [
    ButtonComponent,
    FormsModule,
    CommonModalFormComponent
  ],
  templateUrl: './regenerate-statement-modal.component.html',
  styleUrl: './regenerate-statement-modal.component.scss'
})
export class RegenerateStatementModalComponent {
  activeModal = inject(NgbActiveModal);

  @Output() confirm = new EventEmitter<string>();
  
  onClose(): void {
    this.activeModal.close();
  }

  regenerate() {
    this.confirm.emit('regenerate');
    this.activeModal.close();
  }
  reprint() {
    this.confirm.emit('reprint');
    this.activeModal.close();
  }
}
