import { Component, inject, OnInit } from '@angular/core';
import { ButtonComponent } from "../../../components/common/button/button.component";
import { LoaderComponent } from "../../../components/common/loader/loader.component";
import { PageTitleComponent } from "../../../components/common/page-title/page-title.component";
import { AppCopyDirective } from "../../../../directives/copy.directive";
import { DefaulValuePipe } from "../../../../pipes/enumsPipes/defaultValuePipe";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import {
  EditDocumentSettingsModalComponent
} from "./edit-document-settings-modal/edit-document-settings-modal.component";
import {
  NotifyWhenAllPartiesSignedDocumentLabelRecord
} from "./edit-document-settings-modal/edit-document-settings-modal.constant";
import { DocumentationType } from "../../../../models/enums/documentConfirmEnums";
import { HasRoleDirective } from '../../../../directives/has-role.directive';
import { ToastrService } from 'ngx-toastr';
import { SettingsService } from '../../../../services/settings.service';
import { AnnualStatementSettingsData, CompanyStatementSignatory, DocumentSettingsData } from '../../../../models/documentSettingsData';

@Component({
  selector: 'app-document-settings-page',
  standalone: true,
  imports: [
    ButtonComponent,
    LoaderComponent,
    PageTitleComponent,
    AppCopyDirective,
    DefaulValuePipe,
    HasRoleDirective
  ],
  templateUrl: './document-settings-page.component.html',
  styleUrls: ['./document-settings-page.component.scss', '../../settings-styles.scss']
})
export class DocumentSettingsPageComponent implements OnInit {
  private modalService = inject(NgbModal);
  #toast = inject(ToastrService);
  settingsService = inject(SettingsService);

  isLoading = false;
  documentSettings = new DocumentSettingsData();
  asSettings = new AnnualStatementSettingsData();

  readonly NotifyWhenAllPartiesSignedDocumentLabelRecord = NotifyWhenAllPartiesSignedDocumentLabelRecord;
  readonly DocumentationType = DocumentationType;
  readonly CompanyStatementSignatory = CompanyStatementSignatory;

  ngOnInit(): void {
    this.loadDocumentSettings();
  }

  openEditDocumentSettingsModal(): void {
    const modalRef = this.modalService.open(EditDocumentSettingsModalComponent, { size: 'lg' });
    const instance = modalRef.componentInstance as EditDocumentSettingsModalComponent;
    instance.documentSettings = this.documentSettings;
    instance.asSettings = this.asSettings;

    modalRef.result.then(
      () => this.loadDocumentSettings(),
      () => {
      });
  }

  loadDocumentSettings(): void {
    this.isLoading = true;

    this.settingsService.getDocumentSettings().subscribe({
      next: (settings) => {
        this.documentSettings = settings;
      },
      error: () => {
        this.#toast.error('Failed to load document settings');
      }
    });

    this.settingsService.getAnnualStatementSettings().subscribe({
      next: (settings) => {
        this.asSettings = settings;
      },
      error: () => {
        this.#toast.error('Failed to load annual statement settings');
      }
    });

    this.isLoading = false;
  }
}
