<div class="asic-documents-container">
  <div class="title-container">
    <h3>ASIC Response</h3>
  </div>

  <div class="d-flex flex-column gap-24">
    @if(notLodgedForm) {
      <app-notification
        [color]="'orange'"
        [title]="'Disclaimer'"
        [text]="notLodgedFormMsg"
      ></app-notification>
    } @else {
      @if(document.asicStatus === AsicStatus.Lodged) {
        <app-notification
          [iconClass]="'icon ic-md ic-circle-check'"
          [color]="'green'"
          [title]="'Successful Lodgement'"
          [text]="successMsg"
          [amount]="document.asicDocumentNumber || document.asicTraceNumber || ''"
          [isMoneyFormat]="false"
        ></app-notification>

        <div class="btn-container">
          <app-button
            [text]="'Request Correction'"
            [iconClass]="'icon ic-form-edit'"
            [btnColor]="'white'"
            (clickBtn)="requestCorrection()"
          ></app-button>

          <app-button
            [text]="'Withdraw'"
            [iconClass]="'icon ic-refresh'"
            [btnColor]="'white'"
            (clickBtn)="withdraw()"
          ></app-button>
        </div>
      } @else if(document.asicStatus === AsicStatus.Rejected) {
        <app-notification
          [iconClass]="'icon ic-md ic-cancel-circle'"
          [color]="'red'"
          [title]="'Rejected Lodgement'"
          [text]="rejectedMsg"
        >
          <div>To edit the data, <a class="red" (click)="editInitialDocument()">click here.</a></div>
        </app-notification>

        <div class="btn-container">
          <app-button
            [text]="'Edit Document'"
            [iconClass]="'icon ic-edit'"
            [btnColor]="'white'"
            (clickBtn)="editInitialDocument()"
          ></app-button>

          <app-button
            [text]="'Delete Document'"
            [iconClass]="'icon ic-trash'"
            [btnColor]="'white'"
            (clickBtn)="deleteDocument()"
          ></app-button>
        </div>

        <div class="error-container">
          @for(item of document.edgeValidationResult?.validationErrors; track $index) {
            <div class="error-row">
              <div><i class="icon ic-arrow-right"></i></div>
              <div class="error-wrapper">
                <div class="title">{{item.code}}</div>
                @if(item.description) {
                  <div class="error-text" [innerHtml]="item.description | replaceStr: '\n' : '<br>'"></div>
                }
              </div>
            </div>
          }
        </div>
      } @else if(document.asicStatus === AsicStatus.Pending) {
        <app-notification
          [iconClass]="'icon ic-md ic-clock'"
          [color]="'blue'"
          [title]="'Lodgement is Pending'"
          [text]="'The form is on queue to be transmitted to ASIC'"
        ></app-notification>
      } @else if(document.asicStatus === AsicStatus.WaitingForASIC) {
        <app-notification
          [iconClass]="'icon ic-md ic-timer orange'"
          [color]="'orange'"
          [title]="'Waiting for ASIC'"
          [text]="'The form has been successfully transmitted and now waiting for ASIC response.'"
        ></app-notification>
      } @else if(document.asicStatus === AsicStatus.ManualProcessing) {
        <app-notification
          [iconClass]="'icon ic-md ic-pause-circle'"
          [color]="'purple'"
          [title]="'Manual Processing'"
          [text]="'Lodgement is currently on hold with ASIC. Waiting for update from ASIC.'"
        ></app-notification>
      } @else {
        <app-notification
          [iconClass]="'icon ic-md ic-circle-info'"
          [title]="AsicStatus[document.asicStatus]"
          [text]="AsicStatus[document.asicStatus]"
        ></app-notification>
      }
    }
  </div>
</div>
