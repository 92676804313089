import { Component, Input } from '@angular/core';
import {
  EntityNameNumberRecordComponent
} from '../../../entity-name-number-record/entity-name-number-record.component';
import { Trust } from '../../../../../../models/trust';
import { ICellRendererParams } from 'ag-grid-community';
import { ABNPipe } from "../../../../../../pipes/abnPipe";
import { RouterLink } from "@angular/router";
import { ICellRendererAngularComp } from "ag-grid-angular";

type ActionEvent = (data: Trust | undefined) => void;

type CellRendererParams = ICellRendererParams<Trust> & {
  openTrustProfile: ActionEvent,
};

@Component({
  selector: 'app-ag-trust-name-abn',
  standalone: true,
  imports: [
    EntityNameNumberRecordComponent,
    ABNPipe,
    RouterLink
  ],
  templateUrl: './ag-trust-name-abn.component.html',
  styleUrl: './ag-trust-name-abn.component.scss'
})
export class AgTrustNameAbnComponent implements ICellRendererAngularComp {
  @Input() name = '';
  @Input() abn = '';
  @Input() entityId = '';

  agInit(params: CellRendererParams): void {
    this.updateCell(params);
  }

  refresh(params: CellRendererParams): boolean {
    return this.updateCell(params);
  }

  updateCell(params: CellRendererParams): boolean {
    this.entityId = params.data?.entityId ?? '';
    this.name = params.data?.name ?? '';
    this.abn = params.data?.abn ?? '';
    return true;
  }
}
