import { Component, inject } from '@angular/core';
import { environment } from '../../environments/environment';
import { MenuService } from '../../services/menu.service';
import { sauperUserMenuItems, menuItemsList } from './menu-items.constant';
import { NgClass, NgOptimizedImage } from "@angular/common";
import { NavMenuLinkComponent } from "./nav-menu-link/nav-menu-link.component";
import { RouterLink } from "@angular/router";
import { AuthService } from '../../services/auth.service';


@Component({
  selector: 'app-nav-menu',
  standalone: true,
  imports: [
    NgClass,
    NavMenuLinkComponent,
    NgOptimizedImage,
    RouterLink
  ],
  templateUrl: './nav-menu.component.html',
  styleUrl: './nav-menu.component.scss'
})
export class NavMenuComponent {
  environment = environment;
  collapsedMenu = this.menuService.collapsedMenu;
  menuItems = this.menuService.menuItems;
  sauperUserMenuItems = sauperUserMenuItems;

  authService = inject(AuthService);

  constructor(private menuService: MenuService) {
    this.menuService.setMenuItems(menuItemsList);
  }

  toggleMenu(): void {
    this.menuService.setMenuState(!this.collapsedMenu());
  }
}
