import { HttpClient } from "@angular/common/http";
import { Injectable, signal } from "@angular/core";
import { Trust } from "../models/trust";
import { environment } from "../environments/environment";
import { Observable, map, Subject, from, concatMap, forkJoin, delay, toArray, of } from "rxjs";
import {
  AgTrustNameAbnComponent
} from "../app/components/common/grid/trusts/ag-trust-name-abn/ag-trust-name-abn.component";
import { CellDataType } from "../models/enums/agGridEnums";
import {
  AgCompanyRegistrationDateComponent
} from "../app/components/common/grid/components/ag-company-registration-date/ag-company-registration-date.component";
import { AgTrustStatusComponent } from "../app/components/common/grid/trusts/ag-trust-status/ag-trust-status.component";
import { AgEntityTagsComponent } from "../app/components/common/grid/components/ag-company-tag/ag-entity-tags.component";
import { Tag } from "../models/tag";
import { ColumnWithExportName } from "../models/columnWithExportName";
import { ExcelRow, ExcelStyle, GridApi } from "ag-grid-community";
import { FilterOption } from "../models/gridFilter";
import { formatDate } from "@angular/common";
import { createFilterOptions } from "../app/helpers/create-filter-options.helper";
import { mergeUniqueValues } from "../app/helpers/unique-merge.helper";
import { generate, Observer, Operation } from "fast-json-patch";
import { filterPatchOperations } from "../functions/filter-patch-operations";
import { uniqueArrayToFilterOptionHelper } from "../app/helpers/unique-array-to-filter-option.helper";
import { convertEnumToFilterOption } from "../app/helpers/enum-to-filter-option.helper";
import { AustralianState } from "../models/enums/countryEnums";
import { JurisdictionType } from "../models/enums/trustEnum";
import { Document } from "../models/document";
import { excelReportTitleStyles, excelTitleForReport } from "../app/helpers/excel-title-for-report";

@Injectable({
  providedIn: 'root'
})
export class TrustsService {
  colDefs = signal<ColumnWithExportName[]>([]);
  numberColumnForExport = signal(0);

  companyStatusFilterOptions: FilterOption[] = [
    { label: 'Active', value: '0', active: true },
    { label: 'Archived', value: '1', active: false },
  ];
  trustTagFilterOptions: FilterOption[] = [];
  trustSystemTagFilterOptions: FilterOption[] = [];
  trustDateOfRegistrationFilterOptions: FilterOption[] = [];
  trustAccountManagerFilterOptions: FilterOption[] = [];
  trustPartnerManagerFilterOptions: FilterOption[] = [];
  familyGroupFilterOptions: FilterOption[] = [];
  trustJurisdictionOptions: FilterOption[] = [];

  gridApi!: GridApi;
  _trustsListUpdated$ = new Subject<void>();
  trustsListUpdated$ = this._trustsListUpdated$.asObservable();

  constructor(private api: HttpClient) { }

  public list() {
    return this.api.get<Trust[]>(`${environment.api_url}/trusts`);
  }

  public getTrustProfile(id: string, excludedDocumentId = ''): Observable<Trust> {
    const options = { params: { excludedDocumentId } };
    return this.api.get<Trust>(`${ environment.api_url }/trusts/${ id }`, options)
      .pipe(map((data) => new Trust(data)));
  }

  public addTrust(trust: Trust): Observable<Trust> {
    return this.api.post<Trust>(`${environment.api_url}/trusts`, trust.prepareToRequest());
  }

  public patchTrust(id: string, observer: Observer<Trust>): Observable<Trust> {
    const filteredPatchOperations: Operation[] = observer ? filterPatchOperations(generate(observer)) : [];
    return this.api.patch<Trust>(`${ environment.api_url }/trusts/${ id }`, filteredPatchOperations, { headers: { 'Content-Type': 'application/json-patch+json' } })
      .pipe(map((data) => new Trust(data)));
  }

  patchOperationTrust(entityId: string, patchOperations: Operation[]): Observable<Trust> {
    const filteredPatchOperations: Operation[] = patchOperations.length ? filterPatchOperations(patchOperations) : [];
    return this.api.patch<Trust>(`${ environment.api_url }/trusts/${ entityId }`, filteredPatchOperations, { headers: { 'Content-Type': 'application/json-patch+json' } })
      .pipe(map((data) => new Trust(data)));
  }

  bulkPatchOperationTrust(createdDocuments: { entityId: string }[], patchOperations: Operation[]): Observable<(Trust | null)[]> {
    const batches = this.chunkArray(createdDocuments, 10);

    return from(batches).pipe(
      concatMap(batch =>
        forkJoin(batch.map(doc => this.patchOperationTrust(doc.entityId, patchOperations))).pipe(
          delay(1000)
        )
      ),
      toArray(),
      concatMap(responses => of(...responses).pipe(concatMap(res => res), toArray()))
    );
  }

  private chunkArray<T>(array: T[], size: number): T[][] {
    const result: T[][] = [];
    for (let i = 0; i < array.length; i += size) {
      result.push(array.slice(i, i + size));
    }
    return result;
  }

  public applyChanges(document: Document): Observable<Trust> {
    return this.api.post<Trust>(`${ environment.api_url }/trusts/apply-changes`, Document.prepareToRequest(document))
  }

  setTrustOptions(trustList: Trust[], actionColumn: ColumnWithExportName): void {
    this.trustDateOfRegistrationFilterOptions = trustList.map(trust => {
      return {
        label: trust.dateOfEstablishment ? formatDate(trust.dateOfEstablishment ?? '','dd MMM yyyy', 'en') : '',
        value: trust.dateOfEstablishment ?? '',
        active: false
      }
    });

    const accountManagerList = trustList?.filter( t => t.accountManager?.fullName)
      .map(t => t.accountManager?.fullName) as string[];
    this.trustAccountManagerFilterOptions = (uniqueArrayToFilterOptionHelper([...new Set(accountManagerList)]));

    const partnerManagerList = trustList?.filter( t => t.partnerManager?.fullName)
      .map(t => t.partnerManager?.fullName) as string[];
    this.trustPartnerManagerFilterOptions = (uniqueArrayToFilterOptionHelper([...new Set(partnerManagerList)]));

    this.trustTagFilterOptions = createFilterOptions<Tag>(mergeUniqueValues<Tag[]>(trustList.map(c => c.tags), 'tagId'), 'name', 'name');
    this.trustSystemTagFilterOptions = createFilterOptions<Tag>(mergeUniqueValues<Tag[]>(trustList.map(c => c.systemTags), 'tagId'), 'name', 'name');

    const familyGroupList = trustList?.filter(c => c.familyGroup).map(c => c.familyGroup);
    this.familyGroupFilterOptions = (uniqueArrayToFilterOptionHelper([...new Set(familyGroupList)]));

    const jurisdictionOptions = convertEnumToFilterOption(AustralianState, true);
    jurisdictionOptions.forEach(o=> o.value = (JurisdictionType[o.value as string] as number).toString());
    jurisdictionOptions.sort((a, b) => a.label.localeCompare(b.label));
    this.trustJurisdictionOptions = jurisdictionOptions;

    this.setGridConfig(actionColumn);
  }

  setGridConfig(actionColumn: ColumnWithExportName): void {
    this.colDefs.set([
      {
        headerName: 'Trust Name & ABN',
        exportColumnName: 'Trust Name',
        field: 'name',
        suppressMovable: true,
        width: 296,
        flex: 2,
        editable: false,
        sort: 'asc',
        checkboxSelection: true,
        headerCheckboxSelection: true,
        cellRenderer: AgTrustNameAbnComponent,
        menuTabs: ['generalMenuTab'],
      },
      {
        headerName: '',
        exportColumnName: 'ABN',
        field: 'abn',
        width: 100,
        flex: 0.5,
        menuTabs: [],
        hide: true
      },
      {
        headerName: 'Commencement Date',
        field: 'dateOfEstablishment',
        width: 80,
        flex: 0.5,
        filter: 'agSetColumnFilter',
        filterParams: {
          filterOptions: this.trustDateOfRegistrationFilterOptions,
        },
        getQuickFilterText: (params: { data: { abn: string } }) => {
          return params.data.abn ?? '';
        },
        cellDataType: CellDataType.DATE_STRING,
        cellRenderer: AgCompanyRegistrationDateComponent,//todo make it common
        menuTabs: ['generalMenuTab'],
      },
      {
        headerName: 'Status',
        field: 'status',
        width: 80,
        flex: 0.5,
        filter: 'agSetColumnFilter',
        filterParams: {
          filterOptions: this.companyStatusFilterOptions,
        },
        cellRenderer: AgTrustStatusComponent,
        menuTabs: ['generalMenuTab'],
      },
      {
        headerName: 'Tags',
        field: 'tags',
        width: 380,
        flex: 2,
        filter: 'agSetColumnFilter',
        filterParams: {
          filterOptions: this.trustTagFilterOptions,
        },
        cellRendererParams: {
          allowEdit: true,
          handleAddNewTag: (() => this._trustsListUpdated$.next())
        },
        cellRenderer: AgEntityTagsComponent,
        valueFormatter: (params: { value: Tag }) => params.value?.name ?? '',
        comparator: (tagListA: Tag[], tagListB: Tag[]) => {
          const a = tagListA?.length ?? 0;
          const b = tagListB?.length ?? 0;
          return a - b;
        },
        menuTabs: ['generalMenuTab'],
      },
      {
        headerName: '',
        exportColumnName: 'System Tags',
        field: 'systemTags',
        width: 180,
        flex: 1,
        filter: 'agSetColumnFilter',
        filterParams: {
          filterOptions: this.trustSystemTagFilterOptions,
        },
        valueFormatter: (params: { value: Tag }) => params.value?.name ?? '',
        hide: true,
        menuTabs: ['generalMenuTab'],
      },
      {
        headerName: 'Account Manager',
        field: 'accountManager.fullName',
        flex: 2,
        filter: 'agSetColumnFilter',
        filterParams: {
          filterOptions: this.trustAccountManagerFilterOptions,
        },
        hide: true,
        menuTabs: ['generalMenuTab'],
      },
      {
        headerName: 'Partner Manager',
        field: 'partnerManager.fullName',
        flex: 2,
        filter: 'agSetColumnFilter',
        filterParams: {
          filterOptions: this.trustPartnerManagerFilterOptions,
        },
        hide: true,
        menuTabs: ['generalMenuTab'],
      },
      {
        headerName: 'Reference Number',
        field: 'referenceNumber',
        flex: 1,
        hide: true,
        menuTabs: ['generalMenuTab'],
      },
      {
        headerName: 'Family Group',
        field: 'familyGroup',
        flex: 1,
        filter: 'agSetColumnFilter',
        filterParams: {
          filterOptions: this.familyGroupFilterOptions,
        },
        cellDataType: CellDataType.TEXT,
        hide: true,
        menuTabs: ['generalMenuTab'],
      },
      {
        headerName: 'Jurisdiction',
        field: 'jurisdiction',
        flex: 1,
        filter: 'agSetColumnFilter',
        filterParams: {
          filterOptions: this.trustJurisdictionOptions,
        },
        valueFormatter: (params: { value: string }) => {
          return this.trustJurisdictionOptions.find(o => o.value == params.value)?.label ?? ''
        },
        hide: true,
        menuTabs: ['generalMenuTab'],
      },
      {
        ...actionColumn
      }
    ]);
  }

  getColDefs(): ColumnWithExportName[] {
    return this.colDefs();
  }

  setColDefs(colDefs: ColumnWithExportName[]): void {
    this.colDefs.set(colDefs);
  }

  public getExcelTitleRows: (numberColumnForExport: number) => ExcelRow[] = (numberColumnForExport) =>
    excelTitleForReport(numberColumnForExport, 'Trusts Report');

  public readonly excelStyles: ExcelStyle[] = excelReportTitleStyles;
}
