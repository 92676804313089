import { Component, DestroyRef, inject, NgZone } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import {
  NgbDropdown,
  NgbDropdownItem,
  NgbDropdownMenu,
  NgbDropdownToggle,
  NgbModal,
  NgbModalRef
} from '@ng-bootstrap/ng-bootstrap';
import { NgbModalOptions } from '@ng-bootstrap/ng-bootstrap/modal/modal-config';
import { generate, observe, Observer } from 'fast-json-patch';
import { ToastrService } from 'ngx-toastr';
import { catchError, firstValueFrom, tap, throwError } from 'rxjs';
import { Company } from '../../models/company';
import { DocumentRecord } from '../../models/documentRecord';
import { EntityChangeData } from '../../models/entityChangeData';
import { CompanyChangeOfficerType } from '../../models/enums/companyChangeOfficerType';
import { Relationship } from '../../models/relationship';
import { CompanyChangeOfficer } from '../../models/сompanyChangeOfficer';
import { DocumentsService } from '../../services/documents.service';
import { ModalFormsService } from '../../services/modal-forms.service';
import { ButtonComponent } from '../components/common/button/button.component';
import { DividerComponent } from '../components/common/divider/divider.component';
import { LoaderStandaloneComponent } from '../components/common/loader-standalone/loader-standalone.component';
import { TabButtonComponent } from '../components/common/tab-button/tab-button.component';
import { CompanyPendingChangesComponent } from '../components/company-pending-changes/company-pending-changes.component';
import { CompanyProfileCardComponent } from '../components/company-profile-card/company-profile-card.component';
import {
  CompanyProfileDocumentsComponent
} from '../components/company-profile-documents/company-profile-documents.component';
import { EntityProfileTagsComponent } from '../components/company-profile-tags/entity-profile-tags.component';
import { DetailsComponent } from '../components/details/details.component';
import { NotesComponent } from '../components/notes/notes.component';
import { NotesService } from '../components/notes/services/notes.service';
import { OfficeholdersComponent } from '../components/officeholders/officeholders.component';
import { SecurityholdersComponent } from '../components/securityholders/securityholders.component';
import { BillingSigningContactComponent } from '../modals/billing-signing-contact/billing-signing-contact.component';
import { CompanyEditComponent } from '../modals/company-edit/company-edit.component';
import { CompanyChangeName } from '../modals/company-name-change/CompanyChangeName';
import {
  CompanyChangeAddress
} from '../modals/documents/asic-forms/484-forms/a1-company-address-change/CompanyChangeAddress.model';
import {
  CompanyChangePartyName
} from '../modals/documents/asic-forms/484-forms/a2-company-member-name-change/CompanyChangePartyName';
import {
  CompanyChangeHoldingCompany
} from '../modals/documents/asic-forms/484-forms/a3-holding-company-change/company-change-holding-company.model';
import {
  CompanyChangeSpecialPurpose
} from '../modals/documents/asic-forms/484-forms/b3-company-status-change/company-status-change-form.model';
import {
  CompanyWithdrawLodgedDocumentChange
} from '../modals/documents/asic-forms/form106-withdraw-lodged-document/withdraw-lodged-document-form.model';
import { CompanyForm361 } from '../modals/documents/asic-forms/form361-registered-agent-ceasing/CompanyForm361';
import {
  CompanyChangeOfficerResignation
} from '../modals/documents/asic-forms/form370-notification-by-officeholder-of-resignation-or-retirement/CompanyChangeOfficerResignation.model';
import { Form410BModel } from '../modals/documents/asic-forms/form410b/form410B.model';
import { Form410FModel } from '../modals/documents/asic-forms/form410f/form410F.model';
import {
  CompanyMistakeCorrection,
  CompanyMistakeCorrectionType
} from '../modals/documents/asic-forms/form492-request-correction/request-correction-change.model';
import { CompanyChangeDeregister } from '../modals/documents/asic-forms/form6010-voluntary-deregistration/form6010.model';
import {
  RegisterCeaseChangeAgentForm
} from '../modals/documents/asic-forms/RA-forms/ra01-register-cease-change-agent/register-cease-change-agent-form.model';
import {
  AsicFormRA04
} from '../modals/documents/asic-forms/RA-forms/ra04-direct-debit-request/ra04-direct-debit-request.model';
import {
  RA71CompanyStatementRequestComponent
} from '../modals/documents/asic-forms/RA-forms/ra71-company-statement-request/ra71-company-statement-request.component';
import { CopyFinancialStatementsAndReports } from '../modals/form388/form388.model';
import { DetailTab } from '../../models/interfaces';
import { CompanyProfileService } from './company-profile.service';
import { ChangeCompanyType } from '../modals/form206/form206.model';
import { diff } from 'deep-object-diff';
import { EntityType } from "../../models/enums/entityType";
import { AlertsListComponent } from "../components/alerts-list/alerts-list.component";
import { Alert } from "../../models/alerts/alert";
import { ConfirmComponent } from "../modals/confirm/confirm.component";
import { HttpErrorResponse } from "@angular/common/http";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";
import { filterPatchOperations } from "../../functions/filter-patch-operations";
import { AlertStatus } from "../../models/enums/alertEnums";
import { MenuSection, SubMenuItem } from "../../models/sectionFormMenu";
import { DocumentStatusEnum } from "../../models/enums/documentStatusEnum";
import { OfficerContactEditComponent } from "../modals/officer-contact-edit/officer-contact-edit.component";
import { HighlightOfficeholder } from "../../models/highlightOfficeholder";
import { RelationshipType } from "../../models/enums/relationshipTypeEnum";
import { ContactsService } from "../../services/contacts.service";
import { Contact } from "../../models/contact";
import { HasRoleDirective } from '../../directives/has-role.directive';

@Component({
  selector: 'app-company-profile',
  templateUrl: './company-profile.component.html',
  styleUrl: './company-profile.component.scss',
  imports: [
    DetailsComponent,
    CompanyProfileCardComponent,
    EntityProfileTagsComponent,
    CompanyPendingChangesComponent,
    OfficeholdersComponent,
    CompanyProfileDocumentsComponent,
    SecurityholdersComponent,
    NgbDropdown,
    NgbDropdownToggle,
    NgbDropdownMenu,
    NgbDropdownItem,
    ButtonComponent,
    TabButtonComponent,
    DividerComponent,
    NotesComponent,
    LoaderStandaloneComponent,
    AlertsListComponent,
    HasRoleDirective
  ],
  standalone: true
})
export class CompanyProfileComponent {
  private profileService = inject(CompanyProfileService);
  private documentsService = inject(DocumentsService);
  private contactsService = inject(ContactsService);
  private notesService = inject(NotesService);
  private modalService = inject(NgbModal);
  private route = inject(ActivatedRoute);
  private toastr = inject(ToastrService);
  private modalFormsService = inject(ModalFormsService);
  private router = inject(Router);
  private zone = inject(NgZone);
  private destroyRef = inject(DestroyRef);

  tabs: DetailTab[] = [
    { title: 'Details' },
    { title: 'Officeholders' },
    { title: 'Shareholders' },
    { title: 'Documents' },
  ];

  menuSections!: MenuSection[]; 

  officeholdersMenu: SubMenuItem[] = [
    {
      title: 'Change Officer Name',
      formName: 'Form 484A2',
      action: (officeholder: Relationship[]) => this.companyChangePartyName(this.selectedOfficeholders),
    },
    {
      title: 'Change of Address',
      formName: 'Form 484A1',
      action: (officeholder: Relationship[]) => this.address(this.selectedOfficeholders),
    },
    {
      title: 'Cessation of Officeholder',
      formName: 'Form 484B1',
      action: (officeholder: Relationship[]) => this.ceaseCompanyOfficeholder(this.selectedOfficeholders),
    },
    {
      title: 'Notification by Officeholder of Resignation or Retirement',
      formName: 'Form 370',
      action: () => this.resignOrRetireOfficeholder(),
    },
  ];

  shareholdersMenu: SubMenuItem[] = [
    {
      title: 'Change Shareholder Name',
      formName: 'Form 484A2',
      action: (officeholders: Relationship[]) => this.companyChangePartyName(officeholders),
    },
    {
      title: 'Change of Address',
      formName: 'Form 484A1',
      action: (officeholders: Relationship[]) => this.address(officeholders),
    },
  ];

  documentsMenu: SubMenuItem[] = [
    {
      title: 'Request for Corrections',
      formName: 'Form 492',
      action: () => this.requestCorrestionForm492(),
    },
    {
      title: 'Notification of Additional Information',
      formName: 'Form 902',
      action: () => this.notificationOfAdditionalInformationForm902(),
    },
    {
      title: 'Request to Withdraw a Lodged Document',
      formName: 'Form 106',
      action: () => this.withdrawLodgeDocumentForm106(),
    },
  ];

  activeTab = 'Details';
  loading = false;
  hideTabs = false;
  companyProfile: Company = new Company();
  companyProfilePrevious: Company = new Company();
  companyProfileDifference: Partial<Company> | undefined;
  alerts: Alert[] = [];
  isDifferenceBetweenCompany = false;
  pendingDocuments: DocumentRecord[] = [];
  countPendingForm = 0;
  pendingButtonState = true;
  highlightCurrentChanges = false;
  notesVisible = false;
  alertsVisible = false;
  companyId = '';
  notesTotal = 0;
  officeholderRows: HighlightOfficeholder[] = [];
  selectedOfficeholders: Relationship[] = [];
  selectedShareholders: Relationship[] = [];
  selectedDocuments: DocumentRecord[] = [];
  directorContacts: Contact[] = [];

  protected readonly EntityType = EntityType;

  async ngOnInit() {
    const companyId = this.route.snapshot.paramMap.get('id');
    this.companyId = companyId ?? '';

    if (companyId != null) {
      try {
        this.loading = true;
        await this.loadProfile(companyId);
        await this.getDirectorContacts(companyId);
        await this.getPendingDocuments(companyId);
        await this.switchPendingState(this.pendingButtonState);

        this.initFormMenu();

        this.loading = false;
      }
      catch {
        this.toastr.error('Failed to load company profile', 'Error');
      }
    }
  }

  initFormMenu() {
    this.menuSections = [
      {
        title: 'Edit',
        subMenuItems: [
          {
            title: 'Edit company details',
            action: () => this.edit(),
          },
          {
            title: 'Generate Annual Statement (480F)',
            formName: 'Form 480F',
            action: () => this.generateAnnualStatement(),
            roles: ['superuser'],
            classes: 'superuser'
          },
        ],
      },
      {
        title: '',
        subMenuItems: [
          {
            title: 'Edit Billing & Signing contact',
            action: () => this.editSigningBillingContact(),
          },
        ],
      },
      {
        title: 'Company Name',
        subMenuItems: [
          {
            title: 'Company Name Change',
            formName: 'Form 205',
            action: () => this.openCompanyChangeNameForm(),
          },
          {
            title: 'Reserve New Company Name',
            formName: 'Form 410B',
            action: () => this.displayForm410B(),
          },
          {
            title: 'Extend Company Name Reservation',
            formName: 'Form 410F',
            action: () => this.displayForm410F(),
          },
        ],
      },
      {
        title: 'Company Status',
        subMenuItems: [
          {
            title: 'Change to Company Status',
            formName: 'Form 484B3',
            action: () => this.companyStatusChangeB3(),
            disabled: this.companyProfile.isPublic
          },
          // {
          //   title: 'Change to Company Type',
          //   formName: 'Form 206',
          //   action: () => this.openChangeCompanyTypeForm(),
          // },
          {
            title: 'Voluntary De-registration',
            formName: 'Form 6010',
            action: () => this.voluntaryDeregistration6010(),
          },
        ],
      },
      {
        title: 'Company Details Change',
        subMenuItems: [
          {
            title: 'Change of Address',
            formName: 'Form 484A1',
            action: () => this.address()
          },
          {
            title: 'Change of Ultimate Holding Company',
            formName: 'Form 484A3',
            action: () => this.uhcChangeA3(),
          },
        ],
      },
      {
        title: 'Others',
        subMenuItems: [
          // {
          //   title: 'Copy of Financial Statements and Reports',
          //   formName: 'Form 388',
          //   action: () => this.openForm388(),
          // },
          {
            title: 'Registered Agent Ceasing',
            formName: 'Form 361',
            action: () => this.registeredAgentCeasingForm361(),
          },
        ],
      },
      {
        title: 'Officers',
        subMenuItems: [
          {
            title: 'Notification by Officeholder of Resignation or Retirement',
            formName: 'Form 370',
            action: () => this.resignOrRetireOfficeholder(),
          },
          {
            title: 'Change Officer Name',
            formName: 'Form 484A2',
            action: (officeholder: Relationship[]) => this.companyChangePartyName(this.selectedOfficeholders),
          },
        ],
      },
      {
        title: '',
        subMenuItems: [
          {
            title: 'Cessation of Officeholder',
            formName: 'Form 484B1',
            action: (officeholder: Relationship[]) => this.ceaseCompanyOfficeholder(this.selectedOfficeholders),
          },
          {
            title: 'Appoint Officeholder',
            formName: 'Form 484B2',
            action: (officeholder: Relationship[]) => this.appointOfficeholder(this.selectedOfficeholders),
          },
        ],
      },
    ];
  }

  setActiveTab(tab: DetailTab): void {
    this.activeTab = tab.title;
  }

  profileViewChange(hideTabs: boolean) {
    this.hideTabs = hideTabs;
  }

  onSelectOfficeholdersChange(offices: Relationship[] = []): void {
    this.selectedOfficeholders = offices;
  }

  onSelectShareholdersChange(offices: Relationship[] = []): void {
    this.selectedShareholders = offices;
  }

  async loadProfile(companyId: string) {
    this.companyProfile = await firstValueFrom(this.profileService.getCompanyProfile(companyId));
    this.companyProfile.alerts = this.companyProfile.alerts.filter(alert => {
      return alert.status !== AlertStatus.Archived && alert.status !== AlertStatus.Ignored
    });

    this.alerts = this.companyProfile?.alerts;
    this.loadNotesTotal();
  }

  async getDirectorContacts(companyId: string) {
   this.directorContacts = await firstValueFrom(this.contactsService.getContacts(
     companyId, [RelationshipType.Director, RelationshipType.AlternativeDirector]
   ));
  }


  async getPendingDocuments(companyId: string): Promise<void> {
    this.pendingDocuments = await this.documentsService.getPendingDocuments(companyId);
    this.countPendingForm = this.pendingDocuments?.filter(d => d.status !== DocumentStatusEnum.Completed)?.length;
  }

  async switchPendingState(state: boolean): Promise<void> {
    this.pendingButtonState = state;

    const companyProfile = await firstValueFrom(this.profileService.getCompanyProfile(this.companyId, state));
    this.companyProfile.officers = companyProfile.officers;
    this.companyProfile.securityholders = companyProfile.securityholders;
    this.companyProfile = {...this.companyProfile};

    if (!state) {
      this.isDifferenceBetweenCompany = false;
      this.officeholderRows = this.companyProfilePrevious.officers.map(officer => {
        return { ...officer, previousIndividualDataOverride: officer.individualDataOverride };
      }) as HighlightOfficeholder[];

      return;
    }

    this.companyProfilePrevious = await firstValueFrom(this.profileService.getCompanyProfile(this.companyId, false));
    this.companyProfileDifference = diff(this.companyProfile, this.companyProfilePrevious);
    this.officeholderRows = this.getRows(this.companyProfile.officers, this.companyProfilePrevious.officers, state);

    if(Object.keys(this.companyProfileDifference)?.length) {
      this.isDifferenceBetweenCompany = true;
    }
  }

  getRows(officeholders: Relationship[], previousOfficeholders: Relationship[], pendingButtonState: boolean): HighlightOfficeholder[] {
    return officeholders.map((item, index) => {
      const previousOfficeholder = index < previousOfficeholders.length
        ? previousOfficeholders[index]
        : null;

      return {
        ...item,
        previousIndividualDataOverride: previousOfficeholder?.individualDataOverride ?? null,
        previousStart: previousOfficeholder?.start,
        previousEnd: previousOfficeholder?.end,
        pendingButtonState,
      }
    }) as unknown as HighlightOfficeholder[];
  }

  address(offices: Relationship[] = []) {
    if (this.modalOpened()) return;
    const relationshipIds = offices.map((officer) => officer.relationshipId);
    this.openModalWithCompany(new CompanyChangeAddress({ relationshipIds }));
  }

  editOfficer(officer: Relationship): void {
    const modalRef = this.modalService.open(OfficerContactEditComponent, { size: 'sm' });
    const instance = (modalRef.componentInstance as OfficerContactEditComponent);
    instance.company = this.companyProfile;
    instance.officer = officer;
    instance.previousOfficeholder = this.companyProfilePrevious
      .officers.find(o => o.relationshipId === officer.relationshipId);
    instance.din = officer.individualDataOverride?.din ?? '';

    instance.confirm.pipe(
      takeUntilDestroyed(this.destroyRef)
    ).subscribe();
  }

  openCompanyChangeNameForm(): void {
    if (this.modalOpened()) return;
    this.openModalWithCompany(new CompanyChangeName());
  }

  async onCompanyRefreshed() {
    await this.loadProfile(this.companyId);
  }

  selectTab(tab: string): void {
    this.activeTab = tab;
  }

  edit(): void {
    const modalRef = this.modalService.open(CompanyEditComponent);
    const instance = (modalRef.componentInstance as CompanyEditComponent);

    const copy = structuredClone(this.companyProfile);
    instance.editModel = copy;
    const observer = observe(copy);

    instance.$confirm = () => {
      return this.profileService.patchCompany(this.companyProfile.entityId, filterPatchOperations(generate(observer as Observer<Company>)))
        .pipe(tap({
          next:
            () => {
              void this.updateCompanyData();
              this.toastr.success('Changes saved', 'Success');
            },
          error: () => this.toastr.error('Failed to save changes', 'Error')
        }));
    };
  }

  editSigningBillingContact(): void {
    const modalRef = this.modalService.open(BillingSigningContactComponent);
    const instance = (modalRef.componentInstance as BillingSigningContactComponent);
    instance.editModel = structuredClone(this.companyProfile);
    instance.directorContacts = this.directorContacts;

    modalRef.result.then(
      async () => {
        await this.loadProfile(this.companyProfile.entityId);

        this.companyProfilePrevious.billingContactId = this.companyProfile.billingContactId;
        this.companyProfilePrevious.billingContact = this.companyProfile.billingContact;
        this.companyProfilePrevious.signingContactId = this.companyProfile.signingContactId;
        this.companyProfilePrevious.signingContact = this.companyProfile.signingContact;
      },
      () => {
      }
    );
  }

  //for testing
  async generateAnnualStatement() {
    try
    {
      const id = await firstValueFrom(this.profileService.generateAnnualStatementF(this.companyId));
      this.router.navigate(['/annual-statement', id], { queryParams: { step: 0 } });

      this.toastr.success('Annual statement generated', 'Success');
    }
    catch
    {
      this.toastr.error('Failed to generate annual statement', 'Error');
    }
  }


  uhcChangeA3() {
    if (this.modalOpened()) return;
    this.openModalWithCompany(new CompanyChangeHoldingCompany());
  }

  companyStatusChangeB3() {
    this.openModalWithCompany(new CompanyChangeSpecialPurpose());
  }

  voluntaryDeregistration6010(): void {
    this.openModalWithCompany(new CompanyChangeDeregister());
  }

  openFormRa01(): void {
    this.openModalWithCompany(new RegisterCeaseChangeAgentForm());
  }

  statementRequestRa71(): void {
    this.modalService.open(RA71CompanyStatementRequestComponent);
  }

  registeredAgentCeasingForm361(): void {
    this.openModalWithCompany(new CompanyForm361())
  }

  openRa04DirectDebitRequestForm(): void {
    this.openModalWithCompany(new AsicFormRA04());
  }

  requestCorrestionForm492(documentRecord?: DocumentRecord): void {
    this.openModalWithCompany(new CompanyMistakeCorrection());
  }

  notificationOfAdditionalInformationForm902(documentRecord?: DocumentRecord): void {
    this.openModalWithCompany(new CompanyMistakeCorrection({ correctionType: CompanyMistakeCorrectionType.F902 }));
  }

  withdrawLodgeDocumentForm106(): void {
    this.openModalWithCompany(new CompanyWithdrawLodgedDocumentChange());
  }

  displayForm410F(): void {
    this.openModalWithCompany(new Form410FModel());
  }

  displayForm410B(): void {
    this.openModalWithCompany(new Form410BModel());
  }

  appointOfficeholder(offices: Relationship[] = []): void {
    this.openModalWithCompany(new CompanyChangeOfficer({ offices: offices.filter(Boolean) }));
  }

  resignOrRetireOfficeholder(): void {
    this.openModalWithCompany(new CompanyChangeOfficerResignation());
  }

  companyChangePartyName(offices: Relationship[] = []): void {
    const relationshipIds = offices.map((officer) => officer.relationshipId).filter(Boolean);
    this.openModalWithCompany(new CompanyChangePartyName({ relationshipIds }));
  }

  ceaseCompanyOfficeholder(offices: Relationship[] = []): void {
    this.openModalWithCompany(new CompanyChangeOfficer({
      actionType: CompanyChangeOfficerType.Removal,
      offices: offices.filter(Boolean)
    }));
  }

  openChangeCompanyTypeForm(): void {
    return;
    this.openModalWithCompany(new ChangeCompanyType());
  }

  openForm388(): void {
    return;
    this.openModalWithCompany(new CopyFinancialStatementsAndReports());
  }

  openDraftForm(document: DocumentRecord): void {
    if (this.modalOpened()) return;
    this.documentsService.getById(document.documentId).subscribe((document) => {
      document.company = this.companyProfile;
      this.setDataUpdaterForModal(this.modalFormsService.openModalWithDocument(document, document.company, document.changes[0]));
    });
  }

  openShares(): void {
    void this.router.navigate(['shares', this.companyId]);
  }

  public async updateCompanyData(): Promise<void> {
    try {
      this.loading = true;
      await this.loadProfile(this.companyProfile.entityId);
      await this.getPendingDocuments(this.companyProfile.entityId);
      await this.switchPendingState(this.pendingButtonState);
      this.loading = false;
    }
    catch (error) {
      console.warn(error);
      this.loading = false;
    }
  }

  deleteDocumentChange(documentId: string): void {
    const modalRef = this.modalService.open(ConfirmComponent);
    (modalRef.componentInstance as ConfirmComponent).title = 'Delete changes';
    (modalRef.componentInstance as ConfirmComponent).message = `Are you sure you want to delete changes?`;
    (modalRef.componentInstance as ConfirmComponent).confirmText = 'Delete';
    (modalRef.componentInstance as ConfirmComponent).confirm = () => this.documentsService.deleteDocumentChange(documentId).pipe(
      tap(async () => {
        this.pendingDocuments = this.pendingDocuments.filter(doc => doc.documentId !== documentId);
        await this.updateCompanyData();
        this.toastr.success('Changes deleted!', 'Success');
      }),
      catchError((error: HttpErrorResponse) => {
        this.toastr.error("Error while deleting changes", "Error");
        return throwError(() => error);
      })
    );

    modalRef.closed.pipe(
      takeUntilDestroyed(this.destroyRef)
    ).subscribe();
  }

  selectDocuments(documents: DocumentRecord[]): void {
    this.selectedDocuments = documents;
  }

  deleteDocuments(): void {
    const documentIds = this.selectedDocuments.map(d => d.documentId);
    const modalRef = this.modalService.open(ConfirmComponent);
    (modalRef.componentInstance as ConfirmComponent).title = 'Delete changes';
    (modalRef.componentInstance as ConfirmComponent).message = `Are you sure you want to delete changes?`;
    (modalRef.componentInstance as ConfirmComponent).confirmText = 'Delete';
    (modalRef.componentInstance as ConfirmComponent).confirm = () => this.documentsService.deleteDocuments(documentIds).pipe(
      tap(async () => {
        this.pendingDocuments = this.pendingDocuments.filter(doc => !documentIds.includes(doc.documentId));
        await this.updateCompanyData();
        this.toastr.success('Changes deleted!', 'Success');
      }),
      catchError((error: HttpErrorResponse) => {
        this.toastr.error("Error while deleting changes", "Error");
        return throwError(() => error);
      })
    );

    modalRef.closed.pipe(
      takeUntilDestroyed(this.destroyRef)
    ).subscribe();
  }

  private openModalWithCompany<T>(
    change: EntityChangeData,    // instance of changes in form
    isEdit = false,
    props: Partial<T> = {},      // props for form component
    modalOptions: NgbModalOptions = {}
  ): NgbModalRef | undefined {
    if (this.modalOpened()) return;
    return this.setDataUpdaterForModal(this.modalFormsService.openModalWithCompany(change, this.companyProfile, isEdit, props, modalOptions));
  }

  private setDataUpdaterForModal(modalRef: NgbModalRef): NgbModalRef {
    modalRef.result.then(() => { void this.updateCompanyData(); this.highlightCurrentChanges = true; }, () => {});
    return modalRef;
  }

  private loadNotesTotal(): void {
    this.notesService.getNotesTotal(this.companyProfile.entityId)
      .subscribe({ next: (total) => this.notesTotal = total });
  }

  get notesLabel(): string {
    if (this.notesTotal === 0) {
      return 'Notes';
    } else if (this.notesTotal === 1) {
      return '1 Note';
    }
    return `${ this.notesTotal } Notes`;
  }

  get alertsLabel(): string {
    const alertsTotal = this.companyProfile?.alerts?.length;
    if (alertsTotal === 0) {
      return 'Alerts';
    } else if (alertsTotal === 1) {
      return '1 Alert';
    }
    return `${alertsTotal} Alerts`;
  }

  get modalOpened() {
    return this.modalFormsService.modalOpened;
  }
}

