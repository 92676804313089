<app-stepper-form
  header="Company Standard"
  [steps]="steps"
  [currentStep]="currentStep"
  [currentStepIndex]="currentStepIndex"
  [isLoading]="isLoading"
  [form]="stepperForm"
>
  <div class="step-content" [formGroup]="stepperForm">
    <app-stepper-form-description
      formKey="201"
      [hidden]="currentStep !== StepsEnum.FormDescription"
    />


    <!--  ================================================================================================================  -->


    <section
      class="step-section"
      [hidden]="currentStep !== StepsEnum.CompanyDetails"
      [formGroupName]="StepsEnum.CompanyDetails"
    >
      <h1 class="h1-heading">{{ steps[currentStepIndex].label }}</h1>

      <div class="form-group">
        <app-yes-no-control
          label="Use ACN as proposed company name?"
          formControlName="useAcnAsNewCompanyName"
        />
      </div>

      <div
        class="form-group new-company-name-wrapper"
        [hidden]="companyDetailsForm.value.useAcnAsNewCompanyName !== false"
      >
        <app-input
          label="New Company Name"
          formControlName="companyName"
        />
      </div>

      <div
        class="form-group"
        [hidden]="companyDetailsForm.value.useAcnAsNewCompanyName !== true"
      >
        <app-select
          label="Legal Element"
          formControlName="newLegalElementsIfAcnIsUsed"
          [options]="legalElementsOptions"
          [valuePrimitive]="true"
        />
      </div>

      <div [hidden]="companyDetailsForm.value.useAcnAsNewCompanyName !== false">
        <div class="form-group">
          <app-yes-no-control
            label="Has a name reservation been lodged to reserve the proposed new company name?"
            formControlName="newNameReservedWithForm410"
          />
        </div>

        <div class="form-group" [hidden]="companyDetailsForm.value.newNameReservedWithForm410 !== true">
          <app-input
            label="Reservation Number"
            formControlName="reservationNumber"
            [customErrors]="reservationNumberCustomErrors"
          />
        </div>

        <div [hidden]="companyDetailsForm.value.newNameReservedWithForm410 !== true">
          <div class="form-group">
            <app-radio
              formControlName="applicantType"
              [label]="'Type of applicant'"
              [options]="applicantTypeOptions"
            ></app-radio>
          </div>

          <div class="form-group" [hidden]="companyDetailsForm.value.applicantType !== ApplicantType.Company">
            <app-input
              label="Company Name"
              formControlName="applicantCompanyName"
            />
          </div>

          <div class="form-group" [hidden]="companyDetailsForm.value.applicantType !== ApplicantType.Director">
            <app-input
              label="Individual Name"
              formControlName="applicantIndividualName"
              [customErrors]="directorFullNameValidatorMsg"
            />
          </div>
        </div>

        <div class="form-group">
          <app-yes-no-control
            label="Is the proposed name identical to a registered business name(s)?"
            formControlName="isProposedNameIdenticalToRegisteredBusinessName"
          />
        </div>

        <div [hidden]="companyDetailsForm.value.isProposedNameIdenticalToRegisteredBusinessName !== true">
          <div class="form-group">
            <div class="form-group">
              <app-checkbox
                formControlName="abnConfirmation"
                [label]="abnDeclarationMessage"
              />

              @if (companyDetailsForm.controls.abnConfirmation.touched && companyDetailsForm.controls.abnConfirmation.errors) {
                <app-validation-error [control]="companyDetailsForm.controls.abnConfirmation"/>
              }
            </div>
          </div>

          <div class="form-group">
            <app-abn-masked-input
              formControlName="businessNameHolderAbn"
            />
          </div>
        </div>
        <div class="form-group">
          <app-yes-no-control
            label="Would you like ASIC to manually review the company registration?"
            formControlName="manualReviewRequested"
          />
        </div>

        <div class="form-group" [hidden]="companyDetailsForm.value.manualReviewRequested !== true">
          <app-textarea
            label="Manual Processing Reason"
            formControlName="manualProcessingReason"
          />
        </div>
      </div>

      <div class="form-group">
        <app-select
          label="Jurisdiction of Registration"
          formControlName="jurisdiction"
          [options]="JurisdictionTypeOptions"
          [valuePrimitive]="true"
        />
      </div>

      <div class="form-group">
        <app-yes-no-control
          label="Does this company have an Ultimate Holding Company?"
          formControlName="haveUltimateHoldingCompany"
        />
      </div>

      <div
        class="uhc-part"
        [hidden]="companyDetailsForm.controls.haveUltimateHoldingCompany.value !== true"
        [formGroup]="companyDetailsForm.controls.ultimateHoldingCompany">
        <div class="form-group">
          <app-autocomplete
            label="Place of incorporation of Ultimate Holding Company"
            formControlName="ultimateHoldingCompanyCountry"
            [options]="countries"
            [valuePrimitive]="true"
          />
        </div>

        <div
          class="form-group"
          [hidden]="!CountriesService.isAustralianStateOrAustralia(companyDetailsForm.controls.ultimateHoldingCompany.controls.ultimateHoldingCompanyCountry.value)"
        >
          <app-company-name-acn-form-group
            nameTitle="Ultimate Holding Company Name"
            acnTitle="Ultimate Holding Company ACN"
            [formGroup]="companyDetailsForm.controls.ultimateHoldingCompany"
            [shouldLookForCompanyName]="false"
          />
        </div>

        <div class="form-group"
             [hidden]="CountriesService.isAustralianStateOrAustralia(companyDetailsForm.controls.ultimateHoldingCompany.controls.ultimateHoldingCompanyCountry.value)">
          <app-input
            label="Ultimate Holding Company Name"
            formControlName="aboardCompanyName"
          />
        </div>
      </div>
    </section>

    <!--  ================================================================================================================  -->

    <section
      class="step-section"
      [hidden]="currentStep !== StepsEnum.Address"
      [formGroupName]="StepsEnum.Address"
    >
      <h1 class="h1-heading">{{ steps[currentStepIndex].label }}</h1>

      <div class="form-group">
        <app-address-and-occupier-form-group
          label="Registered Address"
          [careOfAllowed]="true"
          [suggestedAddresses]="suggestedAddresses"
          [formGroup]="addressForm.controls.registeredAddressAndOccupier"
          [changeDateHidden]="true"
        >
          <app-validation-error
            [control]="addressForm.controls.registeredAddressAndOccupier.controls.address"
            [customErrors]="addressesCustomErrors"
          ></app-validation-error>
        </app-address-and-occupier-form-group>
      </div>

      <div class="form-group">
        <app-yes-no-control
          label="Is principal address the same as the registered address?"
          formControlName="principalAddressSameAsRegisteredAddress"
        />
      </div>

      <div class="form-group" [hidden]="addressForm.controls.principalAddressSameAsRegisteredAddress.value !== false">
        <app-address-form-group
          label="Principal Address"
          [useInternationalAddresses]="false"
          [suggestedAddresses]="suggestedAddresses"
          [formGroup]="addressForm.controls.principalAddress"
        />

        <app-validation-error
          [control]="addressForm.controls.principalAddress"
          [customErrors]="addressesCustomErrors"
        ></app-validation-error>
      </div>
    </section>


    <!--  ================================================================================================================  -->


    <section
      class="step-section"
      [hidden]="currentStep !== StepsEnum.Officeholders"
      [formGroupName]="StepsEnum.Officeholders"
    >
      <h1 class="h1-heading">{{ steps[currentStepIndex].label }}</h1>

      @if (officeholdersForm.touched && (officeholdersForm.errors?.['foreignAddressForAllDirectors'] || officeholdersForm.errors?.['foreignAddressForAllSecretaries'])) {
        <div class="form-group">
          <app-validation-error
            [control]="officeholdersForm"
            [customErrors]="addressesCustomErrors"
          ></app-validation-error>
        </div>
      }

      <ul class="list-unstyled" formArrayName="officers">
        @for (officeholderFormGroup of officeholdersForm.controls.officers.controls; track officeholderFormGroup; let first = $first; let last = $last; let index = $index) {
          <li [formGroupName]="index">
            @if (officeholdersForm.controls.officers.controls.length > 1) {
              <div class="form-group d-flex flex-row-reverse">
                <app-button
                  iconClass="icon ic-sm ic-close"
                  (clickBtn)="removeOfficeholder(index)"
                />
              </div>
            }

            <div class="form-group">
              <app-individual-data-form-group
                [formGroup]="officeholderFormGroup"
                [useInternationalAddresses]="true"
                [suggestedAddresses]="suggestedAddresses"
                [fullNameSuggestions]="suggestedNames"
              />

              <app-validation-error
                [control]="officeholderFormGroup.controls.address"
                [customErrors]="addressesCustomErrors"
              ></app-validation-error>
            </div>

            <div class="form-group">
              <div class="font-weight-bold mb-2">Position</div>
              <div class="form-group d-flex flex-column gap-12">
                @for(type of companyStandardPositionTypesOptions; track $index; let firstPositionType = $first) {
                  <app-checkbox
                    [label]="type.label"
                    [value]="isEdit ? checkSelectedType(type.value, index) : first && firstPositionType"
                    (change)="onCheckboxChange($event, type.value, index, officeholderFormGroup)">
                  </app-checkbox>
                }
              </div>
            </div>


            @if (!last) {
              <app-divider margin="1rem 0"/>
            }
          </li>
        }

        <li>
          <app-button
            iconClass="icon ic-xs ic-plus"
            text="Add Officeholder"
            (clickBtn)="addOfficeholder()"
          />
        </li>
      </ul>

    </section>


    <!--  ================================================================================================================  -->


    <section
      class="step-section"
      [hidden]="currentStep !== StepsEnum.Shareholders"
      [formGroupName]="StepsEnum.Shareholders"
    >
      <h1 class="h1-heading">{{ steps[currentStepIndex].label }}</h1>

      @for (shareholder of shareholdersForm.controls.securityHolders.controls; track shareholdersForm; let shareholderIndex = $index; let last = $last) {
        @if (shareholderIndex > 0) {
          <div class="form-group d-flex flex-row-reverse">
            <app-button
              iconClass="icon ic-sm ic-close"
              text="Remove Shareholder"
              (clickBtn)="removeShareholder(shareholderIndex)"
            />
          </div>
        }

        <div [formGroup]="shareholder">
          <div class="form-group">
            <app-select
              label="Shareholder Type"
              formControlName="shareholderType"
              [options]="newShareholderTypesSelectOptions"
              [valuePrimitive]="true"
            />
          </div>

          <div
            class="form-group"
            [hidden]="shareholder.controls.shareholderType.value !== ShareholderTypesEnum.Individual"
          >
            <app-individual-data-form-group
              [formGroup]="shareholder.controls.individualShareholder"
              [useInternationalAddresses]="true"
              [individualDataFormLabels]="individualDataFormLabels"
              [hiddenControls]="hiddenIndividualControls"
              [suggestedAddresses]="suggestedAddresses"
              [fullNameSuggestions]="suggestedNames"
            />
          </div>

          <div
            [hidden]="shareholder.controls.shareholderType.value !== ShareholderTypesEnum.Corporate"
            [formGroup]="shareholder"
          >
            <div class="form-group">
              <app-company-name-acn-form-group
                [formGroup]="shareholder"
                [hideAcn]="shareholder.controls.isOverseasCompany.value!"
                [shouldLookForCompanyName]="!shareholder.controls.isOverseasCompany.value"
              >
                <div class="form-group">
                  <app-checkbox
                    label="This is an overseas company or the company doesn't have an ACN"
                    formControlName="isOverseasCompany"
                  />
                </div>
              </app-company-name-acn-form-group>
            </div>

            <div class="form-group">
              <app-address-form-group
                [careOfAllowed]="true"
                [formGroup]="shareholder.controls.registeredAddress"
                [useInternationalAddresses]="shareholder.controls.isOverseasCompany.value!"
                [addressFormLabels]="addressFormLabels"
                [suggestedAddresses]="suggestedAddresses"
              />
            </div>
          </div>

          <div
            class="form-group"
            [hidden]="shareholder.controls.shareholderType.value !== ShareholderTypesEnum.JointShareholder"
          >
            <app-share-joint-security-holder
              formControlName="joint"
              [suggestedAddresses]="suggestedAddresses"
              [fullNameSuggestions]="suggestedNames"
            />
          </div>

          <div
            [hidden]="shareholder.controls.shareholderType.value === null || shareholder.controls.shareholderType.value === ShareholderTypesEnum.ExistingShareholder">
            <div class="form-group">
              <app-yes-no-control
                label="Are the shares owned on behalf of another individual, company, trust or fund?"
                formControlName="isNotBeneficialOwner"
              />
            </div>

            <div class="form-group" [hidden]="!shareholder.controls.isNotBeneficialOwner.value">
              <app-input
                label="Shares are owned on behalf of:"
                formControlName="beneficialOwner"
              />
            </div>
          </div>

          @for (securityHolding of shareholder.controls.securityTypes.controls; track securityHolding; let securityHoldingIndex = $index) {
            @if (securityHoldingIndex > 0) {
              <div class="form-group d-flex flex-row-reverse">
                <app-button
                  iconClass="icon ic-sm ic-close"
                  text="Remove Share"
                  (clickBtn)="removeShareholdersShareHolding(shareholderIndex, securityHoldingIndex)"
                />
              </div>
            }

            <div class="share-numbers-grid" [formGroup]="securityHolding">
              <div class="form-group share-types">
                <app-share-security-types-dropdown
                  formControlName="securityTypeId"
                  [options]="securityTypes"
                  [allowAddNewShareType]="shareholder.controls.shareholderType.value !== null || shareholder.controls.shareholderType.value !== ShareholderTypesEnum.ExistingShareholder"
                  (addShareType)="addShareType(shareholderIndex,securityHoldingIndex, $event)"
                ></app-share-security-types-dropdown>
              </div>

              <div class="form-group number-of-shares-cancelled">
                <app-input-number
                  label="Number of Shares Issued"
                  formControlName="number"
                  [min]="0"
                  [customErrors]="totalNumberOfSharesCustomErrors"
                ></app-input-number>
              </div>

              <div class="form-group total-paid">
                <app-input-number
                  label="Total Paid"
                  formControlName="paid"
                  [min]="0"
                  [customErrors]="totalNumberOfSharesCustomErrors"
                ></app-input-number>
              </div>

              <div class="form-group total-unpaid">
                <app-input-number
                  label="Total Unpaid"
                  formControlName="unpaid"
                  [min]="0"
                  [customErrors]="totalNumberOfSharesCustomErrors"
                ></app-input-number>
              </div>
            </div>

            <div class="form-group" [hidden]="
              securityHolding.controls.number.untouched
              && securityHolding.controls.paid.untouched
              && securityHolding.controls.unpaid.untouched
              && !securityHolding?.errors">
              <app-validation-error
                [control]="shareholdersForm"
                [customErrors]="customErrorsOfShareCancellationNumbers"
              ></app-validation-error>
            </div>
          }

          <div class="form-group d-flex flex-row-reverse">
            <app-button
              iconClass="icon ic-xs ic-plus"
              text="Add Share"
              (clickBtn)="addSecurityHolding(shareholderIndex)"
            />
          </div>
        </div>

        @if (!last) {
          <app-divider margin="1rem 0"/>
        }
      }

      <div class="form-group d-flex flex-row-reverse">
        <app-button
          class="w-100"
          iconClass="icon ic-xs ic-plus"
          text="Register Shareholder"
          (clickBtn)="addShareholder()"
        />
      </div>
    </section>
  </div>
</app-stepper-form>
