<div class="annual-container">
  <div class="annual-header">
    <app-page-title
      header="Annual Statement"
      iconClass="ic-documents"
    />

    <div class="actions-container">
      <div class="btn-container">
        <app-button [text]="'Close'" [btnColor]="'white'" (clickBtn)="close()"></app-button>
        @if(currentStepIndex === AnnualStep.SignDocuments && buttonText) {
          <app-divider [vertical]="true"></app-divider>
          <app-button
            [text]="buttonText"
            [iconClassLast]="'icon ic-arrow-right'"
            [loading]="loading || patchLoading() || sendSignLoading() || generateStatementLoading()"
            [isDisabled]="loading || patchLoading() || disabledHeaderBtn() || sendSignLoading() || generateStatementLoading()"
            (clickBtn)="buttonAction()"
          ></app-button>
        } @else if(currentStepIndex !== AnnualStep.SignDocuments) {
          <app-divider [vertical]="true"></app-divider>
          <app-button
            [text]="'Next Step'"
            [iconClassLast]="'icon ic-arrow-right'"
            [loading]="loading || patchLoading() || sendSignLoading() || generateStatementLoading()"
            [isDisabled]="loading || patchLoading() || disabledHeaderBtn() || sendSignLoading() || generateStatementLoading()"
            (clickBtn)="confirmChanges()"
          ></app-button>
        }
      </div>
    </div>
  </div>

  <div class="annual-content" [class.hide]="collapsedStateBtn">
    <app-annual-statement-sidebar
      [class.hide]="collapsedStateBtn"
      [loadCompany]="loading"
      [currentStepIndex]="currentStepIndex"
      [annualStatement]="annual"
      [entity]="actualCompany || actualTrustProfile"
      [entityType]="entityType"
      (updateAnnualStatement)="updateAnnual()"
    ></app-annual-statement-sidebar>

    <div class="step-content">
      <app-button
        class="sidebar-switch-btn"
        [btnColor]="'white'"
        [iconClass]="collapsedStateBtn ? 'icon ic-arrow-right' : 'icon ic-arrow-left'"
        (clickBtn)="collapsedStateBtn = !collapsedStateBtn"
      ></app-button>

      <div class="d-flex flex-column w-100">
        @if(!loading) {
          <app-clickable-stepper
            class="stepper-container"
            [shouldControlStepsUsingInput]="true"
            [currentStepIndex]="currentStepIndex"
            [steps]="steps"
            [disableSteps]="modalOpened()"
            (stepIndexChange)="setCurrentStep($event)"
          ></app-clickable-stepper>
        }

        <div class="content-container" [class.sign-container]="currentStepIndex === AnnualStep.SignDocuments">
          @switch (currentStepIndex) {
            @case(AnnualStep.Authorization) {
              @if(!loading && annual && actualCompany) {
                <app-annual-statement-authorization
                  [annual]="annual"
                  [company]="actualCompany"
                  [partnerManagerOptions]="partnerManagerOptions"
                  [confirmAction$]="confirmAction$"
                  (nextStep)="setNextStep()"
                ></app-annual-statement-authorization>
              } @else if(loading) {
                <div class="sign-container" *ngTemplateOutlet="skeletonTemplate"></div>
              } @else {
                <div></div>
              }
            } @case(AnnualStep.GenerateDocuments) {
              @if(!loading && annual && actualCompany) {
                <app-annual-generate-documents
                  [annual]="annual"
                  [company]="actualCompany"
                  [confirmAction$]="confirmAction$"
                  (nextStep)="setNextStep()"
                  (updateAnnualDocuments)="updateAnnual()"
                ></app-annual-generate-documents>
              } @else if(loading) {
                <div class="sign-container" *ngTemplateOutlet="skeletonTemplate"></div>
              } @else {
                <div></div>
              }
            } @case(AnnualStep.SignDocuments) {
              @if(!loading && annual) {
                <app-annual-sign-documents
                  [documentId]="annual.documentId"
                  [documentSigning]="annual.documentSigning"
                  [annualStatementStatus]="annual.annualStatementStatus"
                  [changeAuthorisation]="annual.changeAuthorisation"
                  [paymentDeadline]="annual.paymentDeadline"
                  [reviewDate]="annual.reviewDate"
                  [company]="annual.company"
                  [confirmAction$]="confirmAction$"
                  [confirmSentESignAction$]="confirmSentESignAction$"
                  [confirmPaperSignAction$]="confirmPaperSignAction$"
                  (updateDocuments)="updateDocuments()"
                  (nextStep)="setNextStep()"
                ></app-annual-sign-documents>
              } @else if(loading) {
                <div class="sign-container" *ngTemplateOutlet="skeletonTemplate"></div>
              } @else {
                <div></div>
              }
            }
            @default {
              <p>Unexpected step index: {{ currentStepIndex }}</p>
            }
          }
        </div>
      </div>
    </div>
  </div>
</div>

<ng-template #skeletonTemplate>
  <div class="skeleton-container">
    <div class="mb-4">
      <ngx-skeleton-loader count="1" appearance="line" />
    </div>
    <ngx-skeleton-loader count="1" appearance="line" />
    <ngx-skeleton-loader count="2" appearance="line" />
    <div class="d-flex gap-24">
      <ngx-skeleton-loader style="width: 50%" count="1" appearance="line" />
      <ngx-skeleton-loader style="width: 50%" count="1" appearance="line" />
    </div>
    <ngx-skeleton-loader count="2" appearance="line" />
    <div class="d-flex gap-24">
      <ngx-skeleton-loader style="width: 50%" count="1" appearance="line" />
      <ngx-skeleton-loader style="width: 50%" count="1" appearance="line" />
    </div>
    <ngx-skeleton-loader count="6" appearance="line" />
  </div>
</ng-template>
