import { Component, EventEmitter, Input, Output, inject } from '@angular/core';
import { CurrencyPipe, DatePipe } from "@angular/common";
import { Company } from "../../../models/company";
import { DividerComponent } from "../common/divider/divider.component";
import { ButtonComponent } from "../common/button/button.component";
import {
  NgbDropdown,
  NgbDropdownItem,
  NgbDropdownMenu,
  NgbDropdownToggle,
} from "@ng-bootstrap/ng-bootstrap";
import { ExternalSourceType } from '../../../models/enums/externalEntityEnums';
import { ToastrService } from 'ngx-toastr';
import { SubMenuItem } from "../../../models/sectionFormMenu";
import { ModalFormsService } from "../../../services/modal-forms.service";
import { GeneratePayslipComponent } from "../../modals/generate-payslip/generate-payslip.component";
import { TooltipComponent } from "../common/tooltip/tooltip.component";
import {
  AnnualPaymentRemindersComponent
} from "../../modals/annual/annual-payment-reminders/annual-payment-reminders.component";
import { DateAgoPipe } from '../../../pipes/dateAgoPipe';
import { CompaniesService } from '../../../services/companies.service';
import { XpmCardProfileComponent } from "../card-profiles/xpm-card-profile/xpm-card-profile.component";
import { Entity } from '../../../models/entity';
import { EntityType } from '../../../models/enums/entityType';

@Component({
  selector: 'app-company-profile-card',
  standalone: true,
  imports: [
    CurrencyPipe,
    DividerComponent,
    ButtonComponent,
    NgbDropdown,
    NgbDropdownItem,
    NgbDropdownMenu,
    NgbDropdownToggle,
    DatePipe,
    TooltipComponent,
    DateAgoPipe,
    XpmCardProfileComponent
],
  templateUrl: './company-profile-card.component.html',
  styleUrl: './company-profile-card.component.scss'
})
export class CompanyProfileCardComponent {
  @Input() companyProfile!: Company;
  @Input() disableControls = false;
  @Input() countPendingForm = 0;

  @Output() openCompanyChangeNameForm = new EventEmitter<void>();
  @Output() onCompanyRefreshed = new EventEmitter<void>();
  @Output() onSelectTab = new EventEmitter<string>();
  private toastr = inject(ToastrService);
  private modalFormsService = inject(ModalFormsService);
  private companyService = inject(CompaniesService);
  ExternalSourceType = ExternalSourceType;
  entityType: EntityType = EntityType.Company;

  debtUpdatedDate = new Date();
  readonly refreshDebtMsg = 'Your request has been submitted. We are expecting ASIC\'s' +
    ' response soon. Once you\'ve received it, you\'ll be notified via the Alerts page.'
  companyDebtMenu: SubMenuItem[] = [
    {
      title: 'Refresh Company Debt',
      iconClass: 'icon ic-refresh',
      action: () => this.refreshCompanyDebt()
    },
    {
      title: 'Generate a Payslip',
      iconClass: 'icon ic-generate-documents',
      action: () => this.generatePayslip()
    },
    {
      title: 'Send Payment Reminder',
      iconClass: 'icon ic-send',
      action: () => this.sendPaymentReminder()
    },
    {
      title: 'Pay Debt',
      iconClass: 'icon ic-debt',
      action: () => this.payDebt()
    },
  ];

  onOpenCompanyChangeNameForm(): void {
    this.openCompanyChangeNameForm.emit();
  }

  onCompanyRefreshedEvent(): void {
    this.onCompanyRefreshed.emit();
  }

  refreshCompanyDebt(): void {
    alert(this.refreshDebtMsg);
  }

  generatePayslip(): void {
    const componentInstance = this.modalFormsService.openModal(GeneratePayslipComponent, {
      size: 'sm'
    }).componentInstance as GeneratePayslipComponent;
    componentInstance.companyProfile = this.companyProfile;

    const modalElement: HTMLElement | null = componentInstance.modalElement?.nativeElement as HTMLElement | null;
    if (modalElement) {
      modalElement.addEventListener('keydown', (event: KeyboardEvent) => {
        if (event.key === 'Enter') {
          event.stopPropagation();
          event.preventDefault();
        }
      });
    }
  }

  sendPaymentReminder(): void {
    const componentInstance = this.modalFormsService
      .openModal(AnnualPaymentRemindersComponent).componentInstance as AnnualPaymentRemindersComponent;
    componentInstance.isAnnualReminder = false;
    componentInstance.companyProfile = this.companyProfile;

  }

  payDebt(): void {
    const payUrl = 'https://paypaperbills.postbillpay.com.au/postbillpay/pay/default';
    window.open(payUrl, '_blank');
  }

  openDocumentsTab(): void {
    this.onSelectTab.emit('Documents');
  }

  triggerRA71(): void {
    this.disableControls = true;

    this.companyService.triggerRA71(this.companyProfile.entityId).subscribe({
      next: () => {
        this.toastr.success('Company profile refresh has been triggered and will update within a few minutes');
        this.disableControls = false;
      },
      error: () => {
        this.toastr.error('Failed to trigger company profile refresh');
        this.disableControls = false;
      }
    });
  }
}
