import { Component, DestroyRef, EventEmitter, Input, OnInit, Output, inject, input } from '@angular/core';
import { FormBuilder, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { InputPhoneNumberComponent } from '../../../components/common/input-phone-number/input-phone-number.component';
import { InputComponent } from '../../../components/common/input/input.component';
import { CommonModalFormComponent } from '../../common-modal-form/common-modal-form.component';
import { CustomFormValidators } from '../../../../custom-form-validators/custom-form-validators';
import { UpdateRecipientInfoModel } from '../../../../models/signDocuments';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { DocumentSigningsService } from '../../../../services/document-signing.service';
import { ToastrService } from 'ngx-toastr';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { catchError, of, tap } from 'rxjs';

@Component({
  selector: 'app-change-signatory-info-modal',
  standalone: true,
  imports: [
    FormsModule,
    InputComponent,
    InputPhoneNumberComponent,
    CommonModalFormComponent,
    ReactiveFormsModule
  ],
  templateUrl: './change-signatory-info-modal.component.html',
  styleUrl: './change-signatory-info-modal.component.scss'
})
export class ChangeSignatoryInfoModalComponent implements OnInit {
  @Output() confirm = new EventEmitter<UpdateRecipientInfoModel>();
  @Input() envelopeId = '';
  @Input() email: string = '';
  @Input() phone: string = '';
  @Input() id: string = '';
  @Input() fullName: string = '';

  private fb: FormBuilder = inject(FormBuilder);
  private activeModal = inject(NgbActiveModal);
  private documentSigningService = inject(DocumentSigningsService);
  private toastr = inject(ToastrService);
  #destroyRef = inject(DestroyRef);

  form!: FormGroup
  isLoading: boolean = false;

  async ngOnInit(): Promise<void> {
    this.form = this.fb.group({
      recipientId: [this.id, Validators.required],
      fullName: [this.fullName, Validators.required],
      email: [this.email, [Validators.required, CustomFormValidators.emailValidator]],
      phone: [this.phone],
    });
  }

  save(): void {
    this.isLoading = true;
    let updateModel = this.form.getRawValue() as UpdateRecipientInfoModel;
    updateModel.envelopeId = this.envelopeId;
    this.documentSigningService.updateRecipientInfo(updateModel).pipe(
      tap(() => {
        this.confirm.emit(updateModel);
        this.toastr.success("Updated.", "Success");
        this.isLoading = false;
        this.activeModal.close();
      }),
      catchError((err) => {
        this.toastr.error("Error happen.", "Error");
        this.isLoading = false;
        return of('ERR');
      }),
      takeUntilDestroyed(this.#destroyRef)
    ).subscribe();
  }

  close(): void {
    this.activeModal.close();
  }

}
