export class ParsedName {
  firstName: string;
  middleName1: string;
  middleName2: string;
  lastName: string;
  fullName: string;
  fullNameWithMiddle: string;

  constructor(data: Partial<ParsedName> = {}) {
    this.firstName = data.firstName ?? '';
    this.middleName1 = data.middleName1 ?? '';
    this.middleName2 = data.middleName2 ?? '';
    this.lastName = data.lastName ?? '';
    this.fullName = this.buildFullName();
    this.fullNameWithMiddle = this.buildFullNameWithMiddle();
  }

  private buildFullName(): string {
    return this.lastName.includes(' ')
      ? `${ this.lastName }, ${ this.firstName }`.trim()
      : `${ this.firstName } ${ this.lastName }`.trim();
  }

  private buildFullNameWithMiddle(): string {
    const fullNameWithMiddleParts = this.lastName.includes(' ')
      ? [`${ this.lastName },`, this.firstName, this.middleName1, this.middleName2]
      : [this.firstName, this.middleName1, this.middleName2, this.lastName];

    return fullNameWithMiddleParts.filter(Boolean).join(' ').trim();
  }
}

