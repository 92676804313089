import { EsigningEmailRecipients } from "../../../../../models/documentSettingsData";
import { SelectOption } from "../../../../../models/selectOptions";

export const NotifyWhenAllPartiesSignedDocumentLabelRecord: Record<EsigningEmailRecipients, string> = {
  [EsigningEmailRecipients.SenderOnly]: 'Sender Only',
  [EsigningEmailRecipients.RecipientsOnly]: 'Recipients Only',
  [EsigningEmailRecipients.SenderAndRecipients]: 'Sender and Recipients',
  [EsigningEmailRecipients.DoNotSend]: 'Do not Send',
};

export const NotifyWhenAllPartiesSignedDocumentOptions: SelectOption[] = [
  {
    label: NotifyWhenAllPartiesSignedDocumentLabelRecord[EsigningEmailRecipients.SenderOnly],
    value: EsigningEmailRecipients.SenderOnly
  },
  {
    label: NotifyWhenAllPartiesSignedDocumentLabelRecord[EsigningEmailRecipients.RecipientsOnly],
    value: EsigningEmailRecipients.RecipientsOnly
  },
  {
    label: NotifyWhenAllPartiesSignedDocumentLabelRecord[EsigningEmailRecipients.SenderAndRecipients],
    value: EsigningEmailRecipients.SenderAndRecipients
  },
  {
    label: NotifyWhenAllPartiesSignedDocumentLabelRecord[EsigningEmailRecipients.DoNotSend],
    value: EsigningEmailRecipients.DoNotSend
  },
];


