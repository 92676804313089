export class EdgeTransactionRecord {
    public transactionId: string;
    public createdOn: Date;
    public transmittedOn: Date | null;
    public formCode: string | null;
    public direction: EdgeTransactionDirection;
    public status: EdgeTransactionStatus;
    public organisationId: string;
    public traceNumber: string | null;
    public useSecondary: boolean;
    public documentIds: string[];
    public documentIdsString: string;
    public transactionText: string;

    constructor(documentRecord: Partial<EdgeTransactionRecord> = {}) {
        this.transactionId = documentRecord.transactionId ?? '';
        this.createdOn = documentRecord.createdOn ? new Date(documentRecord.createdOn) : new Date();
        this.transmittedOn = documentRecord.transmittedOn ? new Date(documentRecord.transmittedOn) : null;
        this.formCode = documentRecord.formCode ?? null;
        this.direction = documentRecord.direction ?? EdgeTransactionDirection.Inbound;
        this.status = documentRecord.status ?? EdgeTransactionStatus.Created;
        this.organisationId = documentRecord.organisationId ?? '';
        this.traceNumber = documentRecord.traceNumber ?? null;
        this.useSecondary = documentRecord.useSecondary ?? false;
        this.documentIds = documentRecord.documentIds ?? [];
        this.documentIdsString = documentRecord.documentIdsString ?? '';
        this.transactionText = documentRecord.transactionText ?? '';
    }
}

export enum EdgeTransactionDirection {
    Inbound = 0,
    Outbound = 1
}

export enum EdgeTransactionStatus {
    Created = 0,
    Processing = 1,
    Processed = 2,
    Transmitting = 3,
    Transmitted = 4,
    TransmissionReportReceived = 5,
    Error = 6
}