<div
  class="signing-status-tag"
  [ngClass]="{
    'waiting-for-sending': SigningStatus.WaitingForSending === status,
    'ready': SigningStatus.ReadyToSend === status,
    'sent': SigningStatus.Sent === status,
    'rejected': SigningStatus.Rejected === status,
    'signed': SigningStatus.Signed === status,
  }"
>
  <span>{{ status | signingStatus }}</span>
</div>
