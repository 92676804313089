<app-stepper-form
  header="Appointment of Officeholder"
  subheader="Form 484B2"
  [showSteps]="false"
  [currentStepIndex]="currentStepIndex"
  [currentStep]="currentStep"
  [steps]="steps"
  [form]="stepperForm"
  [isLoading]="isLoading"
>
  <div class="step-content" [formGroup]="stepperForm">
    <app-stepper-form-description
      formKey="484 B2"
      [hidden]="currentStep !== StepsEnum.FormDescription"
    />

    <section
      class="step-section"
      [formGroupName]="StepsEnum.AppointOfficer"
      [hidden]="currentStep !== StepsEnum.AppointOfficer"
    >
      <h1 class="h1-heading">Appoint Officer</h1>

      <div class="form-group">
        <app-date-picker
          label="Date of Appointment"
          formControlName="changeDate"
          [minDate]="minDate"
          [maxDate]="maxDate"
          [customErrors]="customDatepickerErrors"
        />
      </div>

      <div class="form-group">
        <app-yes-no-control
          label="Is this an appointment of new officeholder?"
          formControlName="isNewOfficeholder"
        />
      </div>

      @if (isNewOfficeholderSignal() == false) {
        <div class="form-group">
          <app-autocomplete
            label="Appointed Officer"
            formControlName="appointedOfficerId"
            [options]="officersSelectOptions"
            [valuePrimitive]="true"
          />
        </div>

        @if (form.value.appointedOfficerId) {
          <div class="form-group">
            <app-checkbox-group
              label="Position"
              formControlName="type"
              [options]="positionTypesOptions"
              (change)="form.controls.alternativeDirectorFor.reset()"
            />
          </div>
        }
      }

      <div [hidden]="isNewOfficeholderSignal() !== true">
        <div class="form-group">
          <app-individual-data-form-group
            [formGroup]="form.controls.newOfficeholder"
          />
        </div>

        @if (form.touched && form.errors?.['poBoxAddress']) {
          <div class="mb-4">
            <app-validation-error
              [control]="form"
              [customErrors]="addressesCustomErrors"
            />
          </div>
        }

        <div class="form-group">
          <app-checkbox-group
            label="Position"
            formControlName="type"
            [options]="positionTypesOptions"
          />
        </div>
      </div>

      @if (this.form.controls.type.value?.length && (this.form.controls.type.value?.includes(OfficerAppointmentType.Director) || this.form.controls.type.value?.includes(OfficerAppointmentType.AlternativeDirector))) {
        <div class="form-group">
          <app-din-masked-input
            formControlName="din"
          />
        </div>
      }

      @if (this.form.controls.type.value?.length && this.form.controls.type.value?.includes(OfficerAppointmentType.AlternativeDirector)) {
        <div class="form-group">
          <app-date-picker
            label='Expiry Date'
            formControlName="expiryDate"
            [isRequired]="false"
            [minDate]="minExpiryDate"
          />
        </div>

        <div class="form-group">
          <app-select
            label='The appointed "Alternate director" is alternate for'
            noOptionsText="No directors available"
            formControlName="alternativeDirectorFor"
            [options]="allowedDirectorsSignal()"
            [valuePrimitive]="true"
          />
        </div>

        <div class="form-group">
          <app-textarea
            label="Terms of Appointment"
            formControlName="termsOfAppointment"
          />
        </div>
      }
    </section>
  </div>
</app-stepper-form>
