import { Injectable } from "@angular/core";

@Injectable({
    providedIn: 'root',
  })
  export class StringHelpService {

    public removeWhiteSpace(value: string): string {
      return value.replace(/\s/g, '');
    }

    public compareStringWithoutSpaces(value1: string, value2: string): boolean {
        const safeValue1 = value1 || '';
        const safeValue2 = value2 || '';
      
        const cleanedValue1 = safeValue1.replace(/\s/g, '');
        const cleanedValue2 = safeValue2.replace(/\s/g, '');
        return cleanedValue1 === cleanedValue2;
    }
  }