import { Component, DestroyRef, inject } from '@angular/core';
import { ClickableStepperComponent } from "../components/common/clickable-stepper/clickable-stepper.component";
import {
  DocumentsChangesPreviewComponent
} from "../generate-documents/components/documents-changes-preview/documents-changes-preview.component";
import {
  GenerateDocumentsFormComponent
} from "../generate-documents/components/generate-documents-form/generate-documents-form.component";
import {
  GenerateDocumentsHeaderComponent
} from "../generate-documents/components/generate-documents-header/generate-documents-header.component";
import {
  GenerateDocumentsSidebarComponent
} from "../generate-documents/components/generate-documents-sidebar/generate-documents-sidebar.component";
import { SignDocumentsComponent } from "../generate-documents/components/sign-documents/sign-documents.component";
import { IStep } from "../../models/step";
import { GenerateDocumentsStepsEnum } from "../../models/enums/generateDocumentsStepsEnum";
import { CompanyProfileService } from "../company-profile/company-profile.service";
import { TrustsService } from "../../services/trustsService";
import { ActivatedRoute, Router } from "@angular/router";
import { MenuService } from "../../services/menu.service";
import { ModalFormsService } from "../../services/modal-forms.service";
import { SecurityService } from "../../services/security.service";
import { Company } from "../../models/company";
import { Trust } from "../../models/trust";
import { catchError, firstValueFrom, Observable, of, Subject, tap } from "rxjs";
import { takeUntilDestroyed, toObservable } from "@angular/core/rxjs-interop";
import { RelationshipTypeLabels } from "../../models/enums/relationshipTypeEnum";
import { ChangeDictionaryHelper } from "../../models/shared/change-dictionary-helper.model";
import { CorporateHolderModel, IndividualHolderModel } from "../../models/securityRegistryRecord";
import { ACNPipe } from "../../pipes/acnPipe";
import { EntityType } from "../../models/enums/entityType";
import { DocumentStatusEnum } from '../../models/enums/documentStatusEnum';
import { AnnualStatementsService } from "../../services/annual-statements.service";
import { AnnualStatementSidebarComponent } from "./annual-statement-sidebar/annual-statement-sidebar.component";
import { AnnualStatement } from "../../models/annualStatement";
import { ButtonComponent } from "../components/common/button/button.component";
import { DividerComponent } from "../components/common/divider/divider.component";
import {
  AnnualStatementAuthorizationComponent
} from "./annual-statement-authorization/annual-statement-authorization.component";
import { OrganisationService } from "../../services/organisation.service";
import { UserBrief } from "../../models/uiProfile";
import { SelectOption } from "../../models/selectOptions";
import { AnnualGenerateDocumentsComponent } from "./annual-generate-documents/annual-generate-documents.component";
import { AnnualSignDocumentsComponent } from "./annual-sign-documents/annual-sign-documents.component";
import { NgxSkeletonLoaderModule } from "ngx-skeleton-loader";
import { AnnualStep, SigningStatus } from '../../models/enums/annualStatementEnums';
import { NgTemplateOutlet } from "@angular/common";
import { DocumentsService } from "../../services/documents.service";
import { HttpErrorResponse } from "@angular/common/http";
import { ToastrService } from "ngx-toastr";
import { HttpErrorCode } from "../../models/enums/httpErorrEnum";
import { PageTitleComponent } from "../components/common/page-title/page-title.component";
import { annualStatusToStep } from "../helpers/annual-statement-to step";
import { UsersService } from '../../services/users.service';

const ChangeGenerateDocumentsSteps: IStep<GenerateDocumentsStepsEnum>[] = [
  { label: 'Authorization', step: 0, disabled: false },
  { label: 'Generate Documents', step: 1, disabled: false },
  { label: 'Sign Documents', step: 2, disabled: true },
];

@Component({
  selector: 'app-annual-statement',
  standalone: true,
    imports: [
      ClickableStepperComponent,
      DocumentsChangesPreviewComponent,
      GenerateDocumentsFormComponent,
      GenerateDocumentsHeaderComponent,
      GenerateDocumentsSidebarComponent,
      SignDocumentsComponent,
      AnnualStatementSidebarComponent,
      ButtonComponent,
      DividerComponent,
      AnnualStatementAuthorizationComponent,
      AnnualGenerateDocumentsComponent,
      AnnualSignDocumentsComponent,
      NgxSkeletonLoaderModule,
      NgTemplateOutlet,
      PageTitleComponent
    ],
  templateUrl: './annual-statement.component.html',
  styleUrl: './annual-statement.component.scss'
})
export class AnnualStatementComponent {
  private annualStatementsService = inject(AnnualStatementsService);
  private documentsService = inject(DocumentsService);
  private companyProfileService = inject(CompanyProfileService);
  private trustService = inject(TrustsService);
  private route: ActivatedRoute = inject(ActivatedRoute);
  private menuService = inject(MenuService);
  private modalFormsService = inject(ModalFormsService);
  private securityService = inject(SecurityService);
  private organisationService = inject(OrganisationService);
  private usersService = inject(UsersService);
  private toastr = inject(ToastrService);
  private router = inject(Router);
  #destroyRef = inject(DestroyRef);

  steps = ChangeGenerateDocumentsSteps;
  currentStepIndex = 0;
  loading = false;
  updatePreviewTrigger = false;
  collapsedStateBtn = false;

  patchLoading = this.annualStatementsService.patchLoading;
  sendSignLoading = this.annualStatementsService.sendSignLoading;
  disabledHeaderBtn = this.annualStatementsService.disabledHeaderBtn;
  showVoidEnvelope = this.annualStatementsService.showVoidEnvelope;
  showSendEmailBtn = this.annualStatementsService.showSendEmailBtn;
  showSendPaperBtn = this.annualStatementsService.showSendPaperBtn;
  generateStatementLoading = this.annualStatementsService.generateStatementLoading;

  annual!: AnnualStatement;
  actualCompany!: Company;
  actualTrustProfile: Trust | null = null;
  trustProfileBeforeChanges: Trust | null = null;
  documentId = '';
  DocumentStatusEnum = DocumentStatusEnum;
  AnnualStep = AnnualStep;
  relationshipHashset: Map<string, string> = new Map<string, string>();
  saveAction$ = new Subject<boolean>();
  confirmAction$ = new Subject<boolean>();
  confirmSentESignAction$ = new Subject<boolean>();
  confirmPaperSignAction$ = new Subject<boolean>();
  disabledHeaderBtn$: Observable<boolean>;
  partnerManagerOptions: SelectOption[] = [];

  constructor() {
    this.disabledHeaderBtn$ = toObservable(this.disabledHeaderBtn);
  }

  async ngOnInit() {
    this.menuService.setMenuState(true);
    this.documentId = this.route.snapshot.paramMap.get('id') ?? '';

    this.route.queryParams.pipe(
      tap((params: { step?: string }) => {
        if(params?.step !== undefined && params?.step !== null) {
          this.currentStepIndex = +params.step;
        }
      }),
      takeUntilDestroyed(this.#destroyRef)
    ).subscribe();

    if (this.documentId != null) {
      await this.loadAnnual(this.documentId);
    }
    
    await this.loadPartnerManagerOptions();

    this.getRelationshipHashSet();
    this.disabledHeaderBtn$
      .pipe(takeUntilDestroyed(this.#destroyRef))
      .subscribe(disabledHeaderBtn => {
        const nextStepIndex = this.currentStepIndex + 1;
        if((nextStepIndex + 1) <= this.steps.length) {
          this.steps[this.currentStepIndex + 1].disabled = disabledHeaderBtn;
        }
      });
  }

  async loadPartnerManagerOptions(): Promise<void> {
    const users = await firstValueFrom(this.usersService.getAssignableUsersCached());
    this.partnerManagerOptions = users.map(profile => ({
      label: profile.fullName,
      value: profile.id
    }));
  }

  async loadAnnual(documentId: string) {
    try {
      this.loading = true;
      const annual = await firstValueFrom(this.annualStatementsService.getAnnualById(documentId).pipe(
        catchError((error: HttpErrorResponse) => {
          if((error.status as HttpErrorCode) === HttpErrorCode.NOT_FOUND) {
            this.toastr.error(`Document ${documentId} not found`, 'Error!');
          } else {
            this.toastr.error(`${error.message}`, 'Error!')
            console.error(error);
          }

          return of(null);
        })
      ));

      if(annual) {
        this.annual = annual;
      } else {
        this.annualStatementsService.disabledHeaderBtn.set(true);
        return;
      }

      this.currentStepIndex = annualStatusToStep(this.annual.annualStatementStatus);

      this.steps.forEach((step, index) => {
        if(index <= this.currentStepIndex) {
          step.disabled = false;
        }
      });
      this.addStepToQueryParam(this.currentStepIndex);

      if(
        this.annual.documentSigning?.signingStatus === SigningStatus.Sent
        || this.annual.documentSigning?.signingStatus === SigningStatus.Signed
        || this.annual.documentSigning?.signingStatus === SigningStatus.Rejected
      ) {
        this.steps[AnnualStep.Authorization].disabled = true;
        this.steps[AnnualStep.GenerateDocuments].disabled = true;
      }

      await this.loadActualCompany(this.annual.entityId!);
      await this.loadSecurities();

      this.updatePreviewTrigger = !this.updatePreviewTrigger;
    } catch (e) {
      console.error(e);
    } finally {
      this.loading = false;
    }
  }

  async loadCompany(id: string, applyIncompleteDocuments = true, excludedDocumentId = '') {
    if(!id) { return null; }
    return await firstValueFrom(this.companyProfileService.getCompanyProfile(id, applyIncompleteDocuments, excludedDocumentId));
  }

  getRelationshipHashSet() {
    if (this.actualCompany?.officers && this.actualCompany?.securityholders) {
      const collection = [...this.actualCompany.officers, ...this.actualCompany.securityholders];

      collection.forEach(officer => {
        if (officer.relationshipId === undefined) {
          return;
        }
        this.relationshipHashset.set(officer.relationshipId, `${ officer.individualDataOverride?.fullName } (${ RelationshipTypeLabels[officer.type] })`);
      });
    }
  }

  setCurrentStep(newStepIndex: number): void {
    if (newStepIndex > this.currentStepIndex && !this.disabledHeaderBtn()) {
      this.steps[newStepIndex].disabled = false;
      this.currentStepIndex = newStepIndex;
      this.confirmAction$.next(true);
    }

    if (newStepIndex < this.currentStepIndex) {
      this.currentStepIndex = newStepIndex;
    }

    this.addStepToQueryParam(this.currentStepIndex);
  }

  private addStepToQueryParam(step: AnnualStep): void {
    const currentUrl = this.router.url.split('?')[0];
    void this.router.navigate([currentUrl], {
      queryParams: { step },
      queryParamsHandling: 'merge',
      replaceUrl: true
    });
  }

  async updateDocuments(): Promise<void> {
    await this.loadAnnual(this.documentId);
  }

  setNextStep(): void {
    const nextStepIndex = this.currentStepIndex + 1;

    if (nextStepIndex < this.steps.length) {
      this.steps[nextStepIndex].disabled = false;
      this.currentStepIndex = nextStepIndex;
    }
  }

  async updateAnnual(): Promise<void> {
    await this.loadAnnual(this.documentId);
  }

  close(): void {
    const userResponse = confirm("Are you sure you want to close? The data will be cleared.");

    if (userResponse) {
      void this.router.navigate(['annual']);
    }
  }

  saveAndCompleteLater(): void {
    this.saveAction$.next(true);
  }

  confirmChanges(): void {
    this.confirmAction$.next(true);
  }

  private async loadActualCompany(excludedDocumentId: string) {
    try {
      const company = await this.loadCompany(this.annual.entityId!, false, excludedDocumentId);
      if (company) {
        this.actualCompany = company;
        this.getRelationshipHashSet();
      } else {
        this.actualCompany = new Company();
      }
    } catch (error) {
      console.warn(error);
    }
  }

  private async loadSecurities() {
    if (!this.annual?.entityId) {
      return;
    }

    try {
      const records = await firstValueFrom(this.securityService.getSecurityRegistry(this.annual.entityId));
      records.forEach(record => {
        record.holders.forEach(holder => {
          if (holder.$type === IndividualHolderModel.$type) {
            const holderType = holder.details.$type.replace('RelationshipDetails', '');
            this.relationshipHashset.set(holder.relationshipId, `${holder.name} (${holderType})`);
          } else if (holder.$type === CorporateHolderModel.$type) {
            const corporateHolder = holder as CorporateHolderModel;
            const companyNumber = corporateHolder.entityData.entityNumber ? ` - ${ new ACNPipe().transform(corporateHolder.entityData.entityNumber) }` : '';
            this.relationshipHashset.set(holder.relationshipId, corporateHolder.entityData.name + companyNumber);
          }

          if (holder.details.beneficialOwner) {
            this.relationshipHashset.set(ChangeDictionaryHelper.BENEFICIAL_OWNER + holder.relationshipId, holder.details.beneficialOwner);
          }
        });
      });

      this.updatePreviewTrigger = !this.updatePreviewTrigger;
    } catch (error) {
      console.error('Error loading securities:', error);
    }
  }

  get entityType(): EntityType {
    if (this.annual?.type.startsWith('t:'))
      return EntityType.Trust;

    return EntityType.Company;
  }

  get modalOpened() {
    return this.modalFormsService.modalOpened;
  }

  get buttonText(): string {
    if (this.showSendEmailBtn()) {
      return 'Send email';
    } else if (this.showSendPaperBtn()) {
      const isSigned = this.annual?.documentSigning?.signingStatus === SigningStatus.Signed;
      return isSigned ? '': 'Confirm Signing';
    } else {
      return this.showVoidEnvelope() ? '' : 'Send for E-Sign';
    }
  }

  buttonAction(): void {
    if (this.showSendEmailBtn()) {
      this.confirmChanges();
    } else if (this.showSendPaperBtn()) {
      this.confirmPaperSignAction$.next(true);
    } else {
      this.confirmSentESignAction$.next(true);
    }
  }
}
