<app-stepper-form
  header="Issue"
  subheader="Form 484C2"
  [steps]="steps"
  [currentStep]="currentStep"
  [currentStepIndex]="currentStepIndex"
  [isLoading]="isLoading"
  [form]="stepperForm"
>
  <div class="step-content full-width" [formGroup]="stepperForm">
    <div class="step-content">
      <app-stepper-form-description
        formKey="484C2"
        [hidden]="currentStep !== StepsEnum.FormDescription"
      ></app-stepper-form-description>
    </div>

    <section
      class="step-section"
      [formGroupName]="StepsEnum.Shareholders"
      [hidden]="currentStep !== StepsEnum.Shareholders"
    >
      <h1 class="h1-heading">{{ steps[currentStepIndex].label }}</h1>

      <div class="form-group">
        <app-date-picker
          label="Date of Issue"
          formControlName="date"
          [minDate]="minDate"
          [maxDate]="maxDate"
          [smallWidth]="true"
          [customErrors]="customDatepickerErrors"
        ></app-date-picker>
      </div>

      <div class="form-group">
        <app-select-groups
          label="Shareholder Type"
          placeholder="Select Shareholder Type"
          formControlName="shareholderType"
          [optionGroups]="shareholderTypesOptionGroups"
        ></app-select-groups>
      </div>

      <div
        class="form-group"
        [hidden]="shareholdersForm.controls.shareholderType.value !== ShareholderTypesEnum.ExistingShareholder"
      >
        <app-select-shareholder
          label="Current Shareholders"
          formControlName="securityRegistryRecordIndex"
          [securityRegistryRecords]="securityRegistryRecords"
        ></app-select-shareholder>
      </div>

      <div
        class="form-group"
        [hidden]="shareholdersForm.controls.shareholderType.value !== ShareholderTypesEnum.Individual"
        [formGroup]="shareholdersForm"
      >
        <app-individual-data-form-group
          [formGroup]="shareholdersForm.controls.newIndividualShareholder"
          [individualDataFormLabels]="individualDataFormLabels"
          [hiddenControls]="hiddenIndividualControls"
          [useInternationalAddresses]="true"
        ></app-individual-data-form-group>
      </div>

      <div
        [hidden]="shareholdersForm.controls.shareholderType.value !== ShareholderTypesEnum.Corporate"
        [formGroup]="shareholdersForm"
      >
        <div class="form-group">
          <app-company-name-acn-form-group
            [formGroup]="shareholdersForm"
            [hideAcn]="shareholdersForm.controls.isOverseasCompany.value!"
            [shouldLookForCompanyName]="!shareholdersForm.controls.isOverseasCompany.value"
          >
            <div class="form-group">
              <app-checkbox
                label="This is an overseas company or the company doesn't have an ACN"
                formControlName="isOverseasCompany"
              ></app-checkbox>
            </div>
          </app-company-name-acn-form-group>
        </div>

        <div class="form-group">
          <app-address-form-group
            [careOfAllowed]="true"
            [formGroup]="shareholdersForm.controls.registeredAddress"
            [useInternationalAddresses]="shareholdersForm.controls.isOverseasCompany.value!"
            [addressFormLabels]="addressFormLabels"
          ></app-address-form-group>
        </div>
      </div>

      <div
        class="form-group"
        [hidden]="shareholdersForm.controls.shareholderType.value !== ShareholderTypesEnum.JointShareholder"
      >
        <app-share-joint-security-holder formControlName="joint"></app-share-joint-security-holder>
      </div>

      <div
        [hidden]="shareholdersForm.controls.shareholderType.value === null || shareholdersForm.controls.shareholderType.value === ShareholderTypesEnum.ExistingShareholder">
        <div class="form-group">
          <app-yes-no-control
            label="Are the shares owned on behalf of another individual, company, trust or fund?"
            formControlName="isNotBeneficialOwner"
          ></app-yes-no-control>
        </div>

        <div class="form-group" [hidden]="!shareholdersForm.controls.isNotBeneficialOwner.value">
          <app-input
            label="Shares are owned on behalf of:"
            formControlName="beneficialOwner"
          ></app-input>
        </div>
      </div>

      <div class="share-numbers-grid">
        <div class="form-group share-types">
          <app-share-security-types-dropdown
            formControlName="securityTypeId"
            [options]="securityTypes"
            [allowAddNewShareType]="shareholdersForm.controls.shareholderType.value !== null || shareholdersForm.controls.shareholderType.value !== ShareholderTypesEnum.ExistingShareholder"
            (addShareType)="addShareType($event)"
          ></app-share-security-types-dropdown>
        </div>

        <div class="form-group number-of-shares-cancelled">
          <app-input-number
            label="Number of Shares Issued"
            formControlName="numberIncrease"
            [min]="0"
            [customErrors]="totalNumberOfSharesCustomErrors"
          ></app-input-number>
        </div>

        <div class="form-group total-paid">
          <app-input-number
            label="Total Paid"
            formControlName="paidIncrease"
            [min]="0"
            [customErrors]="totalNumberOfSharesCustomErrors"
          ></app-input-number>
        </div>

        <div class="form-group total-unpaid">
          <app-input-number
            label="Total Unpaid"
            formControlName="unpaidIncrease"
            [min]="0"
            [customErrors]="totalNumberOfSharesCustomErrors"
          ></app-input-number>
        </div>
      </div>

      <div class="form-group" [hidden]="formShareNumberErrorMessageHidden">
        <app-validation-error
          [control]="shareholdersForm"
          [customErrors]="customErrorsOfShareCancellationNumbers"
        ></app-validation-error>
      </div>
    </section>

    <section
      class="step-section"
      [formGroupName]="StepsEnum.ShareIssuesAndCertificateDetails"
      [hidden]="currentStep !== StepsEnum.ShareIssuesAndCertificateDetails"
    >
      <h1 class="h1-heading">{{ steps[currentStepIndex].label }}</h1>

      <div class="form-group">
        <app-radio
          label="Consideration Type"
          formControlName="issueForCashConsidarationOnly"
          [options]="considerationTypesOptions"
        ></app-radio>
      </div>

      <div class="form-group" [hidden]="issuesCertificateForm.controls.issueForCashConsidarationOnly.value !== false">
        <app-textarea
          label="Non Cash Consideration Details"
          formControlName="nonCashConsiderationDetails"
        ></app-textarea>
      </div>

      <div class="form-group">
        <app-yes-no-control
          label="Were some or all of the shares issued under a written contract?"
          formControlName="sharesWereWrittenUnderContract"
        ></app-yes-no-control>
      </div>

      <app-disclaimer
        icon="ic-info-circle"
        color="gray"
        [hidden]="issuesCertificateForm.controls.sharesWereWrittenUnderContract.value !== true"
      >
        Proprietary companies must also lodge a <a href="https://download.asic.gov.au/media/1181281/207z_20140701.pdf"
                                                   target="_blank">Form 207Z</a> certifying that all stamp duties have
        been paid. Public companies must also lodge a
        <a href="https://download.asic.gov.au/media/1181281/207z_20140701.pdf" target="_blank">Form 207Z</a> and either
        a <a href="https://download.asic.gov.au/media/1181287/208_20140701.pdf" target="_blank">Form 208</a> or a copy
        of the contract.
      </app-disclaimer>

      <div class="form-group">
        <app-select
          label="Certificate Consolidation"
          formControlName="consolidateCertificate"
          [options]="certificateConsolidationOptions"
          [valuePrimitive]="true"
        ></app-select>
      </div>

      <div class="form-group">
        <app-input-number
          label="Certificate Number"
          formControlName="shareCertificateNumber"
        ></app-input-number>
      </div>
    </section>

    <section
      class="step-section"
      [formGroupName]="StepsEnum.AuthorisedSignatories"
      [hidden]="currentStep !== StepsEnum.AuthorisedSignatories"
    >
      <app-authorised-signatories-step
        [title]="'Authorised Signatories'"
        [autoSave]="false"
        [formGroup]="authorisedSignatoriesForm"
        [securityRegistryRecords]="authorisedSecurityRegistryRecords"
      />
    </section>

    <section
      class="step-section w-100"
      [formGroupName]="StepsEnum.Estimate"
      [hidden]="currentStep !== StepsEnum.Estimate"
    >
      <h1 class="h1-heading">Estimated Shareholding after Transaction</h1>

      <app-share-estimate-table
        [isEstimateLoading]="isEstimateLoading"
        [estimatedSecurityRegistryRecords]="estimatedSecurityRegistryRecords"
      ></app-share-estimate-table>
    </section>
  </div>
</app-stepper-form>
