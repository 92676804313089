import {
  Component,
  DestroyRef,
  inject,
  OnInit,
  signal,
} from '@angular/core';
import { Company } from '../../models/company';
import { EntityChangeData } from '../../models/entityChangeData';
import { ModalFormsService } from '../../services/modal-forms.service';
import { CompanyProfileService } from '../company-profile/company-profile.service';
import { ButtonComponent } from "../components/common/button/button.component";
import { GridComponent } from "../components/common/grid/components/grid/grid.component";
import {
  NgbDropdown,
  NgbDropdownItem,
  NgbDropdownMenu,
  NgbDropdownToggle,
} from '@ng-bootstrap/ng-bootstrap';
import {
  ColDef,
  ColGroupDef,
  CsvExportParams,
  ExcelExportParams, ExcelRow,
  GetContextMenuItems,
  GetDetailRowDataParams, GetRowIdParams,
  GridApi,
  IDetailCellRendererParams, RowClassRules,
  RowGroupingDisplayType,
} from "ag-grid-community";
import { ColumnWithExportName } from "../../models/columnWithExportName";
import { ExportTypeEnum } from '../../models/enums/exportTypeEnum';
import { SharesService } from "../../services/shares.service";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";
import { SecurityService } from "../../services/security.service";
import { catchError, forkJoin, Observable, throwError } from 'rxjs';
import { ToastrService } from "ngx-toastr";
import { DividerComponent } from "../components/common/divider/divider.component";
import { GroupByOption } from "../../models/gridFilter";
import { DonutChartComponent } from "../components/common/donut-chart/donut-chart.component";
import { CorporateHolderModel, IndividualHolderModel, SecurityRegistryRecord } from "../../models/securityRegistryRecord";
import { CompanySecurityCancellation } from '../modals/share/share-cancellation/company-security-cancellation.model';
import { SecurityTransaction } from "../../models/securityTransaction";
import { CompanySecurityIssue } from '../modals/share/share-issue/share-issue.model';
import { ClientSecurityTransaction } from "../../models/clientSecurityTransaction";
import {
  CompanySecurityConsolidationSubdivision
} from '../modals/share/share-subdivision-conversion/share-subdivision-conversion.model';
import { CompanySecurityTransfer } from '../modals/share/share-transfer/share-transfer.model';
import { ColumnForExportComponent } from "../modals/column-for-export/column-for-export.component";
import { ShareTypePipe } from "../../pipes/share-type.pipe";
import { MenuService } from "../../services/menu.service";
import { ActivatedRoute } from "@angular/router";
import {
  AgShareGroupComponent
} from "../components/common/grid/components/shares/ag-share-group/ag-share-group.component";
import { formatDate } from "@angular/common";
import { SubMenuItem } from "../../models/sectionFormMenu";
import { CompanyForm280 } from "../modals/form280/form280.model";
import { CompanyForm281 } from "../modals/form281/form281.model";
import {
  CompanyDividendStatement
} from "../modals/documents/asic-forms/form-divident-statement/CompanyDividendStatement";
import { PageTitleComponent } from "../components/common/page-title/page-title.component";
import { deepClone } from "fast-json-patch/commonjs/core";
import { HasRoleDirective } from '../../directives/has-role.directive';

export interface GroupedShare {
  shareClass?: string,
  shareType?: string,
  shareholderName?: string;
  shareholder?: (IndividualHolderModel | CorporateHolderModel),
  numberOfShares: number,
  paidAmount: number,
  unpaidAmount: number,
  hasIssue?: boolean,
  issueMessage?: string,
  numberOfSharesAsic?: number,
  paidAmountAsic?: number,
  callRecords: ClientSecurityTransaction[]
}

@Component({
  selector: 'app-shares',
  standalone: true,
  imports: [
    ButtonComponent,
    GridComponent,
    NgbDropdown,
    NgbDropdownMenu,
    NgbDropdownToggle,
    DividerComponent,
    DonutChartComponent,
    NgbDropdownItem,
    PageTitleComponent,
    HasRoleDirective
  ],
  templateUrl: './shares.component.html',
  styleUrl: './shares.component.scss'
})
export class SharesComponent implements OnInit {
  private sharesService = inject(SharesService);
  private securityService = inject(SecurityService);
  private toastr = inject(ToastrService)
  private profileService = inject(CompanyProfileService);
  private modalFormsService = inject(ModalFormsService);
  private menuService = inject(MenuService);
  private route = inject(ActivatedRoute);
  private shareTypePipe = inject(ShareTypePipe);
  #destroyRef = inject(DestroyRef);
  public colDefs = this.sharesService.colDefs;
  public groupedColDefs = signal<(ColumnWithExportName | ColGroupDef)[]>([]);
  public detailCellRenderer = AgShareGroupComponent;
  public detailCellRendererParams: unknown;
  public rowClassRules: RowClassRules = {
    'non-lodged-data': (params: { data: ClientSecurityTransaction }) => !params.data.isPosted,
  };

  public addTransactionMenu: { name: string; action: () => void }[] = [
    { name: 'Issue', action: () => this.issueTransaction() },
    { name: 'Transfer', action: () => this.transferTransaction() },
    { name: 'Cancellation', action: () => this.cancelTransaction() },
    { name: 'Subdivision or Consolidation', action: () => this.subdivisionOrConsolidationTransaction() },
    { name: 'Conversion', action: () => this.conversionTransaction() },
    { name: 'Change Amount Paid', action: () => this.changeAmountPaidTransaction() },
    { name: 'Add History Transaction', action: () => this.addHistoryTransaction() },
  ];

  sharesFormMenu: SubMenuItem[] = [
    // {
    //   title: 'Notification of Share Buy-Back Details',
    //   formName: 'Form 280',
    //   action: () => this.openForm280(),
    // },
    // {
    //   title: 'Notification of Intention to Carry Out a Share Buy-Back',
    //   formName: 'Form 281',
    //   action: () => this.openForm281(),
    // },
    {
      title: 'Dividend Statement',
      formName: '',
      action: () => this.openDividendStatementForm(),
    },
  ];

  public shareGroupByOptions: GroupByOption[] = [
    { label: 'Shareholder', description: '', active: false },
    { label: 'Shareholder + Share Type', description: '', active: false },
    { label: 'Shareholder + Share Class', description: '', active: false },
    { label: 'Share Class', description: '', active: false },
  ];

  shareBreakdownTitle = 'Share Breakdown';
  shareBreakdownSeries: number[] = [];
  shareBreakdownLabels: string[] = [];
  shareTypeTitle = 'Share Type';
  shareTypeSeries: number[] = [];
  shareTypeLabels: string[] = [];

  readonly pageTitle = 'Shares';
  readonly defaultPageSize = 20;
  readonly detailRowAutoHeight = true;
  groupDisplayType: RowGroupingDisplayType = "groupRows";
  sharesList: ClientSecurityTransaction[] = [];
  groupedShareRows: GroupedShare[] = [];
  loading = false;
  checkedShares: ClientSecurityTransaction[] = [];
  shareAlertsNumber = 0;
  excelExportParams = this.sharesService.exportParamsXls() as ExcelExportParams;
  csvExportParams = this.sharesService.exportParamsCsv() as CsvExportParams;
  excelStyles = this.sharesService.excelStyles;
  ExportTypeEnum = ExportTypeEnum;
  companyId = '086f5d87-5ce0-441c-a97c-ab455b5ab63d';

  ngOnInit(): void {
    const companyId = this.route.snapshot.paramMap.get('id');
    if(companyId) {
      this.companyId = companyId;
      this.menuService.updateMenuItem('Shares', { routerLink: ['shares', this.companyId] });
    }
    this.sharesService.companyId.set(this.companyId);

    this.menuService.setMenuState(true);
    this.loadSharesList();
  }

  loadSharesList(): void {
    this.loading = true;
    const security = {
      registry: this.securityService.getSecurityRegistry(this.companyId),
      transaction: this.securityService.getSecurityTransactions(this.companyId)
    };

    forkJoin(security).pipe(
      takeUntilDestroyed(this.#destroyRef),
      catchError((error) => {
        this.loading = false;
        const errorMsg = 'Failed to load shares';
        this.toastr.error(errorMsg);
        console.error(error);
        return throwError(() => new Error(errorMsg));
      })
    ).subscribe(({registry, transaction}) => {
      if(transaction.length && registry.length) {
        this.getShareholders(registry, transaction);
        this.shareBreakdownChartData();
        this.shareTypeChartData();
        this.sharesService.setSharesOptions(this.sharesList);
      } else {
        this.sharesService.setSharesOptions([]);
      }
      this.loading = false;
    });
  }

  private getShareholders(registry: SecurityRegistryRecord[], transaction: SecurityTransaction[]): void {
    const transactions = transaction as unknown as ClientSecurityTransaction[];
    registry.forEach(reg => {
      transactions.forEach(t => {
        reg.holders.forEach(holder => {
          if(t.relationshipIds?.length && t.relationshipIds.includes(holder.relationshipId)) {
            t.holder = holder;
          }
        });
      });
    });

    this.sharesList = transactions;
  }

  issueTransaction(): void {
    this.openWithCompanyProfile(new CompanySecurityIssue());
  }

  transferTransaction(): void {
    this.openWithCompanyProfile(new CompanySecurityTransfer());
  }

  cancelTransaction(): void {
    this.openWithCompanyProfile(new CompanySecurityCancellation());
  }

  subdivisionOrConsolidationTransaction(): void {
    this.openWithCompanyProfile(new CompanySecurityConsolidationSubdivision());
  }

  conversionTransaction(): void {
  }

  changeAmountPaidTransaction(): void {
  }

  addHistoryTransaction(): void {
  }


  onGridReady(gridApi: GridApi): void {
    this.sharesService.gridApi = gridApi;
    const autoSizeColumnList = this.sharesService.autoSizeColumnList;
    gridApi.autoSizeColumns(autoSizeColumnList);
    gridApi.resetRowHeights();
  }

  selectShares(shares: ClientSecurityTransaction[]): void {
    this.checkedShares = shares;
  }

  selectGroupByOption(index: number): void {
    this.shareGroupByOptions.forEach((o, i) => o.active = i === index);

    if (this.shareGroupByOptions[index].label === 'Shareholder') {
      this.setShareholderData();
    } else if (this.shareGroupByOptions[index].label === 'Shareholder + Share Type') {
      this.setShareholderPlusShareTypeData();
    } else if (this.shareGroupByOptions[index].label === 'Shareholder + Share Class') {
      this.setShareholderPlusShareClassData();
    } else if (this.shareGroupByOptions[index].label === 'Share Class') {
      this.setShareClassData();
    }

    this.setDetailCellRendererParams();

    this.excelExportParams = this.groupExcelExportParams;
  }

  clearGroupByOption(): void {
    this.shareGroupByOptions.forEach(o => o.active = false);
    const columnDef: ColDef | undefined = this.colDefs().find((col :ColDef) => col.field === 'transactionDate');
    if (columnDef) {
      columnDef.checkboxSelection = true;
      columnDef.headerCheckboxSelection = true;
    }
    this.excelExportParams = this.sharesService.exportParams() as ExcelExportParams;
    this.groupedShareRows = [];
  }

  private setShareholderData(): void {
    const groupedShareRows = this.sharesList.reduce((result: GroupedShare[], share) => {
      const shareholder = share.holder;
      const existingGroupIndex = result.findIndex(group => group?.shareholder?.name === shareholder?.name);

      if (existingGroupIndex !== -1) {
        result[existingGroupIndex].callRecords.push(share);
      } else {
        result.push({
          shareholder,
          numberOfShares: 0,
          paidAmount: 0,
          unpaidAmount: 0,
          callRecords: [share]
        });
      }
      return result;
    }, []);

    groupedShareRows.forEach(share => {
      share.callRecords = this.securityService.calculateBalance(share.callRecords);
    })

    this.groupedShareRows = this.calculateSumGroupedShareRows(groupedShareRows);
    this.groupedColDefs = this.sharesService.groupByShareholderColumnDefs;
  }

  private setShareholderPlusShareTypeData(): void {
    const groupedShareRows = this.sharesList.reduce((result: GroupedShare[], share) => {
      const shareholder = share.holder;
      const shareType = share.securityType.identifier;
      const existingGroupIndex = result.findIndex(group => group?.shareholder?.name === shareholder?.name && group?.shareType === shareType);

      if (existingGroupIndex !== -1) {
        result[existingGroupIndex].callRecords.push(share);
      } else {
        result.push({
          shareholder,
          shareType,
          numberOfShares: 0,
          paidAmount: 0,
          unpaidAmount: 0,
          callRecords: [share]
        });
      }
      return result;
    }, []);

    groupedShareRows.forEach(share => {
      share.callRecords = this.securityService.calculateBalance(share.callRecords);
    });

    this.groupedShareRows = this.calculateSumGroupedShareRows(groupedShareRows);
    this.groupedColDefs = this.sharesService.groupByShareholderPlusShareTypeColumnDefs;
  }

  private setShareholderPlusShareClassData(): void {
    const groupedShareRows = this.sharesList.reduce((result: GroupedShare[], share) => {
      const shareholder = share.holder;
      const shareClass = share.securityType.class;
      const existingGroupIndex = result.findIndex(group => group?.shareholder?.name === shareholder?.name && group?.shareClass === shareClass);

      if (existingGroupIndex !== -1) {
        result[existingGroupIndex].callRecords.push(share);
      } else {
        result.push({
          shareholder,
          shareClass,
          numberOfShares: 0,
          paidAmount: 0,
          unpaidAmount: 0,
          callRecords: [share]
        });
      }
      return result;
    }, []);

    groupedShareRows.forEach(share => {
      share.callRecords = this.securityService.calculateBalance(share.callRecords);
    });

    this.groupedShareRows = this.calculateSumGroupedShareRows(groupedShareRows);
    this.groupedColDefs = this.sharesService.groupByShareholderPlusShareClassColumnDefs;
  }

  private setShareClassData(): void {
    const groupedShareRows = this.sharesList.reduce((result: GroupedShare[], share) => {
      const shareClass = share.securityType.class;
      const existingGroupIndex = result.findIndex(group => group?.shareClass === shareClass);

      if (existingGroupIndex !== -1) {
        result[existingGroupIndex].callRecords.push(share);
      } else {
        result.push({
          shareClass,
          numberOfShares: 0,
          paidAmount: 0,
          unpaidAmount: 0,
          callRecords: [share]
        });
      }
      return result;
    }, []);

    //TODO: only for demonstration, remove when backend was ready
    this.shareAlertsNumber = 1;
    groupedShareRows[0].hasIssue = true;
    groupedShareRows[0].issueMessage = 'The total aggregated figures of number of shares in the the registry does not match ASIC records. We recommend rectifying the register or using Form 492 to correct ASIC register if applicable ASAP';
    groupedShareRows[0].numberOfSharesAsic = 1250;
    groupedShareRows[0].paidAmountAsic = 1800;

    groupedShareRows.forEach(share => {
      share.callRecords = this.securityService.calculateBalance(share.callRecords);
    });

    this.groupedShareRows = this.calculateSumGroupedShareRows(groupedShareRows);
    this.groupedColDefs = this.sharesService.groupByShareClassColumnDefs;
  }

  private shareBreakdownChartData(): void {
    const groupedShareRowsByShareholder = this.sharesList.reduce((result: GroupedShare[], share) => {
      const shareholder = share.holder;
      const shareType = share.securityType.identifier;
      const existingGroupIndex = result.findIndex(group => group?.shareholder?.name === shareholder?.name);

      if (existingGroupIndex !== -1) {
        result[existingGroupIndex].callRecords.push(share);
      } else {
        result.push({
          shareholder,
          shareType,
          numberOfShares: 0,
          paidAmount: 0,
          unpaidAmount: 0,
          callRecords: [share]
        });
      }
      return result;
    }, []);

    const shareBreakdown: { series: number[], labels: string[]} = { series: [], labels: []};
    const calculatedShareholder = this.calculateSumGroupedSharesForChart(groupedShareRowsByShareholder);
    const totalShares = calculatedShareholder.reduce((acc, item) => acc + item.numberOfShares, 0);
    calculatedShareholder.sort((a, b) => b.numberOfShares - a.numberOfShares);

    calculatedShareholder.forEach(shareholder => {
      const name = shareholder.shareholder?.name ?? '';
      const percent = ((shareholder.numberOfShares / totalShares) * 100).toFixed(1);
      shareBreakdown.labels.push(name);
      shareBreakdown.series.push(Number(percent));
    });

    this.shareBreakdownLabels = shareBreakdown.labels;
    this.shareBreakdownSeries = shareBreakdown.series;
  }

  private shareTypeChartData(): void {
    const groupedShareRowsByShareType = this.sharesList.reduce((result: GroupedShare[], share) => {
      const shareholder = share.holder;
      const shareType = share.securityType.identifier;
      const existingGroupIndex = result.findIndex(group => group?.shareType === shareType);

      if (existingGroupIndex !== -1) {
        result[existingGroupIndex].callRecords.push(share);
      } else {
        result.push({
          shareholder,
          shareType,
          numberOfShares: 0,
          paidAmount: 0,
          unpaidAmount: 0,
          callRecords: [share]
        });
      }
      return result;
    }, []);

    const shareType: { series: number[], labels: string[]} = { series: [], labels: []};
    const calculatedShareType = this.calculateSumGroupedSharesForChart(groupedShareRowsByShareType);
    const totalShares = calculatedShareType.reduce((acc, item) => acc + item.numberOfShares, 0);
    calculatedShareType.sort((a, b) => b.numberOfShares - a.numberOfShares);

    calculatedShareType.forEach(shareholder => {
      const name = this.shareTypePipe.transform(shareholder.shareType);
      const percent = ((shareholder.numberOfShares / totalShares) * 100).toFixed(1);
      shareType.labels.push(name);
      shareType.series.push(Number(percent));
    });

    this.shareTypeLabels = shareType.labels;
    this.shareTypeSeries = shareType.series;
  }

  private calculateSumGroupedShareRows(groupedShareRows: GroupedShare[]): GroupedShare[] {
    groupedShareRows.forEach(shareholder => {
      shareholder.numberOfShares = shareholder.callRecords[0].numberBalance;
      shareholder.paidAmount = shareholder.callRecords[0].paidBalance;
      shareholder.unpaidAmount = shareholder.callRecords[0].unpaidBalance;
    });

    return groupedShareRows;
  }

  private calculateSumGroupedSharesForChart(groupedShareRows: GroupedShare[]): GroupedShare[] {
    groupedShareRows.forEach(shareholder => {
      shareholder.callRecords.forEach(transaction => {
        shareholder.numberOfShares += transaction.numberIncrease;
        shareholder.paidAmount += transaction.paidIncrease;
        shareholder.unpaidAmount += transaction.unpaidIncrease;
      });
    });

    return groupedShareRows;
  }

  private setDetailCellRendererParams(): void {
    this.detailCellRendererParams = {
      detailGridOptions: {
        columnDefs: this.colDefs() as unknown as ColGroupDef[],
        getRowId: (params: GetRowIdParams) => {
          return (params.data as { callId: string; }).callId;
        },
        suppressCellFocus: true,
        rowSelection: 'multiple',
      },
      getDetailRowData: (params: GetDetailRowDataParams<unknown, unknown>) => {
        const data = (params.data as { callRecords: unknown; }).callRecords as unknown[];
        params.successCallback(data);
      },
      refreshStrategy: "rows",
      issueTransaction: this.issueTransaction.bind(this),
      transferTransaction: this.transferTransaction.bind(this),
      cancelTransaction: this.cancelTransaction.bind(this),
      subdivisionOrConsolidationTransaction: this.subdivisionOrConsolidationTransaction.bind(this),
      conversionTransaction: this.conversionTransaction.bind(this),
      changeAmountPaidTransaction: this.changeAmountPaidTransaction.bind(this),
      addHistoryTransaction: this.addHistoryTransaction.bind(this),
    } as unknown as IDetailCellRendererParams<unknown, unknown>;
  }

  exportSelectedSharesToXls(): void {
    this.exportSharesList(true, ExportTypeEnum.EXCEL);
  }

  exportSelectedSharesToCsv(): void {
    this.exportSharesList(true, ExportTypeEnum.CSV);
  }

  exportSharesList(isBulkExport: boolean, exportType: ExportTypeEnum): void {
    if (this.modalOpened()) return;
    if (this.groupedShareRows.length) {
      this.groupExportToExcel();
      return;
    }

    const componentInstance = this.modalFormsService.openModal(ColumnForExportComponent, {
      modalDialogClass: 'export-company-list'
    }).componentInstance as ColumnForExportComponent;

    componentInstance.title = 'Export Share List';
    componentInstance.subTitle = 'shares selected';
    componentInstance.colDefs = deepClone(this.colDefs()) as ColGroupDef[];
    componentInstance.numberOfCompanies = isBulkExport ? this.checkedShares.length : this.sharesList.length;
    componentInstance.exportType = exportType;
    componentInstance.confirm.pipe(
      takeUntilDestroyed(this.#destroyRef)
    ).subscribe((columnForExport: string[]) => {
      this.sharesService.numberColumnForExport.set(columnForExport.length - 1);
      if (exportType === ExportTypeEnum.EXCEL) {
        const params: ExcelExportParams = isBulkExport ? { columnKeys: columnForExport, onlySelected: true } : { columnKeys: columnForExport };
        const exportParamsXls = this.sharesService.exportParamsXls() as ExcelExportParams;
        this.sharesService.gridApi.exportDataAsExcel({ ...exportParamsXls, ...params });
      } else if (exportType === ExportTypeEnum.CSV) {
        const params: CsvExportParams = isBulkExport ? { columnKeys: columnForExport, onlySelected: true } : { columnKeys: columnForExport };
        this.sharesService.gridApi.exportDataAsCsv(params);
      }
    });
  }

  groupExcelExportParams: ExcelExportParams = {
    getCustomContentBelowRow: (params) => this.sharesService.getGroupRows(params) as ExcelRow[],
    columnWidth: 120,
    sheetName: 'Grouped shares',
    fileName: 'Grouped shares ' + formatDate(new Date(), 'dd-MM-yyyy', 'en-US')
  };

  groupExportToExcel(): void {
    const columnsForExport: string[] = [];
    const colDefs = this.sharesService.colDefs();
    (colDefs as ((ColDef & ColGroupDef)[])).forEach(c => {
      if(!c.hide && !c.children?.length && c.headerName && c.field) {
        columnsForExport.push(c.field);
      } else if(c?.children?.length) {
        c.children.forEach(child => {
          if(!(child as ColDef).hide) {
            const field = (child as ColDef).field ?? '';
            columnsForExport.push(field);
          }
        });
      }
    });

    this.excelExportParams = this.groupExcelExportParams;
    this.sharesService.numberColumnForExport.set(columnsForExport.length - 1);
    const exportParamsXls = this.sharesService.exportParamsXls() as ExcelExportParams;
    this.sharesService.gridApi.exportDataAsExcel({ ...exportParamsXls, columnKeys: columnsForExport });
  }

  getContextMenuItems(gridApi: GridApi & {defaultItems: unknown[]; node: {data: {entityId: string}}}): GetContextMenuItems {
    const items = gridApi.defaultItems?.slice();

    if(items?.length) {
      return ([
        {
          name: 'Open Share Detail in New Tab',
          action: () => {
            const shareId = gridApi.node.data.entityId;
            const url = `/shares/${shareId}`;
            window.open(url, '_blank');
          }
        },
        'separator',
        ...items,
      ]) as unknown as GetContextMenuItems
    }

    return [] as unknown as GetContextMenuItems;
  }

  private loadCompanyProfile(companyId: string): Observable<Company> {
    return this.profileService.getCompanyProfile(companyId);
  }

  private openWithCompanyProfile(change: EntityChangeData, isEdit = false): void {
    if (this.modalOpened()) return;
    this.loadCompanyProfile(this.companyId)
      .subscribe((company) => {
        const modalRef = this.modalFormsService.openModalWithCompany(change, company, isEdit);
        modalRef.result.then(() => this.loadSharesList(), () => {});
      });
  }

  get modalOpened() {
    return this.modalFormsService.modalOpened;
  }

  public openForm280(): void {
    this.openWithCompanyProfile(new CompanyForm280());
  }

  public openForm281(): void {
    this.openWithCompanyProfile(new CompanyForm281());
  }

  public openDividendStatementForm(): void {
    this.openWithCompanyProfile(new CompanyDividendStatement());
  }

  get companyProfileLink(): string {
    return `company-profile/${ this.companyId }`;
  }
}
