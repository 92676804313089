<div class="card card-profile ">
  <div class="profile">
    <div class="header">
      <i class="fad ic-companies ic-md icon"></i>
      <h3>
        <a
          class="company-name"
          href="javascript:void(0)"
          (click)="onOpenCompanyChangeNameForm()"
        >{{ companyProfile.name }}</a>
      </h3>
    </div>

    <app-divider></app-divider>

    <div class="company-action-list debt-width">
      <div class="company-row">
        <div class="left">
          <div class="icon-container">
            <i class="icon ic-xlg ic-debt"></i>
          </div>
          <div class="text-container">
            <div class="money">{{companyProfile.companyDebt?.amountOwing | currency : "$" : 'code' : '1.2-2'}}</div>
            <div class="title">
              Company Debt
              <app-tooltip
                [iconClass]="'icon ic-circle-info ic-xs ml-2'"
                [tooltipText]="'Last updated on ' + (debtUpdatedDate | date: 'dd/MM/yyyy HH:mm')"
              ></app-tooltip>
            </div>
          </div>
        </div>

        <div class="right">
          <div ngbDropdown [placement]="['bottom-end', 'bottom-start', 'top-start', 'top-end']" class="position-absolute right d-inline-flex">
            <button
              ngbDropdownToggle
              class="w-100 btn btn-secondary d-flex align-items-center justify-content-center"
              [disabled]="disableControls"
            >
              <i class="icon ic-md ic-three-dots"></i>
            </button>
            <div ngbDropdownMenu aria-labelledby="company-actions-dd" class="dropdown-menu dropdown-menu-right">
              @for(item of companyDebtMenu; track $index) {
                <div ngbDropdownItem class="dropdown-item" (click)="item.action()">
                  <a class="dropdown-button">
                    <i class="{{item.iconClass}}"></i>
                    {{item.title}}
                  </a>
                </div>
              }
            </div>
          </div>
        </div>
      </div>

      <div class="company-row">
        <div class="left align-items-center cursor-pointer" (click)="openDocumentsTab()">
          <div class="icon-container">
            <i class="icon ic-xlg ic-form-pending"></i>
          </div>
          <div class="text-container">
            <div class="count">{{countPendingForm}}</div>
            <div class="title">Pending Forms</div>
          </div>
        </div>
      </div>
    </div>

    <app-divider></app-divider>

    <div class="company-action-list">
      <div class="company-row">
        <div class="left">
          <img src="assets/images/asic-logo.png" alt="img" class="img-max36">
          <div class="text-container">
            <div class="text">ASIC</div>
            <div class="sub-text">Synced {{companyProfile.refreshedOn | dateAgo}}</div>
          </div>
        </div>

        <div class="right">
          <app-button [btnColor]="'white'" [iconClass]="'icon ic-md ic-refresh'" [isDisabled]="disableControls" (clickBtn)="triggerRA71()"></app-button>
        </div>
      </div>
      <app-xpm-card-profile
        [(externalEntities)]="companyProfile.externalEntities"
        [entityId]="companyProfile.entityId"
        [entityNumber]="companyProfile.entityNumber"
        [disableControls]="disableControls"
        (onEntityRefreshed)="onCompanyRefreshedEvent()"
        [entityType]="entityType"
      ></app-xpm-card-profile>
    </div>
  </div>
</div>
