import { Component, DestroyRef, inject, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Company } from '../../../models/company';
import { SelectOption } from '../../../models/selectOptions';
import { FormControl, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { CommonModalWrapperComponent } from '../../components/common/common-modal-wrapper/common-modal-wrapper.component';
import { SelectComponent } from '../../components/common/select/select.component';
import { UsersService } from '../../../services/users.service';
import { catchError, EMPTY } from 'rxjs';
import { CompanyProfileService } from '../../company-profile/company-profile.service';
import { PartnerAndManagerChangeRequest } from '../../../models/partnerAndManagerChangeRequest';
import { ToastrService } from 'ngx-toastr';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

export interface PartnerAndAccountManagerFormControls {
  partnerManagerId: FormControl<string | null>;
  accountManagerId: FormControl<string | null>;
}

export type PartnerAndAccountManagerFormGroup = FormGroup<PartnerAndAccountManagerFormControls>;

@Component({
  selector: 'app-company-form-partner-account',
  standalone: true,
  imports: [
    ReactiveFormsModule,
    CommonModalWrapperComponent,
    SelectComponent,
  ],
  templateUrl: './company-form-partner-account.component.html',
  styleUrl: './company-form-partner-account.component.scss',
})
export class CompanyFormPartnerAccount implements OnInit {
  private companyProfileService = inject(CompanyProfileService);
  private usersService = inject(UsersService);
  private toastr = inject(ToastrService);
  private destroyRef = inject(DestroyRef);
  private activeModal = inject(NgbActiveModal);

  @Input() companies!: Company[];

  usersOption: SelectOption[] = [];
  isLoading = false;
  form: PartnerAndAccountManagerFormGroup = new FormGroup({
    partnerManagerId: new FormControl<string | null>(null),
    accountManagerId: new FormControl<string | null>(null),
  });

  ngOnInit(): void {
    this.usersService.getAssignableUsersCached()
      .subscribe({
        next: (users) => {
          this.usersOption = users.map(user => ({ value: user.id, label: user.fullName }));
          this.setupChange();
        }
      });    
  }

  setupChange(): void {
    if(this.companies.length === 1) {
      setTimeout(() => {
        this.form.patchValue({
          partnerManagerId: this.companies[0].partnerManagerId,
          accountManagerId: this.companies[0].accountManagerId,
        });
      });
    }
  }

  save(): void {
    if (this.form.invalid) {
      this.form.markAllAsTouched();
      return;
    }

    this.isLoading = true;
    this.form.disable();
    
    var request = new PartnerAndManagerChangeRequest({
        partnerManagerId: this.form.controls.partnerManagerId.value,
        accountManagerId: this.form.controls.accountManagerId.value,
        companyIds: this.companies.map(x => x.entityId)
    })
    this.companyProfileService.changePartnerAndAccountManager(request)
        .pipe(
          catchError((error) => {
            console.error(error);
            this.form.enable();
            this.isLoading = false;
            this.toastr.error('Error happened while applying updates', 'Error');
            return EMPTY;
          }),
          takeUntilDestroyed(this.destroyRef)
        ).subscribe(res => {
          this.toastr.info('Partner and Account Manager details were updated', 'Success');
          this.activeModal.close();
          this.form.enable();
          this.isLoading = false;
        });
  }

  cancel(): void {
    this.activeModal.dismiss();
  }
}
