<div class="card-body" [formGroup]="form">
  <div class="d-flex justify-content-between mb-4">
    <div class="mr-2">
      @if (rowModelType === 'clientSide') {
        <app-grid-column-search-filter
          [colDefs]="detailColDefs.length ? detailColDefs : $any(colDefs)"
          [rowData]="rowData"
          [gridApi]="gridApi"
          [groupByOptions]="groupByOptions"
          [loading]="loading"
          (selectGroupByOption)="selectGroupByOption.emit($event)"
          (clearGroupByOption)="clearGroupByOption.emit($event)"
          (applyGroupFilter)="applyGroupFilter()"
        ></app-grid-column-search-filter>
      } @else if (rowModelType === 'serverSide') {
        <app-grid-server-column-search-filter
          [colDefs]="$any(colDefs)"
          [rowData]="rowData"
          [gridApi]="gridApi"
          [loading]="loading"
        ></app-grid-server-column-search-filter>
      }
    </div>
    @if (!hideSearch) {
      <div class="search-container">
        <app-search-input
          class="search"
          [placeholder]="'Search ' + pageTitle.toLowerCase() + '...'"
          formControlName="search"
        ></app-search-input>

        <app-list-grid-columns-filter
          [colDefs]="detailColDefs.length ? detailColDefs : $any(colDefs)"
          [gridApi]="$any(gridApi)"
          [isRowData]="rowModelType === 'serverSide' ? !!totalRows : !!rowData.length"
          [loading]="loading"
          (checkboxChange)="onCheckboxChange($any($event))"
          (groupCheckboxChange)="onGroupCheckboxChange($any($event))"
        ></app-list-grid-columns-filter>
      </div>
    }
  </div>

  @if (!loading && rowData) {
    <app-list-grid
      [storageKey]="storageKey"
      [defaultExcelExportParams]="defaultExcelExportParams"
      [defaultCsvExportParams]="defaultCsvExportParams"
      [excelStyles]="excelStyles"
      [rowData]="rowData"
      [colDefs]="$any(colDefs)"
      [maxBlocksInCache]="maxBlocksInCache"
      [getRowHeight]="getRowHeight"
      [rowClassRules]="rowClassRules"
      [gridOptions]="gridOptions"
      [detailRowAutoHeight]="detailRowAutoHeight"
      [autoSizeStrategy]="autoSizeStrategy"
      [getContextMenuItems]="getContextMenuItems"
      [rowSelection]="rowSelection"
      [quickFilterText]="rowModelType === 'clientSide' ? quickFilterText : ''"
      [suppressRowClickSelection]="suppressRowClickSelection"
      [suppressCellFocus]="suppressCellFocus"
      [pagination]="pagination"
      [pageSizeList]="pageSizeList"
      [paginationPageSize]="pageSizeService.getPageSize"
      [enableAdvancedFilter]="enableAdvancedFilter"
      [cacheBlockSize]="cacheBlockSize"
      [paginationPageSizeSelector]="paginationPageSizeSelector"
      [suppressPaginationPanel]="suppressPaginationPanel"
      [suppressScrollOnNewData]="suppressScrollOnNewData"
      [rowModelType]="rowModelType"
      [serverSideDatasource]="serverSideDatasource"
      [getRowId]="getRowId"
      [groupDisplayType]="groupDisplayType"
      [masterDetail]="masterDetail"
      [detailCellRenderer]="detailCellRenderer"
      [detailCellRendererParams]="detailCellRendererParams"
      (select)="onSelect($event)"
      (gridReady)="onGridReady($event)"
    ></app-list-grid>
  } @else {
    <div class="d-flex justify-content-center align-items-center mh-400">
      <i class="fad fa-spinner-third fa-spin"></i>
    </div>
  }
</div>
