import { Component, Input } from '@angular/core';
import { RouterLink } from "@angular/router";
import { ACNPipe } from "../../../../../../../pipes/acnPipe";
import {
  EntityNameNumberRecordComponent
} from "../../../../entity-name-number-record/entity-name-number-record.component";
import { ICellRendererParams } from "ag-grid-community";
import { AnnualRecord } from "../../../../../../../models/annualRecord";
import { ICellRendererAngularComp } from "ag-grid-angular";

type CellRendererParams = ICellRendererParams<AnnualRecord> & {
  canBeOpened: boolean;
}

@Component({
  selector: 'app-ag-annual-name-acn',
  standalone: true,
  imports: [
    ACNPipe,
    EntityNameNumberRecordComponent,
    RouterLink
  ],
  templateUrl: './ag-annual-name-acn.component.html',
  styleUrl: './ag-annual-name-acn.component.scss'
})
export class AgAnnualNameAcnComponent implements ICellRendererAngularComp {
  @Input() name = '';
  @Input() acn = '';
  @Input() documentId = '';
  @Input() canBeOpened = false;

  agInit(params: CellRendererParams): void {
    this.updateCell(params);
  }

  refresh(params: CellRendererParams): boolean {
    return this.updateCell(params);
  }

  updateCell(params: CellRendererParams): boolean {
    this.name = params.data?.companyName ?? '';
    this.acn = params.data?.acn ?? '';
    this.documentId = params.data?.documentId ?? '';
    this.canBeOpened = params.canBeOpened && !!this.documentId;
    return true;
  }
}
