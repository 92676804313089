import { Component, DestroyRef, inject, Input } from '@angular/core';
import { DatePipe } from "@angular/common";
import { NgxSkeletonLoaderModule } from "ngx-skeleton-loader";
import { EdgeTransactionDirection, EdgeTransactionRecord, EdgeTransactionStatus } from '../../../models/edgeTransactionRecord';
import { CommonOffcanvasComponent } from '../../components/common/common-offcanvas/common-offcanvas.component';
import { SuperuserService } from '../../../services/superuser.service';
import { ButtonComponent } from "../../components/common/button/button.component";
import { catchError, of } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { downloadBase64File } from '../../../functions/download-base64-file';
import { HttpErrorResponse } from '@angular/common/http';

@Component({
  selector: 'app-edge-transaction-details',
  standalone: true,
  imports: [
    CommonOffcanvasComponent,
    DatePipe,
    NgxSkeletonLoaderModule,
    ButtonComponent
],
  templateUrl: 'edge-transaction-details.component.html',
  styleUrls: [
    'edge-transaction-details.component.scss'
  ]
})
export class EdgeTransactionDetailsComponent extends CommonOffcanvasComponent {
  EdgeTransactionStatus = EdgeTransactionStatus;
  EdgeTransactionDirection = EdgeTransactionDirection;
  superuserService = inject(SuperuserService);
  #toastr = inject(ToastrService);
  #destroyRef: DestroyRef = inject(DestroyRef);

  @Input({ alias: 'transactionRecord', required: true }) set transactionRecordSetter(record: EdgeTransactionRecord | null) {
    this.transactionRecord = record;
    this.transactionText = null;

    if (record) {
      this.loadTransactionText(record);
    }
  }

  transactionRecord: EdgeTransactionRecord | null = null;
  transactionText: string | null = null;
  isModelLoading = false;
  isActionLoading = false;
  
  private loadTransactionText(record: EdgeTransactionRecord): void {
    this.isModelLoading = true;
    this.transactionText = record.transactionText;
  }

  downloadTransaction() {
    if (!this.transactionRecord) {
      return;
    }

    this.isActionLoading = true;
    this.superuserService.downloadTransaction(this.transactionRecord.transactionId).pipe(
            catchError((error: HttpErrorResponse) => {
              console.error(error);
              this.#toastr.error(`Download error`, "Error");
              return of('ERR');
            }),
            takeUntilDestroyed(this.#destroyRef)
          )
          .subscribe((res) => {
            this.isActionLoading = false;
            if(res !== 'ERR') {
              downloadBase64File(res, `T${this.transactionRecord?.transactionId}.txt`);
            }
          });
  }

  reprocessTransaction() {
    if (!this.transactionRecord) {
      return;
    }

    this.isActionLoading = true;
    this.superuserService.reprocessTransaction(this.transactionRecord.transactionId).subscribe({
      next: () => {
        this.#toastr.success('Transaction scheduled for reprocessing', 'Success');
      },
      error: (error) => {
        console.error(error);
        this.#toastr.error('Failed to reprocess transaction', 'Error');
      },
      complete: () => {
        this.isActionLoading = false;
      }
    });
  }
}
