<div class="modal-header">
  <div class="title-container">
    <div class="icon-container">
      <i class="icon ic-download-file"></i>
    </div>
    <div class="title-wrapper">
      <div class="title">{{title}}</div>
      <div class="sub-title">{{numberOfDocuments}} {{subTitle}}</div>
    </div>
  </div>
  <div class="close-btn" (click)="onClose()">
    <i class="icon ic-xs ic-close"></i>
  </div>
</div>

<div class="modal-body">
  <div class="text">Select the columns you wish to export. The data will be exported in {{exportType}} format.</div>
  <div class="column-all">
    <app-checkbox
      [label]="'Select All Columns'"
      [value]="selectAll"
      (change)="onSelectAllChange($event)"
    ></app-checkbox>
  </div>

  <app-divider [margin]="'16px 0'"></app-divider>

  <div class="column-container">
    @for (column of colDefs; track column.field) {
      @if (column.headerName) {
        <div class="column-name">
          <app-checkbox
            [label]="column.headerName"
            [value]="!column.hide"
            (change)="onCheckboxChange($index)"
          ></app-checkbox>
        </div>
      }
    }
  </div>
</div>

<div class="modal-footer">
  <app-button [text]="'Export'" (click)="onExport()" [isDisabled]="!numberSelectedColumns"></app-button>
  <app-button [text]="'Cancel'" [btnColor]="'white'" (click)="onClose()"></app-button>
</div>
