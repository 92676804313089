@if (alert) {
  <div class="alert-details-container" [style.--header-height]="headerHeight">
    <div class="alert-details-header">
      @if (entityType !== null && entity) {
        <div>
          <app-ag-tag-entity-title
            [isLink]="true"
            [fullWidth]="true"
            [entity]="entity"
            [entityType]="entityType"
          ></app-ag-tag-entity-title>
        </div>
      }

      <div class="alert-details-header-grid">
        <div class="alert-details-item">
          <div class="alert-details-item-title">Date & Time</div>
          <div class="alert-details-item-content">{{ alert.dateCreated | date: 'dd MMM yyyy HH:mm' }}</div>
        </div>

        <div class="alert-details-item">
          <div class="alert-details-item-title">State</div>
          <div class="alert-status-wrapper">
            @if(alert.isReaded && alert.status === AlertStatus.New) {
              <div class="status read">
                {{ 'Read' }}
              </div>
            } @else {
              <div class="status" [ngClass]="AlertStatus[alert.status!]">
                {{ alert.status | alertsStatusPipe }}
              </div>
            }
          </div>
        </div>

        <div class="alert-details-item">
          <div class="alert-details-item-title">Status</div>
          <div class="alert-status-wrapper">
            <div class="status" [ngClass]="ResolvedStatus[alert.resolvedStatus!]">
              {{ ResolvedStatus[alert.resolvedStatus!] | splitByCapitalLetterPipe }}
            </div>
          </div>
        </div>
      </div>

      <div class="alert-details-item">
        <div class="alert-details-item-title">Description</div>
        <div class="alert-details-item-content">{{ alert.modelDetailTitle }}</div>
      </div>
    </div>

    <div class="alert-details-body" [innerHTML]="alert.model.message | insertLineBreak:'Address' | insertLineBreak:'Company'"></div>

    @if(alert.type === AlertType.ValidationReport) {
      <div class="validation-message">
        <div class="title">Incorrect Addres (V444)</div>
        <div class="message">Item 0a//ZDC//Line2//Col3// X015 Mandatory element has no value</div>
        <div class="recommendation">Recommendation</div>
      </div>
    }

    @if((attachments$ | async)?.length) {
      <div class="alert-details-attachments">
        <div class="alert-details-attachments-header d-flex justify-content-between">
          <div class="alert-details-attachments-header-title d-flex align-items-center">
            <span class="icon ic ic-attachment"></span>
            <span class="alert-details-attachments-header-title-label">Attachments</span>
          </div>
          <app-button
            [btnColor]="'white'"
            [iconClass]="'icon ic-download-file'"
            (clickBtn)="downloadAllAttachments()"
          ></app-button>
        </div>

        <div class="alert-details-attachments-body">
          @for(file of attachments$ | async; track $index) {
            <div class="attachment-item">
              <div class="file-name-container">
                <i class="icon ic-file"></i>
                <div class="file-name">{{file.name}}</div>
              </div>
              <div class="download-icon" (click)="downloadFile(alert.id, file.name)">
                <i class="icon ic-download-file"></i>
              </div>
            </div>
          }
        </div>
      </div>
    }

    <div class="alert-details-blank"></div>

    <div class="alert-details-footer">
      @if(alert.status !== AlertStatus.Archived) {
        @for(action of alert.model.actions; track $index) {
          <div 
            *appHasRole="['Admin', 'Manager', 'Preparer']"
            [ngbTooltip]="action.title.length > 20 ? action.title : ''"
          >
            <app-button
              [text]="action.title.length > 20 ? (action.title | slice:0: 20) + '...' : action.title"
              [iconClass]="'icon ic-wrench'"
              [loading]="loading"
              (clickBtn)="handleAction(alert.id, action.id)"
            ></app-button>
          </div>
        }
      }

      @if (alert.type === AlertType.XpmSyncIssue || alert.type === AlertType.AsicDataIssue) {
        <div ngbDropdown [placement]="['bottom-end', 'bottom-start', 'top-start', 'top-end']" class="dropdown">
          <app-button
            ngbDropdownToggle
            id="bulk-actions-dd-2"
            [text]="'Postpone'"
            [btnColor]="'white'"
            [iconClass]="'icon ic-clock'"
            [iconClassLast]="'icon ic-chevron-down'"
          ></app-button>

          <div ngbDropdownMenu aria-labelledby="bulk-actions-dd" class="dropdown-menu dropdown-menu-right">
            @for(item of postponeMenu; track $index) {
              <div class="dropdown-item" (click)="postponeAlert(alert, item.value)">
                <a class="dropdown-button">
                  <span>{{item.label}}</span>
                </a>
              </div>
            }
          </div>
        </div>

        <app-button
          [text]="'Ignore'"
          [btnColor]="'white'"
          [iconClass]="'icon ic-ignore'"
          (clickBtn)="ignoreAlert(alert)"
        ></app-button>
      }

      @if(alert.type === AlertType.ValidationReport) {
        <app-button [text]="'Archive'" [iconClass]="'icon ic-archive'" (clickBtn)="archiveAlert(alert)"></app-button>
        <app-button [text]="'Close'" [btnColor]="'white'" [iconClass]="'icon ic-close'" (clickBtn)="closeAlert()"></app-button>
      }
    </div>
  </div>

}
