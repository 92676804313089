import { SecurityTransaction } from "./securityTransaction";
import { CorporateHolderModel, IndividualHolderModel } from "./securityRegistryRecord";

export class ClientSecurityTransaction extends SecurityTransaction {
  holder: (IndividualHolderModel | CorporateHolderModel);
  numberBalance: number;
  paidBalance: number;
  unpaidBalance: number;

  groupNumberBalance: number;
  groupPaidBalance: number;
  groupUnpaidBalance: number;

  constructor(data: Partial<ClientSecurityTransaction> = {}) {
    super(data);

    this.holder = data.holder ?? new IndividualHolderModel();
    this.numberBalance = data.numberBalance ?? 0;
    this.paidBalance = data.paidBalance ?? 0;
    this.unpaidBalance = data.unpaidBalance ?? 0;
    this.groupNumberBalance = data.groupNumberBalance ?? 0;
    this.groupPaidBalance = data.groupPaidBalance ?? 0;
    this.groupUnpaidBalance = data.groupUnpaidBalance ?? 0;
  }
}
