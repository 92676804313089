import { inject, Injectable } from '@angular/core';
import * as signalR from '@microsoft/signalr';
import { defer, Observable, throwError } from 'rxjs';
import { AuthService } from './auth.service';
import { environment } from '../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class SignalRService {
  private hubConnection: signalR.HubConnection;
  private authService = inject(AuthService);

  constructor() {
    this.hubConnection = new signalR.HubConnectionBuilder()
      .withUrl(environment.signalR_url, { accessTokenFactory: () => this.authService.getToken().then(token => token!) })
      .withAutomaticReconnect()
      .build();
  }

  startConnection(): Observable<void> {
    return defer(() => {
      const currentUserProfile = this.authService.currentUserProfile();

      if (!currentUserProfile || currentUserProfile.organisationSetupRequired || !currentUserProfile.organisationName) {
        console.warn('SignalR connection skipped: user not logged in into company.');
        return throwError(() => new Error('Organisation ID is required to establish a SignalR connection.'));
      }
      
      if (this.hubConnection.state === signalR.HubConnectionState.Connected) {
        console.log('SignalR connection is already established');
        return new Observable<void>((observer) => {
          observer.next();
          observer.complete();
        });
      }
  
      return new Observable<void>((observer) => {
        this.hubConnection
          .start()
          .then(() => {
            console.log('Connection established with SignalR hub');
            observer.next();
            observer.complete();
          })
          .catch((error) => {
            console.error('Error connecting to SignalR hub:', error);
            observer.error(error);
          });
      });
    });
  }

  receive(): Observable<string> {
    return new Observable<string>((observer) => {
      this.hubConnection.on('Receive', (message: string) => {
        observer.next(message);
      });
    });
  }

  onDocumentSigningStatusChanged(): Observable<string> {
    return new Observable<string>((observer) => {
      const callback = (message: string) => {
        console.log('DocumentSigningStatusChanged:', message);
        observer.next(message);
      };
  
      this.hubConnection.on('DocumentSigningStatusChanged', callback);
  
      return () => {
        this.hubConnection.off('DocumentSigningStatusChanged', callback);
      };
    });
  }

  send(message: string): void {
    void this.hubConnection.invoke('Send', message);
  }
}