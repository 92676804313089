<div class="card-header">
  <div class="d-flex align-items-center justify-content-between gap-24">
    <div class="d-flex align-items-center gap-24">
      <app-button
        [btnColor]="'white'"
        [text]="'Back'"
        [iconClass]="'icon ic-arrow-left'"
        [isDisabled]="disableControls"
        (clickBtn)="profileViewChange.emit(false)"
      ></app-button>
      <app-divider [vertical]="true"></app-divider>
      <div class="profile-title">Officeholder Profile</div>
    </div>
    <div class="d-flex gap-12">
      <app-button
        [text]="'Edit Officer'"
        [iconClass]="'icon ic-xs ic-edit'"
        [isDisabled]="disableControls"
        (clickBtn)="onEditOfficer()"
      ></app-button>

      <div ngbDropdown class="dropdown">
        <button
          ngbDropdownToggle
          id="company-actions-dd"
          class="btn btn-primary d-flex align-items-center gap-2"
          [disabled]="disableControls"
        >
          <span>Actions</span>
          <i class="icon ic-xs ic-select-icon"></i>
        </button>

        <div ngbDropdownMenu aria-labelledby="company-actions-dd" class="dropdown-menu dropdown-menu-right">
          <div ngbDropdownItem class="dropdown-item">
            <a class="dropdown-button" (click)="onCompanyChangePartyName()">
              <i class="ic-form ic-md icon"></i>

              <div class="document">
                <div class="title">Change Officer Name</div>
                <div class="asic-form">Form 484A2</div>
              </div>
            </a>
          </div>

          <div ngbDropdownItem class="dropdown-item">
            <a class="dropdown-button" (click)="onChangeOfficerAddress()">
              <i class="ic-form ic-md icon"></i>

              <div class="document">
                <div class="title">Change of Address</div>
                <div class="asic-form">Form 484A1</div>
              </div>
            </a>
          </div>

          <div ngbDropdownItem class="dropdown-item">
            <a class="dropdown-button" (click)="onCeaseRegisteredAgent()">
              <i class="ic-form ic-md icon"></i>

              <div class="document">
                <div class="title">Cessation of Officeholder</div>
                <div class="asic-form">Form 484B1</div>
              </div>
            </a>
          </div>
        </div>
      </div>

    </div>
  </div>
</div>
<div class="card-body" [class.changes-hidden]="!pendingButtonState">
  <div class="officeholder-info-container">
    <div>
      <div class="title">Officeholder Name</div>
      @if(appointedOfficeholder) {
        <div class="prop current">{{ officeholder.individualDataOverride?.fullName }}</div>
      } @else if(changedOfficeholderName) {
        <div class="prop previous">{{ openedPreviousOfficeholder?.individualDataOverride?.fullName }}</div>
        <div class="prop current">{{ officeholder.individualDataOverride?.fullName }}</div>
      } @else if(ceasedOfficeholder) {
        <div class="prop cease">{{ openedPreviousOfficeholder?.individualDataOverride?.fullName }}</div>
      } @else {
        <div class="prop">{{ openedPreviousOfficeholder?.individualDataOverride?.fullName | defaultValue }}</div>
      }
    </div>

    <div>
      <div class="title">Status</div>
      @if(openedPreviousOfficeholder?.end && openedPreviousOfficeholder?.end !== officeholder.end) {
        <div class="prop previous">{{ openedPreviousOfficeholder?.end | officerStatus }}</div>
        <div class="prop current">{{ officeholder.end | officerStatus }}</div>
      } @else {
        <div class="prop">{{ officeholder.end | officerStatus }}</div>
      }
    </div>

    <div>
      <div class="title">Position</div>
      @if(openedPreviousOfficeholder?.type && openedPreviousOfficeholder?.type !== officeholder.type) {
        <div class="prop previous">{{ openedPreviousOfficeholder?.type | relationshipType }}</div>
        <div class="prop current">{{ officeholder.type | relationshipType }}</div>
      } @else {
        <div class="prop">{{ officeholder.type | relationshipType }}</div>
      }
    </div>

    @if(officeholder.type === RelationshipType.Director || officeholder.type === RelationshipType.AlternativeDirector) {
      <div>
        <div class="title">Director ID</div>
        @if(
          openedPreviousOfficeholder?.individualDataOverride?.din
          && openedPreviousOfficeholder?.individualDataOverride?.din !== officeholder.individualDataOverride?.din
          ) {
          <div class="prop previous">{{ openedPreviousOfficeholder?.individualDataOverride?.din | defaultValue }}</div>
          <div class="prop current">{{ officeholder.individualDataOverride?.din | defaultValue }}</div>
        } @else {
          <div class="prop">{{ officeholder.individualDataOverride?.din | defaultValue }}</div>
        }
      </div>
    } @else {
      <div>
        <div class="title">Address</div>
        @if(
          openedPreviousOfficeholder?.individualDataOverride?.address?.normalizedFullAddress
          && openedPreviousOfficeholder?.individualDataOverride?.address?.normalizedFullAddress !== officeholder.individualDataOverride?.address?.normalizedFullAddress
          ) {
          <div class="prop previous">
            {{ openedPreviousOfficeholder?.individualDataOverride?.address?.normalizedFullAddress || '' | titlecase | defaultValue }}
          </div>
          <div class="prop current">
            {{ officeholder.individualDataOverride?.address?.normalizedFullAddress || '' | titlecase | defaultValue }}
          </div>
        } @else {
          <div class="prop">
            {{ officeholder.individualDataOverride?.address?.normalizedFullAddress || '' | titlecase | defaultValue }}
          </div>
        }
      </div>
    }

    <div>
      <div class="title">Appointment Date</div>
      @if(openedPreviousOfficeholder?.start && openedPreviousOfficeholder?.start !== officeholder.start) {
        <div class="prop previous">{{ openedPreviousOfficeholder?.start | dateFormat }}</div>
        <div class="prop current">{{ officeholder.start | dateFormat }}</div>
      } @else {
        <div class="prop">{{ officeholder.start | dateFormat }}</div>
      }
    </div>

    <div>
      <div class="title">Date of Birth</div>
      @if(
        openedPreviousOfficeholder?.individualDataOverride?.dob
        && openedPreviousOfficeholder?.individualDataOverride?.dob !== officeholder.individualDataOverride?.dob
        ) {
        <div class="prop previous">{{ openedPreviousOfficeholder?.individualDataOverride?.dob | dateFormat }}</div>
        <div class="prop current">{{ officeholder.individualDataOverride?.dob | dateFormat }}</div>
      } @else {
        <div class="prop">{{ officeholder.individualDataOverride?.dob | dateFormat }}</div>
      }
    </div>

    <div>
      <div class="title">Cessation Date</div>
      @if(openedPreviousOfficeholder?.end && openedPreviousOfficeholder?.end !== officeholder.end) {
        <div class="prop previous">{{ openedPreviousOfficeholder?.end | dateFormat }}</div>
        <div class="prop current">{{ officeholder.end | dateFormat }}</div>
      } @else {
        <div class="prop">{{ officeholder.end | dateFormat }}</div>
      }
    </div>

    <div>
      <div class="title">Place of Birth</div>
      @if(
        (openedPreviousOfficeholder?.individualDataOverride?.birthCountry || openedPreviousOfficeholder?.individualDataOverride?.birthCity)
        && (openedPreviousOfficeholder?.individualDataOverride?.birthCountry) + ', ' +  openedPreviousOfficeholder?.individualDataOverride?.birthCity
        !== (officeholder.individualDataOverride?.birthCountry) + ', ' +  officeholder.individualDataOverride?.birthCity
        ) {
        <div class="prop previous">
          {{ (openedPreviousOfficeholder?.individualDataOverride?.birthCountry) + ', ' +  openedPreviousOfficeholder?.individualDataOverride?.birthCity }}
        </div>
        <div class="prop current">
          {{ (officeholder.individualDataOverride?.birthCountry) + ', ' +  officeholder.individualDataOverride?.birthCity }}
        </div>
      } @else {
        <div class="prop">
          {{ (officeholder.individualDataOverride?.birthCountry) + ', ' +  officeholder.individualDataOverride?.birthCity }}
        </div>
      }
    </div>

    @if(officeholder.type === RelationshipType.Director || officeholder.type === RelationshipType.AlternativeDirector){
      <div>
        <div class="title">Address</div>
        @if(
          openedPreviousOfficeholder?.individualDataOverride?.address?.normalizedFullAddress
          && openedPreviousOfficeholder?.individualDataOverride?.address?.normalizedFullAddress !== officeholder.individualDataOverride?.address?.normalizedFullAddress
          ) {
          <div class="prop previous">
            {{ openedPreviousOfficeholder?.individualDataOverride?.address?.normalizedFullAddress || '' | titlecase | defaultValue }}
          </div>
          <div class="prop current">
            {{ officeholder.individualDataOverride?.address?.normalizedFullAddress || '' | titlecase | defaultValue }}
          </div>
        } @else {
          <div class="prop">
            {{ officeholder.individualDataOverride?.address?.normalizedFullAddress || '' | titlecase | defaultValue }}
          </div>
        }
      </div>
    }
  </div>
</div>
