import { Injectable } from "@angular/core";
import { environment } from "../environments/environment";
import { Observable } from "rxjs";
import { HttpClient } from "@angular/common/http";
import { Approver, CarbonCopy, UpdateRecipientInfoModel } from "../models/signDocuments";
import { DocumentSigning } from "../models/documentEnteties/document-signing";

@Injectable({
  providedIn: 'root'
})
export class DocumentSigningsService {
  constructor(private api: HttpClient) { }

  getDocumentSigning(id: string) : Observable<DocumentSigning> {
    return this.api.get<DocumentSigning>(`${environment.api_url}/document-signings/${id}`);
  }

  deleteApprover(id: string) : Observable<void> {
    return this.api.delete<void>(`${environment.api_url}/document-signings/${id}/approver`);
  }

  addApprover(id: string, approver: Approver) : Observable<void> {
    return this.api.post<void>(`${environment.api_url}/document-signings/${id}/approver`, { approver });
  }

  updateCarbonCopies(id: string, carbonCopies: CarbonCopy[]) : Observable<void> {
    return this.api.put<void>(`${environment.api_url}/document-signings/${id}/carbon-copies`, carbonCopies);
  }

  updateRecipientInfo(updateModel: UpdateRecipientInfoModel) : Observable<void> {
    return this.api.post<void>(`${environment.api_url}/document-signings/recipient-info/update`, updateModel);
  }
}
