import { ChangeDetectorRef, Component, DestroyRef, inject, Input, OnInit } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Address } from '../../../../../models/address';
import { EntityData } from '../../../../../models/entityData';
import { CorporateHolderModel } from '../../../../../models/securityRegistryRecord';
import { acnValidator } from '../../../../../validators/acn.validator';
import { companyNameValidator } from '../../../../../validators/compny-name.validator';
import { CheckboxComponent } from '../../../../components/common/checkbox/checkbox.component';
import { Guid } from '../../../../helpers/guid.helper';
import { CommonModalFormComponent } from '../../../common-modal-form/common-modal-form.component';
import {
  CompanyNameAcnFormGroupComponent
} from "../../../../components/reusable-form-groups/company-name-acn-form-group/company-name-acn-form-group.component";
import {
  AddressFormGroupComponent,
  AddressFormGroupControls
} from "../../../../components/reusable-form-groups/address-form-group/address-form-group.component";

@Component({
  selector: 'app-add-corporate-security-holder-modal',
  standalone: true,
  imports: [
    ReactiveFormsModule,
    CheckboxComponent,
    CommonModalFormComponent,
    AddressFormGroupComponent,
    CompanyNameAcnFormGroupComponent,
  ],
  templateUrl: './add-corporate-security-holder-modal.component.html',
  styleUrl: './add-corporate-security-holder-modal.component.scss'
})
export class AddCorporateSecurityHolderModalComponent implements OnInit {
  activeModal = inject(NgbActiveModal);
  destroyRef = inject(DestroyRef);
  cdr = inject(ChangeDetectorRef);

  @Input() corporateHolderModel: CorporateHolderModel | null = null;
  @Input() suggestedAddresses: Address[] = [];

  form = new FormGroup({
    ...CompanyNameAcnFormGroupComponent.defineFormControls(),
    isOverseasCompany: new FormControl<boolean>(false),
    registeredAddress: AddressFormGroupComponent.defineForm()
  });

  header = '';

  addressFormLabels: Record<keyof AddressFormGroupControls, string> = {
    normalizedFullAddress: 'Registered address',
  } as Record<keyof AddressFormGroupControls, string>;

  ngOnInit(): void {
    this.activeModal.update({ modalDialogClass: 'common-modal-form-dialog overflow-y-auto' });
    this.listenForOverseasCompanyChange();
    this.setHeader();

    this.form.controls.isOverseasCompany.patchValue(this.form.controls.isOverseasCompany.value);

    if (this.corporateHolderModel) {
      this.form.patchValue({
        registeredAddress: this.corporateHolderModel.entityData.registeredAddress,
        name: this.corporateHolderModel.name,
        acn: this.corporateHolderModel.entityData.entityNumber,
        isOverseasCompany: this.corporateHolderModel.entityData.registeredAddress?.country !== 'AU'
      });
    } else {
      this.corporateHolderModel = new CorporateHolderModel({
        relationshipId: Guid.generate(),
        entityData: new EntityData({
          registeredAddress: new Address({ country: 'AU' }),
        }),
      });
    }
  }

  save(): void {
    if (this.form.invalid) {
      this.form.markAllAsTouched();
      return;
    }

    this.corporateHolderModel!.entityData = new EntityData({
      ...this.corporateHolderModel?.entityData,
      ...this.form.value as Partial<EntityData>,
      entityNumber: this.form.controls.acn.value!,
    });

    this.activeModal.close(this.corporateHolderModel);
  }

  cancel(): void {
    this.activeModal.dismiss();
  }

  listenForOverseasCompanyChange(): void {
    this.form.controls.isOverseasCompany.valueChanges
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((isOverseasCompany) => {
        if (isOverseasCompany) {
          this.form.controls.acn.clearValidators();
          this.form.controls.name.setValidators([Validators.required, Validators.minLength(7)]);
        } else {
          this.form.controls.acn.setValidators([Validators.required, acnValidator()]);
          this.form.controls.name.setValidators([Validators.required, Validators.minLength(7), companyNameValidator()]);
        }

        this.form.updateValueAndValidity();
        this.cdr.detectChanges();
        this.form.updateValueAndValidity();
        this.form.controls.acn.updateValueAndValidity();
        this.form.controls.acn.patchValue(this.form.value.acn ?? '');
      });
  }

  private setHeader(): void {
    this.corporateHolderModel ? this.header = 'Edit Corporate' : this.header = 'New Corporate';
  }

  get hideAcn(): boolean {
    return Boolean(this.form.value.isOverseasCompany);
  }
}
