import { Guid } from "../app/helpers/guid.helper";
import { DatepickerHelper } from '../custom-form-validators/date-picker-form-validators';
import { Company } from "./company";
import { Contact } from "./contact";
import { Entity } from "./entity";
import { RelationshipType } from "./enums/relationshipTypeEnum";
import { Individual } from "./individual";
import { IndividualData } from "./individualData";
import { Trust } from "./trust";
import { EntityData } from "./entityData";
import { IPostable } from "./postable";


export class Relationship implements IPostable {
  relationshipId: string;
  organisationId?: string;
  contactId?: string;
  contact?: Contact;
  start?: Date;
  end?: Date;
  type!: RelationshipType;
  details?: ShareholderRelationshipDetails | OfficerRelationshipDetails;
  individualDataOverride: IndividualData | null;
  entityDataOverride: EntityData | null;
  entityId: string;
  entity?: Entity | Company | Trust;
  entityOverride?: Entity | Company | Trust;
  individualId: string | null;
  individual?: Individual;
  relatedEntityId: string | null;
  relatedEntity?: Entity | Company | Trust;

  //temporary
  checked = false;
  isPosted = true;
  key= ''; // only for front-end part

  constructor(relationship: Partial<Relationship> = {}) {
    this.relationshipId = relationship.relationshipId ?? Guid.EmptyGuid;
    this.organisationId = relationship.organisationId;
    this.contactId = relationship.contactId;
    this.contact = relationship.contact;
    this.start = relationship.start ?? undefined;
    this.end = relationship.end ?? undefined;
    this.type = relationship.type!;
    this.details = relationship.details;
    this.individualDataOverride = relationship.individualDataOverride ? new IndividualData(relationship.individualDataOverride) : null;
    this.entityId = relationship.entityId ?? Guid.EmptyGuid;
    this.entity = relationship.entity ?? undefined;
    this.entityDataOverride = relationship.entityDataOverride ?? null;
    this.entityOverride = relationship.entityOverride ?? undefined;
    this.individualId = relationship.individualId ?? null;
    this.individual = relationship.individual ?? undefined;
    this.relatedEntityId = relationship.relatedEntityId ?? null;
    this.isPosted = relationship.isPosted ?? true;
    this.key = this.individualId ?? '';
  }

  toJSON() {
    const { key, checked, ...rest } = this;
    return rest;
  }

  static prepareToRequest(relationship: Relationship): Relationship {
    relationship.start = relationship.start ? DatepickerHelper.buildDateString(new Date(relationship.start)) : relationship.start;
    relationship.end = relationship.end ? DatepickerHelper.buildDateString(new Date(relationship.end)) : relationship.end;
    relationship.individualDataOverride = relationship.individualDataOverride ? IndividualData.prepareToRequest(relationship.individualDataOverride) : null;
    relationship.entityDataOverride = relationship.entityDataOverride ? EntityData.prepareToRequest(relationship.entityDataOverride) : null;
    if (relationship.details?.$type === new OfficerRelationshipDetails().$type && (relationship.details as OfficerRelationshipDetails).expiryDate)
      (relationship.details as OfficerRelationshipDetails).expiryDate = DatepickerHelper.buildDateString((relationship.details as OfficerRelationshipDetails).expiryDate!);
    return relationship;
  }
}

export class ShareholderRelationshipDetails {
  $type = "ShareholderRelationshipDetails";
  isBeneficialOwner: boolean;
  beneficialOwner: string;
  beneficialOwnerEntityId: string | null;
  authorisedSignatories?: IndividualData[];

  get beneficialOwnerLabel(): string {
    return this.isBeneficialOwner ? "" : (this.beneficialOwner ? this.beneficialOwner : "NBO");
}

  constructor(details: Partial<ShareholderRelationshipDetails> = {}) {
      this.isBeneficialOwner = details?.isBeneficialOwner ?? false;
      this.beneficialOwner = details?.beneficialOwner ?? '';
      this.beneficialOwnerEntityId = details?.beneficialOwnerEntityId ?? null;
      this.authorisedSignatories = details?.authorisedSignatories?.map(signatory => new IndividualData(signatory));
  }
}

export class OfficerRelationshipDetails {
  $type = "OfficerRelationshipDetails";
  isAlternativeDirector: boolean;
  alternativeDirectorFor?: string;
  termsOfAppointment?: string;
  expiryDate?: Date;
  officerAsicId?: string;

  constructor(details: Partial<OfficerRelationshipDetails> = {}) {
    this.isAlternativeDirector = details.isAlternativeDirector ?? false;
    this.alternativeDirectorFor = details.alternativeDirectorFor ?? undefined;
    this.termsOfAppointment = details.termsOfAppointment ?? undefined;
    this.expiryDate = details.expiryDate ? new Date(details.expiryDate) : undefined;
    this.officerAsicId = details.officerAsicId ?? undefined;
  }
}
