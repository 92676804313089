import { Component, EventEmitter, Input, Optional, Output } from '@angular/core';
import { LinkComponent } from "../link/link.component";
import { ValidationErrorComponent } from "../validation-error/validation-error.component";
import { ControlValueAccessor, NgControl, ReactiveFormsModule } from "@angular/forms";
import { SelectOption } from "../../../../models/selectOptions";
import { CheckboxComponent } from "../checkbox/checkbox.component";

export interface ICheckboxGroupOption extends SelectOption {
  checked: boolean;
}

@Component({
  selector: 'app-checkbox-group',
  standalone: true,
  imports: [
    ReactiveFormsModule,
    LinkComponent,
    CheckboxComponent,
    ValidationErrorComponent,
  ],
  templateUrl: 'checkbox-group.component.html',
  styles: `.header {
    margin-bottom: 4px;
  }`
})
export class CheckboxGroupComponent implements ControlValueAccessor {
  @Input() label = '';
  @Input() helperText = '';
  @Input() customErrors: Record<string, string> = { required: 'Field is required' };
  @Input() disabled = false;

  @Input({ required: true }) set options(options: SelectOption[]) {
    this.controlOptions = options.map((option) => ({ ...option, checked: this.value.includes(option.value) }));
  }

  @Output() change = new EventEmitter<unknown[]>();

  #value: unknown[] = []; // array of checked ICheckboxGroupOption.value
  controlOptions: ICheckboxGroupOption[] = [];

  constructor(@Optional() protected ngControl: NgControl) {
    if (ngControl) {
      ngControl.valueAccessor = this;
    }
  }

  get displayValidationError(): boolean {
    return !!(this.ngControl && this.ngControl.touched && this.ngControl.errors);
  }

  get value(): unknown[] {
    return this.#value;
  }

  set value(value: unknown[]) {
    this.#value = value;
    this.onChange(this.#value);
  }

  onChange: any = (): void => {
  };
  onTouched: any = (): void => {
  };

  onChecked(checked: boolean, checkboxIndex: number): void {
    this.controlOptions.find((_, index) => index == checkboxIndex)!.checked = checked;

    this.value = this.controlOptions
      .filter((option) => option.checked)
      .map((option) => option.value);

    this.onTouched();
  }

  writeValue(value: unknown[]): void {
    this.controlOptions.forEach((option, index) => {
      this.controlOptions[index].checked = value.includes(option.value);
    });

    this.value = this.controlOptions
      .filter((option) => option.checked)
      .map((option) => option.value);
  }

  registerOnChange(fn: () => void): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }
}
