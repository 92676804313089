import { NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import {
  OfficerAppointmentComponent, positionTypesOptions,
} from '../app/modals/officer-appointment/officer-appointment.component';
import { enumToSelectOptions } from '../functions/enums-to-list-formatter';
import { Company } from './company';
import { Document } from './document';
import { EntityChangeData } from './entityChangeData';
import { CompanyChangeData } from './enums/companyChangeData';
import { CompanyChangeOfficerType } from './enums/companyChangeOfficerType';
import { RelationshipType, RelationshipTypeLabels } from './enums/relationshipTypeEnum';
import { OfficerRelationshipDetails, Relationship } from './relationship';
import { SelectOption } from './selectOptions';
import { ChangeDictionaryHelper } from './shared/change-dictionary-helper.model';
import { OfficerAppointmentType } from "./officerAppointmentForm";
import { IndividualData } from "./individualData";
import { CountriesService } from "../services/countries.service";


export class CompanyChangeOfficer extends EntityChangeData {
  static override readonly $type = 'CompanyChangeOfficer';
  offices: Relationship[];
  actionType: CompanyChangeOfficerType;
  cessationReason: ReasonOfCessation | null;
  noticeOfResignation: string | null;
  isNewOfficeholder?: boolean | null;
  appointedOfficerId?: string | null;
  type?: RelationshipType | OfficerAppointmentType | null;
  din?: string | null;
  alternativeDirectorFor?: string | null;
  expiryDate?: Date | null;
  termsOfAppointment?: string | null;
  newOfficeholder?: Partial<IndividualData>;

  constructor(data: Partial<CompanyChangeOfficer> = {}) {
    super(data);

    this.offices = data.offices?.filter(Boolean) ?? [];
    this.actionType = data.actionType ?? CompanyChangeOfficerType.Appointment;
    this.cessationReason = data.cessationReason ?? null;
    this.noticeOfResignation = data.noticeOfResignation ?? null;
    this.isNewOfficeholder = data.isNewOfficeholder ?? false;
    this.appointedOfficerId = data.appointedOfficerId ?? null;
    this.type = data.type ?? null;
    this.din = data.din ?? null;
    this.alternativeDirectorFor = data.alternativeDirectorFor ?? null;
    this.expiryDate = data.expiryDate ?? null;
    this.termsOfAppointment = data.termsOfAppointment ?? null;
    this.newOfficeholder = data.newOfficeholder ?? new IndividualData();
  }

  override toDictionary(): { key: string; value: string; }[] {
    const dict = new ChangeDictionaryHelper();
    const officeholder = this.offices[0] ?? new Relationship();
    const officeholderName = officeholder?.individualDataOverride?.fullName.trim() ?? '';
    const positions = this.offices
      .map((relationship) => RelationshipTypeLabels[relationship.type])
      .join(', ');
    const officerWithRole = (role: RelationshipType) => this.offices.find(relationship => relationship.type === role);
    if (this.actionType === CompanyChangeOfficerType.Removal) {
      // B1 - Resignation
      // Officer Name
      // Position
      // Date of Cessation
      // Reason of Cessation
      // Notice of Resignation (If reason of cessation is Resignation)

      dict
        .add('Name of Officeholder', officeholder.individualDataOverride?.fullName.trim() || '')
        .add('Position', positions)
        .addDate('Date of Cessation', this.changeDate)
        .add('Reason of Cessation', ReasonOfCessation[this.cessationReason as ReasonOfCessation] ?? '')
        .addIfValueIsNotEmpty('Notice of Resignation', this.noticeOfResignation?.toString()?.trim() ?? '');
    } else if (this.actionType === CompanyChangeOfficerType.Appointment) {
      // B2 - Appointment
      // Date of Appointment
      // Name of Officeholder
      // Former Name
      // Address
      // Date of Birth
      // Place of Birth(Country/State)
      // PLace of Birth (Suburb/City)
      // Position
      // Director ID
      // The appointed "Alternate Director" is alternate for
      // Expiry Date
      // Terms of Appointment

      const alternativeDirectorOfficer = officerWithRole(RelationshipType.AlternativeDirector);
      const directorOfficer = officerWithRole(RelationshipType.Director);
      const birthCountryName = officeholder?.individualDataOverride?.birthCountry ?? '';
      const birthCountry = (CountriesService as { getAustralianCodeByStateName: (countryName: string) => string })
        .getAustralianCodeByStateName(birthCountryName) || birthCountryName;

      dict
        .addDate('Date of Appointment', this.changeDate)
        .addEmptyLine()
        .add('Name of Officeholder', officeholderName)
        .add('Former Name', officeholder?.individualDataOverride?.formerName?.fullName ?? '')
        .add('Address', officeholder?.individualDataOverride?.address?.normalizedFullAddress ?? '')
        .addDate('Date of Birth', officeholder?.individualDataOverride?.dob)
        .addCountry('Place of Birth (Country / State)', birthCountry)
        .add('Place of Birth (Suburb / City)', officeholder?.individualDataOverride?.birthCity ?? '')
        .add('Position', positions);

      if (directorOfficer || alternativeDirectorOfficer) {
        dict.addDin('Director ID', (directorOfficer || alternativeDirectorOfficer)?.individualDataOverride?.din?.trim());
      }

      if (alternativeDirectorOfficer) {
        dict.add('The appointed "Alternate director" is alternate for', (alternativeDirectorOfficer?.details as OfficerRelationshipDetails)?.alternativeDirectorFor || '');

        if ((officeholder.details as OfficerRelationshipDetails)?.expiryDate) {
          dict.addDate('Expiry Date', (officeholder.details as OfficerRelationshipDetails)?.expiryDate);
        }

        dict.add('Terms of Appointment', (alternativeDirectorOfficer?.details as OfficerRelationshipDetails)?.termsOfAppointment || '');
      }
    }

    return dict.dictionary;
  }

  override fillModal(modalRef: NgbModalRef, document: Document, actualCompany: Company): NgbModalRef {
    modalRef = this.fillModalWithCompany(modalRef, actualCompany, true);
    const instance = (modalRef.componentInstance as OfficerAppointmentComponent);
    instance.companyChangeData.documentId = document.documentId;

    this.offices = this.offices
      .map(officer => {
        officer.key = officer.individualId ?? instance.officersSelectOptions
          .find(option => option.label === officer?.individualDataOverride?.fullName)?.value as string ?? '';
        return officer;
      })
      .filter((officer) => positionTypesOptions.find((allowedType) => allowedType.value == officer.type));

    return modalRef;
  }

  fillModalWithCompany(modalRef: NgbModalRef, company: Company, isEdit = false): NgbModalRef {
    const instance = (modalRef.componentInstance as OfficerAppointmentComponent);

    instance.isEdit = isEdit;
    instance.officeholders = company.officers;
    instance.formModel = this;
    instance.companyChangeData = new CompanyChangeData(company);

    const directorList = company.officers?.filter(officer => officer.type === RelationshipType.Director && !officer.end);
    instance.moreThenOneDirectorExist = directorList?.length > 1;

    // TODO: remove after individualId works fine
    const officerIndividualsIdDictionary: Record<string, string> = {};

    instance.officersSelectOptions = structuredClone(company.officers)
      .reduce((officersSelectOptions: SelectOption[], officer) => {
          // TODO: remove after individualId works fine
          if (!officerIndividualsIdDictionary[officer.individualDataOverride!.fullName]) {
            officerIndividualsIdDictionary[officer.individualDataOverride!.fullName] = Math.random().toString(36).substring(7);
          }

          // TODO: remove after individualId works fine
          officer.key = officer.individualId ?? officerIndividualsIdDictionary[officer.individualDataOverride!.fullName];

          const officerOption: SelectOption = {
            label: officer.individualDataOverride!.fullName,
            value: officer.key,
          };

          if (!instance.officers[officer.key]) {
            instance.officers[officer.key] = [];

            officersSelectOptions?.push(officerOption);
          }

          if (officer.type === RelationshipType.Director) {
            instance.allDirectorsOptions?.push(officerOption);
          }

          instance.officers[officer.key]?.push(officer);

          return officersSelectOptions;
        },
        []
      )
      .sort((a, b) => a.label?.toLowerCase().localeCompare(b.label?.toLowerCase()));

    return modalRef;
  }

  override buildPreviewType(): string {
    return this.changeType
      .replace(this.actionType == CompanyChangeOfficerType.Appointment ? '1' : '2', '')
      .replace(':b', 'B');
  }

  override prepareToRequest(): CompanyChangeOfficer {
    return {
      ...this,
      ...super.prepareToRequest(),
      offices: this.offices.map((officer) => Relationship.prepareToRequest(officer))
    };
  }
}

export enum ReasonOfCessation {
  Resignation = 0,
  Removal = 1,
  Death = 2
}

export const reasonOfCessationOptions: SelectOption[] = enumToSelectOptions(ReasonOfCessation);
