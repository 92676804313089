import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../environments/environment';
import { UIProfile, UserBrief } from '../models/uiProfile';
import { KindeProfile } from '../models/kindeProfile';
import { BehaviorSubject, map, Observable, switchMap, tap } from 'rxjs';
import { OrganisationModel } from "../models/organisationModel";
import { OrganisationBrandModel } from "../models/OrganisationBrandModel";

@Injectable({
  providedIn: 'root'
})
export class OrganisationService {
  private cachedProfile: UIProfile | undefined;
  private profileUsers: UserBrief[] = [];
  private currentProfileUser: UserBrief | undefined;
  logoSubject = new BehaviorSubject<null>(null);
  headerLogoLoader = this.logoSubject.asObservable().pipe(switchMap(() => this.getSmallLogo()));

  constructor(private api: HttpClient) {
  }

  public save(organisation: OrganisationModel) {
    return this.api.put<string>(`${ environment.api_url }/organisation`, organisation, { responseType: 'text' as 'json' });
  }

  public sync(kindeProfile: KindeProfile) {
    return this.api.post<void>(`${ environment.api_url }/organisation/sync`, kindeProfile);
  }

  public getOrganisation(): Observable<OrganisationModel> {
    return this.api.get<OrganisationModel>(`${ environment.api_url }/organisation`)
      .pipe(map(organisation => new OrganisationModel(organisation)));
  }

  public getProfile() {
    return this.api.get<UIProfile>(`${ environment.api_url }/organisation/profile`).pipe(
      tap(profile => {
        if (!this.profileUsers.length) {
          this.cachedProfile = profile;
          this.profileUsers = profile.users ?? [];
          this.currentProfileUser = profile.users?.find(user => user.id === profile.userId);
        }
      })
    );
  }

  public getOrganisationProfile(): Observable<UIProfile> {
    return this.api.get<UIProfile>(`${ environment.api_url }/organisation/profile`);
  }

  public getOrganisationBranding(): Observable<OrganisationBrandModel> {
    return this.api.get<OrganisationBrandModel>(`${ environment.api_url }/organisation/brand`);
  }

  public putOrganisationBranding(organisationBrandModel: OrganisationBrandModel): Observable<void> {
    return this.api.put<void>(`${ environment.api_url }/organisation/brand`, organisationBrandModel);
  }

  public getSmallLogo() {
    return this.api.get(`${ environment.api_url }/organisation/brand/logo/small`, { responseType: 'blob' });
  }

  public getLargeLogo() {
    return this.api.get(`${ environment.api_url }/organisation/brand/logo/large`, { responseType: 'blob' });
  }

  public getSmallLogByDocumentId(docuemntId) {
    return this.api.get(`${ environment.api_url }/organisation/brand/logo/small/${docuemntId}`, { responseType: 'blob' });
  }

  public uploadLogo(imagePayload: FormData): Observable<void> {
    const headers = new HttpHeaders();
    headers.append('Content-Type', 'multipart/form-data');
    return this.api.post<void>(`${ environment.api_url }/organisation/brand/logo`, imagePayload, { headers })
      .pipe(tap(() => this.logoSubject.next(null)));
  }

  public getCachedProfile(): UIProfile | undefined {
    return this.cachedProfile;
  }

  public getCachedProfileUsers(): UserBrief[] {
    return this.profileUsers;
  }

  public getCurrentUser(): UserBrief | undefined {
    return this.currentProfileUser;
  }

  public deleteLogo(): Observable<void> {
    return this.api.delete<void>(`${ environment.api_url }/organisation/brand/logo`);
  }
}
