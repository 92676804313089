<div class="transactions-page-container">
  <div class="card-cell transactions-container">
    <div class="card">
      <div class="card-header">
        <div class="d-flex align-items-center justify-content-between">
          <div class="d-flex align-items-center">
            <i class="ic-inbox ic-md icon"></i>
            <h2 class="h2-heading mb-0 ml-3">Edge Transactions</h2>
          </div>
          <div class="button-container">
            <app-button
              [text]="'Refresh'"
              [iconClass]="'icon ic-xs ic-refresh'"
              (clickBtn)="refreshTransactionsGrid()"
            ></app-button>
          </div>
        </div>
      </div>
      <app-grid
        [loading]="loading"
        [suppressRowClickSelection]="true"
        storageKey="edge-transactions"
        [colDefs]="$any(colDefs)"
        [pageTitle]="'Transactions'"
        [rowModelType]="'serverSide'"
        [serverSideDatasource]="dataSource"
        [pagination]="true"
        [paginationPageSize]="20"
        [cacheBlockSize]="20"
        [paginationPageSizeSelector]="false"
        [getRowId]="getRowId"
        [hideSearch]="true"
        (gridReady)="onGridReady($event)"
      ></app-grid>
    </div>
  </div>

  <div class="card-cell connections-container">
    <div class="card">
      <div class="card-header">
        <div class="d-flex align-items-center justify-content-between">
          <div class="d-flex align-items-center">
            <i class="ic-log-out ic-md icon"></i>
            <h2 class="h2-heading mb-0 ml-3">Connections</h2>
          </div>
          <div class="button-container">
            <app-button
              [text]="'Trigger Organisation Signal'"
              [iconClass]="'icon ic-xs ic-external-link'"
              (clickBtn)="triggerOrganisationSignal()">
            </app-button>
            <app-button
              [text]="'Refresh'"
              [iconClass]="'icon ic-xs ic-refresh'"
              (clickBtn)="refreshConnectionsGrid()"
            ></app-button>
          </div>
        </div>
      </div>
      <app-grid
        [loading]="loading"
        storageKey="edge-connections"
        [colDefs]="$any(connectionsColDefs)"
        [pageTitle]="'Connections'"
        [suppressRowClickSelection]="true"
        [rowModelType]="'serverSide'"
        [serverSideDatasource]="connectionsDataSource"
        [pagination]="true"
        [paginationPageSize]="20"
        [cacheBlockSize]="20"
        [paginationPageSizeSelector]="false"
        [getRowId]="connectionsGetRowId"
        [hideSearch]="true"
        (gridReady)="onConnectionsGridReady($event)"
      ></app-grid>
    </div>
  </div>
</div>

<app-edge-transaction-details 
  [opened]="!!$selectedTransactionRecord()"
  [transactionRecord]="$selectedTransactionRecord()"
  (close)="onCloseDetails()"
  (refreshData)="refreshAll()">

</app-edge-transaction-details>