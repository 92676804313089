import { Component, DestroyRef, inject, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import {
  NgbDropdown,
  NgbDropdownItem,
  NgbDropdownMenu,
  NgbDropdownToggle,
  NgbModal,
  NgbModalRef
} from '@ng-bootstrap/ng-bootstrap';
import { Trust } from '../../models/trust';
import { DetailTab } from '../../models/interfaces';
import { DocumentRecord } from '../../models/documentRecord';
import { TrustsService } from '../../services/trustsService';
import { catchError, firstValueFrom, throwError } from 'rxjs';
import { DocumentsService } from '../../services/documents.service';
import { TrustType } from '../../models/enums/trustEnum';
import { Relationship } from '../../models/relationship';
import { ColDef, ValueGetterParams } from 'ag-grid-community';
import { RelationshipTypePipe } from '../../pipes/enumsPipes/relationshipTypePipe';
import { DatePipe } from '@angular/common';
import { ButtonComponent } from '../components/common/button/button.component';
import { AgOpenActionComponent } from '../components/common/grid/components/ag-open-action/ag-open-action.component';
import { ListGridComponent } from '../components/common/grid/components/list-grid/list-grid.component';
import { LoaderStandaloneComponent } from '../components/common/loader-standalone/loader-standalone.component';
import { TabButtonComponent } from '../components/common/tab-button/tab-button.component';
import {
  CompanyPendingChangesComponent
} from '../components/company-pending-changes/company-pending-changes.component';
import {
  CompanyProfileDocumentsComponent
} from '../components/company-profile-documents/company-profile-documents.component';
import { EntityProfileTagsComponent } from '../components/company-profile-tags/entity-profile-tags.component';
import { NotesComponent } from '../components/notes/notes.component';
import { NotesService } from '../components/notes/services/notes.service';
import { SecurityholdersComponent } from '../components/securityholders/securityholders.component';
import { TrustDetailsComponent } from '../components/trust-details/trust-details.component';
import { TrustProfileCardComponent } from '../components/trust-profile-card/trust-profile-card.component';
import { Guid } from '../helpers/guid.helper';
import { EntityType } from "../../models/enums/entityType";
import { AlertsListComponent } from "../components/alerts-list/alerts-list.component";
import { ModalFormsService } from "../../services/modal-forms.service";
import {
  FormEditTrustDetailsComponent
} from "../trusts/components/form-edit-trust-details/form-edit-trust-details.component";
import { RelationshipTypeLabels } from "../../models/enums/relationshipTypeEnum";
import {
  AgOfficeholdersStatusComponent
} from "../components/common/grid/components/ag-officeholders-status/ag-officeholders-status.component";
import { CheckboxComponent } from "../components/common/checkbox/checkbox.component";
import { FormChangeOfTrusteeComponent } from "../trusts/components/form-edit-trustee/form-change-of-trustee.component";
import {
  AppointmentType,
  TrustChangeRelationship
} from "../../models/trust-change-relationship";
import {
  FormChangeOfAppointorComponent
} from "../trusts/components/form-change-of-appointor/form-change-of-appointor.component";
import { Company } from "../../models/company";
import { EntityChangeData } from "../../models/entityChangeData";
import {
  BaseStepperAppliableComponent
} from "../modals/stepper-form/base-stepper-appliable/base-stepper-appliable.component";
import {
  FormTrustDistributionComponent
} from "../trusts/components/form-distribution-resolution-or-minutes/form-trust-distribution.component";
import { ConfirmComponent } from '../modals/confirm/confirm.component';
import { HttpErrorResponse } from '@angular/common/http';
import { ToastrService } from 'ngx-toastr';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import {
  TrustDistribution
} from "../trusts/components/form-distribution-resolution-or-minutes/form-distribution-resolution-or-minutes.model";
import { HasRoleDirective } from '../../directives/has-role.directive';

@Component({
  selector: 'app-trust-profile',
  templateUrl: './trust-profile.component.html',
  styleUrl: './trust-profile.component.scss',
  providers: [RelationshipTypePipe, DatePipe],
  imports: [
    TrustProfileCardComponent,
    EntityProfileTagsComponent,
    CompanyPendingChangesComponent,
    TabButtonComponent,
    ButtonComponent,
    NgbDropdown,
    NgbDropdownToggle,
    NgbDropdownMenu,
    NgbDropdownItem,
    TrustDetailsComponent,
    ListGridComponent,
    SecurityholdersComponent,
    CompanyProfileDocumentsComponent,
    LoaderStandaloneComponent,
    NotesComponent,
    AlertsListComponent,
    CheckboxComponent,
    HasRoleDirective
  ],
  standalone: true
})
export class TrustProfileComponent implements OnInit {
  private route = inject(ActivatedRoute);
  private trustService = inject(TrustsService);
  private documentsService = inject(DocumentsService);
  private notesService = inject(NotesService);
  private router = inject(Router);
  private modalFormsService = inject(ModalFormsService);
  private modalService = inject(NgbModal);
  private toastr = inject(ToastrService);
  private destroyRef = inject(DestroyRef);

  readonly EntityType = EntityType;
  readonly TrustType = TrustType;
  readonly trustsWebformCloseConfirmationMessage = 'Are you sure you want to close the form? The data will not be saved.';

  activeTab = 'Details';
  loading = false;
  hideTabs = false;
  defaultPageSize = 5;
  rowSelection: 'single' | 'multiple' = 'multiple';

  trustProfile: Trust = new Trust();
  pendingDocuments: DocumentRecord[] = [];
  pendingButtonState = true;

  checkedOnItems: Relationship[] = [];
  allRelationships: Relationship[] = [];
  trustRelationships: Relationship[] = []; // all trust relationships except for Trustee and Beneficiary
  colDefs: ColDef[] = [];
  relationshipColDefs: ColDef[] = [];

  alertsVisible = false;
  notesVisible = false;
  notesTotal = 0;
  showFormerRelationship = true;

  tabs: DetailTab[] = [
    { title: 'Details' },
    { title: 'Trustee' },
    { title: 'Beneficiaries' },
    { title: 'Relationships' },
    { title: 'Documents' },
  ];

  async ngOnInit() {
    const trustId = this.route.snapshot.paramMap.get('id');
    if (trustId != null) {
      this.loading = true;
      await this.loadProfile(trustId);
      this.updateColumns();
      this.updateTabs();
      await this.getPendingDocuments(trustId);
      this.allRelationships = this.trustProfile.getAllRelationships();
      this.loading = false;
    }
  }

  updateTab(tabName: string): void {
    this.activeTab = tabName;

    if (this.activeTab != 'Unitholders')
      this.updateColumns();

    this.checkedOnItems = [];
  }

  updateColumns() {
    this.relationshipColDefs = [
      {
        headerName: 'Name',
        valueGetter: (params: ValueGetterParams<Relationship>) => {
          return this.getRelationshipName(params.data!);
        },
        width: 150,
        flex: 2,
        editable: false,
        checkboxSelection: true,
        headerCheckboxSelection: true,
      },
      {
        headerName: 'Position',
        field: 'type',
        width: 180,
        flex: 1,
        valueFormatter: params => RelationshipTypeLabels[params.value] || ''
      },
      {
        headerName: 'Status',
        field: 'end',
        width: 130,
        flex: 1,
        cellRenderer: AgOfficeholdersStatusComponent,
      },
      {
        headerName: 'Address',
        valueGetter: (params: ValueGetterParams<Relationship>) => {
          return this.getRelationshipAddress(params.data!);
        },
        editable: false,
        width: 150,
        flex: 1,
      },
      {
        field: '',
        width: 50,
        suppressHeaderMenuButton: true,
        suppressMovable: true,
        resizable: false,
        sortable: false,
        cellClass: 'actions-button-cell',

        cellRenderer: AgOpenActionComponent,
        cellRendererParams: {
          hidden: (params) => !!params.data.entityDataOverride,
          open: this.openProfile.bind(this),
        }
      }
    ];

    this.colDefs = [
      {
        headerName: 'Name',
        valueGetter: (params: ValueGetterParams<Relationship>) => {
          return this.getRelationshipName(params.data!);
        },
        width: 150,
        flex: 2,
        editable: false,
        checkboxSelection: true,
        headerCheckboxSelection: true,
      },
      {
        headerName: 'Position',
        field: 'type',
        width: 100,
        initialHide: this.activeTab != 'Relationship',
        flex: 1,
        valueFormatter: params => RelationshipTypeLabels[params.value] ?? '-'
      },
      {
        headerName: 'Address',
        valueGetter: (params: ValueGetterParams<Relationship>) => {
          return this.getRelationshipAddress(params.data!);
        },
        editable: false,
        width: 150,
        flex: 1,
      },
      {
        field: '',
        width: 50,
        suppressHeaderMenuButton: true,
        suppressMovable: true,
        resizable: false,
        sortable: false,
        cellClass: 'actions-button-cell',

        cellRenderer: AgOpenActionComponent,
        cellRendererParams: {
          hidden: (params) => !!params.data.entityDataOverride,
          open: this.openProfile.bind(this),
        }
      }
    ];
  }

  updateTabs() {
    if (this.trustProfile.trustType == TrustType.Unit) {
      this.tabs = this.tabs.map(tab => {
        if (tab.title === 'Beneficiaries') {
          return { title: 'UnitHolders' };
        }
        return tab;
      });
    }
  }

  async loadProfile(trustId: string): Promise<void> {
    this.trustProfile = await firstValueFrom(this.trustService.getTrustProfile(trustId));
    this.filterTrustRelationship();
    this.loadNotesTotal();
  }

  async onTrustRefreshedEvent(){
    await this.loadProfile(this.trustProfile.entityId);
  }

  selectItems(items: Relationship[]): void {
    this.checkedOnItems = items;
  }

  async updateTrustProfile(): Promise<void> {
    await this.loadProfile(this.trustProfile.entityId);
    await this.getPendingDocuments(this.trustProfile.entityId);
  }

  async getPendingDocuments(trustId: string): Promise<void> {
    this.pendingDocuments = await this.documentsService.getPendingDocuments(trustId);
  }

  edit(): void {
    if (this.modalOpened()) return;
    const modalRef = this.modalFormsService.openModal(
      FormEditTrustDetailsComponent,
      { size: 'lg' },
      this.trustsWebformCloseConfirmationMessage
    );
    const instance = modalRef.componentInstance as FormEditTrustDetailsComponent;

    instance.trustProfile = structuredClone(this.trustProfile);

    modalRef.result.then(
      async () => {
        this.loading = true;
        await this.loadProfile(this.trustProfile.entityId);
        this.loading = false;
      },
      () => {
      });
  }

  changeTrustee(): void {
    if (this.modalOpened()) return;
    const change = new TrustChangeRelationship({ appointmentType: AppointmentType.Trustees });
    const modalRef = this.openAppliableModal(change);
    const instance = modalRef.componentInstance as FormChangeOfTrusteeComponent;

    instance.trustProfile = this.trustProfile;

    modalRef.result.then(
      async () => {
        this.loading = true;
        await this.loadProfile(this.trustProfile.entityId);
        this.loading = false;
      },
      () => {
      });
  }

  changeAppointor(): void {
    if (this.modalOpened()) return;
    if (this.trustProfile.trustType != TrustType.Discretionary) return;
    const change = new TrustChangeRelationship({ appointmentType: AppointmentType.Appointor });
    const modalRef = this.openAppliableModal(change);
    const instance = modalRef.componentInstance as FormChangeOfAppointorComponent;

    instance.trustProfile = this.trustProfile;

    modalRef.result.then(
      async () => {
        this.loading = true;
        await this.loadProfile(this.trustProfile.entityId);
        this.loading = false;
      },
      () => {
      });
  }

  openTrustDistributionModal(): void {
    if (this.modalOpened()) return;
    const change = new TrustDistribution();
    const modalRef = this.modalFormsService.openTrustModalWithCompany(change, new Company(), false, this.trustsWebformCloseConfirmationMessage);
    const instance = modalRef.componentInstance as FormTrustDistributionComponent;

    instance.formModel = change;
    instance.trustProfile = this.trustProfile;

    modalRef.result.then(
      async () => {
        this.loading = true;
        await this.loadProfile(this.trustProfile.entityId);
        this.loading = false;
      },
      () => {
      });
  }

  async openProfile(relationship: Relationship) {
    if (relationship) {
      if (relationship.relatedEntityId && relationship.relatedEntityId != Guid.EmptyGuid) {
        await this.router.navigate(['/company-profile', relationship.entityId]);
      }

      if (relationship.individualId && relationship.individualId != Guid.EmptyGuid) {
        await this.router.navigate(['/individual', relationship.individualId]);
      }
    }
  }

  getRelationshipAddress(relationship: Relationship): string {
    if (relationship && relationship.entityDataOverride && relationship.entityDataOverride.registeredAddress) {
      return relationship.entityDataOverride.registeredAddress.normalizedFullAddress;
    }

    if (relationship && relationship.individualDataOverride && relationship.individualDataOverride.address) {
      return relationship.individualDataOverride.address.normalizedFullAddress;
    }
    return '-';
  }

  getRelationshipName(relationship: Relationship): string {
    if (relationship && relationship.entityDataOverride && relationship.entityDataOverride.name) {
      return relationship.entityDataOverride.name;
    }

    if (relationship && relationship.individualDataOverride && relationship.individualDataOverride.fullName) {
      return relationship.individualDataOverride.fullName;
    }
    return '-';
  }

  profileViewChange(hideTabs: boolean) {
    this.hideTabs = hideTabs;
  }

  filterTrustRelationship(): void {
    this.showFormerRelationship = !this.showFormerRelationship;
    const trustRelationships = this.getTrustRelationships(this.trustProfile);

    if (this.showFormerRelationship) {
      this.trustRelationships = trustRelationships;
    } else {
      this.trustRelationships = trustRelationships.filter((relationship) => !relationship.end);
    }
  }

  deleteDocumentChange(documentId: string): void {
    const modalRef = this.modalService.open(ConfirmComponent);
    (modalRef.componentInstance as ConfirmComponent).title = 'Delete changes';
    (modalRef.componentInstance as ConfirmComponent).message = `Are you sure you want to delete changes?`;
    (modalRef.componentInstance as ConfirmComponent).confirm =
      () => this.documentsService.deleteDocumentChange(documentId).pipe(catchError((error: HttpErrorResponse) =>
      {
        this.toastr.error("Error while deleting changes", "Error");
        return throwError(() => error);
      }));

    modalRef.closed.pipe(
      takeUntilDestroyed(this.destroyRef)
    ).subscribe(async () => {
      this.pendingDocuments = this.pendingDocuments.filter(doc => doc.documentId !== documentId);
      await this.updateTrustProfile();
      this.toastr.success('Changes deleted!', 'Success');
    });
  }

  private loadNotesTotal(): void {
    this.notesService.getNotesTotal(this.trustProfile.entityId)
      .subscribe({ next: (total) => this.notesTotal = total });
  }

  private getTrustRelationships(trustProfile: Trust): Relationship[] {
    // all trust relationships except for Trustee and Beneficiary
    const list: Relationship[] = [];
    if (trustProfile.appointors) list.push(...trustProfile.appointors);
    if (trustProfile.settlor) list.push(trustProfile.settlor);
    if (trustProfile.unitHolders.length) list.push(...trustProfile.unitHolders);
    return list;
  }

  private openAppliableModal(change: EntityChangeData): NgbModalRef {
    const modalRef = this.modalFormsService.openTrustModalWithCompany(change, new Company(), false, this.trustsWebformCloseConfirmationMessage);
    const instance = modalRef.componentInstance as BaseStepperAppliableComponent<any, EntityChangeData>;
    instance.documentApplier = this.trustService.applyChanges.bind(this.trustService);
    return modalRef;
  }

  get notesLabel(): string {
    if (this.notesTotal === 0) {
      return 'Notes';
    } else if (this.notesTotal === 1) {
      return '1 Note';
    }
    return `${ this.notesTotal } Notes`;
  }

  get alertsLabel(): string {
    const alertsTotal = this.trustProfile?.alerts?.length;
    if (alertsTotal === 0) {
      return 'Alerts';
    } else if (alertsTotal === 1) {
      return '1 Alert';
    }
    return `${ alertsTotal } Alerts`;
  }

  get modalOpened() {
    return this.modalFormsService.modalOpened;
  }
}
