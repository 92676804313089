import { DestroyRef, Injectable, inject } from "@angular/core";
import { ExternalEntity } from "../models/externalEntity";
import { ExternalSourceType } from "../models/enums/externalEntityEnums";
import { LinkToExternalEntityModalComponent } from "../app/modals/link-to-external-entity-modal/link-to-external-entity-modal.component";
import { Guid } from "../app/helpers/guid.helper";
import { EntityIdentityService } from "./entity.identity.service";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { Observable, firstValueFrom, take } from "rxjs";
import { EntityType } from "../models/enums/entityType";
import { ToastrService } from "ngx-toastr";
import { SubMenuItem } from "../models/sectionFormMenu";

@Injectable({
  providedIn: 'root',
})
export class ExternalEntitiesService {
  private destroyRef = inject(DestroyRef);
  private modalService = inject(NgbModal);
  private entityIdentityService = inject(EntityIdentityService);
  private toastr = inject(ToastrService);

  isConnectedtoExtentedEntity(externalEntities: ExternalEntity[], sourceType: ExternalSourceType) {
    const externalEntity = externalEntities.find(x => x.source == sourceType);
    return externalEntity != undefined;
  }

  getLinkedExternalEntity(externalEntities: ExternalEntity[], sourceType: ExternalSourceType) : ExternalEntity{
    const externalEntity = externalEntities.find(x => x.source == sourceType);

    if(externalEntity == undefined){
      throw new Error("External entity not found");
    }

    return externalEntity;
  }

  redirectToExternalProfile(externalEntities: ExternalEntity[], sourceType: ExternalSourceType) {
    const externalEntity = externalEntities.find(x => x.source == sourceType);
    if(externalEntity?.linkTo && externalEntity.linkTo != ""){
      window.open(externalEntity.linkTo, '_blank');
      return
    }

    this.toastr.error("Link was not found");
  }

  async unlinkExternalProfile(entityId: string, externalEntities: ExternalEntity[], sourceType: ExternalSourceType): Promise<ExternalEntity[]> {
   try{
      const externalEntity = this.getLinkedExternalEntity(externalEntities, sourceType);
      await firstValueFrom(this.entityIdentityService.unlinkEntity(entityId, externalEntity.externalId));
      this.toastr.success("Unlink successfully");
      return externalEntities.filter(x => x.source != sourceType);
   }catch {
    this.toastr.error("Error happen");
    return externalEntities;
   }
  }

  linkExternalProfile(entityId: string, entityNumber: string, sourceType: ExternalSourceType, entityType: EntityType): Observable<ExternalEntity> {
    const componentInstance = this.modalService
      .open(LinkToExternalEntityModalComponent, { size: 'xl', centered: true }).componentInstance as LinkToExternalEntityModalComponent;
    componentInstance.sourceType = sourceType;
    componentInstance.entityId = entityId;
    componentInstance.entityType = entityType;
    componentInstance.entityNumber = entityNumber;

    return componentInstance.confirm.pipe(take(1));
  }

  async refreshProfile(entityId: string, externalEntities: ExternalEntity[], sourceType: ExternalSourceType){
    try{
      const externalEntity = this.getLinkedExternalEntity(externalEntities, sourceType);

      await firstValueFrom(this.entityIdentityService.refresh(entityId, externalEntity.externalId, sourceType));
      this.toastr.success("Refreshed");
    }catch {
     this.toastr.error("Error happen");
    }
  }

  getLastSyncDateMessage(externalEntities: ExternalEntity[], sourceType: ExternalSourceType): string {
    const externalEntity = externalEntities.find(x => x.source === sourceType);
    
    if (externalEntity?.lastSync) {
      const lastSyncDate = new Date(externalEntity.lastSync);
  
      if (isNaN(lastSyncDate.getTime())) {
        return "";
      }
  
      const now = new Date();
      const diffMs = now.getTime() - lastSyncDate.getTime();
      const diffSeconds = Math.floor(diffMs / 1000);
      const diffMinutes = Math.floor(diffSeconds / 60);
      const diffHours = Math.floor(diffMinutes / 60);
      const diffDays = Math.floor(diffHours / 24);
  
      let timeDiffStr = '';
  
      if (diffDays > 0) {
        timeDiffStr = diffDays === 1 ? '1 day' : `${diffDays} days`;
      } else if (diffHours > 0) {
        timeDiffStr = diffHours === 1 ? '1 hour' : `${diffHours} hours`;
      } else if (diffMinutes > 0) {
        timeDiffStr = diffMinutes === 1 ? '1 minute' : `${diffMinutes} minutes`;
      } else {
        return "Synced just now";
      }
      
      return `Synced ${timeDiffStr} ago`;
    }
    return "";
  }
}