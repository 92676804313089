import { SignGroup, SigningStatus } from "./enums/annualStatementEnums";
import { EmailTemplate } from "./email-templates/emailTemplate";
import { Signature } from "./documentEnteties/signature";
import { ApproverStatus } from "./enums/approverStatus";
import { IEmailTemplateMockedData } from "./email-templates/emailTemplatesHelper";
import { DocumentFileInfoShort, FileCreateDto, FileMetadata } from "./files";
import { SignatureStatus } from "./enums/signatureStatus";

export interface Signatory {
  id: string;
  firstName?: string;
  lastName?: string;
  fullName: string;
  email: string;
  phone: string;
  status?: ApproverStatus | SigningStatus | SignatureStatus;
  signatureId: string | null | undefined;
  signedOn?: string | null | undefined;
  approvedOn?: string | null | undefined;
  disabled: boolean;
}

export interface SignRow extends Signatory {
  groupName: string;
  signedOn?: string | null | undefined;
  approvedOn?: string | null | undefined;
  approverAdded: boolean;
  approverApproved: boolean;
  type: SignGroup;
}

export interface Email {
  subjectTemplate: string | null;
  emailTemplate: string | null;
  footerTemplate: string | null;
}

export interface ESignBody {
  signatures: Signature[];
  emailTemplate: EmailTemplate;
  carbonCopyList: CarbonCopy[];
  approver?: Approver | null;
  carbonCopyEmails: string[];
  payloadModel: Partial<IEmailTemplateMockedData>;
}

export interface CarbonCopy {
  recipientId: string;
  email: string;
  phone: string;
  fullName: string;
  emailSendId: string;
}

export interface UpdateRecipientInfoModel {
  envelopeId: string;
  recipientId: string;
  fullName: string;
  email: string;
  phone: string;
}

export interface Approver extends CarbonCopy {
  approverStatus?: ApproverStatus;
  approvedOn?: string | null;
}

export interface PaperSignBody {
  signedDate: string;
  attachmentsToUpload: FileCreateDto[];
}

export interface ESignBulkModel {
  signingBulkModelStatus: SigningStatus;
  signatoryGroups: BulkSignatoryGroup[];
}

export interface BulkSignatoryGroup {
  signatoryStatus: SignatureStatus;
  name: string;
  email: string;
  phone: string;
  filesInfo: DocumentFileInfoShort [];
}

export interface SendBulkEsignModel {
  documentsId: string[];
  signatoryGroups: BulkSignatoryGroup[];
  emailMessage: string;
}

export enum BulkSignColumnEnum {
  Name = 0,
  Email = 1,
  Phone = 2,
  Status = 3,
  FilesMetadata = 4
}
