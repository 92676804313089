import { Component, inject, Input, OnInit } from '@angular/core';
import { CommonModalFormComponent } from "../../../../modals/common-modal-form/common-modal-form.component";
import { FormControl, FormGroup, ReactiveFormsModule } from "@angular/forms";
import { AppCopyDirective } from "../../../../../directives/copy.directive";
import { DefaulValuePipe } from "../../../../../pipes/enumsPipes/defaultValuePipe";
import { LoaderComponent } from "../../../../components/common/loader/loader.component";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { SelectComponent } from "../../../../components/common/select/select.component";
import { CheckboxComponent } from "../../../../components/common/checkbox/checkbox.component";
import { RadioComponent } from "../../../../components/common/radio/radio.component";
import {
  NotifyWhenAllPartiesSignedDocumentOptions
} from "./edit-document-settings-modal.constant";
import { ToastrService } from "ngx-toastr";
import { YesNoControlComponent } from "../../../../components/common/yes-no-control-component/yes-no-control.component";
import { AnnualStatementSettingsData, CompanyStatementSignatory, DocumentSettingsData, EsigningEmailRecipients } from '../../../../../models/documentSettingsData';
import { compare } from 'fast-json-patch';
import { SettingsService } from '../../../../../services/settings.service';
import { combineLatestWith } from 'rxjs';

@Component({
  selector: 'app-edit-document-settings-modal',
  standalone: true,
  imports: [
    CommonModalFormComponent,
    AppCopyDirective,
    DefaulValuePipe,
    LoaderComponent,
    SelectComponent,
    CheckboxComponent,
    RadioComponent,
    ReactiveFormsModule,
    YesNoControlComponent
  ],
  templateUrl: './edit-document-settings-modal.component.html',
  styleUrl: './edit-document-settings-modal.component.scss'
})
export class EditDocumentSettingsModalComponent implements OnInit {
  private activeModal = inject(NgbActiveModal);
  private toastr = inject(ToastrService);
  private settingsService = inject(SettingsService);

  @Input() documentSettings = new DocumentSettingsData();
  @Input() asSettings = new AnnualStatementSettingsData();

  readonly NotifyWhenAllPartiesSignedDocumentOptions = NotifyWhenAllPartiesSignedDocumentOptions;

  isLoading = false;
  form = new FormGroup({
    // documents
    receiveEmailsForDocs: new FormControl<EsigningEmailRecipients | null>(EsigningEmailRecipients.DoNotSend),

    // annual statement
    receiveEmailsForAS: new FormControl<EsigningEmailRecipients | null>(EsigningEmailRecipients.DoNotSend),
    insertDirectorSignatureInCompanyStatement: new FormControl<boolean | null>(false),
  });

  ngOnInit() {
    this.activeModal.update({ modalDialogClass: 'common-modal-form-dialog overflow-y-auto' });

    this.form.patchValue({
      receiveEmailsForDocs: this.documentSettings.esigningEmailRecipients,
      receiveEmailsForAS: this.asSettings.esigningEmailRecipients,
      insertDirectorSignatureInCompanyStatement: this.asSettings.companyStatementSignatory === CompanyStatementSignatory.AddDirectorSignature
    });
  }

  confirm(): void {
    if (this.form.invalid) {
      this.form.markAllAsTouched();
      return;
    }

    const documentSettings = new DocumentSettingsData({
      esigningEmailRecipients: this.form.value.receiveEmailsForDocs!
    });
    const documentSettingsPatch = compare(this.documentSettings, documentSettings);

    const asSettings = new AnnualStatementSettingsData({
      esigningEmailRecipients: this.form.value.receiveEmailsForAS!,
      companyStatementSignatory: this.form.value.insertDirectorSignatureInCompanyStatement ? CompanyStatementSignatory.AddDirectorSignature : CompanyStatementSignatory.DoNotAddSignature
    });
    const asSettingsPatch = compare(this.asSettings, asSettings);

    this.isLoading = true;

    const documentPatch$ = this.settingsService.patchDocumentSettings(documentSettingsPatch);
    const asPatch$ = this.settingsService.patchAnnualStatementSettings(asSettingsPatch);

    documentPatch$.pipe(
      combineLatestWith(asPatch$)
    ).subscribe({
      next: () => {
        this.toastr.success('Data saved successfully', 'Success');
        this.activeModal.close();
      },
      error: () => {
        this.toastr.error('Error happened while saving data', 'Error');
      },
      complete: () => {
        this.isLoading = false;
      }
    });
  }

  close(): void {
    this.activeModal.dismiss();
  }
}
