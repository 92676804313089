import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../environments/environment';
import { map, Observable } from 'rxjs';
import { ParsedName } from "../models/parsedName";
import { IAddressParseResponse } from "../models/text-messages/addressParseResponse";
import { Address } from "../models/address";

@Injectable({
  providedIn: 'root'
})
export class AuxiliaryService {
  constructor(private api: HttpClient) { }

  public getParseAddress(fullAddress: string): Observable<IAddressParseResponse> {
    return this.api.get<IAddressParseResponse>(`${ environment.api_url }/services/address/parse/${ fullAddress }`)
      .pipe(map(({ address, valid }) => ({ valid, address: new Address(address) })));
  }

  public searchAddress(term: string): Observable<string[]> {
    return this.api.get<string[]>(`${environment.api_url}/services/address/autocomplete/${term}`);
  }

  public getCompanyNameByACn(acn: string) {
    return this.api.get<{ entityName: string, entityId: string }>(`${environment.api_url}/services/lookup-acn/${acn}`);
  }
}
