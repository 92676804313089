import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "../../environments/environment";
import { Observable, map } from "rxjs";
import { Company } from "../../models/company";
import { Operation } from "fast-json-patch";
import { SigningBillingContactBody } from "../../models/signingBillingContact";
import { PartnerAndManagerChangeRequest } from "../../models/partnerAndManagerChangeRequest";

@Injectable({
  providedIn: 'root'
})
export class CompanyProfileService {
  constructor(private http: HttpClient) {
  }

  getCompanyProfile(id: string, applyIncompleteDocuments = true, excludedDocumentId = ''): Observable<Company> {
    const options = { params: { excludedDocumentId }};
      return this.http.get<Company>(`${environment.api_url}/companies/${id}?applyIncompleteDocuments=${applyIncompleteDocuments}`, options)
        .pipe(map((data) => new Company(data)));
  }

  patchCompany(id: string, patchOperations: Operation[]) {
    return this.http.patch<Company>(`${environment.api_url}/companies/${id}`, patchOperations, { headers: { 'Content-Type': 'application/json-patch+json' } })
      .pipe(map((data) => new Company(data)));
  }

  generateAnnualStatementF(id: string) {
    return this.http.get(`${environment.api_url}/companies/generate-annual-statement-f/${id}`);
  }

  generatePayslip(companyId: string, description: string, amount: string): Observable<string> {
    return this.http.get<string>(`${environment.api_url}/companies/${companyId}/payslip`, { params: { description, amount } });
  }

  public updateSigningBillingContact(companyId: string, body: SigningBillingContactBody): Observable<unknown> {
    return this.http.post<unknown>(`${environment.api_url}/companies/${companyId}/signing-billing-contact`, body);
  }

  public changePartnerAndAccountManager(body: PartnerAndManagerChangeRequest): Observable<unknown> {
    return this.http.put<unknown>(`${environment.api_url}/companies/partner-manager`, body);
  }
}
