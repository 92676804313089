import { Contact } from "./contact";
import { WarningState } from "./enums/individualEnums";
import { ExternalEntityLink } from "./externalEntity";
import { IndividualData } from "./individualData";
import { Relationship } from "./relationship";
import { Tag } from "./tag";

export class Individual extends IndividualData{
    individualId: string;
    tags: Tag[];
    systemTags: Tag[];
    contacts: Contact[];
    relationships: Relationship[];
    warningStates: WarningState[];
    externalEntityLinks: ExternalEntityLink[] = [];

    constructor(data: Partial<Individual> = {}) {
        super(data);
        this.individualId = data.individualId ?? '';
        this.tags = data.tags ?? [];
        this.systemTags = data.systemTags ?? [];
        this.contacts = data.contacts ? data.contacts.map((c) => new Contact(c)) : [];
        this.relationships = data.relationships ?? [];
        this.warningStates = data.warningStates ?? [];
        this.externalEntityLinks = data.externalEntityLinks ? data.externalEntityLinks.map((link) => new ExternalEntityLink(link)) : [];
    }
}
