import { Component } from '@angular/core';
import { RouterLink } from "@angular/router";
import { ICellRendererParams } from "ag-grid-community";
import { DocumentRecord } from "../../../../../../models/documentRecord";
import { CommonModule } from '@angular/common';
import { ICellRendererAngularComp } from "ag-grid-angular";
import { documentStatusToStep } from "../../../../../helpers/document-status-to-step";

type CellRendererParams = ICellRendererParams<DocumentRecord> & {
  documentCanBeOpened: boolean;
};

@Component({
  selector: 'app-ag-document-name',
  standalone: true,
  imports: [CommonModule, RouterLink],
  templateUrl: './ag-document-name.component.html',
  styleUrl: './ag-document-name.component.scss'
})
export class AgDocumentNameComponent implements ICellRendererAngularComp {
  documentId = '';
  documentCanBeOpened = false;
  queryParams: Record<string, number | null> = {};
  name = '';

  agInit(params: CellRendererParams): void {
    this.updateCell(params);
  }

  refresh(params: CellRendererParams): boolean {
    return this.updateCell(params);
  }

  updateCell(params: CellRendererParams): boolean {
    this.documentId = params.data?.documentId ?? '';
    this.documentCanBeOpened = params.documentCanBeOpened && !!this.documentId;
    this.queryParams = { step: this.documentCanBeOpened ? documentStatusToStep(params.data?.status) : null };
    this.name = params.data?.documentName ?? '';
    return true;
  }
}
