<div class="card" [formGroup]="form">
  <div class="card-header">
    <div class="d-flex align-items-center justify-content-between">
      <div class="d-flex align-items-center">
        <i class="ic-bulk-changes ic-md icon"></i>
        <div class="d-flex flex-column ml-3">
          <div class="title">{{pageTitle}}</div>
          <div class="subtitle">{{pageSubTitle}}</div>
        </div>
      </div>

      <div class="button-container">
        <app-button [text]="'Cancel'" [btnColor]="'white'" (clickBtn)="backToDocuments()"></app-button>
        <app-divider [vertical]="true"></app-divider>
        @if(isReadyToSentStatus) {
          <app-button
            [text]="'Bulk send'"
            [iconClass]="'icon ic-md ic-send'"
            [isDisabled]="invalidData || form.invalid"
            [loading]="sendLoading"
            (clickBtn)="bulkSendForSign()"
          ></app-button>
        }
      </div>
    </div>
  </div>

  <div class="card-body">
    @if(!dataloading && rows){
      <app-list-grid
        class="bulk-sign"
        [rowData]="rows"
        [colDefs]="colDefs"
        [pageSizeList]="pageSizeList"
        [suppressRowClickSelection]="true"
        [suppressCellFocus]="true"
        (gridReady)="onGridReady($event)"
      ></app-list-grid>
    } @else {
      <div class="d-flex justify-content-center align-items-center mh-400">
        <i class="fad fa-spinner-third fa-spin"></i>
      </div>
    }
  </div>

  @if(isReadyToSentStatus) {
    <div class="footer-container">
      <div class="message-container">
        <app-textarea
          formControlName="message"
          label="Message for recipients (optional)"
          [rows]="rowsNumber"
          [customErrors]="{ maxlength: 'You\'ve exceeded the limit of ' + maxMessageLen + ' characters.' }"
        ></app-textarea>
        <app-checkbox formControlName="confirm" [label]="confirmText"></app-checkbox>
      </div>
    </div>
  }
</div>
