import { Component, EventEmitter, inject, Input, Output } from '@angular/core';
import { FormsModule } from "@angular/forms";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { CommonModalFormComponent } from "../../modals/common-modal-form/common-modal-form.component";
import { SelectComponent } from "../../components/common/select/select.component";
import { SelectOption } from '../../../models/selectOptions';
import { ToastrService } from 'ngx-toastr';
import { SuperuserService } from '../../../services/superuser.service';

@Component({
  selector: 'app-officer-contact-edit',
  standalone: true,
  imports: [
    CommonModalFormComponent,
    SelectComponent,
    FormsModule 
],
  templateUrl: './publish-organisation-signal-modal.component.html',
  styleUrl: './publish-organisation-signal-modal.component.scss'
})
export class PublishOrganisationSignalComponent {
  @Output() confirm = new EventEmitter<boolean>();
  @Input({required: true}) organisations!: SelectOption[];

  mailboxes = [{label: 'Primary', value: false}, {label: 'Secondary', value: true}];

  #toastr = inject(ToastrService);
  superuserService = inject(SuperuserService);

  private activeModal = inject(NgbActiveModal);

  @Input() orgId = '';
  @Input() useSecondary = false;

  public publish(): void {
    this.superuserService.publishOrganisationSignal(this.orgId, this.useSecondary).subscribe({
      next: () => {
        this.#toastr.success('Signal published successfully');
        this.confirm.emit(true);
        this.activeModal.close();
      },
      error: (error) => {
        console.error(error);
        this.#toastr.error('Failed to publish signal', 'Error');
      }
    });
  }

  public close(): void {
    this.activeModal.close();
  }
}
