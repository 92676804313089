
export class PartnerAndManagerChangeRequest {
  partnerManagerId: string | null;
  accountManagerId: string | null;
  companyIds: string[];

  constructor(data: Partial<PartnerAndManagerChangeRequest> = {}) {
    this.partnerManagerId = data.partnerManagerId ?? null;
    this.accountManagerId = data.accountManagerId ?? null;
    this.companyIds = data.companyIds ?? [];
  }
}
