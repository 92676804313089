import { Guid } from "../app/helpers/guid.helper";
import { Address } from "./address";
import { Entity } from "./entity";
import { EntityType } from "./enums/entityType";
import { ExternalSourceType } from "./enums/externalEntityEnums";
import { Individual } from "./individual";

export class ExternalEntity{
    id: string;
    source: ExternalSourceType;
    type: EntityType;
    name: string;
    entityNumber: string;
    date?: string;
    address?: Address;
    email?: string;
    phone?: string;
    externalId: string;
    lastSync: Date;
    linkTo?: string;
    lastModified: Date;
    externalEntityLinks: ExternalEntityLink[] = [];

    constructor(data: Partial<ExternalEntity> = {}) {
        this.id = data.id ?? Guid.EmptyGuid;
        this.source = data.source!;
        this.type = data.type!;
        this.name = data.name ?? '';
        this.entityNumber = data.entityNumber ?? '';
        this.date = data.date;
        this.address = data.address;
        this.email = data.email;
        this.phone = data.phone;
        this.externalId = data.externalId ?? '';
        this.lastSync = data.lastSync ?? new Date();
        this.linkTo = data.linkTo;
        this.lastModified = data.lastModified ?? new Date();
    }
}

export class ExternalEntityLink {
    id: string;
    entityId: string | undefined;
    entity: Entity | undefined;
    individualId: string | undefined;
    individual: Individual | undefined;
    linkDate: Date;
    externalEntityId: string;
    externalEntity: ExternalEntity | undefined;

    constructor(data: Partial<ExternalEntityLink> = {}) {
        this.id = data.id ?? Guid.EmptyGuid;
        this.entityId = data.entityId ?? Guid.EmptyGuid;
        this.entity = new Entity(data.entity) ?? undefined;
        this.individualId = data.individualId ?? Guid.EmptyGuid;
        this.individual = new Individual(data.individual) ?? undefined;
        this.linkDate = data.linkDate ?? new Date();
        this.externalEntityId = data.externalEntityId ?? Guid.EmptyGuid;
        this.externalEntity = new ExternalEntity(data.externalEntity) ?? undefined;
    }
}