import { Component, Input } from '@angular/core';
import {
  ReusableFormGroupComponent
} from "../reusable-form-group/reusable-form-group.component";
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from "@angular/forms";
import { InputComponent } from "../../common/input/input.component";
import {
  AddressFormGroupComponent,
  AddressFormGroup
} from "../address-form-group/address-form-group.component";
import { CustomFormValidators } from "../../../../custom-form-validators/custom-form-validators";
import { directorFullNameValidatorMsg } from "../../../../validators/validatorMessages/custom-form-validators-messages";

export interface RelationshipFormGroupControls {
  name: FormControl<string | null>;
  address: AddressFormGroup;
}

export type RelationshipFormGroup = FormGroup<RelationshipFormGroupControls>

@Component({
  selector: 'app-relationship-form-group',
  standalone: true,
  imports: [
    ReactiveFormsModule,
    InputComponent,
    AddressFormGroupComponent
  ],
  templateUrl: './relationship-form-group.component.html',
})
export class RelationshipFormGroupComponent extends ReusableFormGroupComponent<RelationshipFormGroup> {
  @Input() nameControlLabel = '';
  @Input() addressControlLabel = '';
  @Input() hideAddress = false;

  protected readonly directorFullNameValidatorMsg = directorFullNameValidatorMsg;

  static override defineForm(): RelationshipFormGroup {
    return new FormGroup<RelationshipFormGroupControls>({
      name: new FormControl(null, [Validators.required, CustomFormValidators.directorFullNameValidator]),
      address: AddressFormGroupComponent.defineForm()
    });
  }
}
