<div class="card-row">
  <div class="left">
    <img src="assets/images/xpm-logo.svg" alt="img" class="img-max36">
    <div class="text-container">
      <div class="text">XPM</div>
      <div class="sub-text">{{getLastSyncDateMessage()}}</div>
    </div>
  </div>

  <div class="right">
    <div
      ngbDropdown
      [placement]="['bottom-end', 'bottom-start', 'top-start', 'top-end']"
      class="menu-btn right d-inline-flex"
    >
      <button
        ngbDropdownToggle
        class="w-100 btn btn-secondary d-flex align-items-center justify-content-center"
        [disabled]="disableControls"
      >
        <i class="icon ic-md ic-three-dots"></i>
      </button>
        <div ngbDropdownMenu aria-labelledby="company-actions-dd" class="dropdown-menu dropdown-menu-right">
          @for(item of xpmMenu; track $index) {
            <div *ngIf="item?.isVisable()" ngbDropdownItem class="dropdown-item" (click)="item.action()">
              <a class="dropdown-button">
                <i class="{{item.iconClass}}"></i>
                {{item.title}}
              </a>
            </div>
          }
        </div>
    </div>
  </div>
</div>