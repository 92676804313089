<div class="confirm-modal">
    <app-common-modal-form
      [header]="'Mark as Lodged'"
      [icon]="''"
      [confirmText]="'Save'"
      [closeText]="'Cancel'"
      (close)="close()"
      (confirm)="save()"
    >
        <div class="message">Changing the ASIC Status to Lodged will not lodge the form with ASIC. Provide the ASIC document number for the lodgement.</div>

        <div [formGroup]="form">
            <app-input
                label="ASIC Document Number"
                formControlName="asicNumber"
                [customErrors]="{ required: 'Document number is required.', maxLength: 'Your input must be 9 characters', minLength: 'Your input must be 9 characters' }"
            ></app-input>
        </div>
    </app-common-modal-form>
</div>