import { Injectable } from "@angular/core";
import { HttpClient, HttpParams } from "@angular/common/http";
import { environment } from "../environments/environment";
import { ExternalSourceType } from "../models/enums/externalEntityEnums";
import { EntityType } from "../models/enums/entityType";
import { ExternalEntity } from "../models/externalEntity";
import { Observable } from "rxjs";

@Injectable({
    providedIn: 'root'
  })
  export class EntityIdentityService {
    constructor(private api: HttpClient) { }

    public refresh(entityId: string, externalEntityId: string, sourceType: ExternalSourceType) {
        let params = new HttpParams();
        params = params.append('externalEntityId', externalEntityId);
        params = params.append('sourceType', sourceType);
    
        return this.api.get<void>(`${environment.api_url}/identities/${entityId}/refresh`, { params: params });
    }
    public unlinkEntity(entityId: string, externalEntityId: string) {
        let params = new HttpParams();
        params = params.append('externalEntityId', externalEntityId);

        return this.api.post<void>(`${environment.api_url}/identities/${entityId}/unlink`, null, { params: params });
    }

    
    public linkEntity(entityId: string, externalEntityId: string, entityType: EntityType) {
        let params = new HttpParams();
        params = params.append('externalEntityId', externalEntityId);
        params = params.append('entityType', entityType);

        return this.api.post<void>(`${environment.api_url}/identities/${entityId}/link`, null, { params: params });
    }

    public list(sourceType :ExternalSourceType, entityType: EntityType[] | null): Observable<ExternalEntity[]> {
        let params = new HttpParams();
        if (entityType != null && entityType.length > 0) {
            entityType.forEach(et => {
              params = params.append('entityType', et.toString());
            });
          }

        return this.api.get<ExternalEntity[]>(`${environment.api_url}/identities/${sourceType}/list`, { params: params });
    }

    public sync(sourceType: ExternalSourceType) {
        return this.api.get<void>(`${environment.api_url}/identities/${sourceType}/sync`);
    }
}