import { Component, Input } from '@angular/core';
import { ICellRendererParams } from "ag-grid-community";
import { ACNPipe } from "../../../../../../pipes/acnPipe";
import { Company } from "../../../../../../models/company";
import {
  EntityNameNumberRecordComponent
} from '../../../entity-name-number-record/entity-name-number-record.component';
import { RouterLink } from "@angular/router";
import { ICellRendererAngularComp } from "ag-grid-angular";

type ActionEvent = (data: Company | undefined) => void;

type CellRendererParams = ICellRendererParams<Company> & {
  openCompanyProfile: ActionEvent,
};

@Component({
  selector: 'app-ag-company-name-acn',
  standalone: true,
  imports: [
    RouterLink,
    ACNPipe,
    EntityNameNumberRecordComponent,
  ],
  templateUrl: './ag-company-name-acn.component.html',
  styleUrl: './ag-company-name-acn.component.scss'
})
export class AgCompanyNameAcnComponent implements ICellRendererAngularComp {
  @Input() name = '';
  @Input() acn = '';
  @Input() entityId = '';

  agInit(params: CellRendererParams): void {
    this.updateCell(params);
  }

  refresh(params: CellRendererParams): boolean {
    return this.updateCell(params);
  }

  updateCell(params: CellRendererParams): boolean {
    this.entityId = params.data?.entityId ?? '';
    this.name = params.data?.name ?? '';
    this.acn = params.data?.acn ?? '';
    return true;
  }
}
