<div class="confirm-modal">
    <app-common-modal-form
      [header]="'Re-generate Annual Statement'"
      [icon]="''"
      [isCustomFooter]="true"
      (close)="onClose()"
    >
        <div class="message">Please select re-generate option</div>
     
        <div class="custom-footer d-flex gap-12">
            <app-button
            [text]="'Reprint annual statement (RA71) '"
            (clickBtn)="reprint()"
            ></app-button>
        
            <app-button
            [text]="'Re-generate annual statement (480F) '"
            (clickBtn)="regenerate()"
            ></app-button>
        </div>
    </app-common-modal-form>
</div>
  