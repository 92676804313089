import { Component, EventEmitter, OnInit, Output, inject } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { CommonModalFormComponent } from '../common-modal-form/common-modal-form.component';
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { CustomFormValidators } from '../../../custom-form-validators/custom-form-validators';
import { InputComponent } from '../../components/common/input/input.component';

@Component({
  selector: 'app-mark-as-lodged',
  standalone: true,
  imports: [
    CommonModalFormComponent,
    InputComponent,
    FormsModule,
    ReactiveFormsModule
  ],
  templateUrl: './mark-as-lodged.component.html',
  styleUrl: './mark-as-lodged.component.scss'
})
export class MarkAsLodgedComponent implements OnInit {

  @Output() confirm = new EventEmitter<string>();

  activeModal = inject(NgbActiveModal);
  private fb: FormBuilder = inject(FormBuilder);
  form!: FormGroup

  ngOnInit(): void {
    this.initForm();
  }

  private initForm(): void {
    this.form = this.fb.group({
      asicNumber: new FormControl('', [Validators.required, CustomFormValidators.onlyLettersAndNumbersAllowedValidator, CustomFormValidators.minLength(9), CustomFormValidators.maxLength(9)])
  
    });
  }

  close(): void {
    this.activeModal.close();
  }
  save() {
    if (this.form.invalid) {
      this.form.markAllAsTouched();
      return;
    }
    
    this.confirm.emit(this.form.value.asicNumber);
    this.activeModal.close();
  }

}
