import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map, Observable } from 'rxjs';
import { environment } from '../environments/environment';
import { FilterModel, IServerSideGetRowsRequest } from 'ag-grid-enterprise';
import { PageResult } from '../models/pageResult';
import { EdgeTransactionRecord } from '../models/edgeTransactionRecord';
import { EdgeConnectionRecord } from '../models/edgeConnectionRecord';

@Injectable({
  providedIn: 'root'
})
export class SuperuserService {
  constructor(private api: HttpClient) {
  }

  getEdgeTransactions(request: IServerSideGetRowsRequest, search?: string): Observable<PageResult<EdgeTransactionRecord>> {
    //this is a temporary workaround for polymorphic serialization issue in dot net - discriminator should always come first in the json
    const filterModel = request.filterModel as FilterModel;
    if (filterModel && Object.keys(filterModel)?.length > 0) {
      Object.keys(filterModel).forEach(element => {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
        filterModel[element] = Object.assign({ filterType: null }, filterModel[element]);
      });
    }

    let params = new HttpParams();
    if (search) {
      params = params.append('search', search);
    }

    return this.api.post<PageResult<EdgeTransactionRecord>>(`${environment.api_url}/superuser/edge/transactions/grid`, request, { params: params })
      .pipe(map(data => { data.records = data.records.map(d => new EdgeTransactionRecord(d)); return data; }));
  }

  getEdgeConnections(request: IServerSideGetRowsRequest): Observable<PageResult<EdgeConnectionRecord>> {
    //this is a temporary workaround for polymorphic serialization issue in dot net - discriminator should always come first in the json
    const filterModel = request.filterModel as FilterModel;
    if (filterModel && Object.keys(filterModel)?.length > 0) {
      Object.keys(filterModel).forEach(element => {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
        filterModel[element] = Object.assign({ filterType: null }, filterModel[element]);
      });
    }

    return this.api.post<PageResult<EdgeConnectionRecord>>(`${environment.api_url}/superuser/edge/connection-attempts/grid`, request)
      .pipe(map(data => { data.records = data.records.map(d => new EdgeConnectionRecord(d)); return data; }));
  }


  public listOrganisationNames(): Observable<Map<string, string>>{
    return this.api.get<Record<string, string>>(`${environment.api_url}/superuser/organisations`)
      .pipe(map((data: Record<string, string>) => {
        const resultMap = new Map<string, string>();
        Object.keys(data).forEach(key => {
          resultMap.set(key, data[key]);
        });

        return resultMap;
      }));
  }

  getTransactionText(transactionId: string) {
    return this.api.get(`${environment.api_url}/superuser/edge/transaction/${transactionId}/text`, {responseType: 'text'});
  }

  downloadTransaction(transactionId: string) {
    return this.api.get<string>(`${environment.api_url}/superuser/edge/transaction/${transactionId}`);
  }
  
  reprocessTransaction(transactionId: string) {
    return this.api.post(`${environment.api_url}/superuser/edge/transaction/${transactionId}/reprocess`, {});
  }

  publishOrganisationSignal(orgId: string, useSecondary: boolean) {
    return this.api.post(`${environment.api_url}/superuser/edge/signal/${orgId}`, {}, { params: { useSecondary: useSecondary.toString() } });
  }
}
