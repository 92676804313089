import { Guid } from "../app/helpers/guid.helper";
import { Address } from "./address";
import { Alert } from "./alerts/alert";
import { Contact } from "./contact";
import { EntityData } from "./entityData";
import { ExternalEntity, ExternalEntityLink } from "./externalEntity";
import { Relationship } from "./relationship";
import { Tag } from "./tag";
import { User } from "./user";

export class Entity extends EntityData {
  entityId: string;
  organisationId: string | null;
  billingContactId: string | null;
  billingContact: Contact | null;
  signingContactId: string | null;
  signingContact: Contact | null;
  referenceNumber: string;
  familyGroup: string;
  tags: Tag[];
  systemTags: Tag[];
  alerts: Alert[];
  securityholders: Relationship[];
  externalEntities: ExternalEntity[];
  accountManagerId: string | null;
  accountManager: User | null;
  partnerManagerId: string | null;
  partnerManager: User | null;

  constructor(entity: Partial<Entity> = {}) {
    super(entity);
    this.name = entity.name ?? '';
    this.entityId = entity.entityId ?? Guid.EmptyGuid;
    this.registeredAddress = entity.registeredAddress ? new Address(entity.registeredAddress) : new Address();
    this.organisationId = entity.organisationId ?? null;
    this.billingContactId = entity.billingContactId ?? null;
    this.billingContact = entity.billingContact ?? null;
    this.signingContactId = entity.signingContactId ?? null;
    this.signingContact = entity.signingContact ?? null;
    this.referenceNumber = entity.referenceNumber ?? '';
    this.familyGroup = entity.familyGroup ?? '';
    this.tags = entity.tags ?? [];
    this.alerts = entity.alerts ?? [];
    this.systemTags = entity.systemTags ?? [];
    this.securityholders = entity.securityholders ? entity.securityholders.map((holder) => new Relationship(holder)) : [];
    this.externalEntities = entity.externalEntities ?? [];
    this.accountManagerId = entity.accountManagerId ?? null;
    this.accountManager = entity.accountManager ? new User(entity.accountManager) : null;
    this.partnerManagerId = entity.partnerManagerId ?? null;
    this.partnerManager = entity.partnerManager ? new User(entity.partnerManager) : null;
  }

  static override prepareToRequest(entity: Entity): Entity {
    return {
      ...entity,
      ...EntityData.prepareToRequest(entity),
      securityholders: entity.securityholders.map(r => Relationship.prepareToRequest(r)) ?? [],
    }
  }
}
