<div class="confirm-modal">
    <app-common-modal-form
      [header]="'Mask as Signed'"
      [icon]="''"
      [isCustomFooter]="true"
      (close)="onClose()"
    >
        <div class="message">Changing the Signing Status to Signed will not lodge the form with ASIC. Do you want also to lodge the form?</div>

        <div class="signing-contact">
            <app-date-picker
            label="Sign Date"
            [minDate]="minDate"
            [maxDate]="maxDate"
            [(ngModel)]="signingDate"
            (ngModelChange)="onSigningDateChange($event)"
            [ngModelOptions]="{standalone: true}"
            ></app-date-picker>
        </div>
     
        <div class="custom-footer d-flex gap-24">
            <app-button
            [text]="'Yes'"
            [iconClass]="'icon ic-xs ic-send'"
            (clickBtn)="lodge()"
            ></app-button>
        
            <app-button
            [text]="'No'"
            [iconClass]="'icon ic-xs ic-send'"
            (clickBtn)="markAsSigned()"
            ></app-button>
        
            <app-button
            [text]="'Cancel'"
            [btnColor]="'white'"
            (clickBtn)="onClose()"
            ></app-button>
        </div>
    </app-common-modal-form>
</div>
  