<app-common-modal-form
  header="Link to External Entity"
  icon="icon ic-bell"
  [isCustomFooter]="true"
  (close)="close()"
>
  <div class="body-container">
    <app-grid
        [colDefs]="$any(colDefs)"
        [rowData]="externalEntities"
        [maxBlocksInCache]="2"
        [suppressRowClickSelection]="true"
        [pagination]="true"
        [loading]="loadingGrid"
        [paginationPageSize]="5"
        (itemSelect)="selectEntities($event)"
        (gridReady)="onGridReady($event)"
    ></app-grid>
  </div>

  <div class="custom-footer d-flex gap-24">
    @if (!isEntityNumberEqual) {
      <div class="message">
        <span>{{differentEnitityNumbersMessage}}</span> <br>
        <span>To continue, click the "Link Identity" button.</span>
      </div>
    }
    @if (moreThenOneEntitySelected) {
      <div class="message">
        <span>You can link only one entity at a time.</span>
      </div>
    }
    <div class="button-row">
      <app-button
        text="Link Identity"
        iconClass="icon ic-xs ic-link"
        [loading]="loadingLinkIdentity"
        [isDisabled]="isLinkButtonEnabled"
        (clickBtn)="linkIdentity()"
      ></app-button>

      <app-button
        text="Cancel"
        btnColor="white"
        (clickBtn)="close()"
      ></app-button>
    </div>
  </div>
</app-common-modal-form>
