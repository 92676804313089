<div class="card">
  <div class="card-header">
    <div class="d-flex align-items-center justify-content-between">
      <div class="d-flex align-items-center">
        <i class="ic-trusts ic-md icon"></i>
        <h2 class="h2-heading mb-0 ml-3">{{pageTitle}}</h2>
      </div>

      <div class="button-container" *appHasRole="['Admin', 'Manager', 'Preparer']">
        <app-button
          style="width: 138px"
          [text]="'Add Trust'"
          [iconClass]="'icon ic-xs ic-plus'"
          [isDisabled]="modalOpened()"
          (clickBtn)="addTrust()"
        ></app-button>

        @if (checkedTrust.length) {
          <div ngbDropdown [placement]="['bottom-end', 'bottom-start', 'top-start', 'top-end']" class="dropdown">
            <button
              ngbDropdownToggle
              id="bulk-actions-dd"
              style="width: 128px"
              class="btn btn-primary d-flex align-items-center gap-2"
              [disabled]="modalOpened()"
            >
              <span>Bulk actions</span>
              <i class="icon ic-xs ic-select-icon"></i>
            </button>
            <div ngbDropdownMenu aria-labelledby="bulk-actions-dd" class="dropdown-menu dropdown-menu-right">
              <div class="dropdown-item" (click)="exportSelectedTrustsToXls()">
                <a class="dropdown-button">
                  <span>Export to xlsx</span>
                </a>
              </div>
              <div class="dropdown-item" (click)="exportSelectedTrustsToCsv()">
                <a class="dropdown-button">
                  <span>Export to csv</span>
                </a>
              </div>
              <div class="dropdown-item" (click)="manageTags()">
                <a class="dropdown-button">
                  <span>Manage Tags</span>
                </a>
              </div>
            </div>
          </div>
        } @else {
          <app-button
            style="width: 180px"
            [text]="'Export Trust List'"
            [iconClass]="'icon ic-xs ic-download-file'"
            [isDisabled]="modalOpened()"
            (clickBtn)="exportTrustList(false, ExportTypeEnum.EXCEL)"
          ></app-button>
        }
      </div>
    </div>
  </div>
  <app-grid
    storageKey="trusts"
    [defaultExcelExportParams]="excelExportParams"
    [defaultCsvExportParams]="csvExportParams"
    [pinFirstColumnName]="'name'"
    [excelStyles]="excelStyles"
    [rowData]="trustList"
    [colDefs]="$any(colDefs())"
    [pageTitle]="pageTitle"
    [getContextMenuItems]="$any(getContextMenuItems)"
    [loading]="loading"
    (itemSelect)="selectTrust($event)"
    (gridReady)="onGridReady($event)"
  ></app-grid>
</div>

<app-notes
  [entityType]="EntityType.Trust"
  [entity]="entityForNotes"
  [opened]="notesVisible"
  (close)="notesVisible = false"
></app-notes>

<app-alerts-list
  [alerts]="alerts"
  [entityType]="EntityType.Trust"
  [entity]="entityForAlerts"
  [opened]="alertsVisible"
  (close)="alertsVisible = false"
></app-alerts-list>
