import { Component, DestroyRef, EventEmitter, inject, Input, Output } from '@angular/core';
import {
    DinMaskedInputComponent
} from "../../components/common/masked-input/specific-masked-inputs/din-masked-input/din-masked-input.component";
import { FormBuilder, FormGroup, FormsModule, ReactiveFormsModule } from "@angular/forms";
import { CommonModalFormComponent } from "../common-modal-form/common-modal-form.component";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { InputComponent } from "../../components/common/input/input.component";
import { InputPhoneNumberComponent } from "../../components/common/input-phone-number/input-phone-number.component";
import { CustomFormValidators } from "../../../custom-form-validators/custom-form-validators";
import { emailValidatorMsg } from "../../../validators/validatorMessages/custom-form-validators-messages";
import { Contact } from "../../../models/contact";
import { Relationship } from "../../../models/relationship";
import { Company } from "../../../models/company";
import { observe, Observer } from "fast-json-patch";
import { IndividualData } from "../../../models/individualData";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";
import { OfficerRelationUpdateRequest, RelationshipsService } from '../../../services/relationships.service';
import { catchError, EMPTY } from 'rxjs';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-officer-contact-edit',
  standalone: true,
  imports: [
    DinMaskedInputComponent,
    FormsModule,
    ReactiveFormsModule,
    CommonModalFormComponent,
    InputComponent,
    InputPhoneNumberComponent
  ],
  templateUrl: './officer-contact-edit.component.html',
  styleUrl: './officer-contact-edit.component.scss'
})
export class OfficerContactEditComponent {
  @Input() company: Company | undefined;
  @Input() officer: Relationship | undefined;
  @Input() previousOfficeholder: Relationship | undefined;
  @Input() din = '';
  @Output() confirm = new EventEmitter<boolean>();

  private relationshipsService = inject(RelationshipsService);
  private activeModal = inject(NgbActiveModal);
  private fb: FormBuilder = inject(FormBuilder);
  private destroyRef = inject(DestroyRef);
  private toastr = inject(ToastrService);

  protected readonly CustomFormValidators = CustomFormValidators;
  protected readonly emailValidatorMsg = emailValidatorMsg;

  form!: FormGroup
  observer!: Observer<Company>;

  ngOnInit(): void {
    this.initForm();

    if(this.company) {
      this.observer = observe(this.company);
    }
  }

  private initForm(): void {
    this.form = this.fb.group({
      din: [this.din],
    });
  }

  public save(): void {
    const { din } = this.form.getRawValue();
    const request = new OfficerRelationUpdateRequest(
      this.officer?.relationshipId!,
      din
    );

    this.relationshipsService.updateOfficer(request).pipe(
      takeUntilDestroyed(this.destroyRef)
    )
    .pipe(
      catchError((error) => {
        console.error(error);
        this.toastr.error('Error happened while applying updates', 'Error');
        return EMPTY;
      }),
      takeUntilDestroyed(this.destroyRef)
    ).subscribe(() => {
      this.updateOfficerContact(din);
      this.updatePreviousOfficeholderContact(din);
      this.close();
    });
  }

  private updateOfficerContact(din: string): void {
    this.officer!.individualDataOverride =
      this.officer!.individualDataOverride ?? new IndividualData();
    this.officer!.individualDataOverride.din = din ?? '';
  }

  private updatePreviousOfficeholderContact(din: string): void {
    this.previousOfficeholder!.individualDataOverride =
      this.previousOfficeholder!.individualDataOverride ?? new IndividualData();
    this.previousOfficeholder!.individualDataOverride.din = din ?? '';
  }

  public close(): void {
    this.activeModal.close();
  }

  get checkAtLeastOneFieldFilled(): boolean {
    const { din, email, phone } = this.form.value;
    return !!din?.trim() || !!email?.trim() || !!phone?.trim();
  }
}
