import { Component, DestroyRef, EventEmitter, Input, OnInit, Output, inject } from '@angular/core';
import { ButtonComponent } from '../../components/common/button/button.component';
import { EntityIdentityService } from '../../../services/entity.identity.service';
import { ToastrService } from 'ngx-toastr';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { EntityType } from '../../../models/enums/entityType';
import { ExternalSourceType } from '../../../models/enums/externalEntityEnums';
import { ExternalEntity } from '../../../models/externalEntity';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { CommonModalFormComponent } from "../common-modal-form/common-modal-form.component";
import { GridComponent } from "../../components/common/grid/components/grid/grid.component";
import { ColDef, GridApi } from 'ag-grid-community';
import { EMPTY, catchError, finalize, tap } from 'rxjs';
import { StringHelpService } from '../../helpers/string.help.service';
import { convertEnumToFilterOption } from '../../helpers/enum-to-filter-option.helper';

@Component({
  selector: 'app-link-to-external-entity-modal',
  standalone: true,
  imports: [
    ButtonComponent,
    CommonModalFormComponent,
    GridComponent
],
  templateUrl: './link-to-external-entity-modal.component.html',
  styleUrl: './link-to-external-entity-modal.component.scss'
})
export class LinkToExternalEntityModalComponent implements OnInit {
  @Input() entityType!: EntityType;
  @Input() entityId!: string;
  @Input() entityNumber!: string;
  @Input() sourceType!: ExternalSourceType;

  @Output() confirm = new EventEmitter<ExternalEntity>();

  private destroyRef = inject(DestroyRef);
  private entityIdentityService = inject(EntityIdentityService);
  private toastr = inject(ToastrService);
  private activeModal = inject(NgbActiveModal);
  private stringHelpService = inject(StringHelpService);
  protected modalService = inject(NgbModal);

  colDefs: ColDef[] = [];
  gridApi!: GridApi;
  externalEntities: ExternalEntity[] = [];
  checkedExternalEntities: ExternalEntity[] = [];
  loadingGrid = false;
  loadingLinkIdentity = false;
  isEntityNumberEqual = true;
  moreThenOneEntitySelected = false;

  ngOnInit(): void {
    this.getExternalEntityList();
    this.setColDef();
  }

  get isLinkButtonEnabled() {
    return this.checkedExternalEntities.length != 1
  }

  get differentEnitityNumbersMessage() {
    let entityNumberName = this.entityType == EntityType.Company ? 'ACN' : 'ABN';
    let entityName = this.entityType == EntityType.Company ? 'companies' : 'trusts';
    let message = `**Warning:** A discrepancy has been found in the ${entityNumberName}s of the ${entityName} you want to link. Please check that the ${entityNumberName}s match, as linking mismatched ${entityName} may cause errors.`; 
    return message;
  }

  selectEntities(externalEntities: ExternalEntity[]): void {
    this.checkedExternalEntities = externalEntities;

    this.moreThenOneEntitySelected = this.checkedExternalEntities.length > 1;

    this.isEntityNumberEqual = this.checkedExternalEntities.length == 1 
        ? this.stringHelpService.compareStringWithoutSpaces(this.checkedExternalEntities[0].entityNumber, this.entityNumber)
        : true;

  }

  onGridReady(gridApi: GridApi): void {
    this.gridApi = gridApi;
  }

  private setColDef() {
    this.colDefs = [
      {
        headerName: 'Entity Name',
        field: 'name',
        sort: 'desc',
        flex: 1,
        checkboxSelection: true,
        headerCheckboxSelection: true
      },
      {
        headerName: 'Entity Number',
        field: 'entityNumber',
        flex: 0.5,
      },
      {
        headerName: 'Entity Type',
        field: 'type',
        flex: 0.5,
        valueFormatter: (params) => {
          return EntityType[params.value];
        },
        filter: 'agSetColumnFilter',
        filterParams: {
          filterOptions: convertEnumToFilterOption(EntityType),
          valueFormatter: (params: { value: number }) => {
            return params.value !== undefined && params.value !== null ? EntityType[params.value] : '';
          }
        }
      },
      {
        headerName: 'Entity adress',
        field: 'address.normalizedFullAddress',
        flex: 1,
      },
    ]
  }


  private getExternalEntityList() {
    this.loadingGrid = true;
    this.entityIdentityService.list(this.sourceType, [EntityType.Company, EntityType.Trust]).pipe(
      takeUntilDestroyed(this.destroyRef)
    )
    .subscribe({
      next: result => {
        this.externalEntities = result;
      },
      error: () => {
        this.toastr.error('Failed to get external entity list');
      },
      complete: () => {
        this.loadingGrid = false;
      }
    });
  }


  protected linkIdentity() {
    if(this.checkedExternalEntities.length > 1) {
      this.toastr.warning('Please select only one external entity.');
      return;
    }
    
    this.loadingLinkIdentity = true;

    this.entityIdentityService.linkEntity(
      this.entityId, 
      this.checkedExternalEntities[0].externalId, 
      this.entityType
    ).pipe(
      tap(() => {
        this.toastr.success('Entity successfully linked.', 'Success');
        this.confirm.emit(this.checkedExternalEntities[0]);
        this.activeModal.close();
      }),
      catchError(() => {
        this.toastr.error('Failed to link entity');
        return EMPTY;
      }),
      finalize(() => {
        this.loadingLinkIdentity = false;
      })
    ).subscribe();
  }

  close(): void {
    this.activeModal.close();
  }

}
