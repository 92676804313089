import { NgClass, DatePipe } from '@angular/common';
import { Component } from '@angular/core';
import { ICellRendererParams } from 'ag-grid-community';
import { ApproverStatus } from '../../../../../../models/enums/approverStatus';
import { SignatureStatus } from '../../../../../../models/enums/signatureStatus';
import { BulkSignatoryGroup } from '../../../../../../models/signDocuments';
import { SplitByCapitalLetterPipePipe } from '../../../../../../pipes/split-by-capital-letter-pipe.pipe';

@Component({
  selector: 'app-ag-bulk-signatory-status',
  standalone: true,
  imports: [
    SplitByCapitalLetterPipePipe,
    NgClass,
    DatePipe],
  templateUrl: './ag-bulk-signatory-status.component.html',
  styleUrl: './ag-bulk-signatory-status.component.scss'
})
export class AgBulkSignatoryStatusComponent {
  protected status: SignatureStatus | null | undefined;
  protected signedOn = '';
  protected readonly SignatureStatus = SignatureStatus;

  agInit(params: ICellRendererParams<BulkSignatoryGroup, SignatureStatus>): void {
    //this.status = params.value;
    this.status = params.data?.signatoryStatus;

    //this.signedOn = params.data?.signedOn ?? '';
  }

  refresh(params: ICellRendererParams<BulkSignatoryGroup, SignatureStatus>): boolean {
    this.status = params.value;
    //this.signedOn = params.data?.signedOn ?? ''; // TODO add
    return true;
  }

}
