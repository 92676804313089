import { Component, Input } from '@angular/core';
import {
  AbstractControl,
  FormArray,
  FormControl,
  FormGroup,
  ReactiveFormsModule,
  ValidationErrors
} from "@angular/forms";
import { CorporateHolderModel, SecurityRegistryRecord } from "../../../../../../models/securityRegistryRecord";
import { AuthSignatoryComponent } from "../../../../../components/common/auth-signatory/auth-signatory.component";
import { CustomFormValidators } from "../../../../../../custom-form-validators/custom-form-validators";
import {
  ReusableFormGroupComponent
} from "../../../../../components/reusable-form-groups/reusable-form-group/reusable-form-group.component";
import { IndividualData } from '../../../../../../models/individualData';

export interface IAuthorisedSignatoriesFormGroupControls {
  corporateHoldersAuthorisedSignatories: FormArray<FormControl<IndividualData[]>>;
}
export type IAuthorisedSignatoriesFormGroup = FormGroup<IAuthorisedSignatoriesFormGroupControls>;


@Component({
  selector: 'app-authorised-signatories-step',
  standalone: true,
  imports: [
    ReactiveFormsModule,
    AuthSignatoryComponent
  ],
  templateUrl: './authorised-signatories-step.component.html',
  styleUrl: './authorised-signatories-step.component.scss'
})
export class AuthorisedSignatoriesStepComponent extends ReusableFormGroupComponent<FormGroup> {
  @Input() title = '';
  @Input() autoSave: boolean = true;

  @Input({
    alias: 'securityRegistryRecords',
    required: true
  }) set securityRegistryRecordSetter(value: SecurityRegistryRecord[]) {
    this.securityRegistryRecords = value.filter((record) => 
      record.holders.some((holder) => holder.$type === CorporateHolderModel.$type)
    );
    
    this.corporateHolders = this.securityRegistryRecords
      .flatMap((record) => record.holders)
      .filter((holder) => holder.$type === CorporateHolderModel.$type) as CorporateHolderModel[];

    this.setupAuthorisedSignatoriesForm(this.corporateHolders);
  }

  readonly authSignatoriesCustomErrors = {
    startsWithDigit: 'The name must start with a letter.',
    oneLetterPartName: 'Initials aren\'t allowed.',
    atLeastOneSign: 'The authorised signatories must contain at least one sign.',
    restrictEmptySigns: 'The authorised signatories must not contain empty signs.',
  };

  securityRegistryRecords: SecurityRegistryRecord[] = [];
  corporateHolders: CorporateHolderModel[] = [];
  override form: IAuthorisedSignatoriesFormGroup = AuthorisedSignatoriesStepComponent.defineForm();

  updateValidation(): void {
    this.form.updateValueAndValidity();
    this.form.controls.corporateHoldersAuthorisedSignatories.updateValueAndValidity();
  }

  private setupAuthorisedSignatoriesForm(corporateHolders: CorporateHolderModel[]): void {
    const signsControls = corporateHolders.map((holder) => this.createAuthorisedSignatoryForm(holder.details.authorisedSignatories ?? []));
    this.form.setControl('corporateHoldersAuthorisedSignatories', new FormArray<FormControl<IndividualData[]>>(signsControls));
  }

  private createAuthorisedSignatoryForm(value: IndividualData[]): FormControl<IndividualData[]> {
    return this.setupAuthorisedSignatoryFormValidator(new FormControl<IndividualData[]>(value, { nonNullable: true }));
  }

  private setupAuthorisedSignatoryFormValidator(control: FormControl<IndividualData[]>): FormControl<IndividualData[]> {
    const atLeastOneSignValidator = (control: AbstractControl): ValidationErrors | null => {
      const value = control.value as IndividualData[] ?? [];
      return value.length > 0 ? null : { atLeastOneSign: true };
    };

    const restrictEmptySigns = (control: AbstractControl): ValidationErrors | null => {
      const value = control.value as IndividualData[] ?? [];
      const noEmptySigns = value.every((sign) => sign.firstName && sign.lastName);
      return noEmptySigns ? null : { restrictEmptySigns: true };
    };

    control.setValidators([
      CustomFormValidators.directorNameValidator,
      CustomFormValidators.directorOneLetterNameValidator,
      atLeastOneSignValidator,
      restrictEmptySigns,
    ]);

    return control;
  }

  get control(): FormGroup | null {
    return this.form.get('corporateHoldersAuthorisedSignatories') as FormGroup ?? null;
  }

  static override defineForm(): IAuthorisedSignatoriesFormGroup {
    return new FormGroup(AuthorisedSignatoriesStepComponent.defineFormControls());
  }

  static override defineFormControls(): IAuthorisedSignatoriesFormGroupControls {
    return { corporateHoldersAuthorisedSignatories: new FormArray<FormControl<IndividualData[]>>([]) };
  }
}
