import { Injectable } from '@angular/core';
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";
import { environment } from "../environments/environment";
import { ESignBody, ESignBulkModel, PaperSignBody, SendBulkEsignModel } from "../models/signDocuments";
import { DocumentSigning } from "../models/documentEnteties/document-signing";

@Injectable({
  providedIn: 'root'
})
export class ESignService {

  constructor(private api: HttpClient) { }

  public sendToESign(documentId: string, signBody: ESignBody): Observable<DocumentSigning> {
    return this.api.post<DocumentSigning>(`${ environment.api_url }/e-sign/${documentId}/send`, signBody);
  }

  public sendToEsignBulk(esignBody: SendBulkEsignModel): Observable<ESignBulkModel> {
    return this.api.post<ESignBulkModel>(`${ environment.api_url }/e-sign/bulk/send`, esignBody);
  }

  public getEsignBulkModel(documentsId: string[]): Observable<ESignBulkModel> {
    return this.api.post<ESignBulkModel>(`${ environment.api_url }/e-sign/bulk/model`, documentsId);
  }

  public resendESign(documentId: string, signatureId: string): Observable<DocumentSigning> {
    if(signatureId) {
      return this.api.post<DocumentSigning>(`${ environment.api_url }/e-sign/${documentId}/email/resend?signatureId=${signatureId}`, {});
    } else {
      return this.api.post<DocumentSigning>(`${ environment.api_url }/e-sign/${documentId}/email/resend`, {});
    }
  }

  public voidEnvelope(documentId: string): Observable<unknown> {
    return this.api.put<unknown>(`${environment.api_url}/e-sign/${documentId}/void`, {});
  }

  public voidPaperSign(documentId: string): Observable<unknown> {
    return this.api.put<unknown>(`${environment.api_url}/e-sign/${documentId}/paper/void`, {});
  }

  public markAsSign(documentsId: string[], signedDate: string): Observable<void> {
    return this.api.patch<void>(`${ environment.api_url }/e-sign/mark-as-signed?signedDate=${signedDate}`, documentsId);
  }

  public paperSign(documentId: string, paperSignBody: PaperSignBody): Observable<DocumentSigning> {
    return this.api.post<DocumentSigning>(`${ environment.api_url }/e-sign/${documentId}/paper`, paperSignBody);
  }
}
