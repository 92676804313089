<div class="generate-documents-form-container">
  <div class="title-container">
    <h3>Authorization</h3>
  </div>

  @if(annual) {
    <div class="form-body-container">
      <div class="document-type">
        <app-radio
          [label]="'Choose the type of document to generate'"
          [options]="documentTypeOptions"
          [(ngModel)]="this.formModel.documentationType"
          (ngModelChange)="onDocumentationTypeChange()"
          [ngModelOptions]="{standalone: true}"
        ></app-radio>
      </div>

      @if(this.formModel.documentationType === DocumentationType.Minutes) {
        @if (formModel.chairperson) {
          <div class="chair">
            <app-select
              [label]="'Chairperson'"
              [placeholder]="'Select Chairperson'"
              [valuePrimitive]="true"
              [options]="chairOptions"
              [(ngModel)]="formModel.chairperson.relationshipId"
              [ngModelOptions]="{standalone: true}"
              (ngModelChange)="onChairPersonSelect($event)"
            ></app-select>
          </div>
        }

        <div class="meeting-type">
          <app-radio
            [label]="'Type of meeting'"
            [options]="meetingTypeOptions"
            [(ngModel)]="formModel.meetingAddressType"
            (ngModelChange)="onDocumentationTypeChange()"
            [ngModelOptions]="{standalone: true}"
          ></app-radio>
        </div>

        @if(formModel.meetingAddressType === MeetingLocationTypeEnum.Online) {
          <div class="meeting-address">
            <app-select
              [label]="'Communication Method'"
              [placeholder]="'Select Communication Method'"
              [valuePrimitive]="true"
              [options]="meetingOnlineTypeOptions"
              [(ngModel)]="formModel.meetingOnlineAddress"
              (ngModelChange)="setDisableHeaderBtn()"
              [ngModelOptions]="{standalone: true}"
            ></app-select>
          </div>
        }

        <div class="meeting-date">
          <app-date-picker
            [label]="'Date Meeting was Held'"
            [minDate]="minDateOfMeeting"
            [maxDate]="maxDateOfMeeting"
            [(ngModel)]="formModel.meetingDate"
            (ngModelChange)="onMeetingDateChange($event)"
            [ngModelOptions]="{standalone: true}"
          ></app-date-picker>
        </div>

        @if (formModel.meetingAddressType !== undefined && formModel.meetingAddressType !== MeetingLocationTypeEnum.Online && formModel.meetingAddressType !== MeetingLocationTypeEnum.Phone) {
          <div class="address" [formGroup]="form">
            <app-address-control
              [label]="MeetingLocationTypeEnum[formModel.meetingAddressType] + ' Address'"
              [expandedAddressForm]="expandedAddressForm"
              [addressStatus]="addressStatus"
              [halfWidth]="false"
              (onDataStatusChange)="onDataStatusChange($event)"
            ></app-address-control>
          </div>
        }

        <div class="attendees">
          <app-list-grid
            [rowData]="attendeesList"
            [colDefs]="$any(colDefs)"
            (gridReady)="onGridReady($event)"
            [suppressRowClickSelection]="true"
            [suppressCellFocus]="true"
            [pagination]="false"
            [paginationPageSizeSelector]="false"
          ></app-list-grid>
        </div>
      }
      <div class="partner" [formGroup]="form">
        <app-select
          formControlName="partnerManager"
          [label]="'Partner Name'"
          [placeholder]="'Select Partner Name'"
          [valuePrimitive]="true"
          [options]="partnerManagerOptions"
        ></app-select>
      </div>
    </div>
  }
</div>
