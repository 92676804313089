<div class="radios-container">
  @if (label) {
    <div class="header">
      <div class="label">{{ label }}</div>
    </div>
  }

  <ul class="list-unstyled d-flex flex-column gap-6" [class.no-label]="!label">
    @for (option of controlOptions; track option.value; let index = $index) {
      <li>
        <app-checkbox
          [label]="option.label"
          [value]="option.checked"
          [disabled]="disabled"
          (change)="onChecked($event, index)"
        />
      </li>
    }
  </ul>

  @if (helperText) {
    <div class="helper">{{ helperText }}</div>
  }

  @if (displayValidationError) {
    <app-validation-error [customErrors]="customErrors"></app-validation-error>
  }
</div>
