import { Component } from '@angular/core';
import { ICellRendererParams } from "ag-grid-community";
import { CurrencyPipe, NgClass } from "@angular/common";
import { ReplaceStrPipe } from "../../../../../../pipes/replace-str.pipe";
import { ICellRendererAngularComp } from 'ag-grid-angular';

@Component({
  selector: 'app-ag-company-debt',
  standalone: true,
  imports: [
    CurrencyPipe,
    NgClass,
    ReplaceStrPipe
  ],
  templateUrl: './ag-company-debt.component.html',
  styleUrl: './ag-company-debt.component.scss'
})
export class AgCompanyDebtComponent implements ICellRendererAngularComp {
  amountOwing: number | null | undefined;

  agInit(params: ICellRendererParams<number,number>): void {
    this.amountOwing = params.value;
  }

  refresh(params: ICellRendererParams<number,number>): boolean {
    this.amountOwing = params.value;
    return true;
  }
}
