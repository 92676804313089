import { Component, EventEmitter, Input, OnInit, Output, inject } from '@angular/core';
import { CommonModalFormComponent } from '../common-modal-form/common-modal-form.component';
import { DatePickerComponent } from '../../components/common/date-picker/date-picker.component';
import { formatDate } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { NgbActiveModal, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { DatepickerHelper } from '../../../custom-form-validators/date-picker-form-validators';
import { ButtonComponent } from '../../components/common/button/button.component';

@Component({
  selector: 'app-mark-as-signed',
  standalone: true,
  imports: [
    CommonModalFormComponent,
    DatePickerComponent,
    ButtonComponent,
    FormsModule
  ],
  templateUrl: './mark-as-signed.component.html',
  styleUrl: './mark-as-signed.component.scss'
})
export class MarkAsSignedComponent implements OnInit {
  @Output() confirm = new EventEmitter<{ signingDate: string; lodge: boolean }>();
  @Input() earliestChangeDate!: Date;

  activeModal = inject(NgbActiveModal);

  signingDate = new Date();
  formattedSigningDate = '';
  minDate!: NgbDateStruct;
  maxDate!: NgbDateStruct;

  ngOnInit(): void {
    this.minDate = DatepickerHelper.getStructFromDateOrNull(this.earliestChangeDate)!;
    this.maxDate = DatepickerHelper.getTodayStruct();
  }

  lodge() {
    const result = {
      signingDate: this.formattedSigningDate,
      lodge: true, 
    };
    this.confirm.emit(result);
    this.activeModal.close();
  }

  markAsSigned() {
    const result = {
      signingDate: this.formattedSigningDate,
      lodge: false, 
    };
    this.confirm.emit(result);
    this.activeModal.close();
  }

  onClose(): void {
    this.activeModal.close();
  }

  onSigningDateChange(signingDate: Date | NgbDateStruct): void {
    if(signingDate) {
      const date = signingDate instanceof Date ? signingDate : DatepickerHelper.getDateFromStruct(signingDate);
      this.formattedSigningDate = formatDate(date, 'YYYY-MM-dd', 'en-US');
    }
  }
}