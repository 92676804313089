import { Injectable } from "@angular/core";
import { environment } from "../environments/environment";
import { HttpClient } from "@angular/common/http";

export class OfficerRelationUpdateRequest {
    relationshipId: string;
    din?: string;

    constructor(relationshipId: string, din?: string) {
        this.relationshipId = relationshipId;
        this.din = din;
    }
}

@Injectable({
providedIn: 'root'
})
export class RelationshipsService {
    
    constructor(private api: HttpClient) { }

    public updateOfficer(body: OfficerRelationUpdateRequest) {
        return this.api.put(`${environment.api_url}/relationships/officer`, body);
    }
}