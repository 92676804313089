<app-common-modal-wrapper
  closeBtnText="Cancel"
  saveBtnText="Update"
  header="Partner and Account Manager"
  icon="ic-edit"
  [formGroup]="form"
  [isLoading]="isLoading"
  (submitModal)="save()"
  (close)="cancel()"
>

  <div class="inputs-wrapper">
    <app-select
      label="Partner"
      formControlName="partnerManagerId"
      [options]="usersOption"
      [valuePrimitive]="true"
      class="first-control"
    ></app-select>

    <app-select
      label="Account Manager"
      formControlName="accountManagerId"
      [options]="usersOption"
      [valuePrimitive]="true"
    ></app-select>
  </div>

</app-common-modal-wrapper>
