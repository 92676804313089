<div class="annual-status-wrapper">
  @if(type === SignGroup.Signatories) {
    @if(!approverAdded || approverApproved) {
      <div class="status" [ngClass]="SignatureStatus[status!]">
        {{ SignatureStatus[status!] | splitByCapitalLetterPipe }}
        {{ status === SignatureStatus.Signed ? (signedOn ? (' - ' + (signedOn | date:'dd MMM yyyy')) : '') : '' }}
      </div>
    }
  } @else if (type === SignGroup.Approver) {
    <div class="status" [ngClass]="ApproverStatus[status!]">
      {{ ApproverStatus[status!] | splitByCapitalLetterPipe }}
      {{ status === ApproverStatus.Approved ? (approvedOn ? (' - ' + (approvedOn | date:'dd MMM yyyy')) : '') : '' }}
    </div>
  }
</div>
