import { Component, inject, Input, OnInit, signal, WritableSignal } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { CustomFormValidators } from '../../../../../custom-form-validators/custom-form-validators';
import { DatepickerHelper } from '../../../../../custom-form-validators/date-picker-form-validators';
import { setControlDisabled } from '../../../../../functions/set-control-disabled';
import { Document } from '../../../../../models/document';
import { IndividualData } from '../../../../../models/individualData';
import { Relationship, ShareholderRelationshipDetails } from '../../../../../models/relationship';
import { CorporateHolderModel, SecurityRegistryRecord, } from '../../../../../models/securityRegistryRecord';
import { SelectOption } from '../../../../../models/selectOptions';
import { IStep } from '../../../../../models/step';
import { SecurityService } from '../../../../../services/security.service';
import { PaymentMethod, paymentMethodOptions } from '../../../company-name-change/CompanyChangeName';
import { BaseStepperFormComponent } from '../../../stepper-form/base-stepper-component/base-stepper-form.component';
import { DividendStatementStepsEnum } from '../form-divident-statement/CompanyDividendStatement';
import {
  CompanyChangeDeregister,
  voluntaryDeregistrationDeclarations,
  VoluntaryDeregistrationStepsEnum
} from './form6010.model';
import {EntityChangeData} from "../../../../../models/entityChangeData";
import { StepperFormComponent } from "../../../stepper-form/stepper-form.component";
import {
  StepperFormDescriptionComponent
} from "../../../stepper-form/stepper-form-description/stepper-form-description.component";
import { CheckboxComponent } from "../../../../components/common/checkbox/checkbox.component";
import { RadioComponent } from "../../../../components/common/radio/radio.component";
import { SelectComponent } from "../../../../components/common/select/select.component";
import {
  BeneficialOwnersGroupComponent
} from "../form-divident-statement/nbo-sings-group/beneficial-owners-group.component";
import {
  IndividualDataFormGroupComponent, IndividualDataFormGroupControls
} from "../../../../components/reusable-form-groups/individual-data-form-group/individual-data-form-group.component";
import { parseFullName } from "../../../../../functions/parse-fullname";
import { CompanyNameChangeStepsEnum } from "../../../company-name-change/company-name-change.component";
import {
  AuthorisedSignatoriesStepComponent, IAuthorisedSignatoriesFormGroup
} from "../components/authorised-signatories-step/authorised-signatories-step.component";
import { ParsedName } from "../../../../../models/parsedName";

export interface DetailsOfTheApplicantControls {
  isApplicantDirector: FormControl<boolean | null>,
  paymentMethod: FormControl<PaymentMethod>,
  applicantIfNominee: FormGroup<Partial<IndividualDataFormGroupControls>>,

  // Director Selected
  applicantIfDirector: FormControl<string | null>,
}

export const isApplicantDirectorOptions: SelectOption[] = [
  { label: 'Director', value: true },
  { label: 'Company', value: false }
];

export const VoluntaryDeregistrationSteps: IStep<VoluntaryDeregistrationStepsEnum>[] = [
  { step: VoluntaryDeregistrationStepsEnum.FormDescription, label: 'Form Description' },
  { step: VoluntaryDeregistrationStepsEnum.ApplicantDeclaration, label: 'Applicant Declaration' },
  { step: VoluntaryDeregistrationStepsEnum.DetailsOfTheApplicant, label: 'Details of the Applicant' },
  { step: VoluntaryDeregistrationStepsEnum.AuthorisedSignatories, label: 'Authorised Signatories' },
  { step: VoluntaryDeregistrationStepsEnum.BeneficialOwnersDetails, label: 'Beneficial Owners Details' }
];

@Component({
  selector: 'app-form6010-voluntary-deregistration',
  standalone: true,
  templateUrl: './form6010-voluntary-deregistration.component.html',
  imports: [
    StepperFormComponent,
    ReactiveFormsModule,
    StepperFormDescriptionComponent,
    CheckboxComponent,
    RadioComponent,
    SelectComponent,
    BeneficialOwnersGroupComponent,
    IndividualDataFormGroupComponent,
    AuthorisedSignatoriesStepComponent
  ],
  styleUrls: [
    './form6010-voluntary-deregistration.component.scss',
    '../../../stepper-form/base-stepper-component/base-stepper-form.component.scss'
  ]
})
export class Form6010VoluntaryDeregistrationComponent extends BaseStepperFormComponent<VoluntaryDeregistrationStepsEnum, CompanyChangeDeregister> implements OnInit {
  securityService = inject(SecurityService);

  @Input() officers: Record<string, Relationship[]> = {};
  @Input() directorsOptions: SelectOption[] = [];

  holders: SecurityRegistryRecord[] = [];
  securityRegistryRecords: SecurityRegistryRecord[] = [];
  corporateHolders: CorporateHolderModel[] = [];

  override readonly StepsEnum = VoluntaryDeregistrationStepsEnum;
  override steps = VoluntaryDeregistrationSteps;
  override currentStepIndex = 0;
  override currentStep = this.steps[0].step;
  readonly ShareholderRelationshipDetails = ShareholderRelationshipDetails;
  readonly isApplicantDirectorOptions = isApplicantDirectorOptions;
  readonly paymentMethodOptions: SelectOption[] = paymentMethodOptions;
  readonly disabledIndividualControlKeys: (keyof IndividualDataFormGroupControls)[] = ['formerName', 'dob', 'birthCity', 'birthCountry'];
  readonly voluntaryDeregistrationDeclarations = voluntaryDeregistrationDeclarations;

  stepsSignal: WritableSignal<IStep<VoluntaryDeregistrationStepsEnum>[]> = signal(VoluntaryDeregistrationSteps);

  override stepperForm = new FormGroup({
    [VoluntaryDeregistrationStepsEnum.FormDescription]: new FormGroup({}),
    [VoluntaryDeregistrationStepsEnum.ApplicantDeclaration]: new FormGroup({
      declarationNoAssetsLiabilities: new FormControl<boolean>(false, [CustomFormValidators.valueMatchValidator(true)])
    }),
    [VoluntaryDeregistrationStepsEnum.DetailsOfTheApplicant]: new FormGroup({
      isApplicantDirector: new FormControl<boolean | null>(null, [Validators.required]),
      paymentMethod: new FormControl<PaymentMethod>(PaymentMethod.INV, [Validators.required]),

      // Company Selected
      applicantIfNominee: IndividualDataFormGroupComponent.defineForm(),
      // Director Selected
      applicantIfDirector: new FormControl<string | null>(null, [Validators.required]),
    }) as FormGroup<DetailsOfTheApplicantControls>,
    [CompanyNameChangeStepsEnum.AuthorisedSignatories]: AuthorisedSignatoriesStepComponent.defineForm(),
    [VoluntaryDeregistrationStepsEnum.BeneficialOwnersDetails]: new FormGroup({}),
  });

  constructor() {
    super();
    this.redirectAfterSubmit = true;
  }

  ngOnInit(): void {
    this.loadNboHolders();
    this.listenApplicantTypeChanges();
  }

  override afterSubmit(changes: EntityChangeData[]) {
    this.setupChange(changes[0] as CompanyChangeDeregister);
  }

  override setupChange(change: CompanyChangeDeregister = this.formModel): void {
    if (!this.isEdit && change === this.formModel)
      return;

    const applicantIfDirector = change.applicantIfDirector?.key
      || this.directorsOptions.find((option) => option.label.trim() === change.applicantIfDirector?.individualDataOverride?.fullName?.trim())?.value as string
      || null;
    this.applicantDeclarationForm.controls.declarationNoAssetsLiabilities.patchValue(change.declarationNoAssetsLiabilities);
    this.detailsOfTheApplicantForm.patchValue({
      ...change,
      applicantIfDirector: applicantIfDirector ?? null,
      applicantIfNominee: change.applicantIfNominee ?? {},
    });
  }

  updateSignValue(index: number, newBOName: string): void {
    this.holders[index].holders.forEach(holder => holder.details.beneficialOwner = newBOName);
    this.stepperForm.controls[DividendStatementStepsEnum.BeneficialOwnersDetails].updateValueAndValidity();
  }

  override buildDocument(): Document | null {
    const applicantIfDirector = this.detailsOfTheApplicantForm.value.applicantIfDirector ? this.officers[this.detailsOfTheApplicantForm.value.applicantIfDirector][0] : undefined;
    const applicantIfNominee = this.detailsOfTheApplicantForm.value.applicantIfNominee ?
      new IndividualData({
        ...this.detailsOfTheApplicantForm.value?.applicantIfNominee as Partial<IndividualData>,
        ...parseFullName(this.detailsOfTheApplicantForm.controls.applicantIfNominee.controls.fullName?.value ?? ''),
        formerName: parseFullName(this.detailsOfTheApplicantForm.controls.applicantIfNominee.controls.formerFullName?.value ?? '') as ParsedName,
      })
      : undefined;

    const changes = new CompanyChangeDeregister({
      ...this.detailsOfTheApplicantForm.value as Partial<CompanyChangeDeregister>,
      ...this.applicantDeclarationForm.value as Partial<CompanyChangeDeregister>,
      applicantIfNominee,
      applicantIfDirector,
      changeDate: DatepickerHelper.buildTodayDateString(),
    });

    try {
      return new Document({
        changes: [changes],
        entityId: this.companyChangeData.entityId,
        type: 'c:6010',
        documentId: this.companyChangeData?.documentId,
      });
    } catch (error) {
      console.warn(error);
      this.toastr.error('Failed to create Document.', 'Error');
      return null;
    }
  }

  private listenApplicantTypeChanges(): void {
    this.detailsOfTheApplicantForm.controls.isApplicantDirector.valueChanges
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((applicantType: boolean | null) => {
        setControlDisabled(this.detailsOfTheApplicantForm.controls.applicantIfDirector, applicantType === false);
        setControlDisabled(this.detailsOfTheApplicantForm.controls.applicantIfNominee, applicantType === true);

        if (applicantType === false) {
          // is Company

          this.disabledIndividualControlKeys.forEach(key => {
            setControlDisabled(this.detailsOfTheApplicantForm.controls.applicantIfNominee?.controls[key]);
          });
        } else if (applicantType === true) {
          // is Director
        }
      });
  }

  private updateFormSteps(): void {
    const steps = VoluntaryDeregistrationSteps.filter((step) => {

      if (step.step === VoluntaryDeregistrationStepsEnum.BeneficialOwnersDetails) {
        return this.holders.some(h => !h.isBeneficialOwner);
      }

      if (step.step === VoluntaryDeregistrationStepsEnum.AuthorisedSignatories) {
        return this.corporateHolders.length;
      }

      return true;
    });

    this.stepsSignal.set(steps);
  }

  private loadNboHolders(): void {
    this.isLoading = true;

    this.securityService.getSecurityRegistry(this.companyChangeData.entityId)
      .subscribe((securityHolderRecords) => {
        this.holders = securityHolderRecords.filter(record => !record.isBeneficialOwner);
        this.securityRegistryRecords = securityHolderRecords
        this.corporateHolders = securityHolderRecords
          .filter((record) => record.holders.length === 1 && record.holders[0].$type === CorporateHolderModel.$type)
          .flatMap((record) => record.holders) as CorporateHolderModel[];

        this.updateFormSteps();
        this.setupChange();
        this.isLoading = false;
      });
  }

  get applicantDeclarationForm() {
    return this.stepperForm.controls[VoluntaryDeregistrationStepsEnum.ApplicantDeclaration];
  }

  get detailsOfTheApplicantForm() {
    return this.stepperForm.controls[VoluntaryDeregistrationStepsEnum.DetailsOfTheApplicant];
  }

  get authorisedSignatoriesForm(): IAuthorisedSignatoriesFormGroup {
    return this.stepperForm.controls[VoluntaryDeregistrationStepsEnum.AuthorisedSignatories];
  }
  
  override setCurrentStep(newStepIndex: number): void {
    this.currentStep = this.stepsSignal()[newStepIndex].step;
    this.currentStepIndex = newStepIndex;
  }
}
