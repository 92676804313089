import { Component, Input, ViewChild } from '@angular/core';
import { AsyncPipe, DatePipe, formatDate, NgClass } from "@angular/common";
import { ButtonComponent } from "../common/button/button.component";
import { CheckboxComponent } from "../common/checkbox/checkbox.component";
import { NgbDropdown, NgbDropdownMenu, NgbDropdownToggle, NgbTooltip } from "@ng-bootstrap/ng-bootstrap";
import { ColDefCommunityExtended, DateRange, FilterOption, GridFilterModel } from "../../../models/gridFilter";
import { DateFilterModel, GridApi, TextFilterModel } from "ag-grid-community";
import { ReactiveFormsModule } from "@angular/forms";
import { CellDataType } from '../../../models/enums/agGridEnums';
import { SearchInputComponent } from "../common/search-input/search-input.component";
import { DateRangePickerComponent } from "../common/date-range-picker/date-range-picker.component";
import { DividerComponent } from "../common/divider/divider.component";
import { SearchFilterListComponent } from "../grid-column-search-filter/search-filter-list/search-filter-list.component";

@Component({
  selector: 'app-grid-server-column-search-filter',
  standalone: true,
    imports: [
      AsyncPipe,
      ButtonComponent,
      CheckboxComponent,
      NgbDropdown,
      NgbDropdownMenu,
      NgbDropdownToggle,
      NgbTooltip,
      DatePipe,
      SearchInputComponent,
      DateRangePickerComponent,
      ReactiveFormsModule,
      NgClass,
      DividerComponent,
      SearchFilterListComponent
    ],
  templateUrl: './grid-server-column-search-filter.component.html',
  styleUrl: './grid-server-column-search-filter.component.scss'
})
export class GridServerColumnSearchFilterComponent {
  @ViewChild('dropdown') dropdown!: NgbDropdown;
  @ViewChild('startBtnDropdown') startBtnDropdown: NgbDropdown | undefined;

  @Input() colDefs: ColDefCommunityExtended[] = [];
  @Input() rowData: unknown[] = [];
  @Input() gridApi: GridApi | undefined;
  @Input() loading = false;

  public CellDataType = CellDataType;
  public selectedColIndex = -1;
  public colDefsCopy: ColDefCommunityExtended[] = [];
  public filterStateIndex: number | undefined;
  public isFilterApplied = false;
  public filterOptions: FilterOption[] | undefined;

  get isSomeFilterTag(): boolean {
    if (this.startBtnDropdown && !this.startBtnDropdown.isOpen()) {
      if (this.selectedColIndex > -1  && !this.colDefs[this.selectedColIndex]?.filterParams?.filterOptions?.length) {
        if (this.colDefsCopy[this.selectedColIndex]?.filterParams?.filterOptions?.length) {
          this.colDefs[this.selectedColIndex].filterParams.filterOptions = this.colDefsCopy[this.selectedColIndex].filterParams.filterOptions;
        }
      }

      if (this.filterStateIndex && this.filterStateIndex > -1 && this.filterOptions?.length !== 0) {
        this.colDefs[this.filterStateIndex].filterTagShowed = true;
      }
    }

    return this.colDefs.some(col => col.filterTagShowed);
  }

  get numberOfNotShowedTags(): number {
    return this.colDefs.filter(col => {
      return !col.filterTagShowed && col?.headerName
        && (col?.filter === 'agSetColumnFilter' || col?.filter === 'agDateColumnFilter' || col?.filter === 'agTextColumnFilter');
    }).length;
  }

  get isDateColumn(): boolean {
    if (!this.isFilterApplied) {
      this.resetDropDownStatus();
    }
    return this.selectedColIndex > -1 && this.colDefs[this.selectedColIndex].cellDataType === CellDataType.DATE;
  }

  isActiveOption(filterOptions: FilterOption[] | undefined): boolean {
    return (filterOptions?.filter(option => option.active)?.length ?? 0) > 1;
  }

  isSomeActiveFilterOptions(index: number): boolean {
    return this.colDefs[index].filterParams?.filterOptions?.some(option => option.active);
  }

  onSelectColumn(index: number): void {
    this.selectedColIndex = index;
  }

  resetSelectedColumn(): void {
    this.selectedColIndex = -1;
  }

  closeFilterTag(index: number): void {
    this.selectedColIndex = -1;
    this.filterStateIndex = -1;
    this.colDefs[index].filterTagShowed = false;
    this.colDefs[index].filterParams?.filterOptions?.forEach(option => option.active = false);

    const field = this.colDefs[index].field;
    this.setFilterModel(field, {});
  }

  onSearchByOption(index: number, searchText: string): void {
    if (!this.colDefsCopy.length) {
      this.colDefsCopy = JSON.parse(JSON.stringify(this.colDefs)) as ColDefCommunityExtended[];
    }

    if (this.colDefs[index]?.filterParams?.filterOptions) {
      const originalOptions = this.colDefsCopy[index]?.filterParams?.filterOptions;
      this.colDefs[index].filterParams.filterOptions = [...originalOptions];
    }

    let filterOptions = this.colDefs[index]?.filterParams.filterOptions;

    if (filterOptions?.length) {
      filterOptions = filterOptions.filter((item) => (item.label.toString().toLowerCase().includes(searchText.toLowerCase())));
      this.colDefs[index].filterParams.filterOptions = filterOptions;
    }
  }

  onServerFilterOptionChange(index: number, { indexOption, active }: { indexOption: number, active: boolean }): void {
    this.colDefs[index].filterParams.filterOptions[indexOption].active = active;
    const filterOptions = this.colDefs[index].filterParams.filterOptions.filter(option => option.active);
    this.filterStateIndex = index;
    this.filterOptions = filterOptions;
    const someTagShowed = this.colDefs.filter(c => c.filterTagShowed)?.length;
    if (someTagShowed) {
      this.colDefs[index].filterTagShowed = !!filterOptions?.length;
    }

    const field = this.colDefs[index].field;
    const modelValues = filterOptions?.map(option => option.value);

    const filterModel: DateFilterModel | GridFilterModel = {};
    if (filterOptions?.length) {
      filterModel[field] = { filterType: 'set', values: modelValues };
    }

    this.setFilterModel(field, filterModel);
  }

  onServerFilterSearch(index: number, searchText: string): void {
    this.colDefs[index].filterParams.searchText = searchText;
    this.colDefs[index].filterTagShowed = true;
    const field = this.colDefs[index].field;
    const filterModel: DateFilterModel | TextFilterModel | GridFilterModel = {};
    filterModel[field] = { type: 'contains', filter: searchText, filterType: 'text' };
    this.setFilterModel(field, filterModel);
  }

  showTagInfo(filterOptions: FilterOption[] | undefined): string | number {
    const activeFilterOptions = filterOptions?.filter(option => option?.active) ?? [];
    return activeFilterOptions.length > 1 ? activeFilterOptions.length : activeFilterOptions?.[0]?.label ?? '';
  }

  addFilterToTag(index: number): void {
    this.colDefs[index].filterTagShowed = true;
  }

  onServerDateRangeSelected(index: number, { from, to }: DateRange): void {
    this.colDefs[index].filterParams.dateRangeSelected = { from, to };
    this.colDefs[index].filterTagShowed = true;

    const field = this.colDefs[index].field;
    const dateFilterModel: DateFilterModel = {
      filterType: 'date',
      dateFrom: from ? formatDate(from, 'YYYY-MM-dd hh:mm:ss', 'en-US') : null,
      dateTo: to ? formatDate(to, 'YYYY-MM-dd hh:mm:ss', 'en-US') : null,
      type: 'inRange'
    };

    const filterModel: Record<string, DateFilterModel> = {
      [field]: dateFilterModel,
    }

    this.setFilterModel(field, filterModel);
  }

  resetDropDownStatus(): void {
    if (this.startBtnDropdown && !this.startBtnDropdown.isOpen()) {
      this.selectedColIndex = -1;
    }
  }

  setFilterModel(field: string, filterModel: Record<string, DateFilterModel> | TextFilterModel | GridFilterModel): void {
    const prevModels: DateFilterModel | GridFilterModel = this.gridApi?.getFilterModel() ?? {};
    if(Object.keys(prevModels)?.includes(field)) {
      delete prevModels[field];
    }

    this.gridApi?.setFilterModel({ ...filterModel, ...prevModels });
    this.gridApi?.onFilterChanged();
  }

  onFilterClick(): void {
    this.isFilterApplied = false;
  }
}
