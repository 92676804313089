<app-common-modal-form
  [header]="'Change Signatory Info'"
  [icon]="'icon ic-note'"
  [confirmText]="'Save'"
  [closeText]="'Cancel'"
  [isLoading]="isLoading"
  (close)="close()"
  (confirm)="save()"
>
  <div class="body-container d-flex flex-column gap-24" [formGroup]="form">
    <app-input [label]="'Email *'"  formControlName="email"></app-input>
    <app-input-phone-number [label]="'Phone'" class="input-mrgn-20" formControlName="phone"/>
  </div>
</app-common-modal-form>
