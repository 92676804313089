import { HttpClient } from '@angular/common/http';
import { Injectable, signal } from '@angular/core';
import { environment } from '../environments/environment';
import { Observable, map, Subject } from 'rxjs';
import { Company } from '../models/company';
import { createFilterOptions } from "../app/helpers/create-filter-options.helper";
import { Tag } from "../models/tag";
import { mergeUniqueValues } from "../app/helpers/unique-merge.helper";
import { uniqueArrayToFilterOptionHelper } from "../app/helpers/unique-array-to-filter-option.helper";
import { formatDate } from "@angular/common";
import { FilterOption } from "../models/gridFilter";
import { statusFilterOptions } from "../app/helpers/status-filter-options.helper";
import { convertEnumToFilterOption } from "../app/helpers/enum-to-filter-option.helper";
import { AustralianState } from "../models/enums/countryEnums";
import { ExcelRow, ExcelStyle, GridApi } from "ag-grid-community";
import {
  AgCompanyNameAcnComponent
} from "../app/components/common/grid/components/ag-company-name-acn/ag-company-name-acn.component";
import { CellDataType } from "../models/enums/agGridEnums";
import {
  AgCompanyRegistrationDateComponent
} from "../app/components/common/grid/components/ag-company-registration-date/ag-company-registration-date.component";
import {
  AgCompanyStatusComponent
} from "../app/components/common/grid/components/ag-company-status/ag-company-status.component";
import { AgEntityTagsComponent } from "../app/components/common/grid/components/ag-company-tag/ag-entity-tags.component";
import { ColumnWithExportName } from "../models/columnWithExportName";
import { Router } from "@angular/router";
import { JurisdictionType } from "../models/enums/trustEnum";
import {
  AgCompanyDebtComponent
} from "../app/components/common/grid/components/ag-company-debt/ag-company-debt.component";
import { Relationship } from "../models/relationship";
import { RecentSignDocument } from "../models/files";
import { SigningStatus } from '../models/enums/annualStatementEnums';
import { generate, Observer, Operation } from "fast-json-patch";
import { filterPatchOperations } from "../functions/filter-patch-operations";
import { excelReportTitleStyles, excelTitleForReport } from "../app/helpers/excel-title-for-report";

@Injectable({
  providedIn: 'root'
})
export class CompaniesService {
  readonly activeOptions = ['active', 'non-agent', 'deregistered', 'strike-off'];
  companyTagFilterOptions = signal<FilterOption[]>([]);
  companySystemTagFilterOptions = signal<FilterOption[]>([]);
  companyAccountManagerFilterOptions = signal<FilterOption[]>([]);
  companyPartnerManagerFilterOptions = signal<FilterOption[]>([]);
  companyDebtOptions = signal<FilterOption[]>([]);
  companyDateOfEstablishmentFilterOptions = signal<FilterOption[]>([]);
  familyGroupFilterOptions= signal<FilterOption[]>([]);
  nextAnnualReviewDateFilterOptions = signal<FilterOption[]>([]);
  companyStatusFilterOptions = signal<FilterOption[]>([]);
  companyJurisdictionOptions = signal<FilterOption[]>([]);
  colDefs = signal<ColumnWithExportName[]>([]);
  numberColumnForExport = signal(0);
  gridApi!: GridApi;
  _companyListUpdated$ = new Subject<void>();
  companyListUpdated$ = this._companyListUpdated$.asObservable();

  constructor(private api: HttpClient, private router: Router) { }

  public list() {
    return this.api.get<Company[]>(`${environment.api_url}/companies`);
  }

  public listNames(): Observable<Map<string, string>>{
    return this.api.get<Record<string, string>>(`${environment.api_url}/companies/names`)
      .pipe(map((data: Record<string, string>) => {
        const resultMap = new Map<string, string>();
        Object.keys(data).forEach(key => {
          resultMap.set(key, data[key]);
        });

        return resultMap;
      }));
  }

  public officeHolders(companyId: string): Observable<{ isSigningContact: boolean, relationship: Relationship }[]> {
    return this.api.get<{ isSigningContact: boolean, relationship: Relationship }[]>(`${environment.api_url}/companies/get-officeholders?companyId=${companyId}`);
  }

  public getRecentSignDocuments(companyId: string, status: SigningStatus = SigningStatus.ReadyToSend): Observable<RecentSignDocument[]> {
    return this.api.get<RecentSignDocument[]>(`${environment.api_url}/companies/${companyId}/recent-documents/${status}`);
  }

  public refreshDebts(companyId: string): Observable<void> {
    return this.api.post<void>(`${environment.api_url}/companies/${companyId}/debts/refresh`, null);
  }
  public refreshDebtsBulk(companiesId: string[]): Observable<void> {
    return this.api.post<void>(`${environment.api_url}/companies/bulk/debts/refresh`, companiesId);
  }

  public refreshInfo(companyId: string ): Observable<void> {
    return this.api.post<void>(`${environment.api_url}/companies/${companyId}/info/refresh`, null);
  }

  public refreshInfoBulk(companiesId: string[]): Observable<void> {
    return this.api.post<void>(`${environment.api_url}/companies/bulk/info/refresh`, companiesId);
  }

  public patchCompany(companyId: string, observer: Observer<Company>) {
    const filteredPatchOperations: Operation[] = observer ? filterPatchOperations(generate(observer)) : [];

    return this.api.patch<Company>(`${environment.api_url}/companies/${companyId}`, filteredPatchOperations, { headers: { 'Content-Type': 'application/json-patch+json' } })
      .pipe(map((data) => new Company(data)));
  }

  public generateCompanyProfilePdf(companyId: string) {
    return this.api.get(`${environment.api_url}/companies/${companyId}/profile/pdf`, { responseType: 'blob' as 'json' })
  }

  public triggerRA71(entityId: string): Observable<void> {
    return this.api.post<void>(`${environment.api_url}/companies/${entityId}/asic-refresh`, null);
  }

  setCompanyOptions(companiesList: Company[], actionColumn: ColumnWithExportName): void {
    if(!companiesList?.length) { return; }

    this.companyTagFilterOptions.set(createFilterOptions<Tag>(mergeUniqueValues<Tag[]>(companiesList
      .map(c => c.tags), 'tagId'), 'name', 'name'));

    this.companySystemTagFilterOptions.set(createFilterOptions<Tag>(mergeUniqueValues<Tag[]>(companiesList
      .map(c => c.systemTags), 'tagId'), 'name', 'name'));

    const accountManagerList = companiesList?.filter( c => c.accountManager?.fullName)
      .map(c => c.accountManager?.fullName) as string[];
    this.companyAccountManagerFilterOptions.set(uniqueArrayToFilterOptionHelper([...new Set(accountManagerList)]));

    const partnerManagerList = companiesList?.filter( c => c.partnerManager?.fullName)
      .map(c => c.partnerManager?.fullName) as string[];
    this.companyPartnerManagerFilterOptions.set(uniqueArrayToFilterOptionHelper([...new Set(partnerManagerList)]));

    const familyGroupList = companiesList?.filter(c => c.familyGroup)
      .map(c => c.familyGroup);
    this.familyGroupFilterOptions.set(uniqueArrayToFilterOptionHelper([...new Set(familyGroupList)]));

    const amountOwingList = companiesList.filter(company => company.companyDebt?.amountOwing !== undefined)
      ?.map((company) => company.companyDebt?.amountOwing.toString()) as string[];
    this.companyDebtOptions.set(uniqueArrayToFilterOptionHelper([...new Set(amountOwingList)]));

    const dateOfEstablishmentList = companiesList.filter(company => company.dateOfEstablishment)
      ?.map(company => company.dateOfEstablishment);
    this.companyDateOfEstablishmentFilterOptions.set([...new Set(dateOfEstablishmentList)].map(date => {
      return {
        label: formatDate(date ?? '','dd MMM yyyy', 'en'),
        value: date ?? '',
        active: false
      }
    }));

    const nextAnnualReviewDateList = companiesList.filter(company => company.nextAnnualReviewDate)
      ?.map(company => company.nextAnnualReviewDate);
    this.nextAnnualReviewDateFilterOptions.set([...new Set(nextAnnualReviewDateList)].map(reviewDate => {
      return {
        label: formatDate(reviewDate ?? '','dd MMM yyyy', 'en'),
        value: reviewDate ?? '',
        active: false
      }
    }));

    this.companyStatusFilterOptions.set(statusFilterOptions(this.activeOptions));

    const jurisdictionOptions = convertEnumToFilterOption(AustralianState, true);
    jurisdictionOptions.forEach(o=> o.value = (JurisdictionType[o.value as string] as number).toString());
    jurisdictionOptions.sort((a, b) => a.label.localeCompare(b.label));
    this.companyJurisdictionOptions.set(jurisdictionOptions);

    this.setGridConfig(actionColumn);
  }

  setGridConfig(actionColumn: ColumnWithExportName): void {
    this.colDefs.set([
      {
        headerName: 'Company Name & ACN',
        exportColumnName: 'Company Name',
        suppressMovable: true,
        field: 'name',
        width: 200,
        flex: 2,
        editable: false,
        sort: 'asc',
        checkboxSelection: true,
        headerCheckboxSelection: true,
        cellRenderer: AgCompanyNameAcnComponent,
        menuTabs: ['generalMenuTab'],
      },
      {
        headerName: '',
        exportColumnName: 'ACN',
        field: 'acn',
        width: 100,
        flex: 0.5,
        menuTabs: [],
        hide: true
      },
      {
        headerName: 'Registration',
        field: 'dateOfEstablishment',
        width: 80,
        flex: 0.5,
        filter: 'agSetColumnFilter',
        filterParams: {
          filterOptions: this.companyDateOfEstablishmentFilterOptions(),
        },
        getQuickFilterText: (params: { data: { acn: string } }) => {
          return params.data.acn ?? '';
        },
        cellDataType: CellDataType.DATE_STRING,
        cellRenderer: AgCompanyRegistrationDateComponent,
        menuTabs: ['generalMenuTab'],
        exportColumnName: 'Registration Date'
      },
      {
        headerName: 'Status',
        field: 'status',
        width: 80,
        flex: 0.5,
        filter: 'agSetColumnFilter',
        filterParams: {
          filterOptions: this.companyStatusFilterOptions(),
        },
        cellRenderer: AgCompanyStatusComponent,
        menuTabs: ['generalMenuTab'],
      },
      {
        headerName: 'Debt',
        field: 'companyDebt.amountOwing',
        width: 110,
        filter: 'agSetColumnFilter',
        filterParams: {
          filterOptions: this.companyDebtOptions(),
        },
        cellRenderer: AgCompanyDebtComponent,
        cellDataType: CellDataType.NUMBER,
        menuTabs: ['generalMenuTab'],
      },
      {
        headerName: 'Tags',
        field: 'tags',
        width: 380,
        flex: 2,
        filter: 'agSetColumnFilter',
        filterParams: {
          filterOptions: this.companyTagFilterOptions(),
        },
        cellRendererParams: {
          allowEdit: true,
          handleAddNewTag: (() => this._companyListUpdated$.next())
        },
        cellRenderer: AgEntityTagsComponent,
        valueFormatter: (params: { value: Tag }) => params.value?.name ?? '',
        comparator: (tagListA: Tag[], tagListB: Tag[]) => {
          const a = tagListA?.length ?? 0;
          const b = tagListB?.length ?? 0;
          return a - b;
        },
        menuTabs: ['generalMenuTab'],
      },
      {
        headerName: '',
        exportColumnName: 'System tags',
        field: 'systemTags',
        width: 180,
        flex: 1,
        filter: 'agSetColumnFilter',
        filterParams: {
          filterOptions: this.companySystemTagFilterOptions(),
        },
        valueFormatter: (params: { value: Tag }) => params.value?.name ?? '',
        hide: true,
        menuTabs: ['generalMenuTab'],
      },
      {
        headerName: 'Account Manager',
        field: 'accountManager.fullName',
        flex: 2,
        filter: 'agSetColumnFilter',
        filterParams: {
          filterOptions: this.companyAccountManagerFilterOptions(),
        },
        hide: true,
        menuTabs: ['generalMenuTab'],
      },
      {
        headerName: 'Partner Manager',
        field: 'partnerManager.fullName',
        flex: 2,
        filter: 'agSetColumnFilter',
        filterParams: {
          filterOptions: this.companyPartnerManagerFilterOptions(),
        },
        hide: true,
        menuTabs: ['generalMenuTab'],
      },
      {
        headerName: 'Corporate Key',
        field: 'corporateKey',
        flex: 1,
        hide: true,
        menuTabs: ['generalMenuTab'],
      },
      {
        headerName: 'Reference Number',
        field: 'referenceNumber',
        flex: 1,
        hide: true,
        menuTabs: ['generalMenuTab'],
      },
      {
        headerName: 'Family Group',
        field: 'familyGroup',
        flex: 1,
        filter: 'agSetColumnFilter',
        filterParams: {
          filterOptions: this.familyGroupFilterOptions(),
        },
        cellDataType: CellDataType.TEXT,
        hide: true,
        menuTabs: ['generalMenuTab'],
      },
      {
        headerName: 'Next Annual Review',
        field: 'nextAnnualReviewDate',
        flex: 1,
        filter: 'agSetColumnFilter',
        filterParams: {
          filterOptions: this.nextAnnualReviewDateFilterOptions(),
        },
        cellDataType: CellDataType.DATE_STRING,
        cellRenderer: AgCompanyRegistrationDateComponent,
        hide: true,
        menuTabs: ['generalMenuTab'],
      },
      {
        headerName: 'Jurisdiction',
        field: 'jurisdiction',
        flex: 1,
        filter: 'agSetColumnFilter',
        filterParams: {
          filterOptions: this.companyJurisdictionOptions(),
        },
        valueFormatter: (params: { value: string }) => {
          return this.companyJurisdictionOptions().find(o => o.value == params.value)?.label ?? ''
        },
        hide: true,
        menuTabs: ['generalMenuTab'],
      },
      {
        ...actionColumn
      }
    ]);
  }

  getColDefs(): ColumnWithExportName[] {
    return this.colDefs();
  }

  setColDefs(colDefs: ColumnWithExportName[]): void {
    this.colDefs.set(colDefs);
  }

  public getExcelTitleRows: (numberColumnForExport: number) => ExcelRow[] = (numberColumnForExport) =>
    excelTitleForReport(numberColumnForExport, 'Companies Report');

  public readonly excelStyles: ExcelStyle[] = excelReportTitleStyles;
}
