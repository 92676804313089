import { DocumentStatusEnum } from "./enums/documentStatusEnum";
import { AsicStatus } from "./enums/asicStatus";
import { SigningStatus } from "./enums/annualStatementEnums";

export class DocumentRecord {
    documentName: string;
    documentId: string;
    entityId: string;
    entityName: string;
    entityNumber: string;
    description: string;
    asicStatus: AsicStatus;
    status?: DocumentStatusEnum;
    signingStatus?: SigningStatus;
    lodgementDeadline?: Date;
    asicDocumentNumber?: string | null;
    asicTraceNumber?: string;
    createdOn: Date;
    modifiedOn: Date;
    type: string;
    checked: boolean;
    earliestChangeDate: string | null;
    dateSigned: Date | null;
    accountManagerFullName: string;
    accountManagerId: string;
    lastEditorFullName: string;
    LastEditorId: string;
    lateFee: number;
    hasLateFee: boolean;

    constructor(documentRecord: Partial<DocumentRecord> = {}) {
        this.documentName = documentRecord.documentName ?? '';
        this.documentId = documentRecord.documentId ?? '';
        this.entityId = documentRecord.entityId ?? '';
        this.entityName = documentRecord.entityName ?? '';
        this.entityNumber = documentRecord.entityNumber ?? '';
        this.description = documentRecord.description ?? '';
        this.asicStatus = documentRecord.asicStatus ?? AsicStatus.NotLodged;
        this.signingStatus = documentRecord.signingStatus;
        this.lodgementDeadline = documentRecord.lodgementDeadline;
        this.status = documentRecord.status;
        this.createdOn = documentRecord.createdOn ? new Date(documentRecord.createdOn) : new Date();
        this.modifiedOn = documentRecord.modifiedOn ? new Date(documentRecord.modifiedOn) : new Date();
        this.type = documentRecord.type ?? '';
        this.checked = documentRecord.checked ?? false;
        this.earliestChangeDate = documentRecord.earliestChangeDate ?? null;
        this.asicDocumentNumber = documentRecord.asicDocumentNumber ?? null;
        this.asicTraceNumber = documentRecord.asicTraceNumber ?? '';
        this.dateSigned = documentRecord.dateSigned ? new Date(documentRecord.dateSigned) : null;
        this.accountManagerFullName = documentRecord.accountManagerFullName ?? '';
        this.accountManagerId = documentRecord.accountManagerId ?? '';
        this.lastEditorFullName = documentRecord.lastEditorFullName ?? '';
        this.LastEditorId = documentRecord.LastEditorId ?? '';
        this.lateFee = documentRecord.lateFee ?? 0;
        this.hasLateFee = documentRecord.hasLateFee ?? false;
    }
}
