import { Component, EventEmitter, Input, Output } from '@angular/core';
import { DividerComponent } from '../common/divider/divider.component';
import { Trust } from '../../../models/trust';
import { XpmCardProfileComponent } from "../card-profiles/xpm-card-profile/xpm-card-profile.component";
import { EntityType } from '../../../models/enums/entityType';

@Component({
  selector: 'app-trust-profile-card',
  standalone: true,
  imports: [
    DividerComponent,
    XpmCardProfileComponent
],
  templateUrl: './trust-profile-card.component.html',
  styleUrl: './trust-profile-card.component.scss'
})
export class TrustProfileCardComponent {

  @Input() trustProfile!: Trust;
  @Input() disableControls = false;
  @Output() onTrustRefreshed = new EventEmitter<void>();

  entityType: EntityType = EntityType.Trust;

  onTrustRefreshedEvent() {
    this.onTrustRefreshed.emit();
  }
}
