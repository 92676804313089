import { Component } from '@angular/core';
import { ICellRendererParams } from "ag-grid-community";
import { SignRow } from "../../../../../../../models/signDocuments";
import { SignGroup } from "../../../../../../../models/enums/annualStatementEnums";

type CellRendererParams = ICellRendererParams<SignRow>

@Component({
  selector: 'app-ag-annual-sign-name-category',
  standalone: true,
  imports: [],
  templateUrl: './ag-annual-sign-name-category.component.html',
  styleUrl: './ag-annual-sign-name-category.component.scss'
})
export class AgAnnualSignNameCategoryComponent {
  protected params!: CellRendererParams;
  protected name = ''
  protected groupName = '';
  protected type: SignGroup | undefined;

  agInit(params: CellRendererParams): void {
    this.params = params;
    this.name = this.params.data?.fullName ?? '';
    this.groupName =  this.params.data?.groupName ?? '';
    this.type = this.params.data?.type;
  }

  refresh(params: CellRendererParams): boolean {
    this.params = params;
    this.name = this.params.data?.fullName ?? '';
    this.groupName =  this.params.data?.groupName ?? '';
    this.type = this.params.data?.type;
    return true;
  }

  protected readonly AnnualSignGroup = SignGroup;
}
