import { Component, ViewChild, ViewContainerRef } from '@angular/core';
import { InputPhoneNumberComponent } from "../../../../input-phone-number/input-phone-number.component";
import { AbstractControl, FormsModule } from "@angular/forms";
import { ICellRendererParams } from "ag-grid-community";
import { SignRow } from "../../../../../../../models/signDocuments";
import { SignGroup } from "../../../../../../../models/enums/annualStatementEnums";
import { CustomFormValidators } from "../../../../../../../custom-form-validators/custom-form-validators";

type ActionEvent = (data: unknown) => void;

type CellRendererParams = ICellRendererParams<SignRow> & {
  edit: ActionEvent,
};

@Component({
  selector: 'app-ag-annual-sign-edit-phone',
  standalone: true,
  imports: [
    InputPhoneNumberComponent,
    FormsModule
  ],
  templateUrl: './ag-annual-sign-edit-phone.component.html',
  styleUrl: './ag-annual-sign-edit-phone.component.scss'
})
export class AgAnnualSignEditPhoneComponent {
  protected params!: CellRendererParams;
  public value: string | undefined;
  public disabled = false;
  public validationError = false;
  protected type: SignGroup | undefined;
  protected readonly AnnualSignGroup = SignGroup;
  protected readonly phoneMinLen = 6;

  @ViewChild('input', { read: ViewContainerRef }) public input!: ViewContainerRef;

  agInit(params: CellRendererParams): void {
    this.params = params;
    this.value = params.data?.phone ?? '';
    this.disabled = params.data?.disabled ?? false;
    this.type = params.data?.type;
  }

  refresh(params: CellRendererParams): boolean {
    this.params = params;
    return true;
  }

  ngAfterViewInit() {
    window.setTimeout(() => {
      if(this.input) {
        (this.input.element.nativeElement as Element).querySelector('input')?.focus();
      }
    });
  }

  onChangeValue(value: string): void {
    this.params.edit({ value: value, index: this.params.node.rowIndex, column: 'phone' });
    this.validationError = !this.isPhoneValid(value);

    if(this.params.data) {
      this.params.data.phone = value;
    }
  }

  private isPhoneValid(phone: string): boolean {
    const control = { value: phone } as AbstractControl;
    const errors = CustomFormValidators.phoneNumberValidator(control);

    return errors === null;
  }
}
