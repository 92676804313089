<app-common-offcanvas class="transaction-details" [opened]="opened()" (close)="closeOffcanvas()">
  <div class="header">
    <h2 class="h2-heading m-0 p-0">
      Transactions Details
    </h2>
  </div>
  <div class="content-wrapper">
    <div class="content">
      <div class="transaction-date-status">
        <div>
          <div class="title">Date & Time</div>
          <div class="text-gray900 font-weight-bold">
            {{ transactionRecord?.createdOn| date : 'dd MMM yyyy HH:mm:ss' : 'en-US' }}
          </div>
        </div>
        <div>
          <div class="title">Status</div>
          <div>
            <span>{{EdgeTransactionStatus[transactionRecord?.status!]}}</span>
          </div>
        </div>
      </div>
      <div class="transaction-preview-wrapper preview-wrapper">
        <pre>{{transactionText}}</pre>
      </div>
    </div>
   <div class="controls">
     <app-button
        btnColor="white"
        text="Download"
        iconClass="icon ic-download-file"
        [isDisabled]="isActionLoading"
        (clickBtn)="downloadTransaction()"
      />
      @if (transactionRecord?.direction == EdgeTransactionDirection.Inbound) {
        <app-button
          btnColor="white"
          text="Reprocess"
          iconClass="icon ic-refresh"
          [isDisabled]="isActionLoading"
          (clickBtn)="reprocessTransaction()"
        />
      }
    </div>
  </div>
</app-common-offcanvas>