import { Component, Input } from '@angular/core';
import { RouterLink } from "@angular/router";
import { ICellRendererParams } from "ag-grid-community";
import { IndividualRecord } from "../../../../../../models/IndividualRecord";
import { ICellRendererAngularComp } from "ag-grid-angular";

type CellRendererParams = ICellRendererParams<IndividualRecord>;

@Component({
  selector: 'app-ag-individual-name',
  standalone: true,
  imports: [RouterLink],
  templateUrl: './ag-individual-name.component.html',
  styleUrl: './ag-individual-name.component.scss'
})
export class AgIndividualNameComponent implements ICellRendererAngularComp {
  @Input() name = '';
  @Input() individualId = '';

  agInit(params: CellRendererParams): void {
    this.updateCell(params);
  }

  refresh(params: CellRendererParams): boolean {
    return this.updateCell(params);
  }

  updateCell(params: CellRendererParams): boolean {
    this.name = params.data?.fullName ?? '';
    this.individualId = params.data?.individualId ?? '';
    return true;
  }
}
