export class AnnualStatementSettingsData {
    esigningEmailRecipients: EsigningEmailRecipients;
    companyStatementSignatory: CompanyStatementSignatory;
  
    constructor(data: Partial<AnnualStatementSettingsData> = {}) {
      this.esigningEmailRecipients = data.esigningEmailRecipients ?? EsigningEmailRecipients.DoNotSend;
      this.companyStatementSignatory = data.companyStatementSignatory ?? CompanyStatementSignatory.AddDirectorSignature;
    }
  }
  
  export class DocumentSettingsData {
    esigningEmailRecipients: EsigningEmailRecipients;
  
    constructor(data: Partial<DocumentSettingsData> = {}) {
      this.esigningEmailRecipients = data.esigningEmailRecipients ?? EsigningEmailRecipients.DoNotSend;
    }
  }
  
  export enum EsigningEmailRecipients {
    DoNotSend,
    SenderOnly,
    RecipientsOnly,
    SenderAndRecipients
  }
  
  export enum CompanyStatementSignatory {
    AddDirectorSignature,
    AddSigningContactSignature,
    DoNotAddSignature
  }