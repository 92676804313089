<div class="action-container">
  <app-ag-action-icon-button
    iconClass="ic-alert-triangle"
    [numberOfAlerts]="numberOfAlerts"
    [disabled]="!numberOfAlerts"
    (action)="numberOfAlerts && onOpenAlerts()"
  ></app-ag-action-icon-button>

  <app-ag-action-icon-button
    iconClass="ic-notes ml-1"
    (action)="onOpenNotes()"
  ></app-ag-action-icon-button>

  <app-ag-action-icon-button
    iconClass="ic-eye"
    (action)="onOpenCompanyProfile()"
  ></app-ag-action-icon-button>

  <div ngbDropdown
    *appHasRole="['Admin', 'Manager', 'Preparer']"
    [placement]="['bottom-end', 'bottom-start', 'top-start', 'top-end']"
    class="dropdown d-inline-flex">
    <i class="icon ic-md ic-three-dots mt-1 ml-1" ngbDropdownToggle></i>

    <div ngbDropdownMenu class="dropdown-menu dropdown-menu-right">
      <div ngbDropdownItem class="dropdown-item" (click)="editPartnerAndAccountManager()">
        <a class="menu-action-button">
          <span>Edit Partner and Account Manager</span>
        </a>
      </div>
      <div ngbDropdownItem class="dropdown-item" (click)="onExportCompanyProfile()">
        <a class="menu-action-button">
          <span>Download Company Profile</span>
        </a>
      </div>
    </div>
  </div>
</div>
