import {
  ChangeDetectorRef,
  Component,
  DestroyRef, EventEmitter,
  inject,
  Input,
  OnChanges,
  OnInit,
  Optional, Output,
  signal,
  SimpleChanges
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import {
  FormControl,
  FormGroup,
  FormGroupDirective,
  ReactiveFormsModule,
  UntypedFormGroup,
  Validators
} from '@angular/forms';
import { filter, map } from 'rxjs';
import { CustomFormValidators } from '../../../../custom-form-validators/custom-form-validators';
import { setControlDisabled } from '../../../../functions/set-control-disabled';
import { Address } from '../../../../models/address';
import { AddressStatus } from '../../../../models/enums/addressEnums';
import { autocompleteServiceToken } from '../../../../services/autocomplete.service';
import { AuxiliaryService } from '../../../../services/auxiliary.service';
import { CountriesService } from '../../../../services/countries.service';
import { GoogleMapsService } from '../../../../services/google-maps.service';
import { AddressAutocompleteComponent } from '../address-autocomplete/address-autocomplete.component';
import { InputComponent } from '../input/input.component';
import { SelectComponent } from '../select/select.component';
import { stateOptions } from '../../../constants/au-states.constant';
import { ManualAddressService } from "../../../../services/manual-address.service";
import { SelectOption } from "../../../../models/selectOptions";
import { TitleCasePipe } from "@angular/common";
import {
  SuburbsAutocompleteComponent
} from "../../reusable-form-groups/address-form-group/suburbs-autocomplete/suburbs-autocomplete.component";
import { AutocompleteComponent } from "../autocomplete/autocomplete.component";

export interface AddressFormGroupControls {
  normalizedFullAddress: FormControl<string | null>;
  careOf: FormControl<string | null>;
  street: FormControl<string | null>;
  line2: FormControl<string | null>;
  suburb: FormControl<string | null>;
  state: FormControl<string | null>;
  postcode: FormControl<string | null>;
  country: FormControl<string | null>;
  gnafID: FormControl<string | null>;
  meshBlock: FormControl<string | null>;
  level: FormControl<string | null>;
  buildingName: FormControl<string | null>;
}

export type AddressFormGroup = FormGroup<AddressFormGroupControls>;

@Component({
  selector: 'app-address-control',
  standalone: true,
  imports: [
    InputComponent,
    ReactiveFormsModule,
    AddressAutocompleteComponent,
    SelectComponent,
    SuburbsAutocompleteComponent,
    AutocompleteComponent
  ],
  providers: [
    { provide: autocompleteServiceToken, useClass: AuxiliaryService },
    GoogleMapsService,
    ManualAddressService
  ],
  templateUrl: './address-control.component.html',
  styleUrl: './address-control.component.scss',
})
export class AddressControlComponent implements OnInit, OnChanges {
  #addressService = inject(AuxiliaryService);
  countriesService = inject(CountriesService);
  destroyRef = inject(DestroyRef);
  cdr = inject(ChangeDetectorRef);
  googleMapsService = inject(GoogleMapsService);
  manualAddressService = inject(ManualAddressService);

  @Input() useInternationalAddresses = false;
  @Input() addressFormControlName = 'address';
  @Input() label = '';
  @Input() expandedAddressForm = false;
  @Input() addressStatus: AddressStatus | undefined;
  @Input() halfWidth = true;
  @Input() isDisabled = false;
  @Input() noValidators = false;
  @Input() set address(value: Address | undefined | null) {
    this.form.patchValue(value ?? {});
  }

  @Input('addressFormLabels') set labels(labels: Record<keyof Partial<AddressFormGroupControls>, string>) {
    this.addressFormLabels = Object.assign(this.addressFormLabels, labels);
  }

  @Output() onDataStatusChange = new EventEmitter<AddressStatus | undefined>();

  readonly countryOptions = this.countriesService.getCountries();
  protected readonly stateOptions = stateOptions;
  protected readonly AddressStatus = AddressStatus;

  isAustraliaSelected = signal(false);

  form: AddressFormGroup = new UntypedFormGroup({
    normalizedFullAddress: new FormControl<string | null>(''),
    careOf: new FormControl<string | null>(''),
    street: new FormControl<string | null>('', [Validators.required]),
    line2: new FormControl<string | null>(''),
    suburb: new FormControl<string | null>('', [Validators.required]),
    state: new FormControl<string | null>('', Validators.required),
    postcode: new FormControl<string | null>('', [Validators.required, CustomFormValidators.postCodeValidator]),
    country: new FormControl<string | null>('AU', Validators.required),
    gnafID: new FormControl<string | null>(''),
    meshBlock: new FormControl<string | null>(''),
    level: new FormControl<string | null>(''),
    buildingName: new FormControl<string | null>('')
  }) as AddressFormGroup;

  addressFormLabels: Record<keyof AddressFormGroupControls, string> = {
    normalizedFullAddress: 'Address',
    careOf: 'Care of',
    street: 'Street Address',
    line2: 'Level, Unit or Shop Number',
    suburb: 'Suburb',
    state: 'State',
    postcode: 'Postcode',
    country: 'Country',
    gnafID: 'GNAF ID',
    meshBlock: 'Mesh Block',
    level: 'Level',
    buildingName: 'Building Name'
  };

  constructor(@Optional() private formGroupDirective: FormGroupDirective) {
  }

  ngOnInit(): void {
    this.listenManualAddressFilling();

    if (this.useInternationalAddresses)
      this.listenCountryChanges();

    // sets this component's form to the closest [formGroup] in the template of the parent
    this.updateForm();
    this.setupInternationalAddressForm();

    this.formGroupDirective.form.get(this.addressFormControlName)?.valueChanges.pipe(
      takeUntilDestroyed(this.destroyRef)
    ).subscribe((address: { normalizedFullAddress?: string }) => {
      const meetingAddress = this.formGroupDirective.form.get(this.addressFormControlName)?.getRawValue() as Address;
      if (meetingAddress) {
        this.updateForm();
      }
    });

    if (this.noValidators)
      this.clearValidators();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes?.['label']?.currentValue) {
      this.updateForm();
    }
  }

  switchAddressForm(): void {
    this.expandedAddressForm = !this.expandedAddressForm;
  }

  parseAddress(value: Address | string | null): void {
    if (value instanceof Address) {
      this.addressStatus = AddressStatus.CHECKED;
      value.suburb = new TitleCasePipe().transform(value.suburb);
      this.patchForm(value);
      this.formGroupDirective.form.get(this.addressFormControlName)?.patchValue(value);
      return;
    }

    if (!value) {
      this.patchForm({ street: this.form.controls.normalizedFullAddress.value as string ?? '' } as Address);
      return;
    }
    this.#addressService.getParseAddress(this.form.controls.normalizedFullAddress?.value as string)
      .pipe(
        filter(normalizedFullAddress => !!normalizedFullAddress),
        map(({ address }) => ({ ...address, country: address.country === 'Australia' ? 'AU' : address.country }))
      )
      .subscribe(parsedAddress => {
        if (parsedAddress === null) {
          this.addressStatus = AddressStatus.WARNING;
        } else {
          this.addressStatus = AddressStatus.CHECKED;
          parsedAddress.suburb = new TitleCasePipe().transform(parsedAddress.suburb);
          this.patchForm(parsedAddress as any);
          this.formGroupDirective.form.get(this.addressFormControlName)?.patchValue(parsedAddress);
        }
      });
  }

  textChange(searchText: string): void {
    if(!searchText) {
      const address = new Address();
      this.form.patchValue(address);
      this.formGroupDirective.form.get(this.addressFormControlName)?.patchValue(this.form.getRawValue());
    }
  }

  dataStatusChange(addressStatus: AddressStatus | undefined): void {
    this.addressStatus = addressStatus;
    this.onDataStatusChange.emit(addressStatus);
  }

  patchForm(parsedAddress: Address | undefined): void {
    this.form.patchValue({
      careOf: parsedAddress?.careOf ?? '',
      street: parsedAddress?.street ?? '',
      line2: parsedAddress?.line2 ?? '',
      suburb: parsedAddress?.suburb ?? '',
      state: parsedAddress?.state ?? '',
      postcode: parsedAddress?.postcode ?? '',
      country: parsedAddress?.country ?? '',
      gnafID: parsedAddress?.gnafID ?? '',
      meshBlock: parsedAddress?.meshBlock ?? ''
    });
  }

  selectSuburb(selectSuburbEvent: { value: SelectOption, index: number }): void {
    if (selectSuburbEvent.value.value === -1 || !selectSuburbEvent.value || (!this.isAustraliaSelected() && this.useInternationalAddresses))
      return;

    const data = this.manualAddressService.getAddressPartByIndex(selectSuburbEvent.value.value as number);
    this.form.controls.postcode.setValue(data.postcode.toString());
    this.form.controls.state.setValue(data.state);
  }

  private updateForm(): void {
    if (!this.formGroupDirective?.form?.get(this.addressFormControlName)) {
      this.formGroupDirective.form.setControl(this.addressFormControlName, this.form);
    } else {
      const form = this.formGroupDirective?.form?.get(this.addressFormControlName)?.value as AddressFormGroup;
      if (form) {
        Object.keys(form).forEach(key => this.form.get(key)?.patchValue(form?.[key]));
      }
    }

    if (this.address) {
      this.form.patchValue(this.address);
    }
  }

  private setupInternationalAddressForm(): void {
    if (this.useInternationalAddresses)
      this.form.controls.country.setValue('AU');
  }

  private setAustraliaFormValidators(): void {
    if (this.noValidators)
      return;
    this.form.controls.normalizedFullAddress.setValidators([Validators.required]);
    this.form.controls.street.setValidators([Validators.required, CustomFormValidators.streetValidator]);
    this.form.controls.suburb.setValidators([Validators.required]);
    this.form.controls.state.setValidators([Validators.required]);
    this.form.controls.postcode.setValidators([Validators.required, CustomFormValidators.postCodeValidator]);
    this.form.controls.country.setValidators([Validators.required]);
  }

  private setInternationalFormValidators(): void {
    this.form.controls.normalizedFullAddress.clearValidators();
    this.form.controls.street.clearValidators();
    this.form.controls.suburb.clearValidators();
    this.form.controls.state.clearValidators();
    if (this.noValidators)
      return;
    this.form.controls.postcode.setValidators([Validators.maxLength(10)]);
  }

  private listenCountryChanges(): void {
    this.form.controls.country.valueChanges
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((country) => {
        this.isAustraliaSelected.set(country === 'AU');

        if (this.isAustraliaSelected()) {
          this.setAustraliaFormValidators();
        } else {
          this.setInternationalFormValidators();
        }

        setControlDisabled(this.form.controls.state, !this.isAustraliaSelected());

        this.form.updateValueAndValidity();
        this.cdr.detectChanges();
      });
  }

  private listenManualAddressFilling(): void {
    const requiredKeys: (keyof Partial<AddressFormGroupControls>)[] = [
      'line2',
      'street',
      'suburb',
      'street',
      'postcode',
    ];

    this.form.valueChanges
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((form) => {
        if (!this.expandedAddressForm)
          return;

        const requiredFieldsFilled = requiredKeys.every((key) => form[key] && form[key]?.toString()?.trim());
        if (requiredFieldsFilled) {
          this.form.controls.normalizedFullAddress.setValue(Address.buildNormalizedFullAddress(form as Address), { emitEvent: false });
        }
      });
  }

  private clearValidators(): void {
    const addressFormGroupControls = this.form.controls;
    Object.keys(addressFormGroupControls)
      .forEach((key) => (addressFormGroupControls[key] as FormControl).clearValidators());
    this.form.updateValueAndValidity();
    this.cdr.detectChanges();
  }
}
