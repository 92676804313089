import { Component, DestroyRef, inject, Input, OnInit } from '@angular/core';
import { RadioControl } from "../../common/radio/radio.component";
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from "@angular/forms";
import { ReusableFormGroupComponent } from "../reusable-form-group/reusable-form-group.component";
import { CustomFormValidators } from "../../../../custom-form-validators/custom-form-validators";
import { DatepickerHelper } from "../../../../custom-form-validators/date-picker-form-validators";
import { AddressFormGroupComponent, AddressFormGroup } from "../address-form-group/address-form-group.component";
import { NgbDateStruct } from "@ng-bootstrap/ng-bootstrap";
import { setControlDisabled } from "../../../../functions/set-control-disabled";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";
import { DatePickerComponent } from "../../common/date-picker/date-picker.component";
import { InputComponent } from "../../common/input/input.component";
import { YesNoControlComponent } from "../../common/yes-no-control-component/yes-no-control.component";
import { Address } from "../../../../models/address";

export interface AddressAndOccupierFormGroupControls {
  address: AddressFormGroup;
  occupiesTheAddress: RadioControl<boolean>;
  occupierNameIfDoesntOccupy: FormControl<string | null>;
  companyHaveOccupiersConsent: RadioControl<boolean>;
  changeDate: FormControl<Date | null>;
}

export type AddressAndOccupierFormGroup = FormGroup<AddressAndOccupierFormGroupControls>;

@Component({
  selector: 'app-address-and-occupier-form-group',
  standalone: true,
  imports: [
    ReactiveFormsModule,
    InputComponent,
    YesNoControlComponent,
    DatePickerComponent,
    AddressFormGroupComponent
  ],
  templateUrl: './address-and-occupier-form-group.component.html',
  styleUrl: './address-and-occupier-form-group.component.scss',
})
export class AddressAndOccupierFormGroupComponent extends ReusableFormGroupComponent<AddressAndOccupierFormGroup> implements OnInit {
  destroyRef = inject(DestroyRef);

  @Input() label = 'New Address';
  @Input() useInternationalAddresses = false;
  @Input() expandedAddressForm = false;
  @Input() minDateOfAddressChange: NgbDateStruct = DatepickerHelper.getTodayStruct();
  @Input() maxDateOfAddressChange: NgbDateStruct = DatepickerHelper.getNextYearStruct();
  @Input() occupierPartHidden = false;
  @Input() changeDateHidden = false;
  @Input() suggestedAddresses: Address[] = [];
  @Input() customDatepickerErrors: Record<string, string> = {};
  @Input() careOfAllowed = false;

  override ngOnInit(): void {
    super.ngOnInit();
    this.listenOccupiesTheAddress();
    this.form.controls.occupiesTheAddress.setValue(this.form.controls.occupiesTheAddress.value);
    this.form.controls.occupierNameIfDoesntOccupy.patchValue(this.form.controls.occupierNameIfDoesntOccupy.value);
    this.form.controls.companyHaveOccupiersConsent.patchValue(true);
  }

  private listenOccupiesTheAddress(): void {
    this.form.controls.occupiesTheAddress.valueChanges
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((occupiesTheAddress) => {
        setControlDisabled(this.form.controls.occupierNameIfDoesntOccupy, !!occupiesTheAddress);
        setControlDisabled(this.form.controls.companyHaveOccupiersConsent, !!occupiesTheAddress);
      });
  }

  get occupierNameHidden(): boolean {
    return this.form.controls.occupiesTheAddress.value !== false;
  }

  static override defineForm(): AddressAndOccupierFormGroup {
    return new FormGroup<AddressAndOccupierFormGroupControls>(AddressAndOccupierFormGroupComponent.defineFormControls());
  }

  static override defineFormControls(): AddressAndOccupierFormGroupControls {
    return {
      occupiesTheAddress: new FormControl<boolean | null>(true, [Validators.required]),
      occupierNameIfDoesntOccupy: new FormControl<string | null>(null, [Validators.required]),
      companyHaveOccupiersConsent: new FormControl<boolean | null>(null, [CustomFormValidators.valueMatchValidator(true)]),
      changeDate: new FormControl<Date | null>(DatepickerHelper.getToday()),
      address: AddressFormGroupComponent.defineForm()
    };
  }
}
