export enum DocumentationType {
    Default,
    FormOnly,
    Minutes,
    Resolution
}

export enum DocumentSelectionType {
  Minutes,
  Resolution
}

export enum MeetingLocationType {
    Registered,
    Principal,
    Phone,
    Other,
    Online
}

export enum MeetingOnlineType {
    GoogleMeet,
    Zoom,
    MicrosoftTeams,
}

export enum MinutesMeetingPresence {
    PresentPhysically,
    PresentVirtually,
    PresentOverPhone,
    Apology
}
