import { Component, inject } from '@angular/core';
import { ICellRendererParams } from "ag-grid-community";
import { ICellRendererAngularComp } from "ag-grid-angular";
import { Company } from "../../../../../../models/company";
import { NgbDropdown, NgbDropdownItem, NgbDropdownMenu, NgbDropdownToggle } from "@ng-bootstrap/ng-bootstrap";
import { AgActionIconButtonComponent } from '../ag-action-icon-button/ag-action-icon-button.component';
import { HasRoleDirective } from '../../../../../../directives/has-role.directive';
import { CompaniesService } from '../../../../../../services/companies.service';

type ActionEvent = (data: unknown) => void;

type CellRendererParams = ICellRendererParams<Company> & {
  openCompanyProfile: ActionEvent,
  editPartnerAndAccountManager: ActionEvent,
  exportCompanyProfile: ActionEvent,
  managePins: ActionEvent,
  openAlerts: ActionEvent,
  onOpenNotes: ActionEvent,
};

@Component({
  selector: 'app-ag-company-actions',
  standalone: true,
  imports: [
    NgbDropdown,
    NgbDropdownItem,
    NgbDropdownMenu,
    NgbDropdownToggle,
    AgActionIconButtonComponent,
    HasRoleDirective
  ],
  templateUrl: './ag-company-actions.component.html',
  styleUrl: './ag-company-actions.component.scss'
})
export class AgCompanyActionsComponent implements ICellRendererAngularComp {
  protected params!: CellRendererParams;
  protected numberOfAlerts = 0;
  companiesService = inject(CompaniesService);

  agInit(params: CellRendererParams): void {
    this.params = params;
    this.numberOfAlerts = this.params.data?.alerts?.length ?? 0;
  }

  refresh(params: CellRendererParams): boolean {
    this.params = params;
    return true;
  }

  onOpenCompanyProfile(): void {
    this.params.openCompanyProfile(this.params.data);
  }

  editPartnerAndAccountManager(): void {
    this.params.editPartnerAndAccountManager(this.params.data);
  }

  onExportCompanyProfile(): void {
    this.companiesService.generateCompanyProfilePdf(this.params.data!.entityId!)
      .subscribe((blob: any) => {
        const blobUrl = window.URL.createObjectURL(blob);

        const link = document.createElement('a');
        link.href = blobUrl;
        //link.download = fileName;
        link.download = "Company Profile.pdf";

        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);

        window.URL.revokeObjectURL(blobUrl);
      });
  }

  onManagePins(): void {
    // this.params.managePins(this.params.data);
  }

  onOpenAlerts(): void {
    this.params.openAlerts(this.params.data);
  }

  onOpenNotes(): void {
    this.params.onOpenNotes(this.params.data);
  }
}
