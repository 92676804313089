<div class="card card-profile ">
  <div class="profile">
    <div class="header">
      <i class="ic-trusts ic-md icon"></i>
      <h3>{{ trustProfile.name }}</h3>
    </div>

    <app-divider></app-divider>

    <div class="info">
      <div class="info-row">
        <div class="info-item">
          <div class="icon-container">
            <span class="figure">15</span>
            <i class="icon ic-md ic-form-pending"></i>
          </div>
          <div class="text">Pending Forms</div>
        </div>
        <div class="info-item">
          <div class="icon-container">
            <span class="figure">100%</span>
            <i class="icon ic-md ic-notes"></i>
          </div>
          <div class="text">Readines</div>
        </div>
      </div>
    </div>

    <app-divider></app-divider>

    <div class="company-action-list">
      <app-xpm-card-profile
        [(externalEntities)]="trustProfile.externalEntities"
        [entityId]="trustProfile.entityId"
        [entityNumber]="trustProfile.entityNumber"
        [disableControls]="disableControls"
        (onEntityRefreshed)="onTrustRefreshedEvent()"
        [entityType]="entityType"
      ></app-xpm-card-profile>
    </div>

  </div>
</div>
