export class EdgeConnectionRecord {
    public connectionAttemptId: string;
    public createdOn: Date;
    public organisationId: string;
    public responseCode: string | null;
    public secondaryMailbox: boolean;
    public communicationFinished: boolean;
    
    constructor(documentRecord: Partial<EdgeConnectionRecord> = {}) {
        this.connectionAttemptId = documentRecord.connectionAttemptId ?? '';
        this.createdOn = documentRecord.createdOn ? new Date(documentRecord.createdOn) : new Date();
        this.organisationId = documentRecord.organisationId ?? '';
        this.responseCode = documentRecord.responseCode ?? null;
        this.secondaryMailbox = documentRecord.secondaryMailbox ?? false;
        this.communicationFinished = documentRecord.communicationFinished ?? false;
    }
}