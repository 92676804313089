<div class="generate-documents-form-container">
  <div class="title-container">
    <h3>Generate Documents</h3>
  </div>

  @if(!loading) {
    <div class="form-body-container" [formGroup]="form">
      @if(documentTypeOptions.length) {
        <div class="document-type">
          <app-radio
            [label]="'Choose the type of document to generate'"
            [options]="documentTypeOptions"
            [(ngModel)]="formModel.documentationType"
            (ngModelChange)="onDocumentationTypeChange()"
            (select)="onDocumentTypeSelectionChange()"
            [ngModelOptions]="{standalone: true}"
          ></app-radio>
        </div>
      }

      @if(formModel.documentationType === DocumentationTypeEnum.Minutes) {
        <div class="d-flex gap-24">
          <div class="meeting-date">
            <app-date-picker
              [label]="'Date Meeting was Held'"
              [minDate]="minDateOfMeeting"
              [maxDate]="maxDateOfMeeting"
              [customErrors]="customDatepickerErrors"
              [(ngModel)]="formModel.meetingDate"
              (ngModelChange)="onDisableHeaderBtn()"
              [ngModelOptions]="{standalone: true}"
            ></app-date-picker>
          </div>

          <div class="meeting-type">
            <app-select
              [label]="'Type'"
              [placeholder]="'Select type'"
              [valuePrimitive]="true"
              [options]="meetingTypeOptions"
              [(ngModel)]="formModel.meetingAddressType"
              [ngModelOptions]="{standalone: true}"
              (ngModelChange)="onMeetingAddressTypeSelect($event)"
            ></app-select>
          </div>
        </div>

        @if(formModel.meetingAddressType === MeetingLocationTypeEnum.Online) {
          <div class="meeting-address">
            <app-select
              [label]="'Communication Method'"
              [placeholder]="'Select Communication Method'"
              [valuePrimitive]="true"
              [options]="meetingOnlineTypeOptions"
              [(ngModel)]="formModel.meetingOnlineAddress"
              (ngModelChange)="onDisableHeaderBtn()"
              [ngModelOptions]="{standalone: true}"
            ></app-select>
          </div>
        }

        @if (formModel.meetingAddressType !== undefined && formModel.meetingAddressType !== MeetingLocationTypeEnum.Online && formModel.meetingAddressType !== MeetingLocationTypeEnum.Phone) {
          <div class="address" [formGroup]="form">
            <app-address-control
              [label]="MeetingLocationTypeEnum[formModel.meetingAddressType] + ' Address'"
              [expandedAddressForm]="expandedAddressForm"
              [addressStatus]="addressStatus"
              [halfWidth]="false"
              [isDisabled]="formModel.meetingAddressType !== MeetingLocationTypeEnum.Other"
              (onDataStatusChange)="onDataStatusChange($event)"
            ></app-address-control>
          </div>
        }

        @if(getOfficers(false, false).length) {
          <div class="attendees">
            <app-list-grid
              [rowData]="attendeesList"
              [colDefs]="$any(colDefs)"
              (gridReady)="onGridReady($event)"
              [suppressRowClickSelection]="true"
              [suppressCellFocus]="true"
              [pagination]="false"
              [paginationPageSizeSelector]="false"
            ></app-list-grid>
          </div>

          @if (formModel.chairperson) {
            <div class="chair">
              <app-select
                [label]="'Chairperson'"
                [placeholder]="'Select Chairperson'"
                [valuePrimitive]="true"
                [options]="chairOptions"
                [(ngModel)]="formModel.chairperson.relationshipId"
                [ngModelOptions]="{standalone: true}"
                (ngModelChange)="onChairPersonSelect($event)"
              ></app-select>
            </div>
          }
        }

        @if (documentTypeIs362 || (document.entityId === null && documentTypeIs484)) {
          <app-multiple-name-input
            formControlName="directorNameList"
            [label]="'Name of the Directors who attended the meeting'"
            [tip]="'Separate names of directors with semicolon'"
            [customErrors]="{
            startsWithDigit: 'The name must start with a letter',
            oneLetterPartName: 'The name must have more than one letter'
          }"
          ></app-multiple-name-input>
        }
      }

      <div class="d-flex gap-24">
        @if(!(documentTypeIs361 || isRaForm || documentTypeIs370 || (document.entityId === null && documentTypeIs362 ))) {
          <div class="authorising-officeholder">
            <app-select
              [label]="'Authorising Officeholder'"
              [placeholder]="'Select auth officeholder'"
              [valuePrimitive]="true"
              [options]="officerOptions"
              [(ngModel)]="formModel.authorisingParty.relationshipId"
              (ngModelChange)="onDisableHeaderBtn()"
              [ngModelOptions]="{standalone: true}"
            ></app-select>
          </div>
        }

        @if(!company.officers.length && documentTypeIs362) {
          <div class="authorising-officeholder">
            <app-input
              formControlName="directorName"
              [label]="'Authorising Officeholder'"
              [placeholder]="'Enter authorising officeholder'"
              [customErrors]="directorFullNameValidatorMsg"
            ></app-input>
          </div>
        }

        <div class="signing-manager">
          <app-select
            [label]="'Contact Name'"
            [placeholder]="'Select contact name'"
            [valuePrimitive]="true"
            [options]="contactNameOptions"
            [(ngModel)]="formModel.signingManager"
            (ngModelChange)="onDisableHeaderBtn()"
            [ngModelOptions]="{standalone: true}"
          ></app-select>
        </div>
      </div>

      @if(!company.officers.length && documentTypeIs362) {
        <div class="d-flex gap-24">
          <div class="phone">
            <app-input-phone-number
              formControlName="phone"
              [label]="'Authorising Officeholder Phone Number'"
              [placeholder]="'Enter phone number'"
            ></app-input-phone-number>
          </div>

          <div class="email">
            <app-input
              formControlName="email"
              [label]="'Authorising Officeholder Email Address'"
              [placeholder]="'Enter email address'"
              [customErrors]="emailValidatorMsg"
            ></app-input>
          </div>
        </div>
      }

      @if (directorOptions.length && formModel.directorReplacedByAlternative) {
        <div class="director">
          <app-select
            [label]="'Director for alternative'"
            [placeholder]="'Select director'"
            [valuePrimitive]="true"
            [options]="directorOptions"
            [(ngModel)]="formModel.directorReplacedByAlternative.relationshipId"
            (ngModelChange)="onDisableHeaderBtn()"
            [ngModelOptions]="{standalone: true}"
          ></app-select>
        </div>
      }

      @if(documentTypeIs361 || isRaForm) {
        <div class="declaration">
          <app-checkbox
            [label]="'I declare the agent is authorised to give notice'"
            [boldLabel]="true"
            [(ngModel)]="authAgentDeclaration"
            (ngModelChange)="onDisableHeaderBtn()"
            [ngModelOptions]="{standalone: true}"
          ></app-checkbox>
        </div>
      }

      @if(documentSelection && documentSelection.length > 1) {
        <app-document-selection
          [title]="'Choose Documents to Generate'"
          [documentSelectionList]="documentSelection"
          [documentationType]="formModel.documentationType"
          (documentSelectionChange)="onDocumentSelectionChange($event)"
        ></app-document-selection>
      }

      <div class="declaration">
        <app-checkbox
          [label]="'I declare that the information provided is true and correct'"
          [boldLabel]="true"
          [(ngModel)]="declaration"
          (ngModelChange)="onDisableHeaderBtn()"
          (change)="declarationSelect($event)"
          [ngModelOptions]="{standalone: true}"
        ></app-checkbox>
      </div>
    </div>
  } @else {
    <div class="skeleton-container">
      <ngx-skeleton-loader count="2" appearance="line" />
      <div class="d-flex gap-24">
        <ngx-skeleton-loader style="width: 50%" count="1" appearance="line" />
        <ngx-skeleton-loader style="width: 50%" count="1" appearance="line" />
      </div>
      <ngx-skeleton-loader count="2" appearance="line" />
      <div class="d-flex gap-24">
        <ngx-skeleton-loader style="width: 50%" count="1" appearance="line" />
        <ngx-skeleton-loader style="width: 50%" count="1" appearance="line" />
      </div>
      <ngx-skeleton-loader count="2" appearance="line" />
    </div>
  }
</div>
