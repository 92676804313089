import { Injectable } from '@angular/core';
import { environment } from "../environments/environment";
import { HttpClient, HttpParams } from "@angular/common/http";
import { Observable } from "rxjs";
import { FileCreateDto, FilesResponse } from "../models/files";

@Injectable({
  providedIn: 'root'
})
export class FilesService {

  constructor(private readonly http: HttpClient) { }

  getFiles(documentId: string): Observable<FilesResponse> {
    return this.http.get<FilesResponse>(`${environment.api_url}/files/${documentId}`);
  }

  downloadFile(documentId: string, fileName: string, subFolderName: string): Observable<string> {
    const params = new HttpParams().set('fileName', fileName);
    return this.http.get<string>(`${environment.api_url}/files/${documentId}/download/${subFolderName}`, { params });
  }

  downloadFolder(documentId: string, asOnePdfFile: boolean, subFolderName: string): Observable<string> {
    const params = new HttpParams().set('asOnePdfFile', asOnePdfFile);
    return this.http.get<string>(`${environment.api_url}/files/${documentId}/download-folder/${subFolderName}`, { params });
  }


  downloadDocumentBulk(documentsId: string[]): Observable<string> {
    return this.http.post<string>(`${environment.api_url}/files/download/bulk`, documentsId);
  }

  uploadFile(documentId: string, body: FileCreateDto, subFolderName: string) {
    return this.http.post(`${environment.api_url}/files/${documentId}/upload/${subFolderName}`, body)
  }

  deleteFile(documentId: string, fileName: string): Observable<unknown> {
    const params = new HttpParams().set('fileName', fileName);
    return this.http.delete<void>(`${ environment.api_url }/files/${documentId}`, { params });
  }
}
