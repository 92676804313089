import { INavMenuItem } from './nav-menu-item.interface';

export const menuItemsList: INavMenuItem[] = [
  {
    routerLink: ['companies'],
    icon: 'ic-companies',
    title: 'Companies'
  },
  {
    routerLink: ['trusts'],
    icon: 'ic-trusts',
    title: 'Trusts'
  },
  {
    routerLink: ['individuals'],
    icon: 'ic-individuals',
    title: 'Individuals'
  },
  {
    routerLink: ['documents'],
    icon: 'ic-documents',
    title: 'Documents'
  },
  {
    routerLink: ['alerts'],
    icon: 'ic-alert-triangle',
    title: 'Alerts',
    count: 0,
    nestedItems: [
      {
        routerLink: ['alerts/inbox'],
        icon: '',
        title: 'Inbox',
        count: 0,
      },
      {
        routerLink: ['alerts/all'],
        icon: '',
        title: 'All',
        count: 0,
      },
      {
        routerLink: ['alerts/required'],
        icon: '',
        title: 'Require Attention',
        count: 0,
      },
      {
        routerLink: ['alerts/postponed'],
        icon: '',
        title: 'Postponed',
        count: 0
      },
    ]
  },
  {
    routerLink: ['outgoing-messages'],
    icon: 'ic-outgoing-emails',
    title: 'Outgoing Messages',
    nestedItems: [
      {
        title: 'Emails',
        routerLink: ['outgoing-messages', 'email'],
      },
      {
        title: 'SMS',
        routerLink: ['outgoing-messages', 'sms'],
      },
    ]
  },
  {
    routerLink: ['annual'],
    icon: 'ic-annual-statement-icon',
    title: 'Annual Statements',
    count: 0,
    nestedItems: [
      {
        routerLink: ['annual/ready-to-send'],
        icon: '',
        title: 'Ready to Send',
        count: 0,
      },
      {
        routerLink: ['annual/not-received'],
        icon: '',
        title: 'Not Received',
        count: 0,
      },
      {
        routerLink: ['annual/expected'],
        icon: '',
        title: 'Expected',
        count: 0,
      },
      {
        routerLink: ['annual/sent'],
        icon: '',
        title: 'Sent',
        count: 0
      },
      {
        routerLink: ['annual/payment-due'],
        icon: '',
        title: 'Payment Due',
        count: 0
      },
      {
        routerLink: ['annual/overdue'],
        icon: '',
        title: 'Overdue',
        count: 0
      },
      {
        routerLink: ['annual/archived'],
        icon: '',
        title: 'Archived',
        count: 0
      },
      {
        routerLink: ['annual/all'],
        icon: '',
        title: 'All',
        count: 0
      },
    ]
  },
  {
    routerLink: ['bulk-changes'],
    icon: 'ic-bulk-changes',
    title: 'Bulk Changes',
  },
  {
    routerLink: ['settings'],
    icon: 'ic-settings',
    title: 'Settings',
  }
];

export const sauperUserMenuItems: INavMenuItem[] = [
  {
    routerLink: ['superuser/me'],
    icon: 'fa-address-card fad',
    title: 'Me'
  },
  {
    routerLink: ['superuser/edge-transactions'],
    icon: 'ic-inbox',
    title: 'Edge Transactions'
  }
];
