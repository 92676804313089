import { ChangeDetectorRef, Component, DestroyRef, inject, Input, OnInit } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { setControlDisabled } from '../../../../../functions/set-control-disabled';
import { EntityData } from '../../../../../models/entityData';
import {
  CorporateHolderModel,
  IndividualHolderModel,
  SecurityRegistryRecord
} from '../../../../../models/securityRegistryRecord';
import { acnValidator } from '../../../../../validators/acn.validator';
import { companyNameValidator } from '../../../../../validators/compny-name.validator';
import { CheckboxComponent } from '../../../../components/common/checkbox/checkbox.component';
import { InputComponent } from '../../../../components/common/input/input.component';
import { SelectGroupsComponent } from '../../../../components/common/select-groups/select-groups.component';
import { YesNoControlComponent } from '../../../../components/common/yes-no-control-component/yes-no-control.component';
import {
  SelectShareholderComponent
} from '../../../../components/shares/select-shareholder/select-shareholder.component';
import { ShareholderTypesEnum, shareholderTypesOptionGroups } from '../../share-issue/share-issue.model';
import {
  ShareJointSecurityHolderComponent
} from '../share-joint-security-holder/share-joint-security-holder.component';
import {
  IndividualDataFormGroup,
  IndividualDataFormGroupComponent, IndividualDataFormGroupControls
} from "../../../../components/reusable-form-groups/individual-data-form-group/individual-data-form-group.component";
import {
  CompanyNameAcnFormGroupComponent
} from "../../../../components/reusable-form-groups/company-name-acn-form-group/company-name-acn-form-group.component";
import {
  AddressFormGroupComponent,
  AddressFormGroup,
  AddressFormGroupControls
} from "../../../../components/reusable-form-groups/address-form-group/address-form-group.component";

export interface SelectShareholderFormControls {
  shareholderType: FormControl<ShareholderTypesEnum | null>;

  securityRegistryRecordIndex: FormControl<number | null>;
  isNotBeneficialOwner: FormControl<boolean | null>;
  beneficialOwner: FormControl<string | null>;

  newIndividualShareholder: IndividualDataFormGroup;

  name: FormControl<string | null>;
  acn: FormControl<string | null>;
  isOverseasCompany: FormControl<boolean>;
  registeredAddress: AddressFormGroup;

  joint: FormControl<(IndividualHolderModel | CorporateHolderModel)[] | null>;
}

@Component({
  selector: 'app-select-shareholder-form',
  standalone: true,
  imports: [
    ReactiveFormsModule,
    InputComponent,
    CheckboxComponent,
    YesNoControlComponent,
    SelectGroupsComponent,
    SelectShareholderComponent,
    IndividualDataFormGroupComponent,
    CompanyNameAcnFormGroupComponent,
    AddressFormGroupComponent,
    ShareJointSecurityHolderComponent,
  ],
  templateUrl: './select-shareholder-form.component.html',
  styleUrl: './select-shareholder-form.component.scss'
})
export class SelectShareholderFormComponent implements OnInit {
  destroyRef = inject(DestroyRef);
  cdr = inject(ChangeDetectorRef);

  @Input({ required: true }) formGroup!: FormGroup<SelectShareholderFormControls>;
  @Input() securityRegistryRecords: SecurityRegistryRecord[] = [];
  @Input() selectedIndividualShareholder: IndividualHolderModel | null = null;
  @Input() selectedCompanyShareholder: EntityData | null = null;

  readonly shareholderTypesOptionGroups = shareholderTypesOptionGroups;
  readonly ShareholderTypesEnum = ShareholderTypesEnum;
  readonly hiddenIndividualControls: (keyof IndividualDataFormGroupControls)[] = ['formerName', 'dob', 'birthCity', 'birthCountry'];
  readonly individualDataFormLabels: Record<keyof Partial<IndividualDataFormGroupControls>, string> = { fullName: 'Individual Name' } as Record<keyof Partial<IndividualDataFormGroupControls>, string>;
  readonly addressFormLabels: Record<keyof Partial<AddressFormGroupControls>, string> = { normalizedFullAddress: 'Registered address' } as Record<keyof Partial<AddressFormGroupControls>, string>;

  ngOnInit(): void {
    this.listenShareholderTypeChanges();
    this.listenIsSharesOwnedOnBehalfChanges();
    this.listenIsOverseasCompanyChange();
  }

  private listenShareholderTypeChanges(): void {
    this.formGroup.controls.shareholderType.valueChanges
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((shareholderType) => {
       if (shareholderType === null) {
          return;
        }

        // set state of the existing security holder
        setControlDisabled(this.formGroup.controls.securityRegistryRecordIndex, shareholderType !== ShareholderTypesEnum.ExistingShareholder);
        setControlDisabled(this.formGroup.controls.isNotBeneficialOwner, shareholderType === null || shareholderType === ShareholderTypesEnum.ExistingShareholder);
        setControlDisabled(this.formGroup.controls.isNotBeneficialOwner, shareholderType === null || shareholderType === ShareholderTypesEnum.ExistingShareholder);
        setControlDisabled(this.formGroup.controls.beneficialOwner, shareholderType === null || shareholderType === ShareholderTypesEnum.ExistingShareholder);

        // set state of the new Individual Shareholder control
        setControlDisabled(this.formGroup.controls.newIndividualShareholder, shareholderType !== ShareholderTypesEnum.Individual);
        this.hiddenIndividualControls.forEach(key => setControlDisabled(this.formGroup.controls.newIndividualShareholder.controls[key]));

        // set state of the new Corporate Shareholder control
        setControlDisabled(this.formGroup.controls.acn, shareholderType !== ShareholderTypesEnum.Corporate);
        setControlDisabled(this.formGroup.controls.name, shareholderType !== ShareholderTypesEnum.Corporate);
        setControlDisabled(this.formGroup.controls.isOverseasCompany, shareholderType !== ShareholderTypesEnum.Corporate);
        setControlDisabled(this.formGroup.controls.registeredAddress, shareholderType !== ShareholderTypesEnum.Corporate);

        // set state of the new Joint Shareholder control
        setControlDisabled(this.formGroup.controls.joint, shareholderType !== ShareholderTypesEnum.JointShareholder);

        if (shareholderType !== ShareholderTypesEnum.ExistingShareholder) {
          this.formGroup.controls.isNotBeneficialOwner.patchValue(this.formGroup.controls.isNotBeneficialOwner.value ?? null);
        }

        this.cdr.detectChanges();
      });
  }

  private listenIsSharesOwnedOnBehalfChanges(): void {
    this.formGroup.controls.isNotBeneficialOwner.valueChanges
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((isSharesOwnedOnBehalf) => {
        setControlDisabled(this.formGroup.controls.beneficialOwner, !isSharesOwnedOnBehalf);
      });
  }

  private listenIsOverseasCompanyChange(): void {
    this.formGroup.controls.isOverseasCompany.valueChanges
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((isOverseasCompany) => {
        if (isOverseasCompany) {
          this.formGroup.controls.acn.clearValidators();
          this.formGroup.controls.name.setValidators([Validators.required, Validators.minLength(7)]);
        } else {
          this.formGroup.controls.acn.setValidators([Validators.required, acnValidator()]);
          this.formGroup.controls.name.setValidators([Validators.required, Validators.minLength(7), companyNameValidator()]);
        }

        this.cdr.detectChanges();
        this.formGroup.updateValueAndValidity();
        this.formGroup.controls.acn.updateValueAndValidity();
        this.formGroup.controls.acn.patchValue(this.formGroup.value.acn ?? '');
      });
  }

  static defineForm(): FormGroup<SelectShareholderFormControls> {
    return new FormGroup({
      shareholderType: new FormControl<ShareholderTypesEnum | null>(null, [Validators.required]),

      securityRegistryRecordIndex: new FormControl<number | null>(null, [Validators.required]),
      isNotBeneficialOwner: new FormControl<boolean | null>(null, [Validators.required]),
      beneficialOwner: new FormControl<string | null>(null, [Validators.required]),

      newIndividualShareholder: IndividualDataFormGroupComponent.defineForm(),

      name: new FormControl<string | null>(null, [Validators.required]),
      acn: new FormControl<string | null>(null, [Validators.required]),
      isOverseasCompany: new FormControl<boolean>(false, [Validators.required]),
      registeredAddress: AddressFormGroupComponent.defineForm(),

      joint: new FormControl<(IndividualHolderModel | CorporateHolderModel)[] | null>(null, [Validators.required])
    }) as FormGroup<SelectShareholderFormControls>;
  }
}
