import { Component, DestroyRef, inject, OnInit, signal } from '@angular/core';
import { GridComponent } from "../components/common/grid/components/grid/grid.component";
import { GetRowIdFunc, GetRowIdParams, GridApi, IServerSideDatasource, IServerSideGetRowsParams, ValueFormatterParams } from 'ag-grid-enterprise';
import { SuperuserService } from '../../services/superuser.service';
import { EdgeTransactionDirection, EdgeTransactionRecord, EdgeTransactionStatus } from '../../models/edgeTransactionRecord';
import { CellDataType } from '../../models/enums/agGridEnums';
import { formatDate } from '@angular/common';
import { MenuService } from '../../services/menu.service';
import { convertEnumToFilterOption } from '../helpers/enum-to-filter-option.helper';
import { dateComparator } from '../components/common/grid/functions/date-comparator';
import { EdgeConnectionRecord } from '../../models/edgeConnectionRecord';
import { EdgeTransactionDetailsComponent } from './edge-transaction-details/edge-transaction-details.component';
import { CellClickedEvent } from 'ag-grid-community';
import { ButtonComponent } from "../components/common/button/button.component";
import { ToastrService } from 'ngx-toastr';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { PublishOrganisationSignalComponent } from './publish-organisation-signal-modal/publish-organisation-signal-modal.component';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Component({
  selector: 'app-bulk-changes',
  standalone: true,
  imports: [
    GridComponent,
    EdgeTransactionDetailsComponent,
    ButtonComponent
  ],
  templateUrl: './edge-transactions.component.html',
  styleUrl: './edge-transactions.component.scss'
})
export class EdgeTransactionsComponent implements OnInit {
  private superuserService = inject(SuperuserService);
  private menuService = inject(MenuService);
  #toast = inject(ToastrService);
  modalService = inject(NgbModal);
  destroyRef = inject(DestroyRef);

  loading = true;

  organisations!: Map<string, string>;

  getRowId: GetRowIdFunc = (params: GetRowIdParams<EdgeTransactionRecord>) => params.data.transactionId;
  connectionsGetRowId: GetRowIdFunc = (params: GetRowIdParams<EdgeConnectionRecord>) => params.data.connectionAttemptId;

  previewOpened = false;

  $selectedTransactionRecord = signal<EdgeTransactionRecord | null>(null);

  colDefs;
  connectionsColDefs;

  setColDefs() {
    this.colDefs = [
      {
        headerName: 'Created On',
        field: 'createdOn',
        width: 200,
        cellDataType: CellDataType.DATE,
        valueFormatter: (params: ValueFormatterParams<EdgeTransactionRecord, Date>) => {
          return params.value ? formatDate(params.value, 'dd/MM/yyyy HH:mm:ss.SSS', 'en-US') : null;
        },
        sort: "desc",
        filter: "agDateColumnFilter",
        filterParams: {
          comparator: dateComparator,
        },
        menuTabs: [],
        onCellClicked: this.cellClickHandler.bind(this)
      },
      {
        headerName: 'Transmitted On',
        field: 'transmittedOn',
        width: 200,
        cellDataType: CellDataType.DATE,
        valueFormatter: (params: ValueFormatterParams<EdgeTransactionRecord, Date>) => {
          return params.value ? formatDate(params.value, 'dd/MM/yyyy HH:mm:ss.SSS', 'en-US') : null;
        },
        filter: "agDateColumnFilter",
        filterParams: {
          comparator: dateComparator,
        },
        menuTabs: [],
        onCellClicked: this.cellClickHandler.bind(this)
      },
      {
        headerName: 'Form Code',
        field: 'formCode',
        flex: 1,
        filter: "agTextColumnFilter",
        cellDataType: CellDataType.TEXT,
        menuTabs: [],
        filterParams: {},
        onCellClicked: this.cellClickHandler.bind(this)
      },
      {
        headerName: 'Direction',
        field: 'direction',
        width: 100,
        valueFormatter: (params: ValueFormatterParams<EdgeTransactionRecord, EdgeTransactionDirection>) => {
          return params.value == EdgeTransactionDirection.Inbound ? 'Inbound' : 'Outbound';
        },
        filter: "agSetColumnFilter",
        filterParams: {
          filterOptions: convertEnumToFilterOption(EdgeTransactionDirection),
          values: Object.values(EdgeTransactionDirection).filter((v) => !isNaN(Number(v))),
        },
        menuTabs: [],
        onCellClicked: this.cellClickHandler.bind(this)
      },
      {
        headerName: 'Status',
        field: 'status',
        flex: 1,
        valueFormatter: (params: ValueFormatterParams<EdgeTransactionRecord, EdgeTransactionStatus>) => {
          return EdgeTransactionStatus[params.value!];
        },
        filter: "agSetColumnFilter",
        filterParams: {
          filterOptions: convertEnumToFilterOption(EdgeTransactionStatus),
          values: Object.values(EdgeTransactionStatus).filter((v) => !isNaN(Number(v))),
        },
        menuTabs: [],
        onCellClicked: this.cellClickHandler.bind(this)
      },
      {
        headerName: 'Organisation',
        field: 'organisationId',
        flex: 1,
        valueFormatter: (params: ValueFormatterParams<EdgeTransactionRecord, string>) => {
          return this.organisations.get(params.value!);
        },
        filter: "agSetColumnFilter",
        filterParams: {
          filterOptions: Array.from(this.organisations, ([key, value]) => ({ label: value, value: key, active: false })).sort((a, b) => a.label.localeCompare(b.label)),
          values: Array.from(this.organisations.keys()),
        },
        menuTabs: [],
        onCellClicked: this.cellClickHandler.bind(this)
      },
      {
        headerName: 'Trace Number',
        field: 'traceNumber',
        width: 160,
        filter: "agTextColumnFilter",
        cellDataType: CellDataType.TEXT,
        menuTabs: [],
        filterParams: {},
        onCellClicked: this.cellClickHandler.bind(this)
      },
      {
        headerName: 'Mailbox',
        field: 'useSecondary',
        width: 110,
        valueFormatter: (params: ValueFormatterParams<EdgeTransactionRecord, boolean>) => {
          return params.value ? 'Secondary' : 'Primary';
        },
        filter: "agSetColumnFilter",
        filterParams: {
          filterOptions: [{ label: 'Primary', value: 'false', active: false }, { label: 'Secondary', value: 'true', active: false }],
          values: ['true', 'false'],
        },
        menuTabs: [],
        onCellClicked: this.cellClickHandler.bind(this)
      },
      {
        headerName: 'Documents',
        field: 'documentIdsString',
        flex: 1,
        filter: "agTextColumnFilter",
        cellDataType: CellDataType.TEXT,
        menuTabs: [],
        filterParams: {},
        onCellClicked: this.cellClickHandler.bind(this),
        hide: true
      },
      {
        headerName: 'Transaction Text',
        field: 'transactionText',
        flex: 1,
        filter: "agTextColumnFilter",
        cellDataType: CellDataType.TEXT,
        menuTabs: [],
        filterParams: {},
        onCellClicked: this.cellClickHandler.bind(this),
        hide: true
      }
    ];


    this.connectionsColDefs = [
      {
        headerName: 'Created On',
        field: 'createdOn',
        width: 200,
        cellDataType: CellDataType.DATE,
        valueFormatter: (params: ValueFormatterParams<EdgeConnectionRecord, Date>) => {
          return params.value ? formatDate(params.value, 'dd/MM/yyyy HH:mm:ss.SSS', 'en-US') : null;
        },
        sort: "desc",
        filter: "agDateColumnFilter",
        filterParams: {
          comparator: dateComparator,
        },
        menuTabs: []
      },
      {
        headerName: 'Organisation',
        field: 'organisationId',
        flex: 1,
        valueFormatter: (params: ValueFormatterParams<EdgeConnectionRecord, string>) => {
          return this.organisations.get(params.value!);
        },
        filter: "agSetColumnFilter",
        filterParams: {
          filterOptions: Array.from(this.organisations, ([key, value]) => ({ label: value, value: key, active: false })).sort((a, b) => a.label.localeCompare(b.label)),
          values: Array.from(this.organisations.keys()),
        },
        menuTabs: []
      },
      {
        headerName: 'Response Code',
        field: 'responseCode',
        width: 80,
        filter: "agTextColumnFilter",
        cellDataType: CellDataType.TEXT,
        menuTabs: [],
        filterParams: {}
      },
      {
        headerName: 'Mailbox',
        field: 'secondaryMailbox',
        width: 85,
        valueFormatter: (params: ValueFormatterParams<EdgeConnectionRecord, boolean>) => {
          return params.value ? 'Secondary' : 'Primary';
        },
        filter: "agSetColumnFilter",
        filterParams: {
          filterOptions: [{ label: 'Primary', value: 'false', active: false }, { label: 'Secondary', value: 'true', active: false }],
          values: ['true', 'false'],
        },
        menuTabs: []
      },
      {
        headerName: 'Finished',
        field: 'communicationFinished',
        width: 80,
        valueFormatter: (params: ValueFormatterParams<EdgeConnectionRecord, boolean>) => {
          return params.value ? 'Yes' : 'No';
        },
        filter: "agSetColumnFilter",
        filterParams: {
          filterOptions: [{ label: 'Yes', value: 'true', active: false }, { label: 'No', value: 'false', active: false }],
          values: ['true', 'false'],
        },
        menuTabs: []
      }
    ];
  }

  ngOnInit() {
    this.menuService.setMenuState(true);
    this.superuserService.listOrganisationNames().subscribe({
      next: (result) => {
        this.organisations = result;
        this.setColDefs();
        this.loading = false;
      },
      error: (err) => {
        console.error(err);
      }
    });
  }

  dataSource: IServerSideDatasource = {
    getRows: (params: IServerSideGetRowsParams) => {
      const request = params.request;
      this.superuserService.getEdgeTransactions(request).subscribe({
        next: (result) => {
          params.success({
            rowData: result.records,
            rowCount: result.total,
          });
        },
        error: (err) => {
          console.error(err);
          params.fail();
        },
      });
    },
  };

  connectionsDataSource: IServerSideDatasource = {
    getRows: (params: IServerSideGetRowsParams) => {
      const request = params.request;
      this.superuserService.getEdgeConnections(request).subscribe({
        next: (result) => {
          params.success({
            rowData: result.records,
            rowCount: result.total,
          });
        },
        error: (err) => {
          console.error(err);
          params.fail();
        },
      });
    },
  };

  gridApi!: GridApi;
  connectionsGridApi!: GridApi;

  //sync CreatedOn/OrgId filters
  //working but confusing
  onGridReady(gridApi: GridApi) {
    this.gridApi = gridApi;
    // gridApi.addEventListener('filterChanged', (event : FilterChangedEvent) => {
    //   const allFilters = event.api.getFilterModel();
    //   const filterState = {
    //       // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    //       createdOn: allFilters['createdOn'],
    //       // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    //       organisationId: allFilters['organisationId']
    //     };

    //   this.connectionsGridApi.setFilterModel(filterState);
    // });
  }

  onConnectionsGridReady(gridApi: GridApi) {
    this.connectionsGridApi = gridApi;
  }

  cellClickHandler(params: CellClickedEvent<EdgeTransactionRecord>) {
    if (params.data) {
      this.$selectedTransactionRecord.set(params.data);
    }
  }

  onCloseDetails(): void {
    this.$selectedTransactionRecord.set(null);
  }

  refreshTransactionsGrid() {
    this.gridApi.refreshServerSide();
    this.#toast.info('Transactions refreshed');
  }

  refreshConnectionsGrid() {
    this.connectionsGridApi.refreshServerSide();
    this.#toast.info('Connections refreshed');
  }

  refreshAll() {
    this.refreshTransactionsGrid();
    this.refreshConnectionsGrid();
  }

  triggerOrganisationSignal() {
    
    const modalRef = this.modalService.open(PublishOrganisationSignalComponent, { size: 'sm' });
    const instance = (modalRef.componentInstance as PublishOrganisationSignalComponent);
    instance.organisations = Array.from(this.organisations, ([key, value]) => ({ label: value, value: key, disabled: false }));
    

    instance.confirm.pipe(
      takeUntilDestroyed(this.destroyRef)
    ).subscribe();
  }
}
