import { Address } from "./address";
import { CompanyDebt } from "./companyDebt";
import { CompanyUhcDetails } from "./companyUhcDetails";
import { Entity } from "./entity";
import { CompanyStatus, CompanyType, CompanySubtype } from "./enums/companyEnums";
import { SecurityStructure } from './enums/securityStructure';
import { Relationship } from "./relationship";

export class Company extends Entity{
    acn: string;
    status: CompanyStatus | undefined;
    specialPurposeStatus: CompanySubtype | undefined;
    companyType: CompanyType | undefined;
    companySubtype?: CompanySubtype | undefined;
    ultimateHoldingCompany?: CompanyUhcDetails;
    nextAnnualReviewDate: string | Date;
    officers: Relationship[];
    companyDebt?: CompanyDebt;
    corporateKey: string;
    principalAddress: Address;
    isPublic: boolean;
    shareClasses: SecurityStructure[];
    notes: any[] = [];
    refreshedOn: Date;

    constructor(data: Partial<Company> = {}) {
        super(data);
        this.notes = data.notes ?? [];
        this.registeredAddress = data.registeredAddress ? new Address(data.registeredAddress) : new Address();
        this.acn = data.acn ?? '';
        this.principalAddress = data.principalAddress ? new Address(data.principalAddress) : new Address();
        this.status = data.status;
        this.specialPurposeStatus = data.specialPurposeStatus;
        this.companyType = data.companyType;
        this.companySubtype = data.companySubtype;
        this.ultimateHoldingCompany = data.ultimateHoldingCompany ? new CompanyUhcDetails(data.ultimateHoldingCompany) : data.ultimateHoldingCompany;
        this.nextAnnualReviewDate = data.nextAnnualReviewDate ?? '';
        this.officers = data.officers?.length ? data.officers.map((officer) => new Relationship(officer)) : [];
        this.companyDebt = data.companyDebt ? new CompanyDebt(data.companyDebt) : new CompanyDebt();
        this.corporateKey = data.corporateKey ?? '';
        this.isPublic = data.isPublic ?? false;
        this.shareClasses = data.shareClasses ?? [];
        this.refreshedOn = data.refreshedOn ? new Date(data.refreshedOn) : new Date();
    }
}
