import {
  Component,
  DestroyRef,
  EventEmitter,
  inject,
  Input,
  OnChanges,
  Output,
  SimpleChanges
} from '@angular/core';
import { Alert } from "../../../models/alerts/alert";
import { ButtonComponent } from "../../components/common/button/button.component";
import { AsyncPipe, DatePipe, NgClass, SlicePipe } from "@angular/common";
import { ActionType, AlertStatus, AlertType, ResolvedStatus } from "../../../models/enums/alertEnums";
import { AlertsStatusPipe } from "../../../pipes/enumsPipes/alertsStatusPipe";
import { SplitByCapitalLetterPipePipe } from "../../../pipes/split-by-capital-letter-pipe.pipe";
import { NgbDropdown, NgbDropdownMenu, NgbDropdownToggle, NgbTooltip } from "@ng-bootstrap/ng-bootstrap";
import { AlertsService } from "../../../services/alert.service";
import { catchError, EMPTY, finalize, forkJoin, Observable, of, switchMap, tap } from "rxjs";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";
import { CompanyNameLinkComponent } from "../../components/common/company-name-link/company-name-link.component";
import { Company } from "../../../models/company";
import {
  AgTagEntityTitleComponent
} from "../../components/common/grid/components/ag-tag-entity-title/ag-tag-entity-title.component";
import { EntityType } from "../../../models/enums/entityType";
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { observe, Observer } from "fast-json-patch";
import { ITagsEntity } from "../../settings/components/tags/manage-tags-modal/manage-tags-modal.component";
import { InsertLineBreakPipe } from "../../../pipes/insert-line-break.pipe";
import { AlertRecord } from '../../../models/alerts/alertRecord';
import { HasRoleDirective } from '../../../directives/has-role.directive';
import { FileMetadata } from '../../../models/files';

@Component({
  selector: 'app-alert-details',
  standalone: true,
  imports: [
    ButtonComponent,
    DatePipe,
    AlertsStatusPipe,
    SplitByCapitalLetterPipePipe,
    NgClass,
    NgbDropdown,
    NgbDropdownMenu,
    NgbDropdownToggle,
    AsyncPipe,
    CompanyNameLinkComponent,
    AgTagEntityTitleComponent,
    NgbTooltip,
    SlicePipe,
    InsertLineBreakPipe,
    HasRoleDirective
  ],
  templateUrl: './alert-details.component.html',
  styleUrl: './alert-details.component.scss'
})
export class AlertDetailsComponent implements OnChanges {
  @Input({ required: true }) alert: Alert | undefined;
  @Input() company: Company | undefined;
  @Input() headerHeight = '0px';
  @Output() updateAlert = new EventEmitter<Alert>();
  @Output() close = new EventEmitter<boolean>();

  alertsService = inject(AlertsService);
  #destroyRef: DestroyRef = inject(DestroyRef);
  attachments$: Observable<FileMetadata[]> | undefined;
  private toastr = inject(ToastrService);
  private router = inject(Router);

  observer!: Observer<Alert[]>;

  protected readonly AlertStatus = AlertStatus;
  protected readonly AlertType = AlertType;
  protected readonly ResolvedStatus = ResolvedStatus;
  entityType: EntityType | null = null;
  entity: ITagsEntity | undefined;
  externalEntity: ITagsEntity | undefined;
  loading: boolean = false;

  postponeMenu = [
    { label: 'One day', value: 1 },
    { label: 'One week', value: 7 },
    { label: 'Two weeks', value: 14 },
    { label: 'One month', value: 30 }
  ];

  ngOnChanges(changes: SimpleChanges): void {
    if(changes['alert'].currentValue) {
      if(this.alert?.id) {
        this.observer = observe(this.alert);
        this.attachments$ = this.alertsService.getAttachments(this.alert.id);
        this.entityType = this.alert.entityType;
        this.entity = this.alert.entity as unknown as ITagsEntity | undefined;

        if(this.alert?.status === AlertStatus.New && !this.alert.isReaded) {
          this.alert.isReaded = true;
          this.update(this.alert);
          if(this.router.url === '/alerts/inbox') {
            this.alertsService.notifyReadStatusUpdated();
          }
        }
      }
    }
  }

  postponeAlert(alert: Alert, numberOfDays: number): void {
    alert.status = AlertStatus.Postponed;

    const currentDate = new Date();
    const postponedDate = new Date();
    postponedDate.setDate(currentDate.getDate() + numberOfDays);
    alert.postponedTill = postponedDate;
    this.update(alert);
  }

  ignoreAlert(alert: Alert): void {
    const ignoreAlertConfirmed = confirm("Are you sure you want to ignore the selected alert(s)?");
    if (ignoreAlertConfirmed) {
      alert.status = AlertStatus.Ignored;
      this.update(alert);
    }
  }

  archiveAlert(alert: Alert): void {
    alert.status = AlertStatus.Archived;
    this.update(alert);
  }

  update(alert: Alert): void {
    this.alertsService.updateAlert(alert.id, this.observer).pipe(
      takeUntilDestroyed(this.#destroyRef)
    ).subscribe();

    this.updateAlert.emit(alert);
  }

  closeAlert(): void {
    this.close.emit(true);
  }

  downloadFile(alertId: string, fileName: string): void {
    this.alertsService.getDownload(alertId, fileName)
      .pipe(takeUntilDestroyed(this.#destroyRef))
      .subscribe();
  }

  downloadAllAttachments(): void {
    this.attachments$?.pipe(
      switchMap(attachments => {
        if(attachments.length) {
          const id = this.alert?.id ?? '';
          const downloadFiles = attachments.map(attachment => this.alertsService.getDownload(id, attachment.name));
          return forkJoin(downloadFiles);
        } else {
          return of([]);
        }
      })
    ).subscribe();
  }

  handleAction(alertId: string, actionId: string): void {
    this.loading = true;
    this.alertsService.resolve(alertId, actionId)
      .pipe(
        takeUntilDestroyed(this.#destroyRef),
        tap((updatedAlert) => {
          this.alert = updatedAlert;
          const action = this.alert?.model.actions?.find(a => a.id === actionId);
          this.toastr.success('Sucessfully resolved alert', 'Success');
          if (action && action.actionType === ActionType.AsicForm) {
            void this.router.navigate(['/document', action.actionData]);
          }
        }),
        catchError((error) => {
          this.toastr.error('Failed to resolve alert');
          return EMPTY;
        }),
        finalize(() => this.loading = false)
      )
      .subscribe();
  }
}
