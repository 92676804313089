<div class="auth-signatory" [class.control-like]="controlLike">
  @if (isEdit) {
    <div class="d-flex align-items-start gap-1">
      <div class="flex-grow-1">
        <app-multiple-name-input
          [formControl]="authSignatoriesControl"
          [isDisabled]="authSignatoriesControl.disabled || isLoading"
          [customErrors]="customErrors"
          [tip]="tip"
        ></app-multiple-name-input>
      </div>

      <div class="d-flex gap-1">
        <app-button
          class="save-btn"
          iconClass="ic-circle-check-20 ic-md icon"
          [isDisabled]="isLoading"
          (clickBtn)="save()"
        ></app-button>

        <app-button
          class="close-btn"
          iconClass="ic-circle-cancel-20 ic-md icon"
          btnColor="white"
          [isDisabled]="isLoading"
          (clickBtn)="cancelEditing()"
        ></app-button>
      </div>
    </div>
  } @else {
    <div>
      <div class="label-wrapper">
        <div class="label ">{{ authSignatories }}</div>

        <app-button
          class="edit-btn"
          btnColor="white"
          iconClass="ic-md ic-edit icon"
          [isDisabled]="isLoading"
          (clickBtn)="startEditing()"
        ></app-button>
      </div>

      <app-validation-error [control]="authSignatoriesControl" [customErrors]="customErrors"/>
    </div>
  }
</div>
