<div class="holder d-flex align-items-center">
  <div class="d-flex align-items-center pr-3">
    <i class="icon" [ngClass]="iconClass()"></i>
  </div>
  <div class="w-100 flex-text-ellipsis">
    @for (groupEntry of holderGroups | keyvalue; track groupEntry) {
    <div class="border-bottom-dashed prop">
      @for (holder of groupEntry.value; track holder) {
        @if (holder.$type === CorporateHolderModel.$type) {
          <app-span-with-open-icon
            class="cell-padding pl-0 grid-line-height"
            [route]="(holder | as : CorporateHolderModel).entityId ? ['/company-profile', (holder | as : CorporateHolderModel).entityId!] : []"
          >
            <app-entity-name-number-record
              [name]="holder.name"
              [numberFormatted]="(holder | as : CorporateHolderModel).entityData.entityNumber | acn"
            />
          </app-span-with-open-icon>
        } @else if (holder.$type === IndividualHolderModel.$type) {
          <div class="cell-padding pl-0">
            <app-span-with-open-icon
              class="cell-padding pl-0 grid-line-height"
              [route]="(holder | as : IndividualHolderModel).individualId ? ['/individual', (holder | as : IndividualHolderModel).individualId!] : []"
            >
              <div [class.changes-hidden]="!pendingButtonState">
                @if (pendingButtonState && (holder | as : IndividualHolderModel).individualData.fullName !== (holder | as : IndividualHolderModel).previousIndividualData?.fullName) {
                  <div class="prop previous">{{ (holder | as : IndividualHolderModel).previousIndividualData?.fullName }}</div>
                  <div class="prop current">{{ holder.name| defaultValue }}</div>
                } @else {
                  <div class="prop">{{ holder.name | defaultValue }}</div>
                }
              </div>
            </app-span-with-open-icon>
          </div>
        }
      }
    </div>
    }
  </div>
</div>
 