import { Component, DestroyRef, inject, OnInit } from '@angular/core';
import { ButtonComponent } from "../components/common/button/button.component";
import { DividerComponent } from "../components/common/divider/divider.component";
import { TextareaComponent } from "../components/common/textarea/textarea.component";
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from "@angular/forms";
import { CheckboxComponent } from "../components/common/checkbox/checkbox.component";
import { GridComponent } from "../components/common/grid/components/grid/grid.component";
import { ColDef, GridApi } from "ag-grid-community";
import { ActivatedRoute, Router } from "@angular/router";
import { catchError, of, tap } from "rxjs";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";
import { ListGridComponent } from "../components/common/grid/components/list-grid/list-grid.component";
import {
  AgAnnualSignEditEmailComponent
} from "../components/common/grid/components/annual/ag-annual-sign-edit-email/ag-annual-sign-edit-email.component";
import {
  AgAnnualSignEditPhoneComponent
} from "../components/common/grid/components/annual/ag-annual-sign-edit-phone/ag-annual-sign-edit-phone.component";
import {
  AgDocumentsForSignComponent
} from "../components/common/grid/components/annual/ag-documents-for-sign/ag-documents-for-sign.component";
import { BulkSignatoryGroup, BulkSignColumnEnum, ESignBulkModel, SendBulkEsignModel } from '../../models/signDocuments';
import { ESignService } from '../../services/e-sign.service';
import { ToastrService } from 'ngx-toastr';
import { SigningStatus } from '../../models/enums/annualStatementEnums';
import { AgBulkSignatoryStatusComponent } from '../components/common/grid/components/ag-bulk-signatory-status/ag-bulk-signatory-status.component';
import { SignalRService } from '../../services/signalr.service';

export interface BulkSendDocumentsConfirmControls {
  message: FormControl<string | null>,
  confirm: FormControl<boolean | null>,
}

@Component({
  selector: 'app-bulk-send-documents-for-signing',
  standalone: true,
  imports: [
    ButtonComponent,
    DividerComponent,
    TextareaComponent,
    ReactiveFormsModule,
    CheckboxComponent,
    GridComponent,
    ListGridComponent
  ],
  templateUrl: './bulk-send-documents-for-signing.component.html',
  styleUrl: './bulk-send-documents-for-signing.component.scss'
})
export class BulkSendDocumentsForSigningComponent implements OnInit {
  private route = inject(ActivatedRoute);
  private router = inject(Router);
  private destroyRef = inject(DestroyRef);
  private esignService = inject(ESignService);
  private toastr = inject(ToastrService);
  protected signalRService = inject(SignalRService);

  readonly pageTitle = 'Bulk Send Documents for Signing';
  readonly pageSubTitle = 'Please enter the signatories contact details';
  readonly confirmText = 'I have read and understood the above disclaimer and I confirm that all reasonable steps' +
    ' have been taken to satisfy the requirements of any relevant laws in relation to the of the E-Signing' +
    ' Functionality fir the relevant document and I proceed on this basis.';
  readonly rowsNumber = 4;
  readonly maxMessageLen = 10;
  readonly phoneMinLen = 6;

  readonly defaultPageSize = 20;
  loading = false;
  invalidData = false;
  pageSizeList = [20, 50];
  documentsList: Document[] = [];
  documentsId: string[] = [];
  colDefs: ColDef[] = [];
  gridApi!: GridApi;
  bulkModel!: ESignBulkModel;
  signColumsEnum = BulkSignColumnEnum;
  SigningStatusEnum = SigningStatus;
  sendLoading: boolean = false;
  dataloading: boolean = false;

  form = new FormGroup<BulkSendDocumentsConfirmControls>({
    message: new FormControl<string | null>(null, Validators.maxLength(this.maxMessageLen)),
    confirm: new FormControl<boolean | null>(null, Validators.requiredTrue),
  });

  rows: BulkSignatoryGroup[] = [];

  ngOnInit() {
    this.route.queryParams.pipe(
      tap((params: { selectedids?: string }) => {
        const docs = Array.isArray(params.selectedids)
          ? params.selectedids
          : [params.selectedids];

        this.documentsId = docs.filter((doc): doc is string => !!doc);
      }),
      takeUntilDestroyed(this.destroyRef)
    ).subscribe();

    this.setGridConfig();

    this.getEsginBulkModel();
  }

  getEsginBulkModel() {
    this.dataloading = true;
    this.esignService.getEsignBulkModel(this.documentsId).pipe(
      tap((data) => {
        this.rows = data.signatoryGroups;
        this.bulkModel = data;
        this.showColumnForSentStatus();
        this.dataloading = false;
      }),
      catchError((err) => {
        this.toastr.error("An error occurred.", "Error");
        console.error(err);
        this.dataloading = false;
        return of(null);
      }),
      takeUntilDestroyed(this.destroyRef)
    ).subscribe();
  }

  onGridReady(gridApi: GridApi): void {
    this.gridApi = gridApi;
  }

  get isReadyToSentStatus(): boolean {
    if(this.bulkModel && this.bulkModel?.signingBulkModelStatus === SigningStatus.ReadyToSend) {
      return true;
    }
    return false;
  }

  public setGridConfig(): void {
    this.colDefs = [
      {
        headerName: 'Name',
        field: 'name',
        flex: 1,
        menuTabs: [],
      },
      {
        headerName: 'Email',
        field: 'email',
        flex: 1,
        cellRenderer: AgAnnualSignEditEmailComponent,
        cellRendererParams: {
          edit: this.edit.bind(this),
        },
        menuTabs: [],
      },
      {
        headerName: 'Phone',
        field: 'phone',
        flex: 1,
        cellRenderer: AgAnnualSignEditPhoneComponent,
        cellRendererParams: {
          edit: this.edit.bind(this),
        },
        menuTabs: [],
      },
      {
        headerName: 'Status',
        field: 'signatoryStatus',
        width: 180,
        flex: 1,
        hide: true,
        editable: false,
        cellRenderer: AgBulkSignatoryStatusComponent,
        menuTabs: [],
      },
      {
        headerName: 'Documents For Signing',
        field: 'filesInfo',
        flex: 2,
        editable: false,
        cellRenderer: AgDocumentsForSignComponent,
        menuTabs: [],
      },
    ];
  }

  showColumnForSentStatus() {
    if(this.bulkModel.signingBulkModelStatus !== SigningStatus.ReadyToSend) {
      this.colDefs[this.signColumsEnum.Status].hide = false;
      this.colDefs[this.signColumsEnum.Email].cellRenderer = null;
      this.colDefs[this.signColumsEnum.Phone].cellRenderer = null;
      this.colDefs = [...this.colDefs];
    }
  }

  public listenSignalRMessages(): void {
    this.signalRService
      .onDocumentSigningStatusChanged()
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe({
        next: (message) => {
          if (this.shouldRefreshSigningInfo(message)) {
            console.log('Refreshing signing info for message:', message);
            this.refreshSigningInfo();
          }
        },
        error: (err) => {
          console.error('Error handling SignalR message:', err);
        },
      });
  }

  refreshSigningInfo(): void {
    if (!this.bulkModel) {
      return;
    }
  
    this.getEsginBulkModel();
  }

  private shouldRefreshSigningInfo(message: string): boolean {
    return (
      this.documentsId.some(x => x == message) &&
      this.bulkModel?.signingBulkModelStatus !== SigningStatus.ReadyToSend
    );
  }

  edit(data: { value: string, index: number; column: string }): void {
    let emailIsRequired = false;
    let invalidPhone = false;

    if(data.column === 'email') {
      this.rows[data.index].email = data.value;
      emailIsRequired = !this.rows?.every(item => item.email);
    } else if(data.column === 'phone') {
      this.rows[data.index].phone = data.value;
      invalidPhone = this.rows.filter(row => row.phone)?.some(item => item.phone.length < this.phoneMinLen);
    }

    this.invalidData = emailIsRequired || invalidPhone;
  }

  backToDocuments(): void {
    void this.router.navigate(['/documents']);
  }

  bulkSendForSign(): void {
    this.sendLoading = true;
    let esignBody: SendBulkEsignModel ={
      documentsId: this.documentsId,
      signatoryGroups: this.rows,
      emailMessage: this.form.value.message ?? ''
    }

    this.esignService.sendToEsignBulk(esignBody).pipe(
      tap((data) => {
        this.toastr.success("Documents sent for signing.", "Success");
        this.bulkModel = data;
        this.showColumnForSentStatus();
        this.sendLoading = false;
      }),
      catchError((err) => {
        this.toastr.error("Error happen.", "Error");
        console.error(err);
        this.sendLoading = false;
        return of('ERR');
      }),
      takeUntilDestroyed(this.destroyRef)
    ).subscribe();
  }
}
