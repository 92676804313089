import { Component, DestroyRef, Input, Optional } from '@angular/core';
import { FormsModule, NgControl } from "@angular/forms";
import { ValidationErrorComponent } from "../validation-error/validation-error.component";
import { InputNameDirective } from "../../../../directives/input-name.directive";
import { AuxiliaryService } from "../../../../services/auxiliary.service";
import { IndividualData } from '../../../../models/individualData';
import { parseFullName } from '../../../../functions/parse-fullname';

@Component({
  selector: 'app-multiple-name-input',
  standalone: true,
  imports: [
    FormsModule,
    ValidationErrorComponent,
    InputNameDirective
  ],
  templateUrl: './multiple-input-name.component.html',
  styleUrl: './multiple-input-name.component.scss'
})
export class MultipleInputNameComponent {
  @Input() label = '';
  @Input() tip = '';
  @Input() placeholder = '';
  @Input() isDisabled = false;
  @Input() shouldShowErrors = true;
  @Input() customErrors: Record<string, string> = {};
  @Input() maxInputTags = 10;

  inputValue = '';
  tags: string[] = [];
  focused = false;
  #value: IndividualData[] = [];
  loading = false;

  constructor(
    @Optional() protected ngControl: NgControl,
    private auxiliaryService: AuxiliaryService,
    private destroyRef: DestroyRef
  ) {
    if (ngControl) {
      ngControl.valueAccessor = this;
    }
  }

  get value(): IndividualData[] {
    return this.#value;
  }

  set value(value: IndividualData[]) {
    this.#value = value ?? [];
    this.onChange(this.#value);
  }

  get displayValidationError(): boolean {
    return !!(this.ngControl && this.ngControl.touched && this.ngControl.errors);
  }

  onChange: (value: IndividualData[]) => void = (): void => {};
  onTouched: () => void = (): void => {};

  writeValue(value: IndividualData[]): void {
    this.value = value;
  }

  registerOnChange(fn: () => void): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  onBlur(): void {
    if(this.inputValue) {
      this.addTag();
    }

    this.focused = false;
    this.onTouched();
  }

  onSubmit(event: Event): void {
    event.preventDefault();
    event.stopPropagation();
    if (this.inputValue) {
      this.addTag();
    }
  }

  onFocus(): void {
    this.focused = true;
  }

  onKeyDown(event: KeyboardEvent): void {
    const key = event.key;

    if (key === ';') {
      event.preventDefault();
      this.addTag();
    }
  }

  addTag(): void {
    if(this.value.length > this.maxInputTags) {
      return;
    }

    const fullName = this.inputValue.trim();
    const parsed = parseFullName(fullName);

    if (!parsed.firstName || !parsed.lastName) {
      return;
    }

    this.#value.push(new IndividualData(parsed));
    this.resetInputValue();
  }

  private resetInputValue(): void {
    this.inputValue = '';
    this.onChange(this.#value);
  }

  removeTag(index: number): void {
    this.#value.splice(index, 1);
    this.onChange(this.#value);
  }
}
