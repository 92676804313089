import { Component, DestroyRef, EventEmitter, inject, Input, Output } from '@angular/core';
import { RadioComponent } from "../../components/common/radio/radio.component";
import { FormBuilder, FormGroup, FormsModule, ReactiveFormsModule, Validators } from "@angular/forms";
import { ColDef, GridApi } from "ag-grid-community";
import { ChangeAuthorisation } from "../../../models/changeAuthorisation";
import { SelectOption } from "../../../models/selectOptions";
import {
  DocumentationType,
  MeetingLocationType,
  MeetingOnlineType, MinutesMeetingPresence,
} from "../../../models/enums/documentConfirmEnums";
import { SelectComponent } from "../../components/common/select/select.component";
import { ListGridComponent } from "../../components/common/grid/components/list-grid/list-grid.component";
import { AnnualStatement } from "../../../models/annualStatement";
import { Attendee } from "../../../models/generateDocuments";
import { Relationship } from "../../../models/relationship";
import {
  AgDirectorMeetingStatusComponent
} from "../../components/common/grid/components/ag-director-meeting-status/ag-director-meeting-status.component";
import { RelationshipType } from "../../../models/enums/relationshipTypeEnum";
import { Company } from "../../../models/company";
import { AddressControlComponent } from "../../components/common/address-control/address-control.component";
import { AddressStatus } from "../../../models/enums/addressEnums";
import { DatePickerComponent } from "../../components/common/date-picker/date-picker.component";
import { NgbDateStruct } from "@ng-bootstrap/ng-bootstrap";
import { DatepickerHelper } from "../../../custom-form-validators/date-picker-form-validators";
import { autocompleteServiceToken } from "../../../services/autocomplete.service";
import { AuxiliaryService } from "../../../services/auxiliary.service";
import { AnnualStatementsService } from "../../../services/annual-statements.service";
import { debounceTime, filter, Subject } from "rxjs";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";
import { observe, Observer } from "fast-json-patch";
import { formatDate } from "@angular/common";
import { deepClone } from "fast-json-patch/commonjs/core";
import { Address } from "../../../models/address";

@Component({
  selector: 'app-annual-statement-authorization',
  standalone: true,
  imports: [
    RadioComponent,
    FormsModule,
    ReactiveFormsModule,
    SelectComponent,
    ListGridComponent,
    AddressControlComponent,
    DatePickerComponent
  ],
  providers: [{provide: autocompleteServiceToken, useClass: AuxiliaryService}],
  templateUrl: './annual-statement-authorization.component.html',
  styleUrl: './annual-statement-authorization.component.scss'
})
export class AnnualStatementAuthorizationComponent {
  @Input() annual: AnnualStatement | undefined;
  @Input() company!: Company;
  @Input() partnerManagerOptions: SelectOption[] = [];
  @Input() confirmAction$: Subject<boolean> | undefined;
  @Output() nextStep = new EventEmitter<boolean>();

  private annualStatementsService = inject(AnnualStatementsService);
  private fb: FormBuilder = inject(FormBuilder);
  private destroyRef: DestroyRef = inject(DestroyRef);

  form!: FormGroup;
  gridApi!: GridApi;
  colDefs: ColDef[] = [];

  formModel!: ChangeAuthorisation;
  expandedAddressForm = false;
  chairOptions: SelectOption[] = [];
  individualsList: Relationship[] = [];
  attendeesList: Attendee[] = [];
  addressStatus: AddressStatus | undefined;

  minDateOfMeeting: NgbDateStruct = DatepickerHelper.getDefaultMinDateStruct();
  maxDateOfMeeting: NgbDateStruct = DatepickerHelper.getNextNMonthStruct(2);

  DocumentationType = DocumentationType;
  documentationType: DocumentationType | undefined;
  protected readonly MeetingLocationTypeEnum = MeetingLocationType;

  documentTypeOptions: SelectOption[] = [
    { label: 'Minutes', value: DocumentationType.Minutes, disabled: false },
    { label: 'Resolution', value: DocumentationType.Resolution, disabled: false },
  ];

  meetingTypeOptions = [
    { label: 'Meeting Address', value: MeetingLocationType.Registered },
    { label: 'Virtual Meeting', value: MeetingLocationType.Online },
  ];

  meetingOnlineTypeOptions: SelectOption[] = [
    { label: 'Microsoft Teams', value: MeetingOnlineType.MicrosoftTeams },
    { label: 'Zoom', value: MeetingOnlineType.Zoom },
    { label: 'Google Meet', value: MeetingOnlineType.GoogleMeet }
  ];

  observer!: Observer<AnnualStatement>;

  ngOnInit(): void {
    const annualCreateOnStr = this.annual?.createdOn ? this.annual.createdOn.toString() : '';
    this.individualsList = this.company?.officers ?? [];
    this.documentationType = this.annual?.changeAuthorisation?.documentationType ?? DocumentationType.Minutes;

    this.initForm();
    this.setFormSelectOptions();
    this.setGridConfig();
    this.listenHeaderButton();
    this.listenPartnerManager();

    if(this.annual) {
      this.observer = observe(this.annual);
    }
  }

  initForm(): void {
    this.formModel = deepClone(this.annual?.changeAuthorisation) as ChangeAuthorisation ?? new ChangeAuthorisation();
    if(!this.formModel.authorisingParty) {
      this.formModel.authorisingParty = new Relationship();
    }

    if(this.formModel.meetingAddressType === null || this.formModel.meetingAddressType === undefined) {
      this.formModel.meetingAddressType = MeetingLocationType.Registered;
    }

    if(!this.formModel.meetingDate) {
      this.formModel.meetingDate = new Date();
    }

    let partnerManagerId = '';
    if(this.formModel.partnerManagerId) {
      partnerManagerId = this.formModel.partnerManagerId;
    } else {
      const partnerManager = this.partnerManagerOptions
        ?.find(o => o.value === this.annual?.company.partnerManagerId);
      partnerManagerId = partnerManager?.value as string ?? '';
    }

    this.form = this.fb.group({
      address: [this.formModel.meetingAddress, Validators.required],
      partnerManager: [partnerManagerId, Validators.required]
    });
  }

  setFormSelectOptions(): void {
    this.attendeesList = this.formModel?.attendees?.map(attendee => ({
      officer: new Relationship({ individualDataOverride: attendee.individual }),
      presence: attendee.presence,
      checked: false
    })) as Attendee[];

    this.chairOptions = this.getOfficers(false, false)?.map(chair => {
      const type = chair.type;
      const label = chair.individualDataOverride?.fullName + ` (${RelationshipType[type]})`;
      return ({ label, value: chair.relationshipId });
    }).sort((a, b) => a.label.localeCompare(b.label));

    if(!this.formModel.chairperson?.relationshipId && this.formModel.chairperson && this.chairOptions.length) {
      this.formModel.chairperson.relationshipId = this.chairOptions[0].value as string;
    }

    if(!this.formModel.documentationType) {
      if(this.chairOptions.length <= 1) {
        this.documentTypeOptions.forEach(o => {
          if(o.value === DocumentationType.Minutes) {
            o.disabled = true;
            this.formModel.documentationType = DocumentationType.Resolution;
          }
        });

        this.documentTypeOptions = [...this.documentTypeOptions];
      } else {
        this.formModel.documentationType = DocumentationType.Minutes;
      }
    }
  }

  onGridReady(gridApi: GridApi): void {
    this.gridApi = gridApi;
  }

  setGridConfig(): void {
    this.colDefs = [
      {
        headerName: 'Directors who attended the meeting',
        field: 'officer.individualDataOverride.fullName',
        width: 296,
        flex: 2,
        editable: false,
        sort: 'asc',
        menuTabs: ['generalMenuTab'],
      },
      {
        headerName: 'Status',
        field: 'presence',
        width: 80,
        flex: 0.7,
        sortable: false,
        cellRenderer: AgDirectorMeetingStatusComponent,
        cellRendererParams: {
          onSelectDirectorMeetingStatus: this.onSelectDirectorMeetingStatus.bind(this),
        },
        cellClass: 'actions-button-cell',
        suppressHeaderMenuButton: true,
      },
    ];
  }

  listenHeaderButton(): void {
    this.confirmAction$?.pipe(
      filter(confirm => confirm),
      takeUntilDestroyed(this.destroyRef)
    ).subscribe(() => {
      void this.confirm();
      this.confirmAction$?.next(false);
    });
  }

  listenPartnerManager(): void {
    this.form.get('partnerManager')?.valueChanges.pipe(
      debounceTime(200),
      takeUntilDestroyed(this.destroyRef)
    ).subscribe(() => {
      this.setDisableHeaderBtn();
    });
  }

  confirm(): void {
    if (!this.validateForm()) {
      return;
    }

    if(this.annual?.changeAuthorisation) {
      this.annual.changeAuthorisation = { ...this.formModel };
      this.annual.changeAuthorisation.meetingAddress = this.form.get('address')?.value as Address;
      this.annual.changeAuthorisation.partnerManagerId = this.form.get('partnerManager')?.value as string;
    }

    this.nextStep.emit(true);
  }

  onSelectDirectorMeetingStatus(attendee: Attendee): void {
    this.attendeesList.forEach((item, index) => {
      if(this.formModel.attendees) {
        this.formModel.attendees[index].presence = item.presence;
      }
    });

    this.setDisableHeaderBtn();
  }

  onDocumentationTypeChange(): void {
    this.setDisableHeaderBtn();
  }

  onChairPersonSelect(chairPersonId: string): void {
    const chair = this.getOfficers(false, false)
      .find(o => o.relationshipId === chairPersonId);

    if(chair && this.formModel.chairperson) {
      this.formModel.chairperson.individualDataOverride = chair.individualDataOverride;
    }

    if (!this.formModel?.authorisingParty?.relationshipId) {
      this.formModel.authorisingParty.relationshipId = chairPersonId;
    }

    this.setDisableHeaderBtn();
  }

  onMeetingDateChange(meetingDate: Date | NgbDateStruct | string): void {
    if(meetingDate) {
      const date = meetingDate instanceof Date ? meetingDate : typeof meetingDate === 'string' ? new Date(meetingDate) : DatepickerHelper.getDateFromStruct(meetingDate);
      const serverDateFormat = formatDate(date, 'YYYY-MM-dd', 'en-US');
      if(this.annual?.changeAuthorisation) {
        this.annual.changeAuthorisation.meetingDate = serverDateFormat as unknown as Date;
      }
    }

    this.setDisableHeaderBtn();
  }

  setDisableHeaderBtn(): void {
    this.annualStatementsService.disabledHeaderBtn.set(!this.validateForm());
  }

  onDataStatusChange(addressStatus: AddressStatus | undefined): void {
    this.addressStatus = addressStatus;
    if(addressStatus === AddressStatus.WARNING) {
      this.annualStatementsService.disabledHeaderBtn.set(true);
    } else {
      this.annualStatementsService.disabledHeaderBtn.set(!this.validateForm());
    }
  }

  private validateForm(): boolean {
    const {
      attendees,
      documentationType,
      chairperson,
      meetingAddressType,
      meetingDate,
      meetingOnlineAddress,
      meetingAddress,
    } = this.formModel;

    if (documentationType === DocumentationType.Minutes) {
      const isChairpersonValid = !!chairperson?.relationshipId;
      const isMeetingDateValid = !!meetingDate && meetingDate >= DatepickerHelper.getDateFromStruct(this.minDateOfMeeting);
      const isMeetingAddressValid = meetingAddress !== null && meetingAddress !== undefined;
      const isMeetingOnlineAddressValid = meetingOnlineAddress !== null && meetingOnlineAddress !== undefined;
      const isSomeAttendeesPresent = !!attendees?.some(a => a.presence !== MinutesMeetingPresence.Apology);
      const isPartnerManagerValid = this.form.get('partnerManager')?.valid as boolean;

      if(meetingAddressType === MeetingLocationType.Registered) {
        return isChairpersonValid && isMeetingDateValid && isMeetingAddressValid && isSomeAttendeesPresent && isPartnerManagerValid;
      } else if(meetingAddressType === MeetingLocationType.Online) {
        return isChairpersonValid && isMeetingDateValid && isMeetingOnlineAddressValid && isSomeAttendeesPresent && isPartnerManagerValid;
      }
    }

    return documentationType === DocumentationType.Resolution;
  }


  getOfficers(includeALt = true, icludeSec = true) {
    let officersSelected: Relationship[] = [];
    const includedTypes: RelationshipType[] = [RelationshipType.Director];

    if (includeALt) {
      includedTypes.push(RelationshipType.AlternativeDirector);
    }
    if (icludeSec) {
      includedTypes.push(RelationshipType.Secretary);
    }

    officersSelected = this.individualsList.filter(
      x => x.type != undefined && includedTypes.includes(x.type)
    );

    return officersSelected;
  }
}
