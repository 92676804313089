<app-stepper-form
  header="Change of Company Name"
  subheader="Form 205A"
  [steps]="stepsSignal()"
  [currentStep]="currentStep"
  [currentStepIndex]="currentStepIndex"
  [isLoading]="isLoading"
  [form]="stepperForm"
>
  <div class="step-content" [formGroup]="stepperForm">
    <app-stepper-form-description
      formKey="205A"
      [hidden]="currentStep !== StepsEnum.FormDescription"
    ></app-stepper-form-description>

    <section
      class="step-section"
      [hidden]="currentStep !== StepsEnum.NewCompanyName"
      [formGroupName]="StepsEnum.NewCompanyName"
    >
      <h1 class="h1-heading">{{ stepsSignal()[currentStepIndex].label }}</h1>

      <div class="form-group">
        <!--  Q1  -->
        <app-yes-no-control
          label="Use ACN as proposed company name?"
          formControlName="useAcnAsNewCompanyName"
        ></app-yes-no-control>
      </div>

      <div formGroupName="companyData">

        <div class="company-name-group">
          <!--  Q2  -->
          <div
            class="form-group new-company-name-wrapper"
            [hidden]="newCompanyNameForm.value.useAcnAsNewCompanyName !== false"
          >
            <app-input
              label="New Company Name"
              formControlName="newCompanyName"
            ></app-input>
          </div>

          <div
            class="form-group"
            [hidden]="newCompanyNameForm.value.useAcnAsNewCompanyName !== true"
          >
            <app-select
              label="Legal Element"
              formControlName="newLegalElementsIfAcnIsUsed"
              [options]="legalElementsOptions"
              [valuePrimitive]="true"
            ></app-select>
          </div>
        </div>

        <div [hidden]="newCompanyNameForm.value.useAcnAsNewCompanyName !== false">
          <div class="form-group">
            <!--  Q3  -->
            <app-yes-no-control
              label="Has a name reservation been lodged to reserve the proposed new company name?"
              formControlName="newNameReservedWithForm410"
            ></app-yes-no-control>
          </div>

          <div class="form-group" [hidden]="companyDataForm.value.newNameReservedWithForm410 !== true">
            <app-input
              label="Reservation Number"
              formControlName="reservationNumber"
              [customErrors]="reservationNumberCustomErrors"
            ></app-input>
          </div>

          <div class="form-group">
            <!--  Q4  -->
            <app-yes-no-control
              label="Is the proposed name identical to a registered business name(s)?"
              formControlName="isProposedNameIdenticalToRegisteredBusinessName"
            ></app-yes-no-control>
          </div>

          <div [hidden]="companyDataForm.value.isProposedNameIdenticalToRegisteredBusinessName !== true">
            <div class="form-group">
              <app-checkbox
                [label]="abnDeclarationMessage"
                [formControl]="companyDataForm.controls.abnConfirmation"
              />

              @if (companyDataForm.controls.abnConfirmation.touched && companyDataForm.controls.abnConfirmation.errors) {
                <app-validation-error [control]="companyDataForm.controls.abnConfirmation"/>
              }
            </div>

            <div class="form-group">
              <app-abn-masked-input
                formControlName="businessNameHolderAbn"
              ></app-abn-masked-input>
            </div>

            <div class="form-group">
              <!--  Q5  -->
              <app-yes-no-control
                label="Would you like ASIC to manually review the name change?"
                formControlName="manualReviewRequested"
              ></app-yes-no-control>
            </div>

            <div class="form-group" [hidden]="newCompanyNameForm.value.companyData?.manualReviewRequested !== true">
              <app-textarea
                label="Manual Processing Reason"
                formControlName="manualProcessingReason"
              ></app-textarea>
            </div>
          </div>
        </div>
      </div>

      <div class="form-group">
        <app-radio
          label="Payment Method"
          formControlName="paymentMethod"
          [options]="availableBillingOptions"
        ></app-radio>
      </div>
    </section>

    <section
      class="step-section"
      [hidden]="currentStep !== StepsEnum.Resolution"
      [formGroupName]="StepsEnum.Resolution"
    >
      <h1 class="h1-heading">{{ stepsSignal()[currentStepIndex].label }}</h1>

      <div class="form-group">
        <app-date-picker
          label="Resolution Date"
          formControlName="changeDate"
          [minDate]="minDate"
          [maxDate]="maxDate"
          [customErrors]="customDatepickerErrors"
        ></app-date-picker>
      </div>

      <div class="form-group">
        <app-textarea
          label="Special Resolution"
          formControlName="resolutionText"
        ></app-textarea>
      </div>
    </section>

    <app-authorised-signatories-step
      [hidden]="currentStep !== StepsEnum.AuthorisedSignatories"
      [title]="stepsSignal()[currentStepIndex].label"
      [formGroup]="authorisedSignatoriesForm"
      [securityRegistryRecords]="securityRegistryRecords"
    />

    <section
      class="step-section"
      [hidden]="currentStep !== StepsEnum.BeneficialOwnersDetails"
    >
      <h1 class="h1-heading">{{ stepsSignal()[currentStepIndex].label }}</h1>
      <nbo-beneficial-owners-group
        [hidden]="!(holders && holders.length)"
        [holders]="holders"
        [formGroup]="stepperForm.controls[StepsEnum.BeneficialOwnersDetails]"
        (dataSaved)="updateSignValue($event.index, $event.newBOName)"
      ></nbo-beneficial-owners-group>
    </section>
  </div>
</app-stepper-form>
