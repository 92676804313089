<div class="card">
  <div class="card-header">
    <div class="d-flex align-items-center justify-content-between">
      <div class="d-flex align-items-center">
        <i class="ic-annual-statement-icon ic-md icon"></i>
        <div class="d-flex flex-column ml-3">
          <div class="title">Annual Statements</div>
          <div class="subtitle">{{pageTitle}}</div>
        </div>
      </div>

      <div *appHasRole="['Admin', 'Manager', 'Preparer']">
        @if (checkedAnnualStatements.length) {
          <div ngbDropdown [placement]="['bottom-end', 'bottom-start', 'top-start', 'top-end']" class="dropdown">
            <app-button
              ngbDropdownToggle
              id="bulk-actions-dd"
              style="width: 97px"
              [text]="'Actions'"
              [iconLast]="true"
              [iconClass]="'icon ic-xs ic-select-icon'"
              [isDisabled]="modalOpened()"
            ></app-button>
            <div ngbDropdownMenu aria-labelledby="bulk-actions-dd" class="dropdown-menu dropdown-menu-right">
              @for (item of getMainMenuAction(pageFilter); track $index) {
                <div class="dropdown-item" (click)="item.action()">
                  <a class="dropdown-button">
                    <span>{{item.name}}</span>
                  </a>
                </div>
              }
            </div>
          </div>
        } @else {
          <app-button
            style="width: 97px"
            [text]="'Export'"
            [iconClass]="'icon ic-xs ic-download-file'"
            [isDisabled]="!annualRows.length || modalOpened()"
            (clickBtn)="exportAnnualList(false, ExportTypeEnum.EXCEL)"
          ></app-button>
        }
      </div>
    </div>
  </div>

  <app-grid
    storageKey="annual-not-received"
    [defaultExcelExportParams]="excelExportParams"
    [excelStyles]="excelStyles"
    [colDefs]="$any(colDefs)"
    [rowData]="[]"
    [pinFirstColumnName]="'companyName'"
    [totalRows]="totalRows"
    [gridOptions]="gridOptions"
    [pageTitle]="pageTitle"
    [suppressRowClickSelection]="true"
    [rowModelType]="serverRowModelType"
    [getContextMenuItems]="$any(getContextMenuItems)"
    [serverSideDatasource]="dataSource"
    [pagination]="true"
    [paginationPageSize]="defaultPageSize"
    [cacheBlockSize]="cacheBlockSize"
    (gridReady)="onGridReady($event)"
    (itemSelect)="selectAnnualStatements($event)"
    (search)="onSearch($event)"
  ></app-grid>
</div>
